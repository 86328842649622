import React, { useEffect, useCallback, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import styles from "./styling.module.css";
import axios from "axios";
import Loader from "react-loader-spinner";
import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { submitFeedback } from "../hooks/submitFeedback";
import "./feedback.css";
import { getAllJobs } from "../../Profile/MyAssessments/Assessments-main/hooks/getAlljobs";
import { useDispatch, useSelector } from "react-redux";
import { setCandidateDetails } from "../../../redux/reducers/CandidateDetails/CandidateDetailsSlice";
import Scrollbars from "react-custom-scrollbars";
import { GoArrowRight } from "react-icons/go";
import useOutsideClick from "../../../Components/OutsideClick/OutsideClick";
import {
  gender,
  candidate_statuses,
  highestEducation,
  ethnicity,
  language,
  experience_years,
} from "../../../data/mapData";
import { IoAddCircle } from "react-icons/io5";
import { updateCandidateData } from "../../../redux/reducers/CandidateDetails/CandidateLoginDetailsSlice";
import { updateCandidateMeta } from "../hooks/updateCandidateMeta";
import https from "../../../https";

const Feedback = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const textareaRef = useRef(null);
  const [clicked, setClicked] = useState([true, true, true, true, true]);
  const [feedback, setFeedback] = useState("");
  const educationref = useRef(null);
  const fieldRef = useRef(null);
  const genderRef = useRef(null);
  const countryRef = useRef(null);
  const ethRef = useRef(null);
  const langRef = useRef(null);
  const queryClient = useQueryClient();
  const theme = JSON?.parse(localStorage.getItem("theme"));
  const [isHovered, setIsHovered] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchCountry, setsearchCountry] = useState("");
  const [scrollbarPosition, setScrollbarPosition] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const candidateRef = useRef(null);
  const [years, setYears] = useState([]);
  const yearRef = useRef(null);
  const listInnerRef = useRef(null);
  const isrecruiter = localStorage.getItem("CP-CANDIDATE-Role");
  const [paginationInfo, setPaginationInfo] = useState({
    currentTake: 1,
    hasNextPage: true,
    pageSize: 10,
  });
  const [allData, setAllData] = useState([]);
  const expRef = useRef(null);
  const candidateDetails = useSelector(
    (state) => state.candidateLoginDetails.candidateData
  );
  const user_exists = localStorage.getItem("user_exists");

  console.log(candidateDetails);
  //#region useStates for dropdowns
  //education
  const [educationLevel, setEducationLevel] = useState("");
  const [educationDrop, setEducationDrop] = useState(false);
  const [otherEducation, setOtherEducation] = useState("");

  //study
  const [study, setStudy] = useState("");

  //field
  const [field, setField] = useState("");
  const [fieldDrop, setFieldDrop] = useState(false);

  //experience years
  const [yearexp, setyearexp] = useState("");

  //gender
  const [gen, setGender] = useState("");
  const [gendrop, setGendrop] = useState(false);
  const [otherGender, setOtherGender] = useState("");

  //born year
  const [born, setBorn] = useState("");

  //country Dropdown
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [countryDrop, setCountryDrop] = useState(false);

  //ethnicity
  const [eth, setEth] = useState("");
  const [ethDrop, setEthDrop] = useState(false);

  //Language
  const [lang, setLang] = useState("");
  const [langDrop, setLangDrop] = useState(false);
  const [otherLanguage, setOtherLanguage] = useState("");

  //candidate status
  const [candidate, setCandidate] = useState("");
  const [candidateDropdown, setCandidateDropdown] = useState(false);
  const [otherValue, setOtherValue] = useState("");

  //year
  const [selectedYear, setSelectedYear] = useState("");
  const [yearDropdown, setYearDropdown] = useState(false);

  //year
  const [selectedYearExp, setYearExp] = useState("");
  const [expDropdown, setExpDropdown] = useState(false);
  //#endregion

  //#region Fetching Countries from API
  useEffect(() => {
    const getCountry = async () => {
      try {
        const response = await https.get("/countries?limit=249");
        let countriesData = Object.values(response.data.data).map((country) => {
          // Keep "Western Sahara" as is, otherwise remove parenthetical content
          if (country.country === "Western Sahara*") {
            return "Western Sahara";
          } else {
            return country.country.replace(/\s*\(.*?\)\s*/g, "");
          }
        });

        // Manually add the desired countries at the top of the list
        const specialCountries = [
          "Global",
          "Saudi Arabia",
          "United Kingdom",
          "United States of America",
        ];

        // Remove the added countries from the original list
        countriesData = countriesData.filter(
          (country) => !specialCountries.includes(country)
        );

        // Add the countries in the desired order
        const finalCountries = [
          ...specialCountries,
          ...countriesData.sort((a, b) => a.localeCompare(b)),
        ];

        // Set the state with the modified list
        setCountries(finalCountries);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    getCountry();
  }, []);

  //#endregion

  //#region Handling stars
  const handleStarClick = (e, index) => {
    e.preventDefault();
    let clickStates = [...clicked];
    for (let i = 0; i < 5; i++) {
      if (i <= index) clickStates[i] = true;
      else clickStates[i] = false;
    }
    setClicked(clickStates);
  };
  //#endregion

  //#region Submit feedback
  const { mutate, isLoading, error } = useMutation(submitFeedback, {
    onSuccess: (data) => {
      // Access the response data here
      queryClient.invalidateQueries("/review");
      dispatch(setCandidateDetails(data?.candidateDetails));
      dispatch(
        updateCandidateData({
          educationLevel: educationLevel,
          study: study,
          relevant_experience: field,
          years_experience: yearexp,
          candidate_status: candidate,
          first_lang: lang,
        })
      );
      localStorage.setItem("test_completed", "");
      updateUserMeta();
    },
  });
  //#endregion

  //#region handle back browser
  useEffect(() => {
    window.dispatchEvent(new CustomEvent("navigationhandler"));
  }, []);
  //#endregion

  //#region submit feedback
  const handleFeedbackSubmit = () => {
    let data = JSON.stringify({
      reviewerId: parseInt(localStorage.getItem("CP-CANDIDATE-ID")),
      assessment: parseInt(localStorage.getItem("CANDIDATE-ASSESSMENT-ID")),
      rating: clicked.filter((i) => i === true).length,
      feedback: feedback,
      educationLevel:
        educationLevel !== "Other (Please specify)"
          ? educationLevel
          : otherEducation,
      study: study,
      releventExperience: field,
      yearOfExperience: yearexp,
      gender: gen !== "Other (Please specify)" ? gen : otherGender,
      yearOfBirth: selectedYear,
      country: selectedCountry,
      ethnicity: eth,
      firstLanguage: lang !== "Other" ? lang : otherLanguage,
      employmentStatus:
        candidate !== "Other (Please specify)" ? candidate : otherValue,
    });
    try {
      mutate(data);
    } catch (err) {
      //catching error
    }
  };
  //#endregion

  //#region Function to darken color
  function darkenHexColor(hex, percent) {
    // Ensure the hex code is in the correct format
    hex = hex?.replace(/^#/, "");

    // Convert 3-digit hex to 6-digit hex
    if (hex?.length === 3) {
      hex = hex
        .split("")
        .map((char) => char + char)
        .join("");
    }

    // Convert hex to RGB
    let r = parseInt(hex?.substring(0, 2), 16);
    let g = parseInt(hex?.substring(2, 4), 16);
    let b = parseInt(hex?.substring(4, 6), 16);

    // Calculate the darkened color
    r = Math.floor(r * (1 - percent / 100));
    g = Math.floor(g * (1 - percent / 100));
    b = Math.floor(b * (1 - percent / 100));

    // Convert RGB back to hex
    const darkenedHex = `#${r.toString(16).padStart(2, "0")}${g
      .toString(16)
      .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;

    return darkenedHex;
  }
  const originalColor = theme.color;
  const darkenedColor = darkenHexColor(originalColor, 15);
  //#endregion

  //#region handling button styling
  const buttonStyle = {
    background: isHovered ? darkenedColor : theme.color,
    transition: "background-color 0.3s, transform 0.6s",
    transform: isHovered ? "scale(1.02)" : "scale(1)",
    color: theme.sec_color,
    border: `1px solid ${"#252E3A"}`,
    fontFamily: "Archia Semibold",
  };

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };
  //#endregion

  //#region filtering experiences and countries
  const filteredExperiences = jobs?.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredCountries = countries.filter((item) =>
    item.toLowerCase().includes(searchCountry.toLowerCase())
  );
  //#endregion

  //#region generate years from 1930 to 2000
  useEffect(() => {
    const generateYears = (startYear = 1930, endYear = 2008) => {
      let yearsArray = [];
      for (let year = endYear; year >= startYear; year--) {
        yearsArray.push(year.toString());
      }
      return yearsArray;
    };

    const yearsGenerated = generateYears();
    setYears(yearsGenerated);
  }, []);
  //#endregion

  //#region handle outside clicks
  useOutsideClick(
    [
      {
        ref: educationref,
        excludeClasses: [".edu"],
        excludeIds: ["education", "education2"],
      },
      {
        ref: fieldRef,
        excludeClasses: [".fieldclass"],
        excludeIds: ["field"],
      },
      {
        ref: genderRef,
        excludeClasses: [".genderClass"],
        excludeIds: ["gender"],
      },
      {
        ref: countryRef,
        excludeClasses: [".countryClass"],
        excludeIds: ["country"],
      },
      {
        ref: ethRef,
        excludeClasses: [".ethClass"],
        excludeIds: ["ethnicity"],
      },
      {
        ref: candidateRef,
        excludeClasses: [".candidateClass"],
        excludeIds: ["candidate"],
      },
      {
        ref: langRef,
        excludeClasses: [".langClass"],
        excludeIds: ["language"],
      },
      {
        ref: yearRef,
        excludeClasses: [".yearClass"],
        excludeIds: ["year"],
      },
      {
        ref: expRef,
        excludeClasses: [".expClass"],
        excludeIds: ["expYear"],
      },
    ],
    (ref) => {
      if (ref === educationref) setEducationDrop(false);
      if (ref === fieldRef) setFieldDrop(false);
      if (ref === genderRef) setGendrop(false);
      if (ref === countryRef) setCountryDrop(false);
      if (ref === ethRef) setEthDrop(false);
      if (ref === candidateRef) setCandidateDropdown(false);
      if (ref === langRef) setLangDrop(false);
      if (ref === yearRef) setYearDropdown(false);
      if (ref === expRef) setExpDropdown(false);
    }
  );
  //#endregion

  //#region handle pagination of jobs
  const { data: jobsData, isLoading: jobsLoading } = useQuery(
    ["jobs", paginationInfo.currentTake, field],
    () => getAllJobs(paginationInfo.currentTake, field),
    {
      keepPreviousData: true,
    }
  );

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight && !jobsLoading) {
        if (
          jobsData?.meta?.hasNextPage !== false &&
          allData.length >= paginationInfo.currentTake * paginationInfo.pageSize
        ) {
          setPaginationInfo((prevPaginationInfo) => ({
            ...prevPaginationInfo,
            currentTake: prevPaginationInfo.currentTake + 1,
          }));
        }
      }
    }
  };
  const handleScrollFrame = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      onScroll();
    }
  };

  useEffect(() => {
    if (!jobsLoading && jobsData && paginationInfo.currentTake === 1) {
      setAllData(jobsData?.data);
    } else if (!jobsLoading && jobsData && paginationInfo.currentTake > 1) {
      setAllData((prevData) => {
        const newData = jobsData?.data || [];
        const filteredData = newData.filter(
          (newItem) => !prevData.some((prevItem) => prevItem.id === newItem.id)
        );
        const updatedData = [...prevData, ...filteredData];

        // Update paginationInfo.hasNextPage based on whether all data is fetched
        if (filteredData.length < paginationInfo.pageSize) {
          setPaginationInfo((prevPaginationInfo) => ({
            ...prevPaginationInfo,
            hasNextPage: false,
          }));
        }

        return updatedData;
      });
    }
  }, [jobsData, jobsLoading, paginationInfo.currentTake, field]);

  useEffect(() => {
    if (paginationInfo?.currentTake > 1) {
      setAllData([]);
      setPaginationInfo((prev) => ({
        ...prev,
        currentTake: 1,
        hasNextPage: true,
      }));
    }
  }, [field]);

  //#region Calculate height based on the number of items
  const calculateHeight = () => {
    const itemHeight = 40;
    const maxVisibleItems = 8;

    // Number of items in allData
    const itemsCount = allData.length > 0 ? allData.length : 1; // Ensure there's height for the "No results" message

    // Determine the height based on the items count
    const height =
      itemsCount > maxVisibleItems
        ? itemHeight * maxVisibleItems
        : itemHeight * itemsCount;

    return height;
  };

  const calculateHeightForFeild = () => {
    const itemHeight = 40;
    const maxVisibleItems = 8;

    // Number of items in allData
    const itemsCount =
      filteredCountries.length > 0 ? filteredCountries.length : 1; // Ensure there's height for the "No results" message

    // Determine the height based on the items count
    const height =
      itemsCount > maxVisibleItems
        ? itemHeight * maxVisibleItems
        : itemHeight * itemsCount;

    return height;
  };
  //#endregion

  //#region adjust colors based on others (specify)
  const getBorderColor = (value1, value2) => {
    if (value1 === "Other (Please specify)" && value2 === "") {
      return "#B6B6B6";
    } else if (value1 !== "" || value2 !== "") {
      return "#252E3A";
    }
    return "#B6B6B6";
  };
  //#endregion

  //#region adjust colors based on others (specify)
  const getTextColor = (value1, value2) => {
    if (value1 === "Other (Please specify)" && value2 === "") {
      return "#999999";
    } else if (value1 !== "" || value2 !== "") {
      return "#252E3A";
    }
    return "#999999";
  };
  //#endregion

  //#region Stopping camera
  const isIOS = () => {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  };

  useEffect(() => {
    const checkPermissions = async () => {
      if (isIOS()) {
        console.warn(
          "Skipping camera permission check on iOS to avoid prompt."
        );
        return;
      }

      try {
        // Check permissions on supported platforms
        const permissions = await navigator.permissions.query({
          name: "camera",
        });

        if (permissions.state === "denied") {
          console.warn("Camera permission was denied");
          return;
        }

        // If permission is not yet prompted or granted, request access
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });

        // Immediately stop all video tracks
        stream.getTracks().forEach((track) => {
          if (track.kind === "video") {
            track.stop();
          }
        });
      } catch (error) {
        if (
          error.name === "NotAllowedError" ||
          error.name === "PermissionDeniedError"
        ) {
          console.warn("Camera permission was denied by user");
        } else {
          console.error("Error accessing camera:", error);
        }
      }
    };

    checkPermissions();

    return () => {
      // Additional cleanup if needed
    };
  }, []);

  // useEffect(() => {
  //   const stopCamera = async () => {
  //     try {
  //       // Get all currently active tracks
  //       const streams = await navigator.mediaDevices.getUserMedia({
  //         video: true,
  //       });

  //       // Stop all video tracks
  //       streams.getTracks().forEach((track) => {
  //         if (track.kind === "video") {
  //           track.stop();
  //         }
  //       });
  //     } catch (error) {
  //       console.error("Error stopping camera:", error);
  //     }
  //   };

  //   stopCamera();
  // }, []);
  //#endregion

  //#region Function to navigate privacy policy
  const handleTermsAndPrivacy = () => {
    const url = `/candidate-recruiter/privacy-policy`;
    window.open(url, "_blank");
  };
  //#endregion

  //#region Function to update user meta
  const updateUserMeta = () => {
    let data = JSON.stringify({
      country: selectedCountry,
      experience_in: field,
      experience_years: yearexp,
      firstLanguage: lang,
      educationLevel: educationLevel,
      study: study,
      employmentStatus: candidate,
    });
    try {
      candidateMutate(data);
    } catch (error) {
      //onError will automatically detect
    }
  };
  //#endregion

  //#region Update user meta
  const { mutate: candidateMutate, isLoading: candidateLoading } = useMutation(
    updateCandidateMeta,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("/candidate-meta/users");
        navigate("/finished");
      },
    }
  );
  //#endregion

  //#region check if user completed feedback
  useEffect(() => {
    if (user_exists === "yes") {
      navigate("/candidate/dashboard");
    } else if (user_exists === "no") {
      navigate("/candidate/login");
    }
  }, []);
  //#endregion

  document.title = "Feedback | Dexta";

  return (
    <>
      <nav
        className={`xl:static 2xl:fixed w-full z-20 top-0 left-0 py-2 bg-white`}
      >
        <div className="w-3/4 px-2 items-center py-2 text-xl text-white mx-auto">
          <div className="grid grid-cols-3 container mx-auto">
            <img
              src={theme.logo}
              className=" object-contain"
              style={{ height: "70px" }}
            />
          </div>
        </div>
      </nav>
      <video autoPlay style={{ display: "none" }}></video>

      <div className="bg-bodyColor">
        <div className="mx-auto lg:container">
          <div className="flex justify-center mt-20 pb-20">
            <div className="w-5/6 mt-10 rounded-md bg-white">
              <div className="rounded-lg">
                <div className="lg:p-8 lg:px-12 sm:max-md:p-6">
                  <h1
                    className="text-3xl text-[#000000] text-center mt-4 font-bold"
                    style={{ fontFamily: "Archia Bold" }}
                  >
                    Thank You
                  </h1>
                  <p
                    className="text-[#767676] mt-2 text-sm text-center"
                    style={{ fontFamily: "Silka" }}
                  >
                    You’ve finished your test. How was your experience?
                  </p>
                  <div className="flex flex-row justify-center mt-5 pb-5">
                    <div className={styles.rating}>
                      <div className="flex flex-row gap-1">
                        <FaStar
                          size={40}
                          onClick={(e) => handleStarClick(e, 0)}
                          className={
                            clicked[0] ? styles.clickedstar : styles.notclicked
                          }
                        />
                        <FaStar
                          size={40}
                          onClick={(e) => handleStarClick(e, 1)}
                          className={
                            clicked[1] ? styles.clickedstar : styles.notclicked
                          }
                        />
                        <FaStar
                          size={40}
                          onClick={(e) => handleStarClick(e, 2)}
                          className={
                            clicked[2] ? styles.clickedstar : styles.notclicked
                          }
                        />
                        <FaStar
                          size={40}
                          onClick={(e) => handleStarClick(e, 3)}
                          className={
                            clicked[3] ? styles.clickedstar : styles.notclicked
                          }
                        />
                        <FaStar
                          size={40}
                          onClick={(e) => handleStarClick(e, 4)}
                          className={
                            clicked[4] ? styles.clickedstar : styles.notclicked
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <textarea
                    style={{ fontFamily: "Silka" }}
                    className="resize-y rounded-lg border border-[#B6B6B6] sm:w-full lg:w-3/4 flex mx-auto p-2"
                    placeholder="Please explain..."
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                  ></textarea>
                  <div className="sm:w-full lg:w-3/4 flex mx-auto">
                    <div className="py-8 sm:px-2 md:px-10 mt-3 shadow-xl rounded-lg bg-[#252E3A33]">
                      <div
                        className="text-coalColor text-sm text-center"
                        style={{ fontFamily: "Silka" }}
                      >
                        <span>
                          To help make sure that our test provide equal
                          opportunities for everyone. We statistically analyse
                          test results anonymously. By sharing this information
                          about your background, you help us improve our tests
                          for all candidates. This information will not be
                          shared with your potential employer. Sharing this
                          information with us is optional, and anything shared
                          will be held in compliance with our{" "}
                          <span
                            onClick={handleTermsAndPrivacy}
                            style={{ fontFamily: "Archia Bold" }}
                            className="font-bold text-coalColor cursor-pointer"
                          >
                            Privacy Policy
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* First two Dropdowns code*/}
                  <div className="w-full flex mx-auto mt-10 lg:px-12 relative">
                    <div className="w-full justify-center gap-5 flex sm:flex-col md:flex-row">
                      <div
                        className="flex flex-col sm:w-full lg:w-2/5 gap-3 relative"
                        style={{ fontFamily: "Silka" }}
                      >
                        <button
                          id="education"
                          data-dropdown-toggle="education-drop"
                          className={` border font-medium rounded-lg text-sm px-5 w-full py-2.5 text-center flex items-center`}
                          style={{
                            borderColor: getBorderColor(
                              educationLevel,
                              otherEducation
                            ),
                            color: getTextColor(educationLevel, otherEducation),
                            // borderColor:
                            //   educationLevel != "" ? "#252E3A" : "#B6B6B6",
                          }}
                          type="button"
                          onClick={(e) => {
                            setEducationDrop(!educationDrop);
                          }}
                        >
                          {educationLevel != "" ? (
                            educationLevel === "Other (Please specify)" ? (
                              <input
                                placeholder="Please specify"
                                autoFocus
                                value={otherEducation}
                                onChange={(e) =>
                                  setOtherEducation(e.target.value)
                                }
                              />
                            ) : (
                              educationLevel
                            )
                          ) : (
                            "Highest education level"
                          )}
                          <svg
                            className="w-2.5 h-2.5 ml-auto"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 10 6"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 1 4 4 4-4"
                            />
                          </svg>
                        </button>
                        {educationDrop && (
                          <div className="edu absolute z-40 border border-coalColor right-0 top-full h-[80 overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2">
                            {highestEducation?.map((i) => (
                              <ul
                                key={i.title}
                                className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                ref={educationref}
                                onClick={() => {
                                  setEducationLevel(i?.title);
                                  setEducationDrop(false);
                                }}
                              >
                                <li>
                                  {" "}
                                  <a className="block px-5 py-2 text-sm font-medium">
                                    {" "}
                                    {i.title}{" "}
                                  </a>
                                </li>
                              </ul>
                            ))}
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col sm:w-full lg:w-2/5 gap-3 relative">
                        <input
                          className={` border border-[#B6B6B6] font-medium rounded-lg  text-sm px-5 w-full py-2.5 text-left flex items-center `}
                          style={{
                            color: study != "" ? "#252E3A" : "#999999",
                            borderColor: study != "" ? "#252E3A" : "#B6B6B6",
                            fontFamily: "Silka",
                          }}
                          placeholder="What did you study"
                          value={study}
                          onChange={(e) => setStudy(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  {/* 3rd and 4th Dropdown code*/}
                  <div className="w-full flex mx-auto sm:mt-5 md:mt-10 md:px-12 relative">
                    <div className="w-full justify-center gap-5 flex sm:flex-col md:flex-row">
                      <div className="flex flex-col sm:w-full md:w-2/5 gap-3 relative">
                        <input
                          type="text"
                          id="field"
                          className={`border font-medium rounded-lg text-sm px-5 w-full py-2.5 text-left flex items-center focus:border-[#4a9bb9da]`}
                          style={{
                            color: field != "" ? "#252E3A" : "#999999",
                            borderColor: field != "" ? "#252E3A" : "#B6B6B6",
                            fontFamily: "Silka",
                          }}
                          placeholder="Most relevant experience"
                          value={field}
                          onChange={(e) => {
                            setField(e.target.value);
                            setSearchTerm(e.target.value);
                            setFieldDrop(true);
                          }}
                          onClick={() => setFieldDrop(!fieldDrop)}
                        />
                        {fieldDrop && (
                          <div
                            className={`fieldclass absolute z-40 border enable-scrollbar2 border-coalColor right-0 top-full  overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2`}
                            id="experienceDropdown"
                            ref={listInnerRef}
                            style={{
                              fontFamily: "Silka",
                              height: calculateHeight(),
                            }}
                          >
                            <Scrollbars
                              style={{
                                width: "100%",
                                height: "100%",
                                overflowX: "hidden",
                              }}
                              onScrollFrame={handleScrollFrame}
                              hideTracksWhenNotNeeded
                            >
                              {allData?.map((item, index) => (
                                <ul
                                  key={index}
                                  className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                  ref={fieldRef}
                                  onClick={() => {
                                    setField(item?.name);
                                    setFieldDrop(false);
                                  }}
                                >
                                  <li>
                                    <a className="block px-5 py-2 text-sm font-medium">
                                      {item.name}
                                    </a>
                                  </li>
                                </ul>
                              ))}
                              <>
                                {field !== "" &&
                                  !allData.some(
                                    (item) => item.name === field
                                  ) && (
                                    <ul
                                      className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                      ref={fieldRef}
                                      onClick={() => {
                                        setFieldDrop(false);
                                      }}
                                    >
                                      <li className="flex flex-row">
                                        <a className="block px-5 py-2 text-sm font-medium">
                                          Add "{field}"
                                        </a>
                                        <IoAddCircle className="w-5 h-5 ml-auto my-auto" />
                                      </li>
                                    </ul>
                                  )}
                              </>
                            </Scrollbars>
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col sm:w-full md:w-2/5 gap-3 relative">
                        <button
                          id="expYear"
                          className={` border font-medium rounded-lg text-sm px-5 w-full py-2.5 text-center flex items-center`}
                          style={{
                            fontFamily: "Silka",
                            borderColor: expDropdown ? "#252E3A" : "#B6B6B6",
                            color: yearexp != "" ? "#252E3A" : "#999999",
                            borderColor: yearexp != "" ? "#252E3A" : "#B6B6B6",
                          }}
                          type="button"
                          onClick={() => setExpDropdown(!expDropdown)}
                        >
                          {yearexp != ""
                            ? yearexp
                            : "Years of experience in role"}
                          <svg
                            className="w-2.5 h-2.5 ml-auto"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 10 6"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 1 4 4 4-4"
                            />
                          </svg>
                        </button>
                        {expDropdown && (
                          <div
                            id="education-drop"
                            className="expClass absolute z-40 enable-scrollbar2 border border-coalColor right-0 top-full h-80 overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                            style={{ fontFamily: "Silka" }}
                          >
                            <Scrollbars
                              autoHide
                              style={{ width: "100%", height: "100%" }}
                            >
                              {experience_years?.map((i) => (
                                <ul
                                  key={i.title}
                                  className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                  ref={expRef}
                                  onClick={() => {
                                    setyearexp(i?.title);
                                    setExpDropdown(false);
                                  }}
                                >
                                  <li>
                                    <a className="block px-5 py-2 text-sm font-medium">
                                      {i.title}
                                    </a>
                                  </li>
                                </ul>
                              ))}
                            </Scrollbars>
                          </div>
                        )}
                      </div>
                      {/* <div className="flex flex-col sm:w-full md:w-2/5 gap-3 relative">
                        <input
                          className={` border font-medium rounded-lg text-sm px-5 w-full py-2.5 text-left flex items-center focus:border-[#4a9bb9da]`}
                          style={{
                            color: yearexp != "" ? "#252E3A" : "#999999",
                            borderColor: yearexp != "" ? "#252E3A" : "#B6B6B6",
                            fontFamily: "Silka",
                          }}
                          placeholder="Years of experience in role"
                          value={yearexp}
                          type="number"
                          onChange={(e) => setyearexp(e.target.value)}
                        />
                      </div> */}
                    </div>
                  </div>

                  {/* 5th and 6th Dropdown code*/}
                  <div className="w-full flex mx-auto sm:mt-5 md:mt-10 md:px-12 relative">
                    <div className="w-full justify-center gap-5 flex sm:flex-col md:flex-row">
                      <div className="flex flex-col sm:w-full md:w-2/5 gap-3 relative">
                        <button
                          id="gender"
                          className={` border font-medium rounded-lg text-sm px-5 w-full py-2.5 text-center flex items-center`}
                          style={{
                            fontFamily: "Silka",
                            borderColor: getBorderColor(gen, otherGender),
                            color: getTextColor(gen, otherGender),
                          }}
                          type="button"
                          onClick={() => setGendrop(!gendrop)}
                        >
                          {gen != "" ? (
                            gen === "Other (Please specify)" ? (
                              <input
                                placeholder="Please specify"
                                autoFocus
                                value={otherGender}
                                onChange={(e) => setOtherGender(e.target.value)}
                              />
                            ) : (
                              gen
                            )
                          ) : (
                            "Gender"
                          )}
                          <svg
                            className="w-2.5 h-2.5 ml-auto"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 10 6"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 1 4 4 4-4"
                            />
                          </svg>
                        </button>
                        {gendrop && (
                          <div
                            id="education-drop"
                            className="genderClass absolute z-40 border border-coalColor right-0 top-full h-auto overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                            style={{ fontFamily: "Silka" }}
                          >
                            {gender?.map((i) => (
                              <ul
                                key={i.title}
                                className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                ref={genderRef}
                                onClick={() => {
                                  setGender(i?.title);
                                  setGendrop(false);
                                }}
                              >
                                <li>
                                  <a className="block px-5 py-2 text-sm font-medium">
                                    {i.title}
                                  </a>
                                </li>
                              </ul>
                            ))}
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col sm:w-full md:w-2/5 gap-3 relative">
                        <button
                          id="year"
                          className={` border font-medium rounded-lg text-sm px-5 w-full py-2.5 text-center flex items-center`}
                          style={{
                            fontFamily: "Silka",
                            borderColor: yearDropdown ? "#252E3A" : "#B6B6B6",
                            color: selectedYear != "" ? "#252E3A" : "#999999",
                            borderColor:
                              selectedYear != "" ? "#252E3A" : "#B6B6B6",
                          }}
                          type="button"
                          onClick={() => setYearDropdown(!yearDropdown)}
                        >
                          {selectedYear != ""
                            ? selectedYear
                            : "What year were you born?"}
                          <svg
                            className="w-2.5 h-2.5 ml-auto"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 10 6"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 1 4 4 4-4"
                            />
                          </svg>
                        </button>
                        {yearDropdown && (
                          <div
                            id="education-drop"
                            className="yearClass absolute z-40 border enable-scrollbar2 border-coalColor right-0 top-full h-[11.5rem] overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                            style={{ fontFamily: "Silka" }}
                          >
                            <Scrollbars
                              autoHide
                              style={{ width: "100%", height: "100%" }}
                            >
                              {years?.map((i) => (
                                <ul
                                  key={i}
                                  className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                  ref={yearRef}
                                  onClick={() => {
                                    setSelectedYear(i);
                                    setYearDropdown(false);
                                  }}
                                >
                                  <li>
                                    <a className="block px-5 py-2 text-sm font-medium">
                                      {i}
                                    </a>
                                  </li>
                                </ul>
                              ))}
                            </Scrollbars>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* 6th and 7th Dropdown code*/}
                  <div className="w-full flex mx-auto sm:mt-5 md:mt-10 md:px-12 relative">
                    <div className="w-full justify-center gap-5 flex sm:flex-col md:flex-row">
                      <div className="flex flex-col sm:w-full md:w-2/5 gap-3 relative">
                        <input
                          type="text"
                          id="country"
                          className={`border font-medium rounded-lg text-sm px-5 w-full py-2.5 text-left flex items-center focus:border-[#4a9bb9da]`}
                          style={{
                            fontFamily: "Silka",
                            color:
                              selectedCountry != "" ? "#252E3A" : "#999999",
                            borderColor:
                              selectedCountry != "" ? "#252E3A" : "#B6B6B6",
                          }}
                          placeholder="Country/Region of Residence"
                          value={selectedCountry}
                          onChange={(e) => {
                            setSelectedCountry(e.target.value);
                            setsearchCountry(e.target.value);
                            setCountryDrop(true);
                          }}
                          onClick={() => setCountryDrop(!countryDrop)}
                        />
                        <div
                          className="absolute inset-y-0 right-0 flex items-center pr-5 text-gray-600 cursor-pointer"
                          onClick={() => setCountryDrop(!countryDrop)}
                        >
                          <svg
                            className={`w-2.5 h-2.5 ml-auto ${
                              selectedCountry === ""
                                ? "text-[#999999]"
                                : "text-[#252E3A]"
                            }`}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 10 6"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 1 4 4 4-4"
                            />
                          </svg>
                        </div>
                        {countryDrop && (
                          <div
                            className="countryClass absolute enable-scrollbar2 z-40 border border-coalColor right-0 bottom-full h-80 overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                            id="education-drop"
                            ref={countryRef}
                            style={{
                              fontFamily: "Silka",
                              height: calculateHeightForFeild(),
                            }}
                          >
                            <Scrollbars
                              style={{
                                width: "100%",
                                height: "100%",
                                overflowX: "hidden",
                              }}
                              onScrollFrame={handleScrollFrame}
                            >
                              {filteredCountries.map((i, index) => (
                                <ul
                                  key={index}
                                  className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                  ref={countryRef}
                                  onClick={() => {
                                    setSelectedCountry(i);
                                    setCountryDrop(false);
                                  }}
                                >
                                  <li>
                                    <a className="block px-5 py-2 text-sm font-medium">
                                      {i}
                                    </a>
                                  </li>
                                </ul>
                              ))}
                              <>
                                {selectedCountry !== "" &&
                                  !filteredCountries.some(
                                    (item) => item === selectedCountry
                                  ) && (
                                    <ul
                                      className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                      ref={countryRef}
                                      onClick={() => {
                                        setCountryDrop(false);
                                      }}
                                    >
                                      <li className="flex flex-row">
                                        <a className="block px-5 py-2 text-sm font-medium">
                                          Add "{selectedCountry}"
                                        </a>
                                        <IoAddCircle className="w-5 h-5 ml-auto my-auto" />
                                      </li>
                                    </ul>
                                  )}
                              </>
                            </Scrollbars>
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col sm:w-full md:w-2/5 gap-3 relative">
                        <button
                          id="ethnicity"
                          className={` border border-[#B6B6B6] font-medium rounded-lg text-sm px-5 w-full py-2.5 text-center flex items-center`}
                          style={{
                            fontFamily: "Silka",
                            borderColor: ethDrop ? "#252E3A" : "#B6B6B6",
                            color: eth != "" ? "#252E3A" : "#999999",
                            borderColor: eth != "" ? "#252E3A" : "#B6B6B6",
                          }}
                          type="button"
                          onClick={() => setEthDrop(!ethDrop)}
                        >
                          {eth != "" ? eth : "Ethnicity"}
                          <svg
                            className="w-2.5 h-2.5 ml-auto"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 10 6"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 1 4 4 4-4"
                            />
                          </svg>
                        </button>
                        {ethDrop && (
                          <div
                            id="education-drop"
                            className="ethClass absolute z-40 border enable-scrollbar2 border-coalColor right-0 top-full h-[11rem] overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                            style={{ fontFamily: "Silka" }}
                          >
                            <Scrollbars
                              autoHide
                              style={{ width: "100%", height: "100%" }}
                            >
                              {ethnicity.map((i, index) => (
                                <ul
                                  key={index}
                                  className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                  ref={ethRef}
                                  onClick={() => {
                                    setEth(i.title);
                                    setEthDrop(false);
                                  }}
                                >
                                  <li>
                                    <a className="block px-5 py-2 text-sm font-medium">
                                      {i.title}
                                    </a>
                                  </li>
                                </ul>
                              ))}
                            </Scrollbars>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* 8th Dropdown code*/}
                  <div className="w-full flex mx-auto sm:mt-5 md:mt-10 md:px-12 relative">
                    <div className="w-full justify-center gap-5 flex sm:flex-col md:flex-row">
                      <div className="flex flex-col sm:w-full md:w-2/5 gap-3 relative">
                        <button
                          id="candidate"
                          className={` border border-[#B6B6B6] font-medium rounded-lg text-sm px-5 w-full py-2.5 text-center flex items-center`}
                          style={{
                            fontFamily: "Silka",
                            borderColor: candidateDropdown
                              ? "#252E3A"
                              : "#B6B6B6",
                            color: getTextColor(candidate, otherValue),
                            borderColor: getBorderColor(candidate, otherValue),
                          }}
                          type="button"
                          onClick={() =>
                            setCandidateDropdown(!candidateDropdown)
                          }
                        >
                          {candidate != "" ? (
                            candidate === "Other (Please specify)" ? (
                              <input
                                placeholder="Please specify"
                                autoFocus
                                value={otherValue}
                                onChange={(e) => setOtherValue(e.target.value)}
                              />
                            ) : (
                              candidate
                            )
                          ) : (
                            "Candidate Status"
                          )}
                          <svg
                            className="w-2.5 h-2.5 ml-auto"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 10 6"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 1 4 4 4-4"
                            />
                          </svg>
                        </button>
                        {candidateDropdown && (
                          <div
                            id="education-drop"
                            className="candidateClass absolute z-40 border enable-scrollbar2 border-coalColor right-0 top-full h-[12rem] overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                            style={{ fontFamily: "Silka" }}
                          >
                            <Scrollbars
                              autoHide
                              style={{ width: "100%", height: "100%" }}
                            >
                              {candidate_statuses.map((i, index) => (
                                <ul
                                  key={index}
                                  className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                  ref={candidateRef}
                                  onClick={() => {
                                    setCandidate(i.title);
                                    setCandidateDropdown(false);
                                  }}
                                >
                                  <li>
                                    <a className="block px-5 py-2 text-sm font-medium">
                                      {i.title}
                                    </a>
                                  </li>
                                </ul>
                              ))}
                            </Scrollbars>
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col sm:w-full md:w-2/5 gap-3 relative">
                        <button
                          id="language"
                          className={` border border-[#B6B6B6] font-medium rounded-lg text-sm px-5 w-full py-2.5 text-center flex items-center`}
                          style={{
                            fontFamily: "Silka",
                            borderColor: langDrop ? "#252E3A" : "#B6B6B6",
                            color: lang != "" ? "#252E3A" : "#999999",
                            borderColor: lang != "" ? "#252E3A" : "#B6B6B6",
                          }}
                          type="button"
                          onClick={() => setLangDrop(!langDrop)}
                        >
                          {lang != "" ? (
                            lang === "Other" ? (
                              <input
                                placeholder="Please specify"
                                autoFocus
                                value={otherLanguage}
                                onChange={(e) =>
                                  setOtherLanguage(e.target.value)
                                }
                              />
                            ) : (
                              lang
                            )
                          ) : (
                            "What is your first language"
                          )}
                          <svg
                            className="w-2.5 h-2.5 ml-auto"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 10 6"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 1 4 4 4-4"
                            />
                          </svg>
                        </button>
                        {langDrop && (
                          <div
                            id="education-drop"
                            className="langClass absolute z-40 border enable-scrollbar2 border-coalColor right-0 top-full h-[11rem] overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                            style={{ fontFamily: "Silka" }}
                          >
                            <Scrollbars
                              autoHide
                              style={{ width: "100%", height: "100%" }}
                            >
                              {language
                                .sort((a, b) => a?.title.localeCompare(b.title))
                                .map((i) => (
                                  <ul
                                    key={i.title}
                                    className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                    ref={langRef}
                                    onClick={() => {
                                      setLang(i.title);
                                      setLangDrop(false);
                                    }}
                                  >
                                    <li>
                                      <a className="block px-5 py-2 text-sm font-medium">
                                        {i.title}
                                      </a>
                                    </li>
                                  </ul>
                                ))}
                            </Scrollbars>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center">
                    <button
                      className="inline-flex items-center sm:w-full md:w-1/6 justify-center px-4 mt-10 py-4  hover:text-white text-white text-sm font-medium rounded-md"
                      style={buttonStyle}
                      onMouseEnter={handleHover}
                      onMouseLeave={handleLeave}
                      onClick={() => {
                        if (isrecruiter === "recruiter") {
                          navigate("/dashboard");
                        } else {
                          handleFeedbackSubmit();
                        }
                      }}
                    >
                      {isLoading && candidateLoading ? (
                        <span className="flex items-center justify-center">
                          <Loader
                            type="Oval"
                            color="black"
                            height={20}
                            width={20}
                          />
                          <span className="ml-2">Submitting...</span>
                        </span>
                      ) : (
                        "Submit"
                      )}
                      <GoArrowRight
                        alt="Add Transaction Icon"
                        className="w-5 h-5 ml-2 icon-image"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Feedback;
