import React, { Fragment, useState, useEffect, useMemo, useRef } from "react";
import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Edit from "../../../Assets/edit-icon.png";
import { IoSearch } from "react-icons/io5";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import styles from "../MyAssessments/Assessments-main/styling2.module.css";
import { fetchSections } from "../Settings/hooks/fetchSections";
import ReactHtmlParser from "react-html-parser";
import http from "../../../http";
import {
  test_duration,
  filters_tests,
  stepsTests,
  stepsTests_v2,
} from "./data";
import { setIndustryData } from "../../../redux/reducers/Industries/IndustriesSlice";
import { setDepartmentData } from "../../../redux/reducers/Departments/DepartmentsSlice";
import { useDispatch, useSelector } from "react-redux";
import { setEntryLevel } from "../../../redux/reducers/EntryLevel/EntrySlice";
import { setStartTime } from "../../../redux/reducers/StartTime/StartTimeSlice";
import notSelected from "../../../Assets/notselected.png";
import { getModuleByID } from "../MyAssessments/Assessments-main/hooks/getModuleByID";
import TextFieldSmall from "../../../Components/TextFieldSmall/TextFieldSmall";
import CustomButton from "../../../Components/CustomButton/CustomButton";
import Scrollbars from "react-custom-scrollbars";
import { getCategoriesForSearch } from "./getCategoriesForSearch";
import { Steps } from "intro.js-react";
import {
  setTourToCompleted,
  setTourToIncomplete,
} from "../../../redux/reducers/TourCompleted/TourCompletedSlice";
import { setTourStepData } from "../../../redux/reducers/TourSteps/TourStepsSlice";
import { settourtofalse } from "../../../redux/reducers/toggleTour/ToggleSlice";
import { getJobsForSearch } from "./getJobsForSearch";
import { setJobsData } from "../../../redux/reducers/Jobs/JobsSlice";
import AssessmentsIcon from "../../../Dexta_assets/navbar3.png";
import clock from "../../../Dexta_assets/clock.png";
import search from "../../../Dexta_assets/searchBar.png";
import d1 from "../../../Dexta_assets/d1.png";
import d2 from "../../../Dexta_assets/d2.png";
import d3 from "../../../Dexta_assets/d3.png";
import d4 from "../../../Dexta_assets/d4.png";
import close from "../../../Dexta_assets/closeModal.png";
import { RiFileListLine } from "react-icons/ri";
import { getSubCategoriesForSearch } from "./getSubCategoriesForSearch";
import GeneralModal from "../../../Components/Modals/GeneralModal";
import { getAccess } from "../MyAssessments/Assessments-main/hooks/getAccess";
import useWindowSize from "../../../Helpers/useWindowSize";
import { IoMdMenu } from "react-icons/io";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { SkeletonCard } from "../../../Components/SkeletonCard";

export default function Tests() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [sectionID, setSectionID] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const userID = localStorage.getItem("CP-USER-ID");
  const [userData, setUserData] = useState("");
  const [isLoad, setIsLoading] = useState(false);
  const industries = useSelector((state) => state.industries.setIndustries);
  const departments = useSelector((state) => state.departments.setDepartments);
  const jobs = useSelector((state) => state.jobs.setJobs);
  const entry_level = useSelector((state) => state.entry_level.setEntry);
  const startTime = useSelector((state) => state.start.startTime);
  const endTime = useSelector((state) => state.start.endTime);
  const [currentTake, setCurrentTake] = useState(1);
  const [TestsData, setTestsData] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [generalModal, setGeneralModal] = useState(false);
  const [heading, setHeading] = useState("");
  const [description, setdescription] = useState("");
  const user_role = useSelector(
    (state) => state.accountTypeDetails.accountType
  );

  console.log(user_role, "yayaya");
  const [staticLoading, setStaticLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [allJobData, setAllJobData] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState({
    currentTake: 1,
    hasNextPage: true,
  });

  const [paginationJobRole, setPaginationJobRole] = useState({
    currentTake: 1,
    hasNextPage: true,
    pageSize: 25,
  });

  const { data, isLoading } = useQuery(
    [
      "section",
      industries,
      departments,
      entry_level,
      startTime.startTime,
      endTime.endTime,
      searchedValue,
      jobs,
      paginationInfo.currentTake,
    ],
    () =>
      fetchSections(
        industries,
        departments,
        entry_level,
        startTime.startTime,
        endTime.endTime,
        searchedValue,
        jobs,
        paginationInfo.currentTake
      )
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [industrySearch, setIndustrySearch] = useState("");
  const [departmentSearch, setDepartmentSearch] = useState("");
  const [jobsSearch, setJobsSearch] = useState("");
  const tourStepCheck = useSelector((state) => state.tourStep.settourStep);
  const [stepNumber, setStepNumber] = useState(1);
  const [show, setShow] = useState(false);
  const [tourExitedManually, setTourExitedManually] = useState(false);
  const [tourCompleted, setTourCompleted] = useState(false);
  const tourCompletedState = useSelector(
    (state) => state.tourCompleted.setCompleted
  );
  const listInnerRef = useRef(null);
  const listJobRef = useRef(null);
  const [jobLoader, setJobLoader] = useState(false);
  const user_package_check = useSelector(
    (state) => state.packageDetails.setPackage
  );
  const [isOpen, setIsOpen] = useState(false);
  const size = useWindowSize();
  const isMobile = size.width <= 640;
  //#region react-query to fetch industries from api
  const {
    data: indusrtyData,
    isLoading: industryLoading,
    error,
  } = useQuery({
    queryKey: ["category", industrySearch],
    queryFn: () => getCategoriesForSearch(industrySearch),
  });
  //#endregion

  //#region react-query to fetch industries from api
  const {
    data: departmentData,
    isLoading: departmentLoading,
    error: departmentError,
  } = useQuery({
    queryKey: ["subCategory", departmentSearch, industries],
    queryFn: () => getSubCategoriesForSearch(departmentSearch, industries),
  });
  //#endregion

  //#region react-query to fetch industries from api

  const { data: jobsData, isLoading: jobsLoading } = useQuery(
    [
      "jobs",
      jobsSearch,
      paginationJobRole.currentTake,
      industries,
      departments,
    ],
    () =>
      getJobsForSearch(
        jobsSearch,
        paginationJobRole.currentTake,
        industries,
        departments
      ),
    {
      keepPreviousData: true,
    }
  );

  //#endregion

  //#region get User Info
  const getUserData = async () => {
    setIsLoading(true);
    const response = await http.get(`/users/${userID}`);
    setUserData(response.data);
    setIsLoading(false);
  };
  useEffect(() => {
    getUserData();
  }, []);
  //#endregion

  //#region Handle checboxes
  const handleCheckbox = (id) => {
    let updatedIndustries = [];
    if (industries.includes(id)) {
      updatedIndustries = industries.filter((item) => item !== id);
    } else {
      if (departments.length > 0 && industries.length === 0) {
        dispatch(setDepartmentData([])); // Clear departments array
      }
      updatedIndustries = [...industries, id];
    }
    dispatch(setIndustryData(updatedIndustries));
    const searchString = updatedIndustries.join(",");
    setSearchParams({ industry: searchString.replace(/,/g, " ") });
  };
  const handleCheckboxDepartments = (id) => {
    let updatedDepartments = [];
    if (departments.includes(id)) {
      updatedDepartments = departments.filter((item) => item !== id);
    } else {
      updatedDepartments = [...departments, id];
    }
    dispatch(setDepartmentData(updatedDepartments));
    const searchString = updatedDepartments.join(",");
    setSearchParams({ department: searchString.replace(/,/g, " ") });
  };

  const handleCheckboxJobs = (id) => {
    let updatedJobs = [];
    if (jobs.includes(id)) {
      updatedJobs = jobs.filter((item) => item !== id);
    } else {
      updatedJobs = [...jobs, id];
    }
    dispatch(setJobsData(updatedJobs));
    const searchString = updatedJobs.join(",");
    setSearchParams({ jobs: searchString.replace(/,/g, " ") });
  };

  const handleDurationRadios = (e, value1, value2) => {
    e.preventDefault();
    const newValue = startTime.startTime === value1 ? "" : value1;
    const newValue2 = endTime.endTime === value2 ? "" : value2;
    dispatch(setStartTime({ startTime: newValue, endTime: newValue2 }));
    setSearchParams({ start_time: newValue, end_time: newValue2 });
  };

  useMemo(() => {
    // const combinedIndustriesAndDepartments = [...industries, ...departments];
    setSearchParams({
      industry: industries.join(",").replace(/,/g, " "),
      department: departments.join(",").replace(/,/g, " "),
      jobs: jobs.join(",").replace(/,/g, " "),
      job_seniority: entry_level,
      start_time: startTime.startTime,
      end_time: endTime.endTime,
    });
  }, [
    industries,
    departments,
    jobs,
    entry_level,
    startTime.startTime,
    endTime.endTime,
    setSearchParams,
  ]);

  function handleButtonClick(event) {
    event.stopPropagation();
  }

  const handleRadio = (e, value) => {
    e.preventDefault();
    const newValue = entry_level === value ? "" : value;
    dispatch(setEntryLevel(newValue));
    setSearchParams({ job_seniority: newValue });
  };
  //#endregion

  //#region updating filters
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const industryParam = params.get("industry");
    const departmentParam = params.get("department");
    const jobParam = params.get("jobs");
    const jobSeniorityParam = params.get("job_seniority");
    const start = params.get("start_time");
    const end = params.get("end_time");

    const selectedIndustries = industryParam
      ? industryParam
          .split(/[,\s+]/)
          .map(Number)
          .filter((id) => !isNaN(id))
      : [];
    const selectedDepartments = departmentParam
      ? departmentParam
          .split(/[,\s+]/)
          .map(Number)
          .filter((id) => !isNaN(id))
      : [];
    const selectedJobs = jobParam
      ? jobParam
          .split(/[,\s+]/)
          .map(Number)
          .filter((id) => !isNaN(id))
      : [];
    const selectedJobSeniority = jobSeniorityParam || "";
    const startValue = start || "";
    const endValue = end || "";

    dispatch(setIndustryData(selectedIndustries));
    dispatch(setDepartmentData(selectedDepartments));
    dispatch(setJobsData(selectedJobs));
    dispatch(setEntryLevel(selectedJobSeniority));
    dispatch(setStartTime({ startTime: startValue, endTime: endValue }));
  }, []);
  //#endregion

  //#region Get module data based on section ID
  const {
    data: ModuleData,
    error: errorModule,
    isLoading: isLoadingModule,
  } = useQuery(["sections", sectionID], () => getModuleByID(sectionID));
  //#endregion

  useEffect(() => {
    if (tourStepCheck === 7) {
      setTimeout(() => {
        setShow(true);
      }, 2000);
    }
  }, [tourStepCheck]);

  const onExit = () => {
    // console.log("hehehe");
    setTourExitedManually(true);
  };

  const onExitTests = () => {
    dispatch(setTourStepData(99));

    // console.log("candy exit")
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        if (data?.meta?.hasNextPage !== false) {
          setPaginationInfo((prevPaginationInfo) => ({
            ...prevPaginationInfo,
            currentTake: prevPaginationInfo.currentTake + 1,
          }));
        }
      }
    }
  };

  const onScrollJobRole = () => {
    if (listJobRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listJobRef.current;
      if (scrollTop + clientHeight === scrollHeight && !jobsLoading) {
        if (
          jobsData?.meta?.hasNextPage !== false &&
          allJobData.length >=
            paginationJobRole.currentTake * paginationJobRole.pageSize
        ) {
          setPaginationJobRole((prevPaginationInfo) => ({
            ...prevPaginationInfo,
            currentTake: prevPaginationInfo.currentTake + 1,
          }));
        }
      }
    }
  };

  const handleScrollFrame = (values) => {
    setJobLoader(true);
    const { scrollTop, scrollHeight, clientHeight } = values;
    if (scrollTop + clientHeight >= scrollHeight - 25) {
      onScrollJobRole();
    }
  };

  useEffect(() => {
    if (jobLoader) {
      setTimeout(() => {
        setJobLoader(false);
      }, 1000);
    }
  }, [jobLoader]);

  const sortDataAlphabetically = (dataToSort) => {
    return [...dataToSort].sort(
      (a, b) => a.name.localeCompare(b.name) // Assuming 'name' is the key used for alphabetical sorting
    );
  };

  useEffect(() => {
    if (!jobsLoading && jobsData && paginationJobRole.currentTake === 1) {
      setAllJobData(jobsData?.data);
    } else if (!jobsLoading && jobsData && paginationJobRole.currentTake > 1) {
      setAllJobData((prevData) => {
        const newData = jobsData?.data || [];
        const filteredData = newData.filter(
          (newItem) => !prevData.some((prevItem) => prevItem.id === newItem.id)
        );
        return [...prevData, ...filteredData];
      });
    }
  }, [
    jobsData,
    jobsLoading,
    paginationJobRole.currentTake,
    industries,
    departments,
  ]);

  useEffect(() => {
    if (!jobsLoading && jobsData && paginationJobRole.currentTake === 1) {
      setAllJobData(jobsData?.data);
    } else if (!jobsLoading && jobsData && paginationJobRole.currentTake > 1) {
      setAllJobData((prevData) => {
        const newData = jobsData?.data || [];
        const filteredData = newData.filter(
          (newItem) => !prevData.some((prevItem) => prevItem.id === newItem.id)
        );
        const updatedData = [...prevData, ...filteredData];

        // Update paginationJobRole.hasNextPage based on whether all data is fetched
        if (filteredData.length < paginationJobRole.pageSize) {
          setPaginationJobRole((prevPaginationInfo) => ({
            ...prevPaginationInfo,
            hasNextPage: false,
          }));
        }

        return updatedData;
      });
    }
  }, [
    jobsData,
    jobsLoading,
    paginationJobRole.currentTake,
    industries,
    departments,
  ]);

  //#region preview function
  const handleButtonClickPreview = (id) => {
    const url = `/preview-module/${id}`;
    window.open(url, "_blank"); // This opens the link in a new tab
  };
  //#endregion

  //#region  Update paginationInfo whenever filters change
  useEffect(() => {
    if (!isLoading) {
      setPaginationInfo({
        currentTake: 1,
        hasNextPage: true,
      });
      setAllData(data?.data?.relatedData || []);
    }
  }, [
    industries,
    entry_level,
    startTime.startTime,
    endTime.endTime,
    searchedValue,
    jobs,
    isLoading,
  ]);
  //#endregion

  useEffect(() => {
    if (isLoading) {
      setStaticLoading(true);
      setTimeout(() => {
        setStaticLoading(false);
      }, 1000);
    }
  }, [isLoading]);

  //#region send email to request new module
  const handleEmailClick = () => {
    //     const recipient = "support@dexta.io";
    //     const subject = "Request of new module";
    //     const body = `Hi Dexta team,

    // Can you please create a new module which covers [add module details]. This module is for the role [add role name].

    // Thanks`;

    //     const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
    //       subject
    //     )}&body=${encodeURIComponent(body)}`;

    //     window.location.href = mailtoLink;
    window.open("https://dexta.io/contact", "_blank");
  };
  //#endregion
  document.title = "Modules | Dexta";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //#region Fetching and Filtering Assessments
  const {
    data: dataAccess,
    isLoading: accessLoading,
    error: accessError,
  } = useQuery(["/assessment/user-can-create", userID], () =>
    getAccess(userID)
  );
  //#endregion

  // Function to toggle drawer visibility
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  //#region mutation callback to check if cancel clicked or not
  useEffect(() => {
    const observer = new MutationObserver(() => {
      const defaultCloseIcon = document.querySelector(".custom-close-icon");
      if (defaultCloseIcon) {
        defaultCloseIcon.addEventListener("click", (e) => {
          e.stopPropagation();
          if (tourStepCheck === 7) {
            onExitTests();
          }
        });
      }
    });
    observer.observe(document.body, { childList: true, subtree: true });
    return () => observer.disconnect();
  }, []);
  //#endregion

  return (
    <div>
      {tourStepCheck === 7 && (
        <Steps
          enabled={show}
          options={{
            hideNext: false,
            exitOnOverlayClick: false,
            exitOnFinish: false,
            doneLabel: user_role === "recruiter" ? "Done" : "Next",
          }}
          steps={user_role === "recruiter" ? stepsTests : stepsTests_v2}
          onComplete={() => {
            if (user_role === "recruiter") {
              dispatch(setTourToCompleted(true));
              setShow(false);
              dispatch(setTourStepData(99));
              dispatch(settourtofalse(false));
              dispatch(setTourToIncomplete(false));
              navigate("/dashboard");
            } else {
              dispatch(setTourToCompleted(true));
              setShow(false);
              dispatch(setTourStepData(4));
              dispatch(settourtofalse(false));
              dispatch(setTourToIncomplete(false));
            }
          }}
          onExit={(tour) => {
            // if (!tourCompletedState) {
            //   if (!tourExitedManually) {
            //     onExit();
            //   } else {
            //     onExitTests();
            //   }
            // }
          }}
          initialStep={0}
          onBeforeChange={(value) => {
            setStepNumber(value);
          }}
        />
      )}
      <GeneralModal
        generalModal={generalModal}
        setGeneralModal={setGeneralModal}
        heading={heading}
        description={description}
      />
      <div>
        <main className="lg:container 2xl:px-0 sm:px-4 mx-auto h-fit">
          <div className="flex justify-between pt-[7rem]">
            <div className="flex flex-row gap-2">
              {isMobile ? (
                <IoMdMenu className="w-4 h-4 my-auto" onClick={toggleDrawer} />
              ) : (
                <img src={AssessmentsIcon} className="w-4 h-4 my-auto" />
              )}
              <h1
                className="sm:text-xl md:text-2xl my-auto font-bold select-text text-coalColor"
                id="step3"
                style={{ fontFamily: "Archia Semibold" }}
              >
                Modules
              </h1>
            </div>
            <div className="flex flex-row gap-4">
              <div className="">
                <div class="relative w-80" id="tests3">
                  <TextFieldSmall
                    value={searchedValue}
                    label="Search"
                    onChangeValue={(e) => setSearchedValue(e.target.value)}
                    backgroundTextColor="bg-[#F0F0F0]"
                    thickBorder="border-[1px]"
                    topTextWeight="font-medium"
                    placeholder="Search module here..."
                    image={search}
                    dimension="h-4 w-4"
                  />
                </div>
              </div>
              {user_role !== "hiring-manager" && (
                <div className="flex relative mb-1">
                  <button
                    id="assessment1"
                    className={`inline-flex py-[0.4rem] leading-[2.10] items-center border ${
                      !userData?.confirmed || user_role === "hiring-manager"
                        ? "bg-[#D3D5D8] text-[#7C8289]"
                        : "bg-primaryGreen text-coalColor hover:bg-coalColor hover:text-white border-coalColor"
                    } px-4 text-base font-medium rounded-md `}
                    onClick={() => {
                      if (!dataAccess?.isAllowed) {
                        setHeading("You’ve reached your limit!");
                        setdescription(
                          user_package_check === "free"
                            ? "Explore For Free subscription allows 1 Active Test, either change the status of your Active Test to ‘Archived’ or upgrade your subscription package"
                            : "Starter subscription allows 5 Active Tests, either change the status of an Active Test to ‘Archived’ or upgrade your subscription package"
                        );
                        setGeneralModal(true);
                      } else {
                        localStorage.setItem("current_module", "general");
                        localStorage.setItem("assessment_ID", "null");
                        navigate("/create-test");
                      }
                    }}
                    disabled={
                      !userData?.confirmed || user_role === "hiring-manager"
                    }
                  >
                    <RiFileListLine
                      alt="Add Transaction Icon"
                      className="w-4 my-auto h-5 mr-2 icon-image"
                    />
                    Create new test
                  </button>
                  {!userData?.confirmed && (
                    <div className="tooltip w-[15rem]  text-center absolute top-full left-0 opacity-0 pointer-events-none">
                      Please verify your email first 👍
                    </div>
                  )}
                  {user_role === "hiring-manager" && (
                    <div className="tooltip w-[15rem]  text-center absolute top-full left-0 opacity-0 pointer-events-none">
                      You do not have permissions to create test
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div>
            {/* Drawer */}
            <div
              id="drawer-navigation"
              className={`fixed top-0 left-0 z-40 h-screen p-4 overflow-y-auto transition-transform ${
                isOpen ? "translate-x-0" : "-translate-x-full"
              } bg-[#DBD9C0] w-full `}
              tabindex="-1"
              aria-labelledby="drawer-navigation-label"
            >
              <h5
                id="drawer-navigation-label"
                className="text-base font-semibold text-coalColor uppercase"
              >
                Filters
              </h5>
              <button
                type="button"
                onClick={toggleDrawer}
                aria-controls="drawer-navigation"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close menu</span>
              </button>
              <form className="lg:block xl:col-span-2 2xl:col-span-1 pb-6">
                <>
                  <div className="bg-white px-3 mt-[3.2rem] rounded-lg ">
                    <div className="overflow-y-scroll h-screen">
                      <div className="h-14 pt-6">
                        <div class="relative mb-1 w-full">
                          <TextFieldSmall
                            value={searchedValue}
                            label="Search"
                            onChangeValue={(e) =>
                              setSearchedValue(e.target.value)
                            }
                            backgroundTextColor="bg-[#F0F0F0]"
                            thickBorder="border-[1px]"
                            topTextWeight="font-medium"
                            placeholder="Search module here..."
                            image={search}
                            dimension="h-4 w-4"
                          />
                        </div>
                      </div>
                      <div className="px-2 pt-5">
                        {filters_tests.map((section) => (
                          <Disclosure
                            as="div"
                            key={section.id}
                            defaultOpen={true}
                            className="border-b border-gray-300 py-6"
                          >
                            {({ open }) => (
                              <>
                                <h3 className="-my-3 flow-root">
                                  <Disclosure.Button className="flex w-full items-center justify-between  py-3 text-sm text-gray-400 hover:text-gray-500">
                                    <span className="font-bold text-base text-gray-900">
                                      {section.name}
                                    </span>
                                    <span className="ml-6 flex items-center">
                                      {open ? (
                                        <IoIosArrowUp
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <IoIosArrowDown
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      )}
                                    </span>
                                  </Disclosure.Button>
                                </h3>
                                <Disclosure.Panel className="pt-6">
                                  <div className="space-y-2 ">
                                    {section?.id === "category" ? (
                                      <div className="grid grid-cols-2 gap-2">
                                        {section?.options
                                          .slice(1)
                                          .map((option, optionIdx) => (
                                            <CustomButton
                                              label={option?.label}
                                              onClickButton={(e) =>
                                                handleRadio(e, option?.value)
                                              }
                                              bgColor={
                                                entry_level === option?.value
                                                  ? "#252E3A"
                                                  : "#F6F7F7"
                                              }
                                              borderCustom={`${
                                                entry_level === option?.value
                                                  ? "text-white"
                                                  : "text-coalColor"
                                              }`}
                                              hoverBgColor="#252E3A"
                                              hoverTextColor="#FFFFFF"
                                            />
                                          ))}
                                      </div>
                                    ) : (
                                      <>
                                        {section?.id === "job_role" ? (
                                          <>
                                            <div className="mb-4">
                                              <TextFieldSmall
                                                value={jobsSearch}
                                                label="Search"
                                                onChangeValue={(e) =>
                                                  setJobsSearch(e.target.value)
                                                }
                                                backgroundTextColor="bg-[#F0F0F0]"
                                                thickBorder="border-[1px]"
                                                topTextWeight="font-medium"
                                                placeholder="Search Job here..."
                                                image={search}
                                                dimension="h-4 w-4"
                                              />
                                            </div>
                                            <div
                                              className="h-[20rem] overflow-hidden enable-scrollbar2 relative"
                                              ref={listJobRef}
                                            >
                                              <Scrollbars
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                  overflowX: "hidden",
                                                }}
                                                onScrollFrame={
                                                  handleScrollFrame
                                                }
                                              >
                                                {allJobData?.filter(
                                                  (i) => i.status === "active"
                                                ).length > 0 ? (
                                                  allJobData?.map(
                                                    (i) =>
                                                      i?.status ===
                                                        "active" && (
                                                        <div
                                                          key={i.id}
                                                          className="flex items-center"
                                                        >
                                                          <label
                                                            className="relative flex cursor-pointer items-center rounded-full p-3"
                                                            htmlFor={`radio_${i.id}`}
                                                            data-ripple-dark="true"
                                                          >
                                                            <input
                                                              id="ripple-on"
                                                              type="checkbox"
                                                              onClick={() =>
                                                                handleCheckboxJobs(
                                                                  i?.id
                                                                )
                                                              }
                                                              checked={jobs.includes(
                                                                i.id
                                                              )}
                                                              onChange={() =>
                                                                handleCheckboxJobs(
                                                                  i?.id
                                                                )
                                                              }
                                                              className={`peer relative h-4 w-4 cursor-pointer checked:bg-coalColor appearance-none rounded border-[2px] transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-8 before:w-8 border-gray-600  before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-coalColor  hover:before:opacity-50 `}
                                                            />
                                                            <div
                                                              className={`pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 ${
                                                                jobs.includes(
                                                                  i.id
                                                                )
                                                                  ? "text-primaryGreen"
                                                                  : "text-coalColor"
                                                              } opacity-0 transition-opacity peer-checked:opacity-100`}
                                                            >
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-3.5 w-3.5"
                                                                viewBox="0 0 20 20"
                                                                fill="currentColor"
                                                                stroke="currentColor"
                                                                strokeWidth="1"
                                                              >
                                                                <path
                                                                  fillRule="evenodd"
                                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                  clipRule="evenodd"
                                                                ></path>
                                                              </svg>
                                                            </div>
                                                          </label>
                                                          <label className="ml-0 text-sm text-gray-600 font-medium">
                                                            {i.name}
                                                          </label>
                                                        </div>
                                                      )
                                                  )
                                                ) : (
                                                  <div className="flex items-center justify-center h-full">
                                                    <p className="text-sm text-gray-600 font-medium">
                                                      No active jobs available
                                                    </p>
                                                  </div>
                                                )}
                                              </Scrollbars>
                                              {/* {jobsLoading ||
                                                (jobLoader && (
                                                  <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-white bg-opacity-75">
                                                    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
                                                  </div>
                                                ))} */}
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            {section?.id === "industry" ? (
                                              <div>
                                                <div className="mb-4">
                                                  <TextFieldSmall
                                                    value={industrySearch}
                                                    label="Search"
                                                    onChangeValue={(e) =>
                                                      setIndustrySearch(
                                                        e.target.value
                                                      )
                                                    }
                                                    backgroundTextColor="bg-[#F0F0F0]"
                                                    thickBorder="border-[1px]"
                                                    topTextWeight="font-medium"
                                                    placeholder="Search industry here..."
                                                    image={search}
                                                    dimension="h-4 w-4"
                                                  />
                                                </div>
                                                <div className="h-[20rem] overflow-auto enable-scrollbar2">
                                                  <Scrollbars
                                                    autoHide
                                                    style={{
                                                      width: "100%",
                                                      height: "100%",
                                                      overflowX: "hidden",
                                                    }}
                                                    renderTrackHorizontal={(
                                                      props
                                                    ) => (
                                                      <div
                                                        {...props}
                                                        style={{
                                                          display: "none",
                                                        }}
                                                        className="track-horizontal"
                                                      />
                                                    )}
                                                    renderView={(props) => (
                                                      <div
                                                        {...props}
                                                        style={{
                                                          ...props.style,
                                                          overflowX: "hidden",
                                                        }}
                                                      />
                                                    )}
                                                  >
                                                    {indusrtyData?.data?.map(
                                                      (i) => (
                                                        <div
                                                          key={i.id}
                                                          className="flex items-center"
                                                        >
                                                          <label
                                                            className="relative flex cursor-pointer items-center rounded-full p-3"
                                                            htmlFor={`radio_${i.id}`}
                                                            data-ripple-dark="true"
                                                          >
                                                            <input
                                                              id="ripple-on"
                                                              type="checkbox"
                                                              onClick={() =>
                                                                handleCheckbox(
                                                                  i?.id
                                                                )
                                                              }
                                                              checked={industries.includes(
                                                                i.id
                                                              )}
                                                              onChange={() =>
                                                                handleCheckbox(
                                                                  i?.id
                                                                )
                                                              }
                                                              className={`peer relative h-4 w-4 cursor-pointer checked:bg-coalColor  appearance-none rounded border-[2px] transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-8 before:w-8 border-gray-600  before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-coalColor  hover:before:opacity-50 `}
                                                            />
                                                            <div
                                                              className={`pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 ${
                                                                industries.includes(
                                                                  i.id
                                                                )
                                                                  ? "text-primaryGreen"
                                                                  : "text-coalColor"
                                                              } opacity-0 transition-opacity peer-checked:opacity-100`}
                                                            >
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-3.5 w-3.5"
                                                                viewBox="0 0 20 20"
                                                                fill="currentColor"
                                                                stroke="currentColor"
                                                                strokeWidth="1"
                                                              >
                                                                <path
                                                                  fillRule="evenodd"
                                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                  clipRule="evenodd"
                                                                ></path>
                                                              </svg>
                                                            </div>
                                                          </label>
                                                          <label className="ml-0 text-sm text-gray-600 font-medium">
                                                            {i.categoryName}
                                                          </label>
                                                        </div>
                                                      )
                                                    )}
                                                  </Scrollbars>
                                                </div>
                                              </div>
                                            ) : (
                                              <div>
                                                <div className="mb-4">
                                                  <TextFieldSmall
                                                    value={departmentSearch}
                                                    label="Search"
                                                    onChangeValue={(e) =>
                                                      setDepartmentSearch(
                                                        e.target.value
                                                      )
                                                    }
                                                    backgroundTextColor="bg-[#F0F0F0]"
                                                    thickBorder="border-[1px]"
                                                    topTextWeight="font-medium"
                                                    placeholder="Search department here..."
                                                    image={search}
                                                    dimension="h-4 w-4"
                                                  />
                                                </div>
                                                <div className="h-[20rem] overflow-auto enable-scrollbar2">
                                                  <Scrollbars
                                                    autoHide
                                                    style={{
                                                      width: "100%",
                                                      height: "100%",
                                                      overflowX: "hidden",
                                                    }}
                                                    renderTrackHorizontal={(
                                                      props
                                                    ) => (
                                                      <div
                                                        {...props}
                                                        style={{
                                                          display: "none",
                                                        }}
                                                        className="track-horizontal"
                                                      />
                                                    )}
                                                    renderView={(props) => (
                                                      <div
                                                        {...props}
                                                        style={{
                                                          ...props.style,
                                                          overflowX: "hidden",
                                                        }}
                                                      />
                                                    )}
                                                  >
                                                    {departmentData?.data
                                                      ?.length > 0 ? (
                                                      departmentData.data.map(
                                                        (i) => (
                                                          <div
                                                            key={i.id}
                                                            className="flex items-center"
                                                          >
                                                            <label
                                                              className="relative flex cursor-pointer items-center rounded-full p-3"
                                                              htmlFor={`radio_${i.id}`}
                                                              data-ripple-dark="true"
                                                            >
                                                              <input
                                                                id="ripple-on"
                                                                type="checkbox"
                                                                onClick={() =>
                                                                  handleCheckboxDepartments(
                                                                    i?.id
                                                                  )
                                                                }
                                                                checked={departments.includes(
                                                                  i.id
                                                                )}
                                                                onChange={() =>
                                                                  handleCheckboxDepartments(
                                                                    i?.id
                                                                  )
                                                                }
                                                                className={`peer relative h-4 w-4 cursor-pointer checked:bg-coalColor appearance-none rounded border-[2px] transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-8 before:w-8 border-gray-600 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-coalColor hover:before:opacity-50`}
                                                              />
                                                              <div
                                                                className={`pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 ${
                                                                  departments.includes(
                                                                    i.id
                                                                  )
                                                                    ? "text-primaryGreen"
                                                                    : "text-coalColor"
                                                                } opacity-0 transition-opacity peer-checked:opacity-100`}
                                                              >
                                                                <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  className="h-3.5 w-3.5"
                                                                  viewBox="0 0 20 20"
                                                                  fill="currentColor"
                                                                  stroke="currentColor"
                                                                  strokeWidth="1"
                                                                >
                                                                  <path
                                                                    fillRule="evenodd"
                                                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                    clipRule="evenodd"
                                                                  ></path>
                                                                </svg>
                                                              </div>
                                                            </label>
                                                            <label className="ml-0 text-sm text-gray-600 font-medium">
                                                              {i.categoryName}
                                                            </label>
                                                          </div>
                                                        )
                                                      )
                                                    ) : (
                                                      <div className="flex items-center justify-center h-full">
                                                        <p className="text-sm text-gray-600 font-medium">
                                                          No departments
                                                          available
                                                        </p>
                                                      </div>
                                                    )}
                                                  </Scrollbars>
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        ))}
                        <Disclosure
                          as="div"
                          key={test_duration[0]?.id}
                          defaultOpen={true}
                          className="border-b border-gray-300 py-6"
                        >
                          {({ open }) => (
                            <>
                              <h3 className="-my-3 flow-root">
                                <Disclosure.Button className="flex w-full items-center justify-between  py-3 text-sm text-gray-400 hover:text-gray-500">
                                  <span className="font-bold text-base text-gray-900">
                                    Time Duration
                                  </span>
                                  <span className="ml-6 flex items-center">
                                    {open ? (
                                      <IoIosArrowUp
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <IoIosArrowDown
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </span>
                                </Disclosure.Button>
                              </h3>
                              <Disclosure.Panel className="pt-6">
                                <div className="grid grid-cols-2 gap-2">
                                  {test_duration?.map((i, j) => (
                                    <CustomButton
                                      label={i?.label}
                                      onClickButton={(e) =>
                                        handleDurationRadios(
                                          e,
                                          i.value1,
                                          i.value2
                                        )
                                      }
                                      bgColor={
                                        startTime.startTime == i.value1 &&
                                        endTime.endTime == i.value2
                                          ? "#252E3A"
                                          : "#F6F7F7"
                                      }
                                      textColor={
                                        startTime.startTime == i.value1 &&
                                        endTime.endTime == i.value2
                                          ? "#C0FF06"
                                          : "#7C8289"
                                      }
                                      borderCustom={`${
                                        startTime.startTime == i.value1 &&
                                        endTime.endTime == i.value2
                                          ? "text-white"
                                          : "text-coalColor"
                                      }`}
                                      hoverBgColor="#252E3A"
                                      hoverTextColor="#FFFFFF"
                                    />
                                  ))}
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      </div>
                    </div>
                  </div>
                </>
              </form>
            </div>
          </div>
          <section
            aria-labelledby="products-heading"
            className="pb-6 sm:px-0 md:px-2"
          >
            <h2 id="products-heading" className="sr-only">
              Products
            </h2>
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 xl:grid-cols-7 2xl:grid-cols-5">
              <form className="hidden lg:block xl:col-span-2 2xl:col-span-1 pb-6">
                <>
                  <div className="bg-white px-3 mt-[3.2rem] rounded-lg flex flex-col h-screen">
                    <div className="overflow-y-scroll h-screen">
                      {/* <div className="h-14 pt-6">
                        <div class="relative mb-1 w-full" id="tests3">
                          <TextFieldSmall
                            value={searchedValue}
                            label="Search"
                            onChangeValue={(e) =>
                              setSearchedValue(e.target.value)
                            }
                            backgroundTextColor="bg-[#F0F0F0]"
                            thickBorder="border-[1px]"
                            topTextWeight="font-medium"
                            placeholder="Search module here..."
                            image={search}
                            dimension="h-4 w-4"
                          />
                        </div>
                      </div> */}
                      <div className="px-2 pt-2">
                        {filters_tests.map((section) => (
                          <Disclosure
                            as="div"
                            key={section.id}
                            defaultOpen={true}
                            className="border-b border-gray-300 py-6"
                          >
                            {({ open }) => (
                              <>
                                <h3 className="-my-3 flow-root">
                                  <Disclosure.Button className="flex w-full items-center justify-between  py-3 text-sm text-gray-400 hover:text-gray-500">
                                    <span className="font-bold text-base text-gray-900">
                                      {section.name}
                                    </span>
                                    <span className="ml-6 flex items-center">
                                      {open ? (
                                        <IoIosArrowUp
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <IoIosArrowDown
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      )}
                                    </span>
                                  </Disclosure.Button>
                                </h3>
                                <Disclosure.Panel className="pt-6">
                                  <div className="space-y-2 ">
                                    {section?.id === "category" ? (
                                      <div className="grid grid-cols-2 gap-2">
                                        {section?.options
                                          .slice(1)
                                          .map((option, optionIdx) => (
                                            <div className="flex relative">
                                              <CustomButton
                                                label={option?.label}
                                                onClickButton={(e) => {
                                                  handleRadio(e, option?.value);
                                                }}
                                                bgColor={
                                                  entry_level === option?.value
                                                    ? "#252E3A"
                                                    : "#F6F7F7"
                                                }
                                                borderCustom={`${
                                                  entry_level === option?.value
                                                    ? "text-white"
                                                    : "text-coalColor"
                                                }`}
                                                hoverBgColor="#252E3A"
                                                hoverTextColor="#FFFFFF"
                                                buttonType="button"
                                              />
                                              <div className="tooltip w-[8rem] z-40 bg-coalColor text-[14px] font-medium text-center">
                                                {option?.value === "Beginner" &&
                                                  "1-2 years experience"}
                                                {option?.value ===
                                                  "Intermediate" &&
                                                  "3-5 years experience"}
                                                {option?.value === "Advanced" &&
                                                  "6+ years experience"}
                                                {option?.value === "All" &&
                                                  "All levels of experience"}
                                              </div>
                                            </div>
                                          ))}
                                      </div>
                                    ) : (
                                      <>
                                        {section?.id === "job_role" ? (
                                          <>
                                            <div className="mb-4">
                                              <TextFieldSmall
                                                value={jobsSearch}
                                                label="Search"
                                                onChangeValue={(e) =>
                                                  setJobsSearch(e.target.value)
                                                }
                                                backgroundTextColor="bg-[#F0F0F0]"
                                                thickBorder="border-[1px]"
                                                topTextWeight="font-medium"
                                                placeholder="Search Job here..."
                                                image={search}
                                                dimension="h-4 w-4"
                                              />
                                            </div>
                                            <div
                                              className="h-[20rem] overflow-hidden enable-scrollbar2 relative"
                                              ref={listJobRef}
                                            >
                                              <Scrollbars
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                  overflowX: "hidden",
                                                }}
                                                onScrollFrame={
                                                  handleScrollFrame
                                                }
                                              >
                                                {allJobData?.filter(
                                                  (i) => i.status === "active"
                                                ).length > 0 ? (
                                                  allJobData?.map(
                                                    (i) =>
                                                      i?.status ===
                                                        "active" && (
                                                        <div
                                                          key={i.id}
                                                          className="flex items-center"
                                                        >
                                                          <label
                                                            className="relative flex cursor-pointer items-center rounded-full p-3"
                                                            htmlFor={`radio_${i.id}`}
                                                            data-ripple-dark="true"
                                                          >
                                                            <input
                                                              id="ripple-on"
                                                              type="checkbox"
                                                              onClick={() =>
                                                                handleCheckboxJobs(
                                                                  i?.id
                                                                )
                                                              }
                                                              checked={jobs.includes(
                                                                i.id
                                                              )}
                                                              onChange={() =>
                                                                handleCheckboxJobs(
                                                                  i?.id
                                                                )
                                                              }
                                                              className={`peer relative h-4 w-4 cursor-pointer checked:bg-coalColor appearance-none rounded border-[2px] transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-8 before:w-8 border-gray-600  before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-coalColor  hover:before:opacity-50 `}
                                                            />
                                                            <div
                                                              className={`pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 ${
                                                                jobs.includes(
                                                                  i.id
                                                                )
                                                                  ? "text-primaryGreen"
                                                                  : "text-coalColor"
                                                              } opacity-0 transition-opacity peer-checked:opacity-100`}
                                                            >
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-3.5 w-3.5"
                                                                viewBox="0 0 20 20"
                                                                fill="currentColor"
                                                                stroke="currentColor"
                                                                strokeWidth="1"
                                                              >
                                                                <path
                                                                  fillRule="evenodd"
                                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                  clipRule="evenodd"
                                                                ></path>
                                                              </svg>
                                                            </div>
                                                          </label>
                                                          <label className="ml-0 text-sm text-gray-600 font-medium">
                                                            {i.name}
                                                          </label>
                                                        </div>
                                                      )
                                                  )
                                                ) : (
                                                  <div className="flex items-center justify-center h-full">
                                                    <p className="text-sm text-gray-600 font-medium">
                                                      No active jobs available
                                                    </p>
                                                  </div>
                                                )}
                                              </Scrollbars>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            {section?.id === "industry" ? (
                                              <div>
                                                <div className="mb-4">
                                                  <TextFieldSmall
                                                    value={industrySearch}
                                                    label="Search"
                                                    onChangeValue={(e) =>
                                                      setIndustrySearch(
                                                        e.target.value
                                                      )
                                                    }
                                                    backgroundTextColor="bg-[#F0F0F0]"
                                                    thickBorder="border-[1px]"
                                                    topTextWeight="font-medium"
                                                    placeholder="Search industry here..."
                                                    image={search}
                                                    dimension="h-4 w-4"
                                                  />
                                                </div>
                                                <div className="h-[20rem] overflow-auto enable-scrollbar2">
                                                  <Scrollbars
                                                    autoHide
                                                    style={{
                                                      width: "100%",
                                                      height: "100%",
                                                      overflowX: "hidden",
                                                    }}
                                                    renderTrackHorizontal={(
                                                      props
                                                    ) => (
                                                      <div
                                                        {...props}
                                                        style={{
                                                          display: "none",
                                                        }}
                                                        className="track-horizontal"
                                                      />
                                                    )}
                                                    renderView={(props) => (
                                                      <div
                                                        {...props}
                                                        style={{
                                                          ...props.style,
                                                          overflowX: "hidden",
                                                        }}
                                                      />
                                                    )}
                                                  >
                                                    {indusrtyData?.data?.map(
                                                      (i) => (
                                                        <div
                                                          key={i.id}
                                                          className="flex items-center"
                                                        >
                                                          <label
                                                            className="relative flex cursor-pointer items-center rounded-full p-3"
                                                            htmlFor={`radio_${i.id}`}
                                                            data-ripple-dark="true"
                                                          >
                                                            <input
                                                              id="ripple-on"
                                                              type="checkbox"
                                                              onClick={() =>
                                                                handleCheckbox(
                                                                  i?.id
                                                                )
                                                              }
                                                              checked={industries.includes(
                                                                i.id
                                                              )}
                                                              onChange={() =>
                                                                handleCheckbox(
                                                                  i?.id
                                                                )
                                                              }
                                                              className={`peer relative h-4 w-4 cursor-pointer checked:bg-coalColor  appearance-none rounded border-[2px] transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-8 before:w-8 border-gray-600  before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-coalColor  hover:before:opacity-50 `}
                                                            />
                                                            <div
                                                              className={`pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 ${
                                                                industries.includes(
                                                                  i.id
                                                                )
                                                                  ? "text-primaryGreen"
                                                                  : "text-coalColor"
                                                              } opacity-0 transition-opacity peer-checked:opacity-100`}
                                                            >
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-3.5 w-3.5"
                                                                viewBox="0 0 20 20"
                                                                fill="currentColor"
                                                                stroke="currentColor"
                                                                strokeWidth="1"
                                                              >
                                                                <path
                                                                  fillRule="evenodd"
                                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                  clipRule="evenodd"
                                                                ></path>
                                                              </svg>
                                                            </div>
                                                          </label>
                                                          <label className="ml-0 text-sm text-gray-600 font-medium">
                                                            {i.categoryName}
                                                          </label>
                                                        </div>
                                                      )
                                                    )}
                                                  </Scrollbars>
                                                </div>
                                              </div>
                                            ) : (
                                              <div>
                                                <div className="mb-4">
                                                  <TextFieldSmall
                                                    value={departmentSearch}
                                                    label="Search"
                                                    onChangeValue={(e) =>
                                                      setDepartmentSearch(
                                                        e.target.value
                                                      )
                                                    }
                                                    backgroundTextColor="bg-[#F0F0F0]"
                                                    thickBorder="border-[1px]"
                                                    topTextWeight="font-medium"
                                                    placeholder="Search department here..."
                                                    image={search}
                                                    dimension="h-4 w-4"
                                                  />
                                                </div>
                                                <div className="h-[20rem] overflow-auto enable-scrollbar2">
                                                  <Scrollbars
                                                    autoHide
                                                    style={{
                                                      width: "100%",
                                                      height: "100%",
                                                      overflowX: "hidden",
                                                    }}
                                                    renderTrackHorizontal={(
                                                      props
                                                    ) => (
                                                      <div
                                                        {...props}
                                                        style={{
                                                          display: "none",
                                                        }}
                                                        className="track-horizontal"
                                                      />
                                                    )}
                                                    renderView={(props) => (
                                                      <div
                                                        {...props}
                                                        style={{
                                                          ...props.style,
                                                          overflowX: "hidden",
                                                        }}
                                                      />
                                                    )}
                                                  >
                                                    {departmentData?.data
                                                      ?.length > 0 ? (
                                                      departmentData.data.map(
                                                        (i) => (
                                                          <div
                                                            key={i.id}
                                                            className="flex items-center"
                                                          >
                                                            <label
                                                              className="relative flex cursor-pointer items-center rounded-full p-3"
                                                              htmlFor={`radio_${i.id}`}
                                                              data-ripple-dark="true"
                                                            >
                                                              <input
                                                                id="ripple-on"
                                                                type="checkbox"
                                                                onClick={() =>
                                                                  handleCheckboxDepartments(
                                                                    i?.id
                                                                  )
                                                                }
                                                                checked={departments.includes(
                                                                  i.id
                                                                )}
                                                                onChange={() =>
                                                                  handleCheckboxDepartments(
                                                                    i?.id
                                                                  )
                                                                }
                                                                className={`peer relative h-4 w-4 cursor-pointer checked:bg-coalColor appearance-none rounded border-[2px] transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-8 before:w-8 border-gray-600 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-coalColor hover:before:opacity-50`}
                                                              />
                                                              <div
                                                                className={`pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 ${
                                                                  departments.includes(
                                                                    i.id
                                                                  )
                                                                    ? "text-primaryGreen"
                                                                    : "text-coalColor"
                                                                } opacity-0 transition-opacity peer-checked:opacity-100`}
                                                              >
                                                                <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  className="h-3.5 w-3.5"
                                                                  viewBox="0 0 20 20"
                                                                  fill="currentColor"
                                                                  stroke="currentColor"
                                                                  strokeWidth="1"
                                                                >
                                                                  <path
                                                                    fillRule="evenodd"
                                                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                    clipRule="evenodd"
                                                                  ></path>
                                                                </svg>
                                                              </div>
                                                            </label>
                                                            <label className="ml-0 text-sm text-gray-600 font-medium">
                                                              {i.categoryName}
                                                            </label>
                                                          </div>
                                                        )
                                                      )
                                                    ) : (
                                                      <div className="flex items-center justify-center h-full">
                                                        <p className="text-sm text-gray-600 font-medium">
                                                          No departments
                                                          available
                                                        </p>
                                                      </div>
                                                    )}
                                                  </Scrollbars>
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        ))}
                        <Disclosure
                          as="div"
                          key={test_duration[0]?.id}
                          defaultOpen={true}
                          className="border-b border-gray-300 py-6"
                        >
                          {({ open }) => (
                            <>
                              <h3 className="-my-3 flow-root">
                                <Disclosure.Button className="flex w-full items-center justify-between  py-3 text-sm text-gray-400 hover:text-gray-500">
                                  <span className="font-bold text-base text-gray-900">
                                    Time Duration
                                  </span>
                                  <span className="ml-6 flex items-center">
                                    {open ? (
                                      <IoIosArrowUp
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <IoIosArrowDown
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </span>
                                </Disclosure.Button>
                              </h3>
                              <Disclosure.Panel className="pt-6">
                                <div className="grid grid-cols-2 gap-2">
                                  {test_duration?.map((i, j) => (
                                    <CustomButton
                                      label={i?.label}
                                      onClickButton={(e) =>
                                        handleDurationRadios(
                                          e,
                                          i.value1,
                                          i.value2
                                        )
                                      }
                                      bgColor={
                                        startTime.startTime == i.value1 &&
                                        endTime.endTime == i.value2
                                          ? "#252E3A"
                                          : "#F6F7F7"
                                      }
                                      textColor={
                                        startTime.startTime == i.value1 &&
                                        endTime.endTime == i.value2
                                          ? "#C0FF06"
                                          : "#7C8289"
                                      }
                                      borderCustom={`${
                                        startTime.startTime == i.value1 &&
                                        endTime.endTime == i.value2
                                          ? "text-white"
                                          : "text-coalColor"
                                      }`}
                                      hoverBgColor="#252E3A"
                                      hoverTextColor="#FFFFFF"
                                    />
                                  ))}
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      </div>
                    </div>
                    {(searchedValue !== "" ||
                      entry_level !== "" ||
                      jobs?.length !== 0 ||
                      industries?.length !== 0 ||
                      departments?.length !== 0 ||
                      startTime?.startTime !== "" ||
                      startTime?.endTime !== "") && (
                      <div className="sticky bottom-0 left-0 right-0 p-4  bg-white border-gray-200 mt-auto">
                        <button
                          className="w-full py-3 px-4 bg-primaryGreen hover:bg-coalColor text-coalColor hover:text-primaryGreen rounded-lg font-medium transition-colors duration-200"
                          type="submit"
                          onClick={() => {
                            setSearchedValue("");
                            dispatch(setEntryLevel(""));
                            dispatch(setIndustryData([]));
                            dispatch(setDepartmentData([]));
                            dispatch(setJobsData([]));
                            dispatch(
                              setStartTime({ startTime: "", endTime: "" })
                            );
                          }}
                        >
                          Reset Filters
                        </button>
                      </div>
                    )}
                  </div>
                </>
              </form>
              <div className="xl:col-span-5 2xl:col-span-4">
                {isLoading ||
                industryLoading ||
                jobsLoading ||
                staticLoading ||
                departmentLoading ? (
                  <div className="mt-[53px] overflow-y-scroll h-screen bg-white rounded-md gap-3 px-2 md:px-5 py-3">
                    <Skeleton width={300} height={24} className="mb-4" />
                    <div className="grid h-auto w-full sm:grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3">
                      {[...Array(9)].map((_, index) => (
                        <SkeletonCard key={index} />
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col pb-6">
                    <div className="">
                      {data?.data?.relatedData?.length == 0 ? (
                        <div className="px-6 mt-3">
                          <div className="border border-[#FF5812] py-4 mt-[14rem] 2xl:mt-[21rem] rounded">
                            <p
                              className="text-alertRed text-center"
                              style={{ fontFamily: "Silka" }}
                            >
                              No modules found. If you would like us to
                              prioritise creation of a new module, please let us
                              know by {""}
                              <span
                                style={{ fontFamily: "Silka Bold" }}
                                className="font-bold cursor-pointer"
                                onClick={handleEmailClick}
                              >
                                clicking here
                              </span>
                              . Our typical turnaround time for a module is 1-2
                              weeks
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="mt-[53px] overflow-y-scroll h-screen bg-white rounded-md gap-3 px-2 md:px-5 py-3">
                          <p
                            style={{ fontFamily: "Silka" }}
                            className="py-4 text-lg font-bold"
                          >
                            {data?.meta?.itemCount} Modules available in English
                          </p>
                          <div className="grid h-auto w-full sm:grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3">
                            {data?.data?.relatedData?.map((i, j) => {
                              return (
                                <React.Fragment key={j}>
                                  {(i.status === "active" ||
                                    i?.status === "coming_soon") && (
                                    <div className="" key={j}>
                                      <div className="py-2">
                                        <div
                                          className={`bg-[#F6F7F7] px-5 border relative border-[#D3D5D8] corner-ribbon p-3 rounded-lg`}
                                          id="tests2"
                                        >
                                          {i?.status === "coming_soon" && (
                                            <div className="ribbon-container">
                                              <div className="ribbon">
                                                <span class="ribbon-text">
                                                  Coming soon
                                                </span>
                                              </div>
                                            </div>
                                          )}
                                          <div className="w-full grid grid-cols-10 gap-4 h-[80px]">
                                            <div className="col-span-7 my-auto">
                                              <h1
                                                className={`text-lg font-bold good3 ${
                                                  i?.status === "coming_soon" &&
                                                  "pl-10"
                                                }  my-auto`}
                                                style={{
                                                  fontFamily: "Archia Semibold",
                                                }}
                                              >
                                                {i.name}
                                              </h1>
                                            </div>
                                            <div
                                              className="my-auto col-span-3"
                                              style={{ fontFamily: "Silka" }}
                                            >
                                              {i?.experience === "Beginner" && (
                                                <div className="inline-flex items-center border border-[#0B5B23] text-black sm:w-[80px] sm:text-xs xl:w-[72px] 2xl:w-[90px] md:w-[100px] py-1 rounded-full xl:text-[10px] 2xl:text-[12px]">
                                                  <span className="text-center mx-auto">
                                                    Beginner
                                                  </span>
                                                </div>
                                              )}

                                              {i?.experience ===
                                                "Intermediate" && (
                                                <div className="inline-flex items-center border border-[#FFB500] text-black sm:w-[90px] sm:text-xs xl:w-[72px] 2xl:w-[90px]  md:w-[100px] py-1 rounded-full xl:text-[10px] 2xl:text-[12px]">
                                                  <span className="text-center mx-auto">
                                                    Intermediate
                                                  </span>
                                                </div>
                                              )}
                                              {i?.experience === "Advanced" && (
                                                <div className="inline-flex items-center border border-[#FF5812] text-black sm:w-[80px] sm:text-xs xl:w-[72px] 2xl:w-[90px] md:w-[100px] py-1 rounded-full xl:text-[10px] 2xl:text-[12px]">
                                                  <span className="text-center mx-auto">
                                                    Advanced
                                                  </span>
                                                </div>
                                              )}
                                              {i?.experience === "General" && (
                                                <div className="inline-flex items-center border border-coalColor text-black sm:w-[80px] sm:text-xs xl:w-[72px] 2xl:w-[90px] md:w-[100px] py-1 rounded-full xl:text-[10px] 2xl:text-[12px]">
                                                  <span className="text-center mx-auto">
                                                    All levels
                                                  </span>
                                                </div>
                                              )}
                                              {i?.type ===
                                                "custom-questions" && (
                                                <div className="inline-flex items-center border border-[#C0FF06] text-black  w-[150px] py-2 rounded-full xl:text-[10px] 2xl:text-[12px]">
                                                  <span className="text-center mx-auto">
                                                    Custom questions
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                          <div className="flex flex-row gap-2 mt-5">
                                            <img
                                              src={clock}
                                              className="w-4 h-4"
                                            />
                                            <p
                                              className="text-xs text-coalColor"
                                              style={{
                                                fontFamily: "Silka Light",
                                              }}
                                            >
                                              {i?.time} mins
                                            </p>
                                          </div>
                                          <div className="h-[90px] pr-3 text-sm 2xl:mt-3 sm:mt-3">
                                            <div
                                              style={{
                                                fontFamily: "Silka",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                display: "-webkit-box",
                                                WebkitLineClamp: 3,
                                                WebkitBoxOrient: "vertical",
                                              }}
                                            >
                                              <div
                                                className={
                                                  styles["html-content"]
                                                }
                                              >
                                                {ReactHtmlParser(i.notes)}
                                              </div>
                                            </div>
                                            {i.notes.length > 100 && (
                                              <div
                                                className="underline"
                                                style={{
                                                  color: "#252E3A",
                                                  cursor: "pointer",
                                                  fontFamily: "Silka",
                                                }}
                                                onClick={() => {
                                                  setSectionID(i.id);
                                                  setDropdownOpen(true);
                                                }}
                                              >
                                                {" "}
                                                Read more
                                              </div>
                                            )}
                                          </div>
                                          <div className="my-auto mt-3">
                                            <button
                                              className={`inline-flex items-center bg-coalColor border border-coalColor justify-center px-4 w-full py-3 hover:text-coalColor hover:bg-primaryGreen text-white text-sm font-medium rounded-md relative`}
                                              onClick={() => {
                                                handleButtonClickPreview(i?.id);
                                              }}
                                              style={{ fontFamily: "Silka" }}
                                            >
                                              Sample Questions
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {dropdownOpen && (
                  <div
                    className="relative z-10"
                    aria-labelledby="modal-title"
                    role="dialog"
                    aria-modal="true"
                  >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                    <div
                      className="fixed inset-0 z-10 overflow-y-auto"
                      onClick={() => setDropdownOpen(false)}
                    >
                      <div className="flex items-start justify-end  text-center">
                        <div
                          className="relative transform overflow-x-scroll no-scrollbar bg-bodyColor text-left shadow-xl transition-all sm:w-full md:w-1/2"
                          onClick={handleButtonClick}
                        >
                          <div className="bg-bodyColor h-screen px-4 sm:px-6 no-scrollbar overflow-auto pb-8">
                            {isLoadingModule ? (
                              <div class="loader-container-1">
                                <div class="loader-1"></div>
                              </div>
                            ) : (
                              <>
                                <div
                                  className="flex justify-end sticky top-0 "
                                  style={{ paddingTop: "100px" }}
                                >
                                  <img
                                    src={close}
                                    className="w-8 h-8 cursor-pointer"
                                    onClick={() => setDropdownOpen(false)}
                                  />
                                </div>
                                <div className="sm:px-0 md:px-0 lg:px-0 xl:px-10">
                                  <h1 className="mt-4 text-xl font-bold">
                                    {ModuleData?.name}
                                  </h1>
                                  <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 bg-coalColor mt-10 rounded-lg">
                                    <div className="p-2 pl-5 border-r py-5">
                                      <img src={d1} className="w-8 h-8" />
                                      <p className="text-lg text-white mt-1">
                                        {ModuleData?.module_type ===
                                        "multiple_choice"
                                          ? "Multiple Choice"
                                          : "Case Study"}
                                      </p>
                                      <p className="text-xs text-white">
                                        {ModuleData?.category?.categoryName}
                                      </p>
                                    </div>
                                    <div className="p-2 pl-5 border-r py-5">
                                      <img src={d2} className="w-8 h-8" />
                                      <p className="text-lg text-white mt-1">
                                        Time
                                      </p>
                                      <p className="text-xs text-white">
                                        {ModuleData?.time} mins
                                      </p>
                                    </div>
                                    <div className="p-2 pl-5 border-r py-5">
                                      <img src={d3} className="w-8 h-8" />
                                      <p className="text-lg text-white mt-1">
                                        Language
                                      </p>
                                      <p className="text-xs text-white">
                                        English
                                      </p>
                                    </div>
                                    <div className="p-2 pl-5 border-r py-5">
                                      <img src={d4} className="w-8 h-8" />
                                      <p className="text-lg text-white mt-1">
                                        Level
                                      </p>
                                      <p className="text-xs text-white">
                                        {ModuleData?.experience === "General"
                                          ? "All levels"
                                          : ModuleData?.experience}
                                      </p>
                                    </div>
                                  </div>
                                  <h1
                                    className="mt-10 text-xl font-bold"
                                    style={{ fontFamily: "Archia Bold" }}
                                  >
                                    Covered Skills
                                  </h1>
                                  <div
                                    className={styles["html-content"]}
                                    style={{ fontFamily: "Silka" }}
                                  >
                                    {ReactHtmlParser(
                                      ModuleData?.covering_skills
                                    )}
                                  </div>
                                  <h1
                                    className="mt-10 text-xl font-bold"
                                    style={{ fontFamily: "Archia Bold" }}
                                  >
                                    This module is relevant for
                                  </h1>
                                  <div
                                    className="text-[#767676]"
                                    style={{ fontFamily: "Silka" }}
                                  >
                                    <div className={styles["html-content"]}>
                                      {ReactHtmlParser(
                                        ModuleData?.test_relevent_for
                                      )}
                                    </div>
                                  </div>
                                  <h1
                                    className="mt-10 text-xl font-bold"
                                    style={{ fontFamily: "Archia Bold" }}
                                  >
                                    Description
                                  </h1>
                                  <div
                                    className={styles["html-content"]}
                                    style={{ fontFamily: "Silka" }}
                                  >
                                    {ReactHtmlParser(ModuleData?.notes)}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
}
