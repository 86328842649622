import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { useQueryClient } from "@tanstack/react-query";
import closeIcon from "../../../../../Dexta_assets/closeModal.png";
import CustomButton from "../../../../../Components/CustomButton/CustomButton";
import queryString from "query-string";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Premium from "../../../../../Components/Modals/Premium";
import CustomizeHiringEmail from "../../../../../Components/CustomizeEmail/CustomizeHiringEmail";
import EmailConfirmation from "./EmailConfirmation";

export default function HiringModal({
  hiringOpen,
  setHiringOpen,
  hiringStatus,
  hiringLabel,
  typeHiring,
  checkboxText,
  firstName,
  lastName,
  uID,
  noCustomization,
  HiringType,
  selectedCandidates,
  setSelectedRows,
  setSelectAll,
}) {
  const [customizeCustomModal, setCustomizeCustomModal] = useState(false);
  const [emailContent, setEmailContent] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailType, setEmailType] = useState("");
  const [premiumOpen, setPremiumOpen] = useState(false);
  const [emailConfirmationOpen, setEmailConfirmationOpen] = useState(false);

  return (
    <Dialog
      open={hiringOpen}
      onClose={() => {
        setHiringOpen(false);
      }}
      className="fixed inset-0 z-40 flex items-center justify-center overflow-y-auto"
    >
      <CustomizeHiringEmail
        customizeCustomModal={customizeCustomModal}
        setCustomizeCustomModal={setCustomizeCustomModal}
        emailContent={emailContent}
        emailSubject={emailSubject}
        emailType={emailType}
        passCheck={true}
        setHiringOpen={setHiringOpen}
        firstName={noCustomization ? "Candidate" : firstName}
        lastName={lastName}
      />
      <EmailConfirmation
        EmailConfirmationOpen={emailConfirmationOpen}
        setEmailConfirmationOpen={setEmailConfirmationOpen}
        hiringStatus={hiringStatus}
        hiringLabel={hiringLabel}
        typeHiring={typeHiring}
        checkboxText={checkboxText}
        firstName={firstName}
        lastName={lastName}
        uID={uID}
        noCustomization={noCustomization}
        HiringType={HiringType}
        selectedCandidates={selectedCandidates}
        setSelectedRows={setSelectedRows}
        setSelectAll={setSelectAll}
        hiringOpen={hiringOpen}
        setHiringOpen={setHiringOpen}
      />
      <Premium premiumOpen={premiumOpen} setPremiumOpen={setPremiumOpen} />
      <div className="fixed inset-0 bg-black bg-opacity-75" />
      <Dialog.Panel className="relative bg-white rounded-lg overflow-hidden shadow-lg transform transition-all sm:max-w-lg sm:w-full h-[12rem]">
        <div className="bg-white">
          <p
            className="text-lg font-medium text-coalColor pl-4 cursor-pointer hover:text-coalColor text-left pt-5"
            style={{ fontFamily: "Archia Bold" }}
          >
            {hiringStatus}
          </p>
          <img
            src={closeIcon}
            className="absolute top-3 right-5 z-20 w-6 h-6 cursor-pointer"
            onClick={() => {
              setHiringOpen(false);
            }}
          />
          <p className="pl-4 mt-5" style={{ fontFamily: "Silka" }}>
            {hiringLabel}
          </p>
          <div className="ml-4 mt-5">
            <div className="flex flex-row justify-end px-8 gap-5 mt-8">
              <CustomButton
                label="No"
                textSize="text-base"
                borderCustom="border border-coalColor text-white"
                bgColor="#252E3A"
                hoverBgColor="#C0FF06"
                hoverTextColor="#252E3A"
                widthButton="w-[6rem]"
                paddingY="0.3rem"
                textColor="black"
                onClickButton={() => {
                  setHiringOpen(false);
                }}
              />
              <CustomButton
                label="Yes"
                textSize="text-base"
                bgColor="#C0FF06"
                widthButton="w-[6rem]"
                textColor="black"
                borderCustom="border border-black text-coalColor"
                hoverBgColor="#252E3A"
                hoverTextColor="white"
                paddingY="0.3rem"
                disabledColor="#D3D5D8"
                disabledTextColor="#7C8289"
                onClickButton={() => {
                  setEmailConfirmationOpen(true);
                }}
              />
            </div>
          </div>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
}
