import React, { useState, useEffect, useRef } from "react";
import TextField from "../../../../Components/Dexta/TextField/TextField";
import { IoIosArrowDown, IoMdClose } from "react-icons/io";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";
import { updateUser } from "../../hooks/updateUser";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateCandidate } from "../../hooks/updateCandidate";
import { highestEducation } from "../../../../data/mapData";
import useOutsideClick from "../../../../Components/OutsideClick/OutsideClick";

const EducationInfo = ({
  educationLevel,
  setEducationLevel,
  study,
  setStudy,
}) => {
  const queryClient = useQueryClient();
  const educationref = useRef(null);
  const [educationDrop, setEducationDrop] = useState(false);
  const [otherEducation, setOtherEducation] = useState("");
  console.log(educationLevel, "hello jee");
  //#region use Formik and yup to handle forms
  const HandleEducationSubmit = () => {
    let data = JSON.stringify({
      candidateMeta: {
        educationLevel:
          educationLevel === "Highest education level" ? "" : educationLevel,
        study: study,
      },
    });
    try {
      updateMutate(data);
    } catch (error) {
      //onError will automatically detect
    }
  };
  //#endregion

  //#region mutate to update data
  const { mutate: updateMutate, isLoading: mutateLoadings } = useMutation(
    updateUser,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
        handleOnBoard();
        toast.success("Information Saved", {
          toastId: "copy-success",
        });
      },
      onError: (error) => {
        setTimeout(() => {
          toast.error(error?.response?.data?.message[0], {
            toastId: "copy-success",
          });
        }, 500);
      },
    }
  );
  //#endregion

  //#region Updating Tour Completed
  const handleOnBoard = () => {
    let data = JSON.stringify({
      isUserOnboard: true,
    });
    try {
      updateCandidateUserMutate(data);
    } catch (err) {
      console.log(err.message);
    }
  };
  //#endregion

  //#region updating tour status
  const { mutate: updateCandidateUserMutate, isLoading: updateLoading } =
    useMutation(updateCandidate, {
      onSuccess: (response) => {
        queryClient.invalidateQueries("/users");
      },
    });
  //#endregion

  //#region Dropdown close on outside click -> Country
  useOutsideClick(
    [
      {
        ref: educationref,
        excludeClasses: [".educationClass"],
        excludeIds: ["education"],
      },
    ],
    (ref) => {
      if (ref === educationref) setEducationDrop(false);
    }
  );
  //#endregion

  //#region placeholder text
  useEffect(() => {
    if (educationLevel === "") {
      setEducationLevel("Highest education level");
    }
  }, [educationLevel]);
  //#endregion

  return (
    <>
      <div className="p-3">
        <h1
          style={{
            fontFamily: "Archia Semibold",
          }}
        >
          Education information
        </h1>
        <div className="grid md:grid-cols-2 gap-8 mt-5">
          <div>
            <p className="mb-2" style={{ fontFamily: "Silka" }}>
              Highest education level
            </p>
            <div className="h-10">
              <div className="relative">
                <button
                  id="education"
                  className={`border border-[#D3D5D8] ${
                    educationLevel === "Highest education level" ||
                    educationLevel === null
                      ? "text-[#999999]"
                      : "text-coalColor"
                  }  focus-within:border focus-within:border-coalColor rounded-md px-3 text-left w-full py-4`}
                  value={educationLevel}
                  style={{ fontFamily: "Silka" }}
                  onClick={() => setEducationDrop(!educationDrop)}
                  type="button"
                >
                  {educationLevel != "" && educationLevel != null ? (
                    educationLevel === "Other (Please specify)" ? (
                      <input
                        placeholder="Please specify"
                        autoFocus
                        value={otherEducation}
                        onChange={(e) => setOtherEducation(e.target.value)}
                      />
                    ) : (
                      educationLevel
                    )
                  ) : (
                    "Highest education level"
                  )}
                  <div className="absolute top-1/2 right-3 transform -translate-y-1/2 flex flex-row ">
                    <IoMdClose
                      onClick={() => {
                        setEducationLevel("");
                      }}
                      className="cursor-pointer"
                    />
                    <IoIosArrowDown />
                  </div>
                </button>
                {educationDrop && (
                  <div className="educationClass absolute z-40 border border-coalColor right-0 top-full h-[80 overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2">
                    {highestEducation?.map((i) => (
                      <ul
                        key={i.title}
                        className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                        ref={educationref}
                        onClick={() => {
                          setEducationLevel(i?.title);
                          setEducationDrop(false);
                        }}
                      >
                        <li>
                          {" "}
                          <a className="block px-5 py-2 text-sm font-medium">
                            {" "}
                            {i.title}{" "}
                          </a>
                        </li>
                      </ul>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <p className="mb-2" style={{ fontFamily: "Silka" }}>
              What did you study?
            </p>
            <div className="h-10">
              <TextField
                type="text"
                name="study"
                label="What did you study"
                rounded="rounded-md"
                border={`border mt-3 border-[#D3D5D8] focus-within:border focus-within:border-coalColor `}
                value={study}
                onChange={(e) => setStudy(e.target.value)}
                placeholder="What did you study"
              />
            </div>
          </div>
        </div>
        <hr className="w-full mt-14 bg-[#D3D5D8] border-1" />
        <h1
          className="mt-5"
          style={{
            fontFamily: "Archia Semibold",
          }}
        >
          Language
        </h1>
        {/* <p className="mt-3" style={{ fontFamily: "Silka" }}>
          Choose the language in which you’d like to use Dexta. You will also
          receive communications in this language.
        </p> */}
        <div className="grid md:grid-cols-2 gap-8 mt-5">
          <div>
            <div className="h-10">
              <div className="relative">
                <button
                  id="education"
                  className={`border border-[#D3D5D8] 
                        text-[#999999]
               focus-within:border focus-within:border-coalColor rounded-md px-3 text-left w-full py-4`}
                  value={educationLevel}
                  style={{ fontFamily: "Silka" }}
                  type="button"
                >
                  English
                  {/* <div className="absolute top-1/2 right-3 transform -translate-y-1/2 flex flex-row ">
                    <IoMdClose
                      onClick={() => {
                        setEducationLevel("");
                      }}
                      className="cursor-pointer"
                    />
                    <IoIosArrowDown />
                  </div> */}
                </button>
              </div>
            </div>
          </div>
          <div></div>
        </div>
        <hr className="w-full mt-[4rem] bg-[#D3D5D8] border-1" />
      </div>
      <div className="mb-3 mt-6 items-center flex justify-end px-1">
        <button
          type="submit"
          name="Sign up"
          onClick={HandleEducationSubmit}
          className="bg-primaryGreen hover:bg-coalColor hover:text-white sm:w-full md:w-1/6 h-12 rounded-md text-coalColor border border-coalColor font-medium text-md relative"
          style={{ fontFamily: "Archia Semibold" }}
        >
          {mutateLoadings || updateLoading ? (
            <span className="flex items-center justify-center">
              <Loader type="Oval" color="black" height={20} width={20} />
              <span className="ml-2">Saving...</span>
            </span>
          ) : (
            "Save"
          )}
        </button>
      </div>
    </>
  );
};

export default EducationInfo;
