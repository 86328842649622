import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import "react-loading-skeleton/dist/skeleton.css";
import { IoClose } from "react-icons/io5";
import { ToastContainer, Zoom, toast } from "react-toastify";
import CustomButton from "../CustomButton/CustomButton";
import { useParams } from "react-router-dom";
import TextFieldCustom from "../TextField/TextFieldCustom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getEmailContent } from "../hooks/getEmailContent";
import ReactHtmlParser from "react-html-parser";
import styles from "./styling.module.css";
import { getCompanyDetails } from "../../Pages/Profile/Settings/hooks/getCompanyDetails";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { postEmail } from "../hooks/postEmail";
import { patchEmail } from "../hooks/patchEmail";
import { getAssessmentByID } from "../../Pages/Profile/MyAssessments/Assessments-main/hooks/getAssessmentByID";
import "./index.css";
import GeneralModal from "../Modals/GeneralModal";

const Quill = ReactQuill.Quill;
var Font = Quill.import("formats/font");
Font.whitelist = ["Archia", "Silka"];
Quill.register(Font, true);

const fontClassToStyleMap = {
  "ql-font-Archia": "font-family: 'Archia Bold';",
  "ql-font-Silka": "font-family: 'Silka';",
};

const fontSizeArr = [
  "8px",
  "9px",
  "10px",
  "12px",
  "14px",
  "16px",
  "20px",
  "24px",
  "32px",
  "42px",
  "54px",
  "68px",
  "84px",
  "98px",
];

var Size = Quill.import("attributors/style/size");
Size.whitelist = fontSizeArr;
Quill.register(Size, true);

export default function CustomizeEmail({
  customizeModal,
  setCustomizeModal,
  modulesData,
  emailOpacity = null,
  assessmentID = null,
}) {
  const { id } = useParams();

  const cancelButtonRef = useRef(null);
  const queryClient = useQueryClient();
  const userID = localStorage.getItem("CP-USER-ID");
  const [error_, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [defaultSubject, setDefaultSubject] = useState("");
  const [description, setDescription] = useState("");
  const [defaultDescription, setDefaultDescription] = useState("");
  const [candidate_name, setcandidateName] = useState("");
  const [resetRequested, setResetRequested] = useState(false);
  const [generalModal, setGeneralModal] = useState(false);

  //#region Fetching Email Content
  const { data, isLoading, error } = useQuery({
    queryKey: ["email-content", assessmentID ? assessmentID : id, userID],
    queryFn: () => getEmailContent(assessmentID ? assessmentID : id, userID),
  });
  //#endregion

  const replaceInlineStylesWithFontClasses = (html) => {
    // Convert the HTML string into a DOM object
    const domParser = new DOMParser();
    const doc = domParser.parseFromString(html, "text/html");

    // Map font-family to the corresponding class
    const fontFamilyToClassMap = {
      "'Archia'": "ql-font-Archia",
      "'Silka'": "ql-font-Silka",
    };

    // Iterate over all elements with inline styles
    const elements = doc.querySelectorAll("[style]");
    elements.forEach((element) => {
      const style = element.getAttribute("style");

      // Check if the style includes font-family and matches a mapping
      Object.keys(fontFamilyToClassMap).forEach((fontFamily) => {
        const className = fontFamilyToClassMap[fontFamily];
        if (style.includes(`font-family: ${fontFamily};`)) {
          // Add the corresponding class to the element
          element.classList.add(className);

          // Remove the font-family property from the style attribute
          const updatedStyle = style
            .replace(`font-family: ${fontFamily};`, "")
            .trim();

          if (updatedStyle) {
            element.setAttribute("style", updatedStyle);
          } else {
            element.removeAttribute("style");
          }
        }
      });
    });

    // Serialize the DOM object back into an HTML string
    return doc.body.innerHTML;
  };

  //#region Editor options
  const Editor = {};
  Editor.modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, false] }, { font: Font.whitelist }],
      [{ size: Size.whitelist }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [
        {
          color: [
            "#252E3A",
            "#C0FF06",
            "#FF5733",
            "#33FF57",
            "#3357FF",
            "#FF33A8",
            "#A833FF",
            "#33FFA8",
          ],
        },
      ],
    ],
  };

  //#endregion

  //#region fetching company data
  const {
    data: userData,
    isLoading: userLoading,
    error: userError,
  } = useQuery({
    queryKey: ["users", assessmentID ? assessmentID : id, userID],
    queryFn: () => getCompanyDetails(userID),
  });
  //#endregion

  //#region Fetch GET assessment
  const {
    data: assessmentData,
    error: assessmentError,
    isLoading: assessmentLoading,
  } = useQuery(["assessment".assessmentID ? assessmentID : id], () =>
    getAssessmentByID(assessmentID ? assessmentID : id)
  );
  //#endregion

  //#region data displaying on editor
  useEffect(() => {
    if (!isLoading && !userLoading && data?.data?.length === 0) {
      const modulesList = modulesData[0]?.section
        ?.map((module, index) => `<li key=${index}>${module?.name}</li>`)
        .join("");
      setDescription(`
                <h1><strong>Hi {candidate_name}</strong></h1>
                <p><br /></p>
                <p>You’ve been invited to take a test for the role of <strong>{role_name}</strong> at {company_name}.</p>
                <p><br /></p>
                <p>The test consists of {numOfModules} module(s) covering the following areas:</p>
                <p><br /></p>
                <ul>${modulesList}</ul>
                <p><br /></p>
                <div style="font-family: Archia Bold; font-size: 15px; line-height: 20px; color: #252e3a; padding: 0px 20px;">
               <p>Completing this test will allow you to demonstrate the skills required for this role, so {company_name} can focus on your abilities rather than comparing CVs. This removes potential bias from the hiring process, giving all candidates an equal opportunity to succeed.</p>
              </div>
                `);
      setcandidateName(userData?.data[0]?.users[0]?.firstName);
      setSubject(
        `You've been invited to take a test for ${userData?.data[0]?.companyName}`
      );
    } else {
      const modulesList = modulesData[0]?.section
        ?.map((module, index) => `<li key=${index}>${module?.name}</li>`)
        .join("");
      setDescription(
        replaceInlineStylesWithFontClasses(data?.data[0]?.body) || ""
      );
      setDefaultDescription(`
        <h1><strong>Hi {candidate_name}</strong></h1>
        <p><br /></p>
        <p>You’ve been invited to take a test for the role of <strong>{role_name}</strong> at {company_name}.</p>
        <p><br /></p>
        <p>The test consists of {numOfModules} module(s) covering the following areas:</p>
        <p><br /></p>
        <ul>${modulesList}</ul>
        <p><br /></p>
        <div style="font-family: Archia Bold; font-size: 15px; line-height: 20px; color: #252e3a; padding: 0px 20px;">
       <p>Completing this test will allow you to demonstrate the skills required for this role, so {company_name} can focus on your abilities rather than comparing CVs. This removes potential bias from the hiring process, giving all candidates an equal opportunity to succeed.</p>
      </div>
        `);
      setcandidateName(userData?.data[0]?.user?.firstName);
      setSubject(data?.data[0]?.subject);
      setDefaultSubject(
        `You've been invited to take a test for ${userData?.data[0]?.companyName}`
      );
    }
  }, [data, isLoading, userData]);
  //#endregion

  const replaceFontClassesWithInlineStyles = (html) => {
    // Convert the HTML string into a DOM object
    const domParser = new DOMParser();
    const doc = domParser.parseFromString(html, "text/html");

    // Helper function to add style to an element
    const addStyleToElement = (element, style) => {
      const existingStyle = element.getAttribute("style") || "";
      element.setAttribute("style", `${existingStyle} ${style}`.trim());
    };

    // Iterate over each font class in the map
    Object.keys(fontClassToStyleMap).forEach((fontClass) => {
      const style = fontClassToStyleMap[fontClass];
      // Select all elements with the font class
      const elements = doc.querySelectorAll(`.${fontClass}`);
      elements.forEach((element) => {
        // Add the corresponding style to each element
        addStyleToElement(element, style);
        // Remove the font class from the element
        element.classList.remove(fontClass);
      });
    });

    // Serialize the DOM object back into an HTML string
    return doc.body.innerHTML;
  };

  //#region post email-content
  const { mutate, isLoading: mutateLoading } = useMutation(postEmail, {
    onSuccess: () => {
      queryClient.invalidateQueries("email-content");
      toast.success("Invitation email created successfully ", {
        toastId: "copy-success",
      });
      setCustomizeModal(false);
      setResetRequested(false);
    },
  });

  const { mutate: updateMutate, isLoading: updateLoading } = useMutation(
    patchEmail,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("email-content");
        toast.success("Invitation email updated successfully", {
          toastId: "copy-success",
        });
        setCustomizeModal(false);
        setResetRequested(false);
      },
    }
  );
  //#endregion

  //#region posting template
  const handleTemplateSubmit = () => {
    let data_x = JSON.stringify({
      type: "INVITE",
      subject: subject,
      body: replaceFontClassesWithInlineStyles(description),
      assessmentId: parseInt(assessmentID ? assessmentID : id),
      userId: parseInt(userID),
    });

    let data_updated = {
      content: {
        type: "INVITE",
        subject: subject,
        body: replaceFontClassesWithInlineStyles(description),
        assessmentId: parseInt(assessmentID ? assessmentID : id),
        userId: parseInt(userID),
      },
      emailID: data?.data[0]?.id,
    };
    try {
      if (data?.data?.length === 0) {
        mutate(data_x);
      } else {
        updateMutate(data_updated);
      }
    } catch (err) {
      //react query will handle
    }
  };
  //#endregion

  //#region Resetting filters
  useEffect(() => {
    if (resetRequested) {
      handleTemplateSubmit();
    }
  }, [resetRequested]);
  //#endregion
  return (
    <Transition.Root show={customizeModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        initialFocus={cancelButtonRef}
        onClose={setCustomizeModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={`fixed inset-0 bg-black ${
              emailOpacity ? "bg-opacity-75" : "bg-opacity-0"
            } transition-opacity`}
          />
        </Transition.Child>

        <ToastContainer
          position="top-center"
          transition={Zoom}
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          enableMultiContainer={false}
          limit={1}
        />
        <GeneralModal
          generalModal={generalModal}
          setGeneralModal={setGeneralModal}
          heading={`Reset Email Template`}
          description={`Are you sure you want to reset the email template? This action cannot be undone`}
          generalCase={true}
          onButtonClick={() => {
            setSubject(defaultSubject);
            setDescription(defaultDescription);
            setResetRequested(true);
          }}
          buttonText="Reset"
          loadingBtn={mutateLoading || updateLoading}
          loadingText="Resetting"
        />
        <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left transition-all sm:my-8 sm:w-full md:w-2/4 h-auto md:h-[45rem]">
                <div className="bg-white">
                  <p className="text-xl font-bold text-black cursor-pointer text-left px-8 pt-5">
                    Edit invitation email
                  </p>
                  <IoClose
                    className="absolute top-3 right-5 z-20 w-5 h-5 cursor-pointer"
                    onClick={() => setCustomizeModal(false)}
                  />
                </div>
                <hr className="w-full mt-8 bg-gray-500 border-1" />
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:h-[33rem] h-auto mt-5 pb-8">
                  <div className="sm:px-4 md:px-0 md:pl-8 pt-5">
                    <h1 className="font-bold text-lg mb-4">Email Content</h1>
                    <TextFieldCustom
                      id="subject"
                      type="text"
                      name="subject"
                      value={subject}
                      label="Subject"
                      onChangeValue={(e) => setSubject(e.target.value)}
                    />
                    <ReactQuill
                      theme="snow"
                      value={description}
                      onChange={setDescription}
                      className="md:h-[21.1rem] h-auto mt-4"
                      modules={Editor?.modules}
                    />
                  </div>
                  <div className="px-4 md:pr-8 pt-5">
                    <h1 className="font-bold text-lg">Preview</h1>
                    <div className="border border-neutral-300 px-2 py-3 rounded-tr overflow-auto rounded-tl mt-3.5">
                      <div className="whitespace-normal md:whitespace-nowrap">
                        {ReactHtmlParser(
                          subject?.replace("{role_name}", assessmentData?.name)
                        )}
                      </div>
                    </div>
                    <div className="border border-neutral-300 border-t-0 px-2 py-3 md:h-[25rem] h-auto overflow-auto">
                      <table className="career_template w-full bg-[#f6f9ff] mx-auto">
                        <thead>
                          <tr>
                            <th className="p-5">
                              {!userLoading && (
                                <>
                                  {userData?.data[0]?.companyAvatar ? (
                                    <img
                                      className="w-15 h-14 mx-auto object-contain"
                                      src={userData?.data[0]?.companyAvatar}
                                      alt="logo"
                                    />
                                  ) : (
                                    <img
                                      className="w-15 h-14 mx-auto object-contain"
                                      src="https://cp-assessment.s3.eu-west-2.amazonaws.com/cp_assessment_logo.png"
                                      alt="logo"
                                    />
                                  )}
                                </>
                              )}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="p-3">
                              <div className="bg-white rounded-lg px-2 py-6">
                                <div>
                                  {ReactHtmlParser(
                                    description
                                      ?.replace(
                                        /{candidate_name}/g,
                                        `${userData?.data[0]?.users[0]?.firstName} ${userData?.data[0]?.users[0]?.lastName}`
                                      )
                                      .replace(
                                        /{role_name}/g,
                                        modulesData[0]?.name
                                      )
                                      .replace(
                                        /{numOfModules}/g,
                                        modulesData[0]?.section?.length
                                      )
                                      .replace(
                                        /{company_name}/g,
                                        userData?.data[0]?.companyName
                                      )
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="px-8 flex justify-between mt-5 flex-row w-full">
                  <div className="text-[#cb7b7a] my-auto"></div>
                  <div className="flex justify-between w-full items-center gap-2">
                    <div style={{ fontFamily: "Silka" }}>
                      {data?.data?.length !== 0 && (
                        <CustomButton
                          label="Reset to default"
                          borderCustom="underline font-bold hover:text-red-500"
                          paddingY="0.3rem"
                          onClickButton={() => {
                            setGeneralModal(true);
                          }}
                        />
                      )}
                    </div>
                    <div className="flex flex-row gap-2">
                      <div className="w-20">
                        <CustomButton
                          label="Cancel"
                          borderCustom="border border-black"
                          paddingY="0.45rem"
                          onClickButton={() => setCustomizeModal(false)}
                        />
                      </div>
                      <div className="w-20">
                        <CustomButton
                          label="Save"
                          bgColor="#C0FF06"
                          textColor="#252E3A"
                          borderCustom="border border-black"
                          paddingY="0.45rem"
                          onClickButton={handleTemplateSubmit}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
