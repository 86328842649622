import React from "react";
import Logo from "../../Dexta_assets/logodexta2.png";
import navbar1 from "../../Dexta_assets/navbar1.png";
import navbar1Green from "../../Dexta_assets/navbar1Green.png";
import navbar2 from "../../Dexta_assets/navbar2.png";
import navbar2Green from "../../Dexta_assets/navbar2Green.png";
import navbar3 from "../../Dexta_assets/navbar3.png";
import dropdownnavbar from "../../Dexta_assets/dropdownnavbar.png";
import navbar3Green from "../../Dexta_assets/navbar3Green.png";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Help from "../../Dexta_assets/helpIcon.png";
import { getUserData } from "../../Pages/Profile/MyAssessments/Assessments-main/hooks/getUserData";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import "./navbar.css";
import { Steps, Hints } from "intro.js-react";
import "intro.js/introjs.css";
import { useMutation } from "@tanstack/react-query";
import { updateUser } from "../../Pages/Candidates/hooks/updateUser";
import { updateMe } from "../../Pages/Profile/Settings/hooks/updateMe";
import Loader from "react-loader-spinner";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// import { Link } from 'react-router-dom'
import { BsInfoSquareFill } from "react-icons/bs";
import { BiWorld } from "react-icons/bi";
import { BiSolidContact } from "react-icons/bi";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { ViewAgenda } from "@mui/icons-material";
import {
  settourtotrue,
  settourtofalse,
} from "../../redux/reducers/toggleTour/ToggleSlice";
import { useDispatch, useSelector } from "react-redux";
import { setTourStepData } from "../../redux/reducers/TourSteps/TourStepsSlice";
import tour from "../../Dexta_assets/tour_icon.png";
import {
  setTourToCompleted,
  setTourToIncomplete,
} from "../../redux/reducers/TourCompleted/TourCompletedSlice";
import { steps, steps_candidate, steps_settings, steps_tests } from "./data.js";
import { CiSettings } from "react-icons/ci";
import closebtn from "../../Dexta_assets/closeModal.png";
import { setCandidateToFalse } from "../../redux/reducers/CandidateBack/CandidateSlice.js";
import { setCurrentTab } from "../../redux/reducers/SettingsTab/SettingsTabSlice.js";

const NavbarUser = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("home");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const userID = localStorage.getItem("CP-USER-ID");
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [stepNumber, setStepNumber] = useState(1);
  const [show, setShow] = useState(false);
  const [showTour, setShowTour] = useState(false);
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [forceClose, setForceClose] = useState(true);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const dispatch = useDispatch();
  const tourCheck2 = useSelector((state) => state.tour.settour);
  const tourStepCheck = useSelector((state) => state.tourStep.settourStep);
  const [showTourSettings, setShowTourSettings] = useState(false);
  const [showTourTests, setTourTests] = useState(false);
  const user_package_check = useSelector(
    (state) => state.packageDetails.setPackage
  );
  const tourCompletedState = useSelector(
    (state) => state.tourCompleted.setCompleted
  );
  const user_role = useSelector(
    (state) => state.accountTypeDetails.accountType
  );
  const currentPath = window.location.pathname;
  const backCheck = useSelector((state) => state.candidateBack.setcandidate);
  const dropdownTriggerRef = useRef(null);
  const tourStepCheckRef = useRef(tourStepCheck);

  //#region Handling dropdown clicks
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  //#endregion

  //#region fetching user's data
  const { data, isLoading, error } = useQuery(["user", userID], () =>
    getUserData(userID)
  );

  //#endregion

  //#region Select item from navbar
  const handleSelectItem = (item) => {
    setSelectedItem(item);
  };
  //#endregion

  //#region toggle navbar for mobile
  const toggleMobileMenu = () => {
    setMobileMenuOpen(true);
    if (isMobileMenuOpen) {
      setMobileMenuOpen(false);
    }
  };
  //#endregion

  //#region Switching tabs
  useEffect(() => {
    if (window.location.pathname === "/dashboard") {
      setSelectedItem("home");
    } else if (window.location.pathname === "/settings") {
      setSelectedItem("settings");
    } else if (window.location.pathname === "/candidates") {
      setSelectedItem("candidates");
    } else if (window.location.pathname === "/tests") {
      setSelectedItem("tests");
    } else if (window.location.pathname.includes("/preview-module")) {
      setSelectedItem("tests");
    } else if (window.location.pathname.includes("/details")) {
      if (backCheck) {
        setSelectedItem("candidates");
      } else {
        setSelectedItem("home");
      }
    }
  }, [selectedItem, window.location.pathname]);
  //#endregion

  //#region Handling outside Click
  useEffect(() => {
    function handleClickOutsideGif(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        event.target.id !== "dropid"
      ) {
        setDropdownOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutsideGif);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideGif);
    };
  }, [dropdownRef]);
  //#endregion

  //#region handling Exit
  function onExitAssessments() {
    setForceClose(false);
    setShowTour(false);
    dispatch(setTourStepData(99));
  }

  const onExitCandidate = () => {
    setShow(false);
    dispatch(setTourStepData(99));
  };

  const onExitSettings = () => {
    setShowTourSettings(false);
    // console.log("exit settings")
    dispatch(settourtofalse(true));
    dispatch(setTourStepData(99));
  };

  const onExitTests = () => {
    setTourTests(false);
    // console.log("exit tests")
    dispatch(settourtofalse(true));
    dispatch(setTourStepData(99));
  };
  //#endregion

  //#region handling tour states
  useEffect(() => {
    if (tourCheck2 === false && tourStepCheck !== 99 && tourStepCheck === 0) {
      setShowTour(true);
    } else if (
      tourCheck2 === false &&
      tourStepCheck !== 99 &&
      tourStepCheck === 2
    ) {
      setShow(true);
    } else if (
      tourCheck2 === false &&
      tourStepCheck !== 99 &&
      tourStepCheck === 4
    ) {
      if (dropdownTriggerRef.current) {
        dropdownTriggerRef.current.click();
      }
      setTimeout(() => {
        setShowTourSettings(true);
      }, 500);
    } else if (
      tourCheck2 === false &&
      tourStepCheck !== 99 &&
      tourStepCheck === 6
    ) {
      setTourTests(true);
    }
  }, [tourCheck2, tourStepCheck, showTour, show]);
  //#endregion

  //#region Updating Tour Completed
  const handleTour = () => {
    let data = JSON.stringify({
      isWebTourCompleted: true,
    });
    try {
      updateMutate(data);
    } catch (err) {
      console.log(err.message);
    }
  };
  //#endregion

  //#region updating tour status
  const { mutate: updateMutate, isLoading: updateLoading } = useMutation(
    updateMe,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries("/users");
        dispatch(settourtofalse(false));
        dispatch(setTourStepData(0));
      },
    }
  );

  const handleTourExit = () => {
    let data = JSON.stringify({
      isWebTourCompleted: true,
    });
    try {
      exitMutate(data);
    } catch (err) {
      console.log(err.message);
    }
  };
  //#endregion

  //#region updating tour status
  const { mutate: exitMutate, isLoading: exitLoading } = useMutation(updateMe, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("/users");
    },
  });
  //#endregion
  // console.log(stepNumber, tourStepCheck, tourCheck2, showTour, show, "steps on modal", "Tour completed", tourCompletedState, "data", data)
  //#region checks to identify on which section we are
  const check2 = tourStepCheck !== 99 && tourStepCheck === 2;
  const check3 = tourStepCheck !== 99 && tourStepCheck === 4;
  const check4 = tourStepCheck !== 99 && tourStepCheck === 6;
  //#endregion

  console.log(tourCheck2, tourStepCheck, "lmao");
  //#region Opening emailbox from the navbar
  const handleOpenEmailInboxButtonClick = () => {
    // const recipient = "support@dexta.io";
    // let subject = "";
    // if (window.location.pathname.includes("/dashboard")) {
    //   subject = "My Tests page";
    // } else if (window.location.pathname.includes("/candidates")) {
    //   subject = "My Candidates page";
    // } else if (window.location.pathname.includes("/tests")) {
    //   subject = "Modules page";
    // } else if (window.location.pathname.includes("/settings")) {
    //   subject = "Settings page";
    // } else if (window.location.pathname.includes("/invite-candidates")) {
    //   subject = "Test candidates page";
    // } else if (window.location.pathname.includes("/details")) {
    //   subject = "Candidate result page";
    // } else if (window.location.pathname.includes("/preview")) {
    //   subject = "Modules preview page";
    // } else if (window.location.pathname.includes("/preview-module")) {
    //   subject = "Module preview page";
    // } else if (window.location.pathname.includes("/create-test")) {
    //   subject = "Create test page";
    // }
    // const body = ``;
    // const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
    //   subject
    // )}&body=${encodeURIComponent(body)}`;

    // window.location.href = mailtoLink;
    window.open("https://dexta.io/contact", "_blank");
  };
  //#endregion

  //#region updating the ref whenever tourStepCheck changes
  useEffect(() => {
    tourStepCheckRef.current = tourStepCheck;
  }, [tourStepCheck]);
  //#endregion

  //#region replacing tour modal icon with custom icon
  useEffect(() => {
    const observer = new MutationObserver(() => {
      const defaultCloseIcon = document.querySelector(".introjs-skipbutton");
      if (defaultCloseIcon) {
        // Ensure the custom icon is added only once
        if (!document.querySelector(".custom-close-icon")) {
          const customIcon = document.createElement("div");
          customIcon.className = "custom-close-icon";
          customIcon.style.cssText = `
            position: absolute;
            top: 7px;
            right: 10px;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background: #252E3A;
            border-radius: 50%;
            z-index: 9999;
          `;

          const span = document.createElement("span");
          span.innerHTML = "&#10006;";
          span.style.color = "#C0FF06";
          span.style.fontSize = "16px";

          customIcon.appendChild(span);

          customIcon.addEventListener("click", (e) => {
            e.stopPropagation();
            const currentTourStep = tourStepCheckRef.current;
            if (currentTourStep === 0) {
              onExitAssessments();
            } else if (currentTourStep === 2) {
              onExitCandidate();
            } else if (currentTourStep === 4) {
              onExitSettings();
            } else if (currentTourStep === 6) {
              onExitTests();
            } else {
            }
          });

          defaultCloseIcon.parentNode.appendChild(customIcon);
        }
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });
    return () => observer.disconnect();
  }, []);
  //#endregion

  //#region reset localstorage in case of downgrade
  useEffect(() => {
    if (
      !window.location.pathname.includes("/place_order") &&
      !window.location.pathname.includes("/step-1") &&
      data?.isUserOnboard === true
    ) {
      if (
        user_package_check &&
        data?.package?.code &&
        user_package_check !== data?.package?.code
      ) {
        localStorage.removeItem("CP-USER-ID");
        localStorage.removeItem("CP-USER-TOKEN");
      }
    }
  }, [data?.package?.code]);
  //#endregion

  return (
    <>
      <Steps
        enabled={
          check2
            ? show
            : check3
            ? showTourSettings
            : check4
            ? showTourTests
            : showTour
        }
        options={{
          hideNext: false,
          exitOnOverlayClick: false,
          exitOnFinish: false,
          doneLabel: "Next",
          exitOnEsc: false,
        }}
        onComplete={() => {
          dispatch(setTourToCompleted(true));
          if (tourStepCheck === 0) {
            setShowTour(false);
            dispatch(settourtotrue(true));
            dispatch(setTourStepData(11));
            navigate("/dashboard");
          } else if (tourStepCheck === 2) {
            setShow(false);
            setShowTour(false);
            dispatch(settourtotrue(true));
            dispatch(setTourStepData(3));
            // dispatch(setTourToIncomplete(false))
            navigate("/candidates");
          } else if (tourStepCheck === 4) {
            setShow(false);
            setShowTour(false);
            setShowTourSettings(false);
            dispatch(settourtotrue(true));
            dispatch(setTourStepData(5));
            // dispatch(setTourToIncomplete(false))
            dispatch(setCurrentTab("My profile"));
            navigate("/settings");
          } else if (tourStepCheck === 6) {
            setShow(false);
            setShowTour(false);
            setShowTourSettings(false);
            setTourTests(false);
            dispatch(settourtotrue(true));
            dispatch(setTourToIncomplete(false));
            dispatch(setTourStepData(7));
            navigate("/tests");
          }
        }}
        steps={
          check2
            ? steps_candidate
            : check3
            ? steps_settings
            : check4
            ? steps_tests
            : steps
        }
        onExit={(tour) => {
          // console.log("outside");
          // if (!tourCompletedState) {
          //   console.log("inside");
          //   if (tourStepCheck === 0) {
          //     onExitAssessments();
          //   } else if (tourStepCheck === 2) {
          //     onExitCandidate();
          //   } else if (tourStepCheck === 4) {
          //     onExitSettings();
          //   } else if (tourStepCheck === 6) {
          //     onExitTests();
          //   }
          // }
        }}
        initialStep={0}
        onBeforeChange={(value) => {
          setStepNumber(value);
        }}
      >
        <div className="introjs-tooltip">
          {/* Your custom button */}
          <button>Custom Button</button>
        </div>
      </Steps>

      {/* <Steps enabled={check2 && show} options={{ hideNext: false }} steps={steps_candidate} onExit={onExitCandidate} initialStep={0} onBeforeChange={value => { setStepNumber(value) }} /> */}
      <nav
        className={`bg-white fixed w-full z-40 top-0 left-0 dark:border-gray-600 ${
          currentPath.includes("/place_order") && "hidden"
        }`}
      >
        <div className="lg:container flex items-center justify-between xl:px-2 2xl:px-0 sm:px-4 mx-auto py-4">
          <Link to="/dashboard" className="flex items-center">
            <img
              src={Logo}
              className="h-[50px] w-[190px] object-fill"
              alt="CP Logo"
            />
          </Link>
          <div className="flex md:order-2">
            <div className="group relative sm:hidden md:block mr-10 my-auto flex justify-center">
              {/* {show ? (
                <Loader type="ThreeDots" color="black" height={30} width={30} />
              ) : ( */}
              <React.Fragment>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <IconButton
                    onClick={(e) => {
                      handleClick(e);
                    }}
                    size="small"
                    sx={{ ml: 2, backgroundColor: "transparent" }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    {window.location.pathname !== "/step-1" &&
                      window.location.pathname !== "/step-2" && (
                        <img src={Help} className="w-8 h-8 cursor-pointer" />
                      )}
                  </IconButton>
                </Box>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem
                    onClick={() => {
                      navigate("/dashboard");
                      setTimeout(() => {
                        dispatch(settourtofalse(false));
                        dispatch(setTourStepData(0));
                        dispatch(setTourToIncomplete(false));
                      }, 1000);
                    }}
                  >
                    <ListItemIcon>
                      <BiWorld className="w-5 h-5 hover:animate-pulse cursor-pointer" />
                    </ListItemIcon>
                    Start Tour
                  </MenuItem>
                  {/* <MenuItem
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      <ListItemIcon>
                        <BsInfoSquareFill className="w-5 h-4 hover:animate-pulse cursor-pointer" />
                      </ListItemIcon>
                      FAQs
                    </MenuItem> */}
                  <MenuItem
                    onClick={() => {
                      handleOpenEmailInboxButtonClick();
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      <BiSolidContact className="w-5 h-5 hover:animate-pulse cursor-pointer" />
                    </ListItemIcon>
                    Contact us
                  </MenuItem>
                </Menu>
              </React.Fragment>
              {/* )} */}
            </div>
            {/* New div to open dropdown */}
            {!isLoading && (
              <div className="sm:hidden md:block">
                <React.Fragment>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <div
                      onClick={handleClick2}
                      ref={dropdownTriggerRef}
                      aria-controls={open2 ? "account-menu2" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open2 ? "true" : undefined}
                      className="flex flex-row cursor-pointer"
                    >
                      <div className="flex flex-row">
                        <button
                          type="button"
                          className="flex text-sm  sm:hidden md:block rounded-full"
                        >
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="w-11 h-11 rounded-full border-primaryGreen border object-cover"
                            src={data?.profilePhoto}
                            alt="user photo"
                          />
                        </button>
                        <h1
                          className="my-auto ml-4 text-[13px] sm:hidden md:block"
                          style={{ fontFamily: "Archia Semibold" }}
                        >
                          Hello {data?.firstName}
                        </h1>
                      </div>
                      <img
                        src={dropdownnavbar}
                        className="w-2 my-auto ml-2 h-[5px]"
                        alt="Dropdown Navbar"
                      />
                    </div>
                  </Box>
                  <Menu
                    anchorEl={anchorEl2}
                    id="account-menu2"
                    open={open2}
                    onClose={handleClose2}
                    onClick={handleClose2}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem>
                      <Avatar src={data?.profilePhoto} />
                      <div className="flex flex-col">
                        <div>
                          {data?.firstName} {data?.lastName}
                        </div>
                        <div className="text-xs">{data?.email}</div>
                      </div>
                    </MenuItem>
                    <Divider />
                    {window.location.pathname !== "/step-1" &&
                      window.location.pathname !== "/step-2" && (
                        <>
                          {/* <MenuItem
                            onClick={() => {
                              handleClose2();
                              navigate("/candidates");
                            }}
                          >
                            <ListItemIcon>
                              <ViewAgenda fontSize="small" />
                            </ListItemIcon>
                            My Candidates
                          </MenuItem> */}
                          {user_role !== "recruiter" &&
                            user_role !== "hiring-manager" && (
                              <MenuItem
                                id="tab3"
                                onClick={() => {
                                  handleClose2();
                                  dispatch(setCurrentTab("My profile"));
                                  navigate("/settings");
                                }}
                              >
                                <ListItemIcon>
                                  <Settings fontSize="small" />
                                </ListItemIcon>
                                Settings
                              </MenuItem>
                            )}
                        </>
                      )}
                    <MenuItem
                      onClick={() => {
                        handleClose2();
                        localStorage.removeItem("CP-USER-TOKEN");
                        localStorage.removeItem("CP-USER-ID");
                        navigate("/login");
                      }}
                    >
                      <ListItemIcon>
                        <Logout fontSize="small" />
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              </div>
            )}
            <div className="">
              <button
                type="button"
                onClick={toggleMobileMenu}
                className="flex items-center ml-20 rounded-md p-2 lg:hidden text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-coalColor"
                aria-controls="mobile-menu"
                aria-expanded={isMobileMenuOpen}
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 17 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 1h15M1 7h15M1 13h15"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div
            className={` ${
              isMobileMenuOpen && "hidden"
            } sm:hidden lg:flex flex-shrink-0 items-center`}
          >
            <div className={`sm:hidden lg:flex `}>
              <ul
                className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-5 md:mt-0 md:border-0 md:bg-white"
                style={{ fontFamily: "Archia Semibold" }}
              >
                {window.location.pathname !== "/step-1" &&
                  window.location.pathname !== "/step-2" && (
                    <>
                      <li
                        className={`flex flex-row gap-2 ${
                          selectedItem === "home"
                            ? "bg-coalColor rounded-md"
                            : null
                        }`}
                        id="tab1"
                      >
                        <Link
                          to="/dashboard"
                          onClick={() => {
                            handleSelectItem("home");
                            dispatch(setCandidateToFalse(false));
                          }}
                          className={`py-2 flex flex-row gap-2 px-4 text-coalColor ${
                            selectedItem === "home" ? "text-primaryGreen" : null
                          }`}
                          aria-current="page"
                        >
                          {selectedItem === "home" ? (
                            <img
                              src={navbar1Green}
                              className="w-4 h-4 my-auto object-contain"
                            />
                          ) : (
                            <img src={navbar1} className="w-4 h-4 my-auto" />
                          )}
                          My Tests
                        </Link>
                      </li>
                      <li
                        id="tab2"
                        className={`flex flex-row  ${
                          selectedItem === "candidates"
                            ? "bg-coalColor rounded-md"
                            : null
                        }`}
                      >
                        <Link
                          to="/candidates"
                          onClick={() => handleSelectItem("candidates")}
                          className={`flex flex-row py-2 px-4 gap-2 text-coalColor ${
                            selectedItem === "candidates"
                              ? "text-primaryGreen"
                              : null
                          }`}
                          aria-current="page"
                        >
                          {selectedItem === "candidates" ? (
                            <img
                              src={navbar2Green}
                              className="w-3 h-4 my-auto"
                            />
                          ) : (
                            <img src={navbar2} className="w-3 h-4 my-auto" />
                          )}
                          My Candidates
                        </Link>
                      </li>
                      <li
                        id="tab4"
                        className={`flex flex-row  ${
                          selectedItem === "tests"
                            ? "bg-coalColor rounded-md"
                            : null
                        }`}
                      >
                        <Link
                          to="/tests"
                          onClick={() => handleSelectItem("tests")}
                          className={`flex flex-row px-4 gap-2 py-2 text-coalColor ${
                            selectedItem === "tests"
                              ? "text-primaryGreen"
                              : null
                          }`}
                          aria-current="page"
                        >
                          {selectedItem === "tests" ? (
                            <img
                              src={navbar3Green}
                              className="w-4 h-4 my-auto"
                            />
                          ) : (
                            <img src={navbar3} className="w-4 h-4 my-auto" />
                          )}
                          Modules
                        </Link>
                      </li>
                      {user_role !== "recruiter" &&
                        user_role !== "hiring-manager" && (
                          <></>
                          // <li
                          //   // id="tab3"
                          //   className={`flex flex-row ${
                          //     selectedItem === "settings"
                          //       ? "bg-coalColor rounded-md"
                          //       : null
                          //   }`}
                          // >
                          //   <Link
                          //     to="/settings"
                          //     onClick={() => handleSelectItem("settings")}
                          //     className={`flex flex-row px-4 gap-2 py-2 text-coalColor ${
                          //       selectedItem === "settings"
                          //         ? "text-primaryGreen"
                          //         : null
                          //     }`}
                          //     aria-current="page"
                          //   >
                          //     <CiSettings
                          //       className={`w-5 h-5 my-auto ${
                          //         selectedItem === "settings"
                          //           ? "text-primaryGreen"
                          //           : "text-coalColor"
                          //       }`}
                          //     />
                          //     Settings
                          //   </Link>
                          // </li>
                        )}
                      <li></li>
                    </>
                  )}
              </ul>
            </div>
          </div>
        </div>
        <div
          className={`lg:hidden ${isMobileMenuOpen ? "block" : "hidden"}`}
          id="mobile-menu"
        >
          <div className="pb-3 pt-2">
            <a
              href="/dashboard"
              onClick={() => handleSelectItem("home")}
              className={`text-[#000000]  block rounded-md px-3 py-2 hover:text-coalColor text-base font-medium`}
              aria-current="page"
            >
              My Tests
            </a>
            <a
              href="/candidates"
              onClick={() => handleSelectItem("candidates")}
              className={`text-[#000000]  block hover:text-coalColor rounded-md px-3 py-2 text-base font-medium`}
            >
              My Candidates
            </a>
            <a
              href="/tests"
              onClick={() => handleSelectItem("tests")}
              className={`text-[#000000]  block rounded-md px-3 hover:text-coalColor py-2 text-base font-medium`}
            >
              Modules
            </a>
            <a
              href="/settings"
              onClick={() => handleSelectItem("candidates")}
              className={`text-[#000000]  block hover:text-coalColor rounded-md px-3 py-2 text-base font-medium`}
            >
              Settings
            </a>
            <a
              onClick={() => {
                localStorage.removeItem("CP-USER-TOKEN");
                localStorage.removeItem("CP-USER-ID");
                navigate("/login");
              }}
              className={`text-[#000000]  block rounded-md px-3 hover:text-[#4A9CB9] py-2 text-base font-medium`}
            >
              Logout
            </a>
          </div>
        </div>
      </nav>
      {!isLoading && !data?.isWebTourCompleted && data?.isUserOnboard && (
        <div
          id="dilit"
          className={`fixed top-0 right-0 left-0 bottom-0 flex justify-center items-center bg-black bg-opacity-50 z-50`}
          tabindex="-1"
          aria-hidden="true"
        >
          <div className="overflow-y-auto overflow-x-hidden relative p-4 w-full max-w-xl h-full md:h-auto">
            <div className="relative p-4 text-center bg-[#fff] rounded-lg shadow sm:p-5">
              <button
                type="button"
                className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                data-modal-toggle="dilit"
                onClick={() => {
                  handleTourExit();
                }}
              >
                <img src={closebtn} className="w-6 h-6" />
              </button>
              <img src={tour} className="w-11 h-11 mx-auto" />
              <h1
                className="font-bold text-lg mt-5"
                style={{ fontFamily: "Archia Semibold" }}
              >
                Take Site Tour
              </h1>
              <p className="mb-4 text-black" style={{ fontFamily: "Silka" }}>
                Would you like to take a tour of the site?
              </p>
              <div className="flex justify-center items-center space-x-4 mt-3">
                <button
                  data-modal-toggle="dilit"
                  type="button"
                  className="bg-coalColor text-white hover:bg-primaryGreen hover:text-coalColor border border-coalColor px-2 py-1 rounded-md"
                  onClick={() => {
                    handleTourExit();
                  }}
                  style={{ fontFamily: "Silka" }}
                >
                  No, thanks
                </button>
                <button
                  type="submit"
                  className="bg-primaryGreen text-coalColor hover:bg-coalColor hover:text-white border border-coalColor px-2 py-1 rounded-md"
                  onClick={() => {
                    handleTour();
                  }}
                  style={{ fontFamily: "Silka" }}
                >
                  Yes, please
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NavbarUser;
