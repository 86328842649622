import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ".././auth.css";
import { useLocation, useNavigate } from "react-router-dom";
import { loginUser } from "../../../Auth/hooks/loginUser";
import { useMutation } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { ToastContainer, Zoom } from "react-toastify";
import TextField from "../../../../Components/Dexta/TextField/TextField";
import Loader from "react-loader-spinner";
import { forgetEmail } from "../../hooks/forgetEmail";
import queryString from "query-string";
import { resetEmail } from "../../../Auth/hooks/resetEmail";
import Password from "../../../../Dexta_assets/password-image.png";
import { resetCandidateEmail } from "../../hooks/resetCandidateEmail";
import MainLogo from "../../../../Dexta_assets/signup_girl.png";
import CustomButton from "../../../../Components/CustomButton/CustomButton";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaInfoCircle } from "react-icons/fa";
import { handlePassword } from "../../../../Components/Regex/Regex";

const ResetPasswordCandidate = () => {
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const queryClient = useQueryClient();
  const [toastSuccess, setToastSuccess] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [BarPercentage, setBarPercentage] = useState(0);
  const [passwordValidation, setPasswordValidation] = useState({
    capital: false,
    number: false,
    length: false,
    special: false,
  });

  //#region calculating trues for bar percentage
  useEffect(() => {
    const trueCount = Object.values(passwordValidation).filter(Boolean).length;
    const newBarPercentage = trueCount * 25;
    setBarPercentage(newBarPercentage);
  }, [passwordValidation]);
  //#endregion

  //#region Setting up new password
  const { mutate, isLoading } = useMutation(resetCandidateEmail, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("/auth/forgot-password");
      if (response.message) {
        setToastSuccess("Reset Successful");
      }
      setTimeout(() => {
        navigate("/candidate/reset-password-complete");
      }, 1000);
    },
    onError: (error) => {
      setError(true);
      let errorMessage;
      if (Array.isArray(error.response.data.message)) {
        errorMessage = error.response.data.message.join(", ");
      } else {
        errorMessage = error.response.data.message;
      }
      setErrorMessage(errorMessage);
      toast.success(errorMessage, {
        toastId: "copy-success",
        style: { width: "400px" },
      });
    },
  });
  //#endregion

  //#region toast for success
  useEffect(() => {
    if (toastSuccess != "") {
      toast.success(toastSuccess, {
        toastId: "copy-success",
      });
    }
  }, [toastSuccess]);
  //#endregion

  //#region Validation on reset password form
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      password1: "",
      password2: "",
    },
    validationSchema: Yup.object({
      password1: Yup.string()
        .required("Password 1 Required")
        .min(12, "Password 1 should be at least 12 characters"),
      password2: Yup.string()
        .required("Password 2 Required")
        .min(12, "Password 2 should be at least 12 characters"),
    }),

    onSubmit: (values) => {
      let data = JSON.stringify({
        password1: values?.password1,
        password2: values?.password2,
        resetToken: parsed?.token,
      });
      try {
        if (values?.password1 !== values?.password2) {
          toast.error("Passwords do not match", {
            toastId: "copy-success",
          });
        } else {
          mutate(data);
        }
      } catch (error) {
        //onError will automatically detect
      }
    },
  });
  //#endregion

  //#region toggle password visible / invisible
  function togglePasswordVisibility() {
    setIsPasswordVisible((prevState) => !prevState);
  }
  //#endregion

  //#region checking password conditions
  const checkingPassword = (text) => {
    const { hasNumber, hasCapital, hasLength, hasSpecialCharacter } =
      handlePassword(text);
    setPasswordValidation({
      number: hasNumber,
      capital: hasCapital,
      length: hasLength,
      special: hasSpecialCharacter,
    });
  };
  //#endregion

  return (
    <>
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="sm:grid-cols-1 lg:grid-cols-2 grid bg-bgAuth h-screen">
        <div className="bg-black sm:hidden lg:block">
          <img src={MainLogo} className="w-full lg:h-full  lg:object-cover" />
        </div>
        <div className="bg-bgAuth flex justify-center flex-col md:pb-40">
          <div className="sm:w-5/6 lg:w-2/3 mx-auto">
            <h1
              className="text-2xl text-left"
              style={{ fontFamily: "Archia Semibold" }}
            >
              Set new password
            </h1>
            <div
              style={{ fontFamily: "Silka" }}
              className="text-sm mt-6 flex flex-col"
            >
              <span>Your new password must be different</span>
              <span>to previously used passwords.</span>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <div className="h-[4.7rem] mt-6">
                <TextField
                  name="password1"
                  id="password1"
                  type={isPasswordVisible ? "text" : "password"}
                  placeholder="Enter Password"
                  onChange={(e) => {
                    validation.handleChange(e);
                    checkingPassword(e.target.value);
                  }}
                  onBlur={validation.handleBlur}
                  marginTop="mt-2"
                  passwordIcon={true}
                  value={validation.values.password1 || ""}
                  onClick={togglePasswordVisibility}
                  isPasswordVisible={isPasswordVisible}
                />
                {validation.touched.password1 && validation.errors.password1 ? (
                  <div className="ml-1">
                    <p className="text-rose-500 fade-in-text-validations sm:text-xs md:text-sm">
                      {validation.errors.password1}
                    </p>
                  </div>
                ) : null}
              </div>
              <ul
                class="max-w-md text-sm mt-3 pl-2 text-black list-inside space-y-1"
                style={{ fontFamily: "Silka" }}
              >
                <li class="flex items-center gap-2 rtl:space-x-reverse">
                  {passwordValidation.number ? (
                    <svg
                      class="flex-shrink-0 w-3.5 h-3.5 fade-in-image text-green-700 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 16 12"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M1 5.917 5.724 10.5 15 1.5"
                      />
                    </svg>
                  ) : (
                    <FaInfoCircle
                      className="w-4 h-4 fade-in-image"
                      color="#252E3A"
                    />
                  )}
                  <span>contains at least one number</span>
                </li>
                <li class="flex items-center gap-2">
                  {passwordValidation.capital ? (
                    <svg
                      class="flex-shrink-0 w-3.5 h-3.5 text-green-700 fade-in-image"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 16 12"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M1 5.917 5.724 10.5 15 1.5"
                      />
                    </svg>
                  ) : (
                    <FaInfoCircle
                      className="w-4 h-4 fade-in-image"
                      color="#252E3A"
                    />
                  )}
                  contains at least one upper character
                </li>
                <li class="flex items-center gap-2">
                  {passwordValidation.special ? (
                    <svg
                      class="flex-shrink-0 w-3.5 h-3.5 text-green-700 fade-in-image"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 16 12"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M1 5.917 5.724 10.5 15 1.5"
                      />
                    </svg>
                  ) : (
                    <FaInfoCircle
                      className="w-4 h-4 fade-in-image"
                      color="#252E3A"
                    />
                  )}
                  contains at least one special character
                </li>
                <li class="flex items-center gap-2">
                  {passwordValidation?.length ? (
                    <svg
                      class="flex-shrink-0 w-3.5 h-3.5 text-green-700 fade-in-image"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 16 12"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M1 5.917 5.724 10.5 15 1.5"
                      />
                    </svg>
                  ) : (
                    <FaInfoCircle
                      className="w-4 h-4 fade-in-image"
                      color="#252E3A"
                    />
                  )}
                  contains at least 12 characters
                </li>
              </ul>
              <div className="h-[4.7rem] mt-6">
                <TextField
                  name="password2"
                  id="password2"
                  type={isPasswordVisible ? "text" : "password"}
                  placeholder="Confirm Password"
                  onChange={(e) => {
                    validation.handleChange(e);
                  }}
                  onBlur={validation.handleBlur}
                  marginTop="mt-2"
                  passwordIcon={true}
                  value={validation.values.password2 || ""}
                  onClick={togglePasswordVisibility}
                  isPasswordVisible={isPasswordVisible}
                />
                {validation.touched.password2 && validation.errors.password2 ? (
                  <div className="ml-1">
                    <p className="text-rose-500 fade-in-text-validations sm:text-xs md:text-sm">
                      {validation.errors.password2}
                    </p>
                  </div>
                ) : null}
              </div>
              <CustomButton
                label="Reset Password"
                borderCustom="border border-black text-white"
                paddingY="0.7rem"
                hoverBgColor="#C0FF06"
                hoverTextColor="#252E3A"
                marginTop="mt-4"
                bgColor="#252E3A"
                iconR={FaArrowRightLong}
                noMarginIcon={false}
                autoLeftMargin="ml-auto"
                textMarginBotton="ml-auto"
                disabledCheck={BarPercentage < 100}
                disableField={BarPercentage < 100}
                disabledColor="#D3D5D8"
                disabledTextColor="#7C8289"
                LoadingBtn={isLoading}
                loadingText="Resetting"
              />
            </form>
            <span
              className="text-coalColor text-sm text-center mt-8"
              style={{ fontFamily: "Silka" }}
            >
              Don't want to reset your password?{" "}
              <span
                style={{ fontFamily: "Silka Bold" }}
                className="text-coalColor font-medium cursor-pointer"
                onClick={() => navigate("/candidate/login")}
              >
                Return to Login
              </span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordCandidate;
