import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { ToastContainer, Zoom, toast } from "react-toastify";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import MainLogo from "../../Dexta_assets/req_demo.png";
import arrowdown from "../../Dexta_assets/arrowdown.png";
import TextField from "../../Components/Dexta/TextField/TextField";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { FaArrowRightLong } from "react-icons/fa6";
import { workForce, TimeSlots } from "../../data/mapData";
import Scrollbars from "react-custom-scrollbars";
import useOutsideClick from "../../Components/OutsideClick/OutsideClick";
import { requestDemo } from "./Hooks/requestDemo";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const RequestDemo = () => {
  //#region useStates
  const navigate = useNavigate();
  const [planHiringDropdown, setPlanHiringDropdown] = useState(false);
  const [workDropdown, setWorkDropdown] = useState(false);
  const planRef = useRef(null);
  const workRef = useRef(null);
  const queryClient = useQueryClient();
  //#endregion

  //#region formik validations and handle submit function
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      country: "",
      city: "",
      role: "",
      plan: "",
      work: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First name required"),
      lastName: Yup.string().required("Last name required"),
      email: Yup.string().required("Email required"),
      phone: Yup.string().required("Phone required"),
      country: Yup.string().required("Country required"),
      city: Yup.string().required("City required"),
      role: Yup.string().required("Role required"),
      plan: Yup.string().required("Selection required"),
      work: Yup.string().required("Selection required"),
    }),
    onSubmit: (values) => {
      let data = JSON.stringify({
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        phoneNumber: values?.phone,
        role: values?.role,
        country: values?.country,
        city: values?.country,
        peoplePlanningToHire: values?.plan,
        peopleWorkingInCompany: values?.work,
      });
      try {
        mutate(data);
      } catch (error) {
        //onError will automatically detect
      }
    },
  });
  //#endregion

  //#region handle outside click
  useOutsideClick(
    [
      {
        ref: planRef,
        excludeClasses: [".planClass"],
        excludeIds: ["plan"],
      },
      {
        ref: workRef,
        excludeClasses: [".workClass"],
        excludeIds: ["work"],
      },
    ],
    (ref) => {
      if (ref === planRef) setPlanHiringDropdown(false);
      if (ref === workRef) setWorkDropdown(false);
    }
  );
  //#endregion

  //#region Request demo mutate
  const { mutate, isLoading } = useMutation(requestDemo, {
    onSuccess: () => {
      queryClient.invalidateQueries("/auth/request-demo");
      navigate("/demo-requested");
    },
    onError: (error) => {
      const errorMessage = error?.response?.data?.message;
      if (Array.isArray(errorMessage)) {
        setTimeout(() => {
          toast.error(errorMessage[0], {
            toastId: "copy-success",
          });
        }, 500);
      } else if (typeof errorMessage === "string") {
        setTimeout(() => {
          toast.error(errorMessage, {
            toastId: "copy-success",
          });
        }, 500);
      } else {
        toast.error("An error occurred.", {
          toastId: "copy-success",
        });
      }
    },
  });
  //#endregion
  document.title = "Login | Dexta";

  return (
    <React.Fragment>
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        enableMultiContainer={false}
        limit={1}
      />
      <div className="sm:grid-cols-1 lg:grid-cols-2 grid overflow-hidden bg-bgAuth h-screen">
        <div className="bg-black sm:hidden lg:block">
          <img
            src={MainLogo}
            className="w-full lg:h-full xl:h-fit lg:object-cover"
          />
        </div>
        <div className="bg-bgAuth flex justify-center flex-col h-screen overflow-auto">
          <div className="sm:w-3/4 2xl:w-2/3 mx-auto">
            <h1
              className="text-2xl text-left"
              style={{ fontFamily: "Archia Semibold" }}
            >
              Request a Demo
            </h1>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              id="login"
            >
              <div className="mt-6 w-full flex flex-row gap-5">
                <div className="2xl:h-[4rem] h-[3.5rem] w-1/2">
                  <TextField
                    name="firstName"
                    id="firstName"
                    type="text"
                    placeholder="First name"
                    textSize="2xl:text-base text-xs"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.firstName || ""}
                  />
                  {validation.touched.firstName &&
                  validation.errors.firstName ? (
                    <div className="ml-1">
                      <p className="text-rose-500 fade-in-text-validations sm:text-xs 2xl:text-sm">
                        {validation.errors.firstName}
                      </p>
                    </div>
                  ) : null}
                </div>
                <div className="2xl:h-[4rem] h-[3.5rem] w-1/2">
                  <TextField
                    name="lastName"
                    id="lastName"
                    type="text"
                    textSize="2xl:text-base text-xs"
                    placeholder="Last name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.lastName || ""}
                  />
                  {validation.touched.lastName && validation.errors.lastName ? (
                    <div className="ml-1">
                      <p className="text-rose-500 fade-in-text-validations sm:text-xs 2xl:text-sm">
                        {validation.errors.lastName}
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="mt-4 w-full flex flex-row gap-5">
                <div className="2xl:h-[4rem] h-[3.5rem] w-1/2">
                  <TextField
                    name="email"
                    id="email"
                    type="text"
                    textSize="2xl:text-base text-xs"
                    placeholder="Email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <div className="ml-1">
                      <p className="text-rose-500 fade-in-text-validations sm:text-xs 2xl:text-sm">
                        {validation.errors.email}
                      </p>
                    </div>
                  ) : null}
                </div>
                <div className="2xl:h-[4rem] h-[3.5rem] w-1/2">
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={validation.values.phone || ""}
                    className="w-full py-4 px-3 bg-white 2xl:text-base rounded-md text-xs"
                    style={{ fontFamily: "Silka" }}
                    onChange={(value) => {
                      validation.setFieldValue("phone", value);
                      validation.setFieldTouched("phone", true);
                    }}
                    international
                    onBlur={() => validation.setFieldTouched("phone", true)}
                  />
                  {validation.touched.phone && validation.errors.phone ? (
                    <div className="ml-1">
                      <p className="text-rose-500 fade-in-text-validations sm:text-xs 2xl:text-sm">
                        {validation.errors.phone}
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="mt-4 w-full flex flex-row gap-5">
                <div className="2xl:h-[4rem] h-[3.5rem] w-1/2">
                  <TextField
                    name="country"
                    id="country"
                    type="text"
                    textSize="2xl:text-base text-xs"
                    placeholder="Country"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.country || ""}
                  />
                  {validation.touched.country && validation.errors.country ? (
                    <div className="ml-1">
                      <p className="text-rose-500 fade-in-text-validations sm:text-xs 2xl:text-sm">
                        {validation.errors.country}
                      </p>
                    </div>
                  ) : null}
                </div>
                <div className="2xl:h-[4rem] h-[3.5rem] w-1/2">
                  <TextField
                    name="city"
                    id="city"
                    textSize="2xl:text-base text-xs"
                    type="text"
                    placeholder="City"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.city || ""}
                  />
                  {validation.touched.city && validation.errors.city ? (
                    <div className="ml-1">
                      <p className="text-rose-500 fade-in-text-validations sm:text-xs 2xl:text-sm">
                        {validation.errors.city}
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="mt-4 w-full flex flex-row">
                <div className="2xl:h-[4rem] h-[3.5rem] w-full">
                  <TextField
                    name="role"
                    id="role"
                    type="text"
                    textSize="2xl:text-base text-xs"
                    placeholder="What is your role in the company?"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.role || ""}
                  />
                  {validation.touched.role && validation.errors.role ? (
                    <div className="ml-1">
                      <p className="text-rose-500 fade-in-text-validations sm:text-xs 2xl:text-sm">
                        {validation.errors.role}
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="mt-4 w-full flex flex-row">
                <div className="relative 2xl:h-[4rem] h-[3.5rem] w-full">
                  <TextField
                    name="plan"
                    id="plan"
                    type="text"
                    textSize="2xl:text-base text-xs"
                    placeholder="How many people do you plan on hiring in the next 12 months?"
                    focus="cursor-pointer truncate overflow-hidden pr-10"
                    value={validation.values.plan || ""}
                    image={arrowdown}
                    onClickInput={() =>
                      setPlanHiringDropdown(!planHiringDropdown)
                    }
                  />
                  {planHiringDropdown && (
                    <div
                      id="education-drop"
                      className="planClass absolute z-20 enable-scrollbar2 border right-0 top-full h-[9rem] overflow-scroll bg-white rounded-md w-full mt-2"
                    >
                      <Scrollbars
                        autoHide
                        style={{ width: "100%", height: "100%" }}
                      >
                        {TimeSlots.map((i) => (
                          <ul
                            key={i.slot}
                            className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                            onClick={() => {
                              validation.setFieldValue("plan", i.slot);
                              setPlanHiringDropdown(false);
                            }}
                            style={{ fontFamily: "Silka" }}
                            ref={planRef}
                          >
                            <li>
                              <p className="block px-5 py-2 text-sm font-medium">
                                {i.slot}
                              </p>
                            </li>
                          </ul>
                        ))}
                      </Scrollbars>
                    </div>
                  )}
                  {validation.touched.plan && validation.errors.plan ? (
                    <div className="ml-1">
                      <p className="text-rose-500 fade-in-text-validations sm:text-xs 2xl:text-sm">
                        {validation.errors.plan}
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="mt-4 w-full flex flex-row">
                <div className="relative 2xl:h-[4rem] h-[3.5rem] w-full">
                  <TextField
                    name="plan"
                    id="work"
                    type="text"
                    textSize="2xl:text-base text-xs"
                    placeholder="How many people work on your company?"
                    focus="cursor-pointer truncate overflow-hidden pr-10"
                    value={validation.values.work || ""}
                    image={arrowdown}
                    onClickInput={() => setWorkDropdown(!workDropdown)}
                  />
                  {workDropdown && (
                    <div
                      id="education-drop"
                      className="workClass absolute z-20 border enable-scrollbar2 right-0 top-full h-[9rem] overflow-scroll bg-white rounded-md w-full mt-2"
                    >
                      <Scrollbars
                        autoHide
                        style={{ width: "100%", height: "100%" }}
                      >
                        {workForce.map((i) => (
                          <ul
                            key={i.slot}
                            className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                            onClick={() => {
                              validation.setFieldValue("work", i.slot);
                              setWorkDropdown(false);
                            }}
                            style={{ fontFamily: "Silka" }}
                            ref={workRef}
                          >
                            <li>
                              <p className="block px-5 py-2 text-sm font-medium">
                                {i.slot}
                              </p>
                            </li>
                          </ul>
                        ))}
                      </Scrollbars>
                    </div>
                  )}
                  {validation.touched.plan && validation.errors.plan ? (
                    <div className="ml-1">
                      <p className="text-rose-500 fade-in-text-validations sm:text-xs 2xl:text-sm">
                        {validation.errors.plan}
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
              <CustomButton
                label="Request a Demo"
                borderCustom="border border-black text-white"
                hoverBgColor="#C0FF06"
                hoverTextColor="#252E3A"
                paddingY="0.7rem"
                marginTop="mt-8"
                bgColor="#252E3A"
                iconR={FaArrowRightLong}
                noMarginIcon={false}
                autoLeftMargin="ml-auto"
                textMarginBotton="ml-auto"
                LoadingBtn={isLoading}
                loadingText="Requesting"
              />
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RequestDemo;
