import http from "../../../../../http";

export const getallcandidates = async (
  selecteditem,
  entries,
  page,
  order,
  category,
  searchCandidates,
  currentPage,
  hiring,
  testName
) => {
  const response = await http.get(
    `/assessment/all-assessments-candidates/candidates?order=${order}&page=${currentPage}&search=${searchCandidates}&take=${entries}&status=${selecteditem}&filterResultBy=${category}&assessmentName=${
      testName === "All" ? "" : testName
    }&hiringStage=${hiring}`
  );
  return response.data;
};
