import React, { useEffect, useRef, useState } from "react";
import { CiSettings } from "react-icons/ci";
import { MdOutlineArrowDropDownCircle } from "react-icons/md";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { ToastContainer, toast, Zoom } from "react-toastify";
import { getCandidateDetails } from "../../hooks/getCandidateDetails";
import useWindowSize from "../../../../Helpers/useWindowSize";
import { BiUpload } from "react-icons/bi";
import { useNavigate } from "react-router-dom/dist";
import ImageInput from "../../../../Components/FileInput/ImageInput";
import https from "../../../../https";
import { updateCandidate } from "../../hooks/updateCandidate";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { getCandidateMetaData } from "../../hooks/getCandidateMetaData";
import { updateCandy } from "../../../Profile/Settings/hooks/updateCandy";
import { updateUser } from "../../hooks/updateUser";
import DpCropper from "../../../../Components/ImageCropper/DpCropper";
import { tabs_sidebar } from "./data";
import GeneralInfo from "./GeneralInfo";
import EducationInfo from "./EducationInfo";
import ExperienceInfo from "./ExperienceInfo";

const CandidateSettings = () => {
  const [tabsVisible, setTabsVisible] = useState(true);
  const [selectedTab, setSelectedTab] = useState("General");
  const chooseImgFunctionRef = useRef();
  const queryClient = useQueryClient();
  const fname = localStorage.getItem("CP-CANDIDATE-FirstName");
  const lname = localStorage.getItem("CP-CANDIDATE-LastName");
  const [profileAllow, setProfileAllow] = useState(false);
  const [dpAfterCrop, setDpAfterCrop] = useState("");
  const [blobdataDp, setblobdataDp] = useState(null);
  const userID = localStorage.getItem("CP-CANDIDATE-ID");
  const [profileImage, setProfileImage] = useState("");
  const size = useWindowSize();
  const navigate = useNavigate();
  const isMobile = size.width <= 640;
  const [isHovered, setIsHovered] = useState(false);
  const [imageLoad, setImageLoad] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [study, setStudy] = useState("");
  const [yearexp, setyearexp] = useState("Years of experience in field");
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [currentPage, setCurrentPage] = useState("choose-img");
  const [candidate, setCandidate] = useState("Candidate Status");
  const [amount, setAmount] = useState(0);
  const [gen, setGender] = useState("");
  const [educationLevel, setEducationLevel] = useState(
    "Highest education level"
  );

  //#region Handle item from tab
  const handleTabClick = (name) => {
    setSelectedTab(name);
  };
  //#endregion

  //#region Fetch candidate Meta Data
  const {
    data: metaData,
    isLoading: metaLoading,
    error: metaError,
  } = useQuery({
    queryKey: ["candidate-meta", userID],
    queryFn: () => getCandidateMetaData(userID),
  });
  //#endregion

  //#region Fetch users data
  const { data, isLoading, error } = useQuery(["users", userID], () =>
    getCandidateDetails(userID)
  );
  //#endregion

  //#region Handle upload button click
  const handleBiUploadClick = () => {
    if (chooseImgFunctionRef.current) {
      chooseImgFunctionRef.current();
    }
  };

  const handleHover = () => {
    setIsHovered(!isHovered);
  };
  //#endregion

  //#region crop functions
  const onCropDoneForDp = (imgCropped) => {
    const canvasEle = document.createElement("canvas");
    canvasEle.width = imgCropped.width;
    canvasEle.height = imgCropped.height;
    const context = canvasEle.getContext("2d");

    // Set a transparent background
    context.clearRect(0, 0, canvasEle.width, canvasEle.height);

    let imageObj1 = new Image();
    imageObj1.src = profileImage;
    imageObj1.onload = function () {
      context.drawImage(
        imageObj1,
        imgCropped.x,
        imgCropped.y,
        imgCropped.width,
        imgCropped.height,
        0,
        0,
        imgCropped.width,
        imgCropped.height
      );

      // Convert the canvas data to a Blob
      canvasEle.toBlob((blob) => {
        // Set the blob data to a separate state variable
        setblobdataDp(blob);
        const dataURL = canvasEle.toDataURL("image/png");
        setDpAfterCrop(dataURL);
        setCurrentPage("img-cropped");
        handlePostImage(blob);
      }, "image/png");
    };
  };

  const onCropCancelForDP = () => {
    setCurrentPage("choose-img");
    setProfileImage("");
  };

  const onDpSelected = (selectedImg) => {
    setProfileImage(selectedImg);
    setCurrentPage("crop-img");
    setProfileModalOpen(true);
  };

  //#endregion

  //#region Handle post image
  const handlePostImage = async (blobx) => {
    setImageLoad(true);
    const formData = new FormData();
    formData.append("file", blobx);
    formData.append("fileType", "user");
    try {
      const response = await https.post(
        "/questions/uploadQuestionImage",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      let data = {
        profilePhoto: response.data.link,
      };
      try {
        mutate(data);
      } catch (error) {}
      setImageLoad(false);
      setProfileAllow(false);
      resetImageState();
    } catch (error) {
      console.error(error);
      setImageLoad(false);
      setProfileAllow(false);
    }
  };

  const { mutate, isLoading: mutateLoading } = useMutation(updateCandy, {
    onSuccess: () => {
      queryClient.invalidateQueries("/users");
      setImageLoad(false);
      toast.success("Changes Saved", {
        toastId: "copy-success",
      });
      resetImageState();
    },
  });

  const resetImageState = () => {
    setProfileImage("");
    setblobdataDp(null);
    setDpAfterCrop(null);
    setCurrentPage("choose-img");
  };
  //#endregion

  //#region Updating States
  useEffect(() => {
    if (!metaError) {
      if (metaData != undefined) {
        setSelectedCountry(
          metaData?.country != "" &&
            metaData?.country &&
            metaData?.country === false
            ? ""
            : metaData?.country
        );
        setEducationLevel(
          metaData?.educationLevel != "" &&
            metaData?.educationLevel != null &&
            metaData?.educationLevel === false
            ? ""
            : metaData?.educationLevel
        );
        setStudy(
          metaData?.study != "" &&
            metaData?.study != null &&
            metaData?.study === false
            ? ""
            : metaData?.study
        );
        setSelectedRole(
          metaData?.experience_in != "" &&
            metaData?.experience_in != null &&
            metaData?.experience_in === false
            ? ""
            : metaData?.experience_in
        );
        setyearexp(
          metaData?.experience_years != "" &&
            metaData?.experience_years != null &&
            metaData?.experience_years === false
            ? ""
            : metaData?.experience_years
        );
        setSelectedLocation(
          metaData?.location_preferences != "" &&
            metaData?.location_preferences != null
            ? metaData?.location_preferences?.split(",")
            : []
        );
        setCandidate(
          metaData?.employmentStatus != "" &&
            metaData?.employmentStatus != null &&
            metaData?.employmentStatus === false
            ? ""
            : metaData?.employmentStatus
        );
        setSelectedCurrency(
          metaData?.desired_currency !== "" &&
            metaData?.desired_currency !== null &&
            metaData?.desired_currency === false
            ? ""
            : metaData?.desired_currency
        );
        setAmount(
          metaData?.desired_annual_salary !== "" &&
            metaData?.desired_annual_salary !== null &&
            metaData?.desired_annual_salary === false
            ? ""
            : metaData?.desired_annual_salary
        );
        setGender(
          metaData?.gender !== "" &&
            metaData?.gender !== null &&
            metaData?.gender === false
            ? ""
            : metaData?.gender
        );
      }
    }
  }, [metaData, metaError]);
  //#endregion
  return (
    <>
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        enableMultiContainer={false}
        limit={1}
      />
      <div className="bg-bodyColor">
        <div className="bg-bodyColor md:container mx-auto">
          <div className="mt-10 sm:px-4 md:px-3">
            <div className="grid sm:grid-cols-1 md:grid-cols-12 sm:py-5 md:py-0 md:p-10 overflow-hidden">
              <div className="col-span-2 pr-4">
                <div className="flex flex-row gap-2">
                  <CiSettings className="w-6 h-6 my-auto" />
                  <h1
                    className="text-2xl my-auto font-bold select-text text-coalColor"
                    id="step3"
                    style={{ fontFamily: "Archia Semibold" }}
                  >
                    Settings
                  </h1>
                  <MdOutlineArrowDropDownCircle
                    className="w-5 h-5 my-auto md:hidden ml-auto"
                    onClick={() => setTabsVisible(!tabsVisible)}
                  />
                </div>
                {tabsVisible && (
                  <div
                    className={`flex flex-col mt-5 ${
                      isMobile && "fade-in-image"
                    }`}
                  >
                    {tabs_sidebar?.map((item, index) => {
                      return (
                        <li
                          id={`setting${index + 1}`}
                          key={index}
                          className={`p-4 cursor-pointer list-none relative text-base flex justify-between rounded-md hover:font-bold ${
                            selectedTab === item.title
                              ? "bg-bgAuth font-bold"
                              : "bg-transparent"
                          }`}
                          onClick={() => {
                            // Handle the tab change and other logic
                            handleTabClick(item.title);
                          }}
                        >
                          <a
                            aria-current="page"
                            style={{ fontFamily: "Silka" }}
                          >
                            {item.title}
                          </a>
                        </li>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="p-4 bg-white col-span-10 rounded-md sm:h-auto md:h-[85vh]">
                <TransitionGroup>
                  <CSSTransition
                    key={selectedTab}
                    timeout={450}
                    classNames="settingFade"
                  >
                    <div className="grid md:grid-cols-6 gap-3">
                      {selectedTab === "General" && (
                        <React.Fragment>
                          {isLoading ? (
                            <div class="loader-container-1 col-span-6">
                              <div class="loader-1"></div>
                            </div>
                          ) : (
                            <React.Fragment>
                              <div className="md:col-span-1 p-3 rounded-md bg-bodyColor sm:h-auto md:h-[80vh]">
                                <DpCropper
                                  profileImage={profileImage}
                                  onCropDoneForDp={onCropDoneForDp}
                                  onCropCancelForDP={onCropCancelForDP}
                                  profileModalOpen={profileModalOpen}
                                  setProfileModalOpen={setProfileModalOpen}
                                  setProfileAllow={setProfileAllow}
                                />
                                <h1 style={{ fontFamily: "Archia Semibold" }}>
                                  Profile picture
                                </h1>
                                <p
                                  className="underline mt-3 cursor-pointer"
                                  style={{ fontFamily: "Silka Light" }}
                                  onClick={handleBiUploadClick}
                                >
                                  Upload
                                </p>
                                <div className="py-8 flex justify-center">
                                  <div
                                    className={`relative w-[8rem] h-[8rem] flex items-center justify-center rounded-full overflow-hidden ${
                                      isHovered ? "bg-black/40" : "bg-white"
                                    }`}
                                    onMouseEnter={handleHover}
                                    onMouseLeave={handleHover}
                                    onClick={handleBiUploadClick}
                                  >
                                    {imageLoad ? (
                                      <div className="w-full h-full flex items-center justify-center">
                                        <div class="three-body">
                                          <div class="three-body__dot"></div>
                                          <div class="three-body__dot"></div>
                                          <div class="three-body__dot"></div>
                                        </div>
                                      </div>
                                    ) : (
                                      <>
                                        <img
                                          src={data?.profilePhoto}
                                          className="h-full w-full object-contain"
                                        />

                                        {!data?.profilePhoto && (
                                          <div className="w-full h-full flex items-center justify-center">
                                            <BiUpload className="h-6 w-6 text-white" />
                                          </div>
                                        )}
                                        <label
                                          htmlFor="profile-picture-upload"
                                          className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full flex items-center justify-center cursor-pointer ${
                                            isHovered || !data?.profilePhoto
                                              ? "block"
                                              : "hidden"
                                          }`}
                                        >
                                          <div className="w-full h-full flex items-center justify-center bg-black/20 rounded-full transition-colors duration-300">
                                            <BiUpload className="h-6 w-6 text-white" />
                                          </div>
                                        </label>
                                      </>
                                    )}
                                    <ImageInput
                                      setProfileImage={setProfileImage}
                                      onDpSelected={onDpSelected}
                                      onChooseImg={(chooseImg) =>
                                        (chooseImgFunctionRef.current =
                                          chooseImg)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="md:px-4 md:col-span-5 overflow-auto pb-10 md:h-[85vh]">
                                <GeneralInfo
                                  fname={fname}
                                  lname={lname}
                                  metaData={metaData}
                                  data={data}
                                  metaError={metaError}
                                  selectedCountry={selectedCountry}
                                  setSelectedCountry={setSelectedCountry}
                                  gen={gen}
                                  setGender={setGender}
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                      {selectedTab === "Education" && (
                        <React.Fragment>
                          {isLoading ? (
                            <div class="loader-container-1 col-span-6">
                              <div class="loader-1"></div>
                            </div>
                          ) : (
                            <React.Fragment>
                              <div className="md:px-4 md:col-span-6 overflow-auto pb-10 md:h-[85vh]">
                                <EducationInfo
                                  educationLevel={educationLevel}
                                  setEducationLevel={setEducationLevel}
                                  study={study}
                                  setStudy={setStudy}
                                  metaData={metaData}
                                  data={data}
                                  metaError={metaError}
                                  selectedCountry={selectedCountry}
                                  setSelectedCountry={setSelectedCountry}
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                      {selectedTab === "Work experience" && (
                        <React.Fragment>
                          {isLoading ? (
                            <div class="loader-container-1 col-span-6">
                              <div class="loader-1"></div>
                            </div>
                          ) : (
                            <React.Fragment>
                              <div className="md:px-4 md:col-span-6 overflow-auto pb-10 md:h-[85vh]">
                                <ExperienceInfo
                                  selectedRole={selectedRole}
                                  setSelectedRole={setSelectedRole}
                                  yearexp={yearexp}
                                  setyearexp={setyearexp}
                                  selectedLocation={selectedLocation}
                                  setSelectedLocation={setSelectedLocation}
                                  candidate={candidate}
                                  setCandidate={setCandidate}
                                  selectedCurrency={selectedCurrency}
                                  setSelectedCurrency={setSelectedCurrency}
                                  amount={amount}
                                  setAmount={setAmount}
                                  educationLevel={educationLevel}
                                  setEducationLevel={setEducationLevel}
                                  study={study}
                                  setStudy={setStudy}
                                  metaData={metaData}
                                  data={data}
                                  metaError={metaError}
                                  selectedCountry={selectedCountry}
                                  setSelectedCountry={setSelectedCountry}
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  </CSSTransition>
                </TransitionGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidateSettings;
