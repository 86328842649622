import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ".././auth.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { ToastContainer, Zoom } from "react-toastify";
import TextField from "../../../../Components/Dexta/TextField/TextField";
import Loader from "react-loader-spinner";
import { forgetEmail } from "../../hooks/forgetEmail";
import queryString from "query-string";
import Scrollbars from "react-custom-scrollbars";
import { IoAddCircle } from "react-icons/io5";
import MainLogo from "../../../../Dexta_assets/signup_girl.png";
import { FaArrowRightLong } from "react-icons/fa6";
import CustomButton from "../../../../Components/CustomButton/CustomButton";

const ResetSuccessful = () => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const parseOptions = { decode: false };
  const parsed = queryString.parse(location.search, parseOptions);

  return (
    <>
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <>
        <div className="sm:grid-cols-1 lg:grid-cols-2 grid bg-bgAuth h-screen">
          <div className="bg-black sm:hidden lg:block">
            <img src={MainLogo} className="w-full lg:h-full  lg:object-cover" />
          </div>
          <div className="bg-bgAuth flex justify-center flex-col md:pb-40">
            <div className="sm:w-5/6 lg:w-2/3 mx-auto">
              <h1
                className="text-2xl text-left"
                style={{ fontFamily: "Archia Semibold" }}
              >
                Password reset
              </h1>
              <div
                style={{ fontFamily: "Silka" }}
                className="text-sm mt-6 flex flex-col"
              >
                <span>Your password has been successfully reset. </span>
                <span>Click below to log in magically.</span>
              </div>
              <p>{parsed?.email}</p>
              <CustomButton
                label="Continue"
                borderCustom="border border-black text-white"
                paddingY="0.7rem"
                hoverBgColor="#C0FF06"
                hoverTextColor="#252E3A"
                marginTop="mt-4"
                bgColor="#252E3A"
                iconR={FaArrowRightLong}
                noMarginIcon={false}
                autoLeftMargin="ml-auto"
                textMarginBotton="ml-auto"
                onClickButton={() => navigate("/candidate/login")}
              />
              <span
                className="text-coalColor text-sm text-center"
                style={{ fontFamily: "Silka" }}
              >
                Don't want to reset your password?{" "}
                <span
                  className="text-coalColor font-medium cursor-pointer"
                  style={{ fontFamily: "Silka Bold" }}
                  onClick={() => navigate("/candidate/login")}
                >
                  Return to Login
                </span>
              </span>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default ResetSuccessful;
