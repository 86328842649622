import React, { useState, useEffect, useMemo } from "react";
import Search from "../../../../Dexta_assets/searchBar.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getCandidates } from "./hooks/getCandidates";
import { updateHiringStatus } from "./hooks/updateHiringStatus";
import { useQueryClient } from "@tanstack/react-query";
import { updateStep } from "./hooks/updateStep";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useRef } from "react";
import "../../../../Components/Loading/Loading.css";
import { deleteAssessment } from "./hooks/deleteAssessment";
import { getSelectedModules } from "./hooks/getSelectedModulesByID";
import { getAssessmentByID } from "./hooks/getAssessmentByID";
import styles from "./styling2.module.css";
import ReactHtmlParser from "react-html-parser";
import { getModuleByID } from "./hooks/getModuleByID";
import Loader from "react-loader-spinner";
import "./invite.css";
import "react-input-range/lib/css/index.css";
import { deleteUser } from "./hooks/deleteUser";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { getHiringstatuses } from "./hooks/getHiringStatusList";
import { useSelector, useDispatch } from "react-redux";
import { setPreviewToFalse } from "../../../../redux/reducers/PreviewBack/PreviewSlice";
import http from "../../../../http";
import "react-toastify/dist/ReactToastify.css";
import Premium from "../../../../Components/Modals/Premium";
import InviteCandidatesModal from "../../../../Components/InviteCandidates/InviteCandidatesModal";
import { styled, alpha } from "@mui/material/styles";
import { IoIosMail, IoMdEye } from "react-icons/io";
import { FaEdit } from "react-icons/fa";
import { GrClone } from "react-icons/gr";
import { MdOutlinePublishedWithChanges } from "react-icons/md";
import { LuArchive } from "react-icons/lu";
import { MdOutlineAutoDelete } from "react-icons/md";
import { Scrollbars } from "react-custom-scrollbars";
import CustomButton from "../../../../Components/CustomButton/CustomButton";
import { AiOutlineSend } from "react-icons/ai";
import eye from "../../../../Dexta_assets/eye.png";
import bin from "../../../../Dexta_assets/bin.png";
import { LuSendHorizonal } from "react-icons/lu";
import TablePagination from "../../../../Components/TablePagination.js/TablePagination";
import clock from "../../../../Dexta_assets/clock.png";
import CustomQuestions from "./Modals/CustomQuestion";
import d1 from "../../../../Dexta_assets/d1.png";
import d2 from "../../../../Dexta_assets/d2.png";
import d3 from "../../../../Dexta_assets/d3.png";
import d4 from "../../../../Dexta_assets/d4.png";
import closem from "../../../../Dexta_assets/closeModal.png";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import ScoresGraph from "../../../../Components/Modals/ScoresGraph";
import DeleteModal from "../../../../Components/ConfirmationModals/DeleteModal";
import DeleteIcon from "../../../../Dexta_assets/deleteIcon.png";
import { setCandidateToFalse } from "../../../../redux/reducers/CandidateBack/CandidateSlice";
import { PiDotsThreeCircle } from "react-icons/pi";
import useWindowSize from "../../../../Helpers/useWindowSize";
import { getMe } from "./hooks/getMe";
import { getCandidatesForChecks } from "./hooks/getCandidatesForChecks";
import HiringModal from "./Modals/HiringModal";
import { getAssessmentUniqueCode } from "./hooks/getAssessmentUniqueCode";
import { type_mail } from "./data";
import GeneralModal from "../../../../Components/Modals/GeneralModal";
import useOutsideClick from "../../../../Components/OutsideClick/OutsideClick";
import EntriesDropdown from "../../../../Components/EntriesDropdown";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import help from "../../../../Dexta_assets/helpIcon.png";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { PiDotsThreeCircleVerticalFill } from "react-icons/pi";

import { getAccess } from "./hooks/getAccess";
//#region styling of mui elements
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
//#endregion

const InviteCandidates = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { id } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [input, setInput] = useState("");
  const [tags, setTags] = useState([]);
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const [lengthTags, setlength] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [sectionID, setSectionID] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedEntries, setSelectedEntries] = useState(10);
  const [pageDropdown, setPageDropdown] = useState(false);
  const [candidatesearch, setCandidate] = useState("");
  const [cadidateinput, setcandidateinput] = useState("");
  const dropdownRef = useRef(null);
  const [value, setValue] = useState({ min: 0, max: 100 });
  const [isopen, setisopen] = useState(false);
  const barRef = useRef(null);
  const [status, setStatus] = useState("");
  const [statusV, setStatusV] = useState("");
  const [statusDrop, setStatusDrop] = useState(false);
  const [hiringDrop, setHiringDrop] = useState(false);
  const [hiringDropInList, setHiringDropInList] = useState({
    show: false,
    usersId: null,
  });
  const fieldRef = useRef(null);
  const hiringRef = useRef(null);
  const [deleteUserModal, setUserModal] = useState(false);
  const [candidateID, setCandidateID] = useState(0);
  const [customQuestionsList, setCustomQuestionsList] = useState(null);
  const [hiring, setHiring] = useState("");
  const [staticLoading, setStaticLoading] = useState(true);
  const dispatch = useDispatch();
  const [editDisable, setEditDisable] = useState(false);
  const [cloneLoading, setCloneLoading] = useState(false);
  const [reminderLoading, setReminderLoading] = useState(false);
  const [premiumOpen, setPremiumOpen] = useState(false);
  const user_package_check = useSelector(
    (state) => state.packageDetails.setPackage
  );
  const [inviteModal, setInviteModal] = useState(false);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const [isAscending, setIsAscending] = useState(true);
  const [soryBy, setSortBy] = useState("score");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortDropdown, setSortDropdown] = useState(false);
  const [typeDropdown, setTypeDropdown] = useState(false);
  const sortRef = useRef(null);
  const typeRef = useRef(null);
  const [sortvalue, setSortvalue] = useState("");
  const [customOpen, setCustomOpen] = useState(false);
  const [ScoreGraph, setScoreGraph] = useState(false);
  const [heading, setHeading] = useState("");
  const [moduleName, setModuleName] = useState("");
  const [sectionIndex, setSectionIndex] = useState(0);
  const userID = localStorage.getItem("CP-USER-ID");
  const [firstN, setFirstN] = useState("");
  const [lasTN, setLastN] = useState("");
  const [uID, setUID] = useState(null);
  const user_role = useSelector(
    (state) => state.accountTypeDetails.accountType
  );
  const [order, setOrder] = useState("DESC");
  const [rows, setRows] = useState([
    { firstName: "", lastName: "", email: "" },
  ]);
  const handleClick_menu = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const size = useWindowSize();
  const isMobile = size.width <= 640;
  const [hiringOpen, setHiringOpen] = useState(false);
  const [hiringLabel, sethiringLabel] = useState("");
  const [hiringStatus, sethiringStatus] = useState("");
  const [typeHiring, settypeHiring] = useState("");
  const [checkboxText, setcheckboxText] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [assessmentCode, setAssessmentCode] = useState(null);
  const [copyCount, setCopyCount] = useState(0);
  const [generalModal, setGeneralModal] = useState(false);
  const [description, setdescription] = useState(
    "By proceeding with the upgrade, your package will be updated, and the required amount will be deducted from your account"
  );
  const [typeMail, setTypeMail] = useState("");
  const [customize, setCustomize] = useState(false);
  const [downloadDropdown, setDownloadDropdown] = useState(false);
  const [selectDropdown, setSelectDropdown] = useState(false);
  const [noButtons, setNoButtons] = useState("");
  const [multiselect, setMultiselect] = useState(false);
  const [allselect, setAllselect] = useState(false);
  const downloadRef = useRef(null);
  const selectRef = useRef(null);
  const handleClose_menu = () => {
    setAnchorEl2(null);
  };

  //#region Handle checkboxes
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedRows(
      !selectAll
        ? data?.data
            ?.filter((j) => {
              // Exclude candidates who are Hired or Rejected unless isFinalizedEmailSent is true
              if (j.hiringStage === "HIRED" || j.hiringStage === "REJECTED") {
                if (j?.status === "completed") {
                  return j?.isFinalizedEmailSent === false;
                }
              }
              // Allow candidates with other statuses
              return (
                j.hiringStage !== "HIRED" &&
                j.hiringStage !== "REJECTED" &&
                j?.status !== "invited" &&
                j?.status !== "InProgress" &&
                j?.status !== "started"
              );
            })
            .map((row) => row?.candidates?.id)
        : []
    );
  };

  const handleRowSelect = (id) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId) => rowId !== id)
        : [...prevSelectedRows, id]
    );
  };
  //#endregion

  const statuses = [
    {
      value: "",
      title: "All",
    },
    {
      value: "invited,draft",
      title: "Invited",
    },
    {
      value: "InProgress,started",
      title: "In Progress",
    },
    {
      value: "completed",
      title: "Completed",
    },
  ];

  const sortValues = [
    {
      value: "dateInv",
      title: "Date invited",
    },
    {
      value: "dateComp",
      title: "Date completed",
    },
    {
      value: "l2h",
      title: "Average score (low to high)",
    },
    {
      value: "h2l",
      title: "Average score (high to low)",
    },
  ];

  const {
    data: myData,
    isLoading: myLoading,
    error: myError,
  } = useQuery({
    queryKey: ["getUserDetail", userID],
    queryFn: () => getMe(userID),
    retry: false,
  });

  useEffect(() => {
    if (myData) {
      setRows((prevRows) => [
        {
          ...prevRows[0],
          firstName: myData?.firstName,
          lastName: myData?.lastName,
          email: myData?.email,
        },
      ]);
    }
  }, [myData]);

  useEffect(() => {
    setTimeout(() => {
      setStaticLoading(false);
    }, 1000);
  }, [staticLoading]);

  useEffect(() => {
    function handleoutsideField(event) {
      if (
        fieldRef.current &&
        !fieldRef.current.contains(event.target) &&
        event.target.id !== "field" &&
        !event.target.closest(".fieldclass")
      ) {
        setStatusDrop(false);
      }
    }
    document.addEventListener("mousedown", handleoutsideField);
    return () => {
      document.removeEventListener("mousedown", handleoutsideField);
    };
  }, [fieldRef]);

  useEffect(() => {
    function handleoutsideField(event) {
      if (
        sortRef.current &&
        !sortRef.current.contains(event.target) &&
        event.target.id !== "sort" &&
        !event.target.closest(".sortClass")
      ) {
        setSortDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleoutsideField);
    return () => {
      document.removeEventListener("mousedown", handleoutsideField);
    };
  }, [sortRef]);

  useEffect(() => {
    function handleoutsideField(event) {
      if (
        typeRef.current &&
        !typeRef.current.contains(event.target) &&
        event.target.id !== "type" &&
        !event.target.closest(".typeClass")
      ) {
        setTypeDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleoutsideField);
    return () => {
      document.removeEventListener("mousedown", handleoutsideField);
    };
  }, [typeRef]);

  useEffect(() => {
    function handleoutsideField(event) {
      if (
        hiringRef.current &&
        !hiringRef.current.contains(event.target) &&
        event.target.id !== "hiring" &&
        !event.target.closest(".hiringClass")
      ) {
        setHiringDrop(false);
        setHiringDropInList({ show: false, usersId: null });
      }
    }
    document.addEventListener("mousedown", handleoutsideField);
    return () => {
      document.removeEventListener("mousedown", handleoutsideField);
    };
  }, [hiringRef]);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    function handleoutsideeducation(event) {
      if (
        barRef.current &&
        !barRef.current.contains(event.target) &&
        event.target.id !== "range-slider" &&
        event.target.id !== "range-slider2"
      ) {
        setisopen(false);
      }
    }
    document.addEventListener("mousedown", handleoutsideeducation);
    return () => {
      document.removeEventListener("mousedown", handleoutsideeducation);
    };
  }, [barRef]);

  useEffect(() => {
    function handleClickOutsideGif(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setPageDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutsideGif);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideGif);
    };
  }, [dropdownRef]);

  const { data, isLoading, refetch } = useQuery(
    [
      "assessment",
      id,
      selectedEntries,
      selectedPage,
      cadidateinput,
      value,
      status,
      hiring,
      soryBy,
      currentPage,
      order,
    ],
    () =>
      getCandidates(
        id,
        selectedEntries,
        selectedPage,
        cadidateinput,
        value,
        status,
        hiring,
        soryBy,
        currentPage,
        order
      ),
    {
      onSuccess: (data) => {
        const statusArray = Array.from(
          new Set(data?.data?.map((item) => item?.status))
        );
        if (
          statusArray.includes("completed") ||
          statusArray.includes("started") ||
          statusArray.includes("inProgress") ||
          statusArray.includes("invited") ||
          statusArray.includes("draft")
        ) {
          setEditDisable(true);
        }
      },
    }
  );

  const { data: candidatesData, isLoading: candidatesLoading } = useQuery(
    ["assessment", id],
    () => getCandidatesForChecks(id)
  );

  //#region take user to top of screen
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //#endregion

  useEffect(() => {
    if (candidatesearch === "") {
      setcandidateinput("");
    }
  }, [candidatesearch]);

  //#region Updating status
  const { mutate, isLoading: mutateLoading } = useMutation(updateStep, {
    onSuccess: () => {
      queryClient.invalidateQueries("assessment");
      toast.success("Invite has been sent", {
        toastId: "copy-success",
      });
      setlength(0);
      setTags([]);
    },
    onError: (error) => {
      setError(true);
      setErrorMessage(error.response.data.message[0]);
      if (error.response.data.message[0].includes(".com")) {
        toast.error(
          `This candidate ${error.response.data.message[0]} has already been invited`,
          {
            toastId: "copy-success",

            style: { width: "450px" },
          }
        );
      }
    },
  });

  const { mutate: archiveMutate, isLoading: archiveLoading } = useMutation(
    updateStep,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("assessment");
      },
      onError: (error) => {
        setError(true);
        setErrorMessage(error.response.data.message[0]);
      },
    }
  );
  //#endregion

  const handleSearchCandidate = () => {
    setcandidateinput(candidatesearch);
  };

  //#region creating clone of assessment
  const cloneAssessment = async () => {
    setCloneLoading(true);
    try {
      const response = await http.get(`/assessment/clone/assessment-id/${id}`);
      if (response.status == 200) {
        toast.success("Test has been cloned.");
        setCloneLoading(false);
        setTimeout(() => {
          navigate("/dashboard");
        }, 2000);
      }
    } catch (error) {
      console.error(error.response.data);
    }
  };
  //#endregion

  //#region Deleting Assessment
  const { mutate: deleteMutate, isLoading: deleteLoading } = useMutation(
    deleteAssessment,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("assessment");
        navigate("/dashboard");
      },
      onError: (error) => {
        setError(true);
        setErrorMessage(error.response.data.message[0]);
      },
    }
  );
  //#endregion

  //#region Handle functions for arhcive or delete
  const handleArchiveClick = () => {
    let data = {
      content: {
        status: "archived",
      },
      categoryID: id,
    };
    try {
      archiveMutate(data);
      toast.success("Test has been archived.", {
        toastId: "copy-success",
      });
      setTimeout(() => {
        navigate("/dashboard");
      }, 1500);
    } catch (err) {}
  };

  const handleArchiveActive = () => {
    let data = {
      content: {
        status: "active",
      },
      categoryID: id,
    };
    try {
      archiveMutate(data);
      toast.success("Test has been activated.", {
        toastId: "copy-success",
      });
      setTimeout(() => {
        navigate("/dashboard");
      }, 1500);
    } catch (err) {}
  };

  const handleDeleteAccount = () => {
    let data = {
      assessmentID: id,
    };
    try {
      deleteMutate(data);
      toast.success("Test has been deleted.", {
        toastId: "copy-success",
      });
    } catch (err) {}
  };

  const handleDeleteUser = () => {
    let data = {
      ID: {
        assessmentID: id,
      },
      ID2: {
        candidateID: candidateID,
      },
    };

    try {
      deleteUserMutate(data);
      toast.success("User has been deleted", {
        toastId: "copy-success",
      });
    } catch (err) {
      console.log(err.message);
    }
  };
  //#endregion

  //#region Fetch Selected Modules
  const {
    data: modulesData,
    error: modulesError,
    isLoading: modulesLoading,
  } = useQuery(["sections", id], () => getSelectedModules(id));
  //#endregion

  //#region Fetch GET assessment
  const {
    data: assessmentData,
    error: assessmentError,
    isLoading: assessmentLoading,
  } = useQuery(["assessment".id], () => getAssessmentByID(id));

  const timeCalculation = React.useMemo(() => {
    // Ensure modulesData is defined and has valid data before calculating
    if (!modulesData || modulesData.length === 0 || !modulesData[0]?.section) {
      return 0; // Default value while loading
    }

    return modulesData[0].section.reduce((accumulator, i) => {
      if (i.time !== "") {
        const timeValue = parseInt(i?.time, 10);
        if (!isNaN(timeValue)) {
          return accumulator + timeValue;
        }
      }
      return accumulator;
    }, 0);
  }, [modulesData]);
  //#endregion

  //#region updating hiring status
  const { mutate: hiringMutate, isLoading: hiringLoading } = useMutation(
    updateHiringStatus,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries("/assessment/candidate");
        queryClient.invalidateQueries(["assessment"]);
        refetch();
        setTimeout(() => {
          refetch();
        }, 500);
      },
      onError: () => {},
    }
  );

  const handlehiringChange = (hiring) => {
    setFirstN(hiring?.fname);
    setLastN(hiring?.lname);
    setUID(hiring?.usersId);
    if (hiring?.status === "INVITED FOR INTERVIEW") {
      sethiringStatus("Invite candidate for interview");
      sethiringLabel("Are you inviting the candidate for an interview?");
      setcheckboxText("Do you want to send an interview invite through Dexta?");
      setHiringOpen(true);
      settypeHiring(hiring?.status);
    } else if (hiring?.status === "OFFER SENT") {
      sethiringStatus("Send offer to candidate");
      sethiringLabel("Are you planning on hiring candidate?");
      setcheckboxText(
        "Would you like to notify the candidate of the offer through Dexta?"
      );
      setHiringOpen(true);
      settypeHiring(hiring?.status);
    } else if (hiring?.status === "REJECTED") {
      sethiringStatus("Reject candidate");
      sethiringLabel("Are you rejecting the candidate?");
      setcheckboxText(
        "Would you like to notify the candidate of the rejection through Dexta?"
      );
      setHiringOpen(true);
      settypeHiring(hiring?.status);
    } else if (hiring?.status === "PASSED") {
      sethiringStatus("Pass candidate");
      sethiringLabel("Do you want to pass this candidate?");
      setcheckboxText("Send a pass email to this candidate as well");
      setHiringOpen(true);
      settypeHiring(hiring?.status);
    } else if (hiring?.status === "REMINDER TO RESPOND TO OFFER") {
      sethiringStatus("Send reminder to candidate");
      sethiringLabel(
        "Do you want to send reminder to this candidate to respond to offer?"
      );
      setHiringOpen(true);
      settypeHiring(hiring?.status);
    } else if (hiring?.status === "HIRED") {
      sethiringStatus("Congratulate candidate on being hired");
      sethiringLabel("Has the candidate accepted your offer?");
      setcheckboxText(
        "Would you like to congratulate candidate through Dexta?"
      );
      setHiringOpen(true);
      settypeHiring(hiring?.status);
    } else if (hiring?.status === "POSITION WITHDRAWN") {
      sethiringStatus("Notify candidate of position withdrawal");
      sethiringLabel("Are you no longer hiring for this position?");
      setcheckboxText("Would you like to notify candidate through Dexta?");
      setHiringOpen(true);
      settypeHiring(hiring?.status);
    } else {
      let data = {
        IDS: {
          userID: hiring.usersId,
          Assessment: hiring?.assessmentID,
        },
        postData: JSON.stringify({
          status: hiring.status,
        }),
      };
      try {
        hiringMutate(data);
        toast.success("Hiring stage changed for candidate.", {
          toastId: "copy-success",

          style: { width: "350px" },
        });
      } catch (err) {
        //react-query will handle error
      }
    }
  };

  //#endregion

  function handleButtonClick(event) {
    event.stopPropagation();
  }

  const {
    data: ModuleData,
    error: errorModule,
    isLoading: isLoadingModule,
  } = useQuery(["sections", sectionID], () => getModuleByID(sectionID));

  const handleSearchByEnter = (e) => {
    if (e.key === "Enter") {
      handleSearchCandidate();
    }
  };

  //#region handle outside clicks
  useOutsideClick(
    [
      {
        ref: downloadRef,
        excludeClasses: [".downloadClass"],
        excludeIds: ["downloadID", "labelID"],
      },
      {
        ref: selectRef,
        excludeClasses: [".selectClass"],
        excludeIds: ["selectID", "threedots"],
      },
    ],
    (ref) => {
      if (ref === downloadRef) setDownloadDropdown(false);
      if (ref === selectRef) setSelectDropdown(false);
    }
  );
  //#endregion

  document.title = "Invite Candidates | Dexta";

  //#region Delete user from assessment
  const { mutate: deleteUserMutate, isLoading: deleteUserLoading } =
    useMutation(deleteUser, {
      onSuccess: () => {
        queryClient.invalidateQueries("assessment");
        setUserModal(false);
      },
      onError: (error) => {
        setError(true);
        setErrorMessage(error.response.data.message[0]);
      },
    });

  //#region Fetching hiring statuses list
  const { data: hiringData, isLoading: HiringLoading } = useQuery(
    ["hiring"],
    () => getHiringstatuses()
  );
  //#endregion
  //#endregion

  const reminderFunction = async (candidateID) => {
    setReminderLoading(true);
    try {
      const response = await http.get(
        `/assessment/send-reminder/${id}/candidate/${candidateID}`
      );
      if (response.status == 200) {
        toast.success("The candidate has been sent a reminder", {
          toastId: "copy-success",

          style: { width: "400px" },
        });
        setReminderLoading(false);
      }
    } catch (error) {
      console.error(error.response.data);
    }
  };

  useMemo(() => {
    if (assessmentData) {
      setCustomQuestionsList(assessmentData?.customQuestionsList);
    }
  }, [assessmentData]);

  const handleIconClick = (index) => {
    setIsAscending((prev) => {
      const newIsAscending = [...prev];
      newIsAscending[index] = !newIsAscending[index];
      return newIsAscending;
    });
  };

  //#region change current page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    const tableElement = document.querySelector(".scrollTable");
    if (tableElement) {
      tableElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  //#endregion

  const [mousePosition, setMousePosition] = useState({ x: null, y: null });

  React.useEffect(() => {
    const updateMousePosition = (ev) => {
      setMousePosition({ x: ev.clientX, y: ev.clientY });
    };
    window.addEventListener("mousemove", updateMousePosition);
    return () => {
      window.removeEventListener("mousemove", updateMousePosition);
    };
  }, [mousePosition]);

  //#region get assessments code
  const { mutate: uniqueMutate } = useMutation(getAssessmentUniqueCode, {
    onSuccess: (res) => {
      queryClient.invalidateQueries("/assessment/invite");
      setAssessmentCode(res?.attemptCode);
    },
  });

  const handleAssessmentCode = () => {
    let data = {
      assessmentId: parseInt(id),
    };

    try {
      uniqueMutate(data);
    } catch (err) {
      //
    }
  };

  useEffect(() => {
    handleAssessmentCode();
  }, [inviteModal, copyCount]);
  //#endregion

  //#region Fetching and Filtering Assessments
  const {
    data: dataAccess,
    isLoading: accessLoading,
    error: accessError,
  } = useQuery(["/assessment/user-can-create", userID], () =>
    getAccess(userID)
  );
  //#endregion

  //#region handling recruiter invite
  const HandleSendInvite = () => {
    let data = {
      content: {
        candidates: rows,
      },
      categoryID: id,
    };
    try {
      inviteMutate(data);
    } catch (err) {
      //
    }
  };

  const { mutate: inviteMutate, isLoading: mutateInviteLoading } = useMutation(
    updateStep,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries("assessment");
        setTimeout(() => {
          toast.success("Invite has been sent");
        }, 500);
      },
      onError: (error) => {
        setError(true);
        setErrorMessage(error.response.data.message[0]);
        if (error.response.data.message[0].includes(".com")) {
          toast.success(
            `This candidate ${error.response.data.message[0]} already invited.`,
            {
              style: { width: "450px" },
            }
          );
        }
      },
    }
  );
  //#endregion

  useEffect(() => {
    if (!allselect && !multiselect) {
      setSelectedRows([]);
      setSelectAll(false);
    }
  }, [multiselect, allselect]);

  // console.log(
  //   assessmentData?.isFinished === false,
  //   assessmentData?.status !== "deactivated",
  //   dataAccess?.isAllowed,
  //   assessmentData?.status,
  //   "Zinger"
  // );

  const checkEditTest = () => {
    if (!assessmentLoading) {
      if (assessmentData?.isFinished) return false;

      const { status } = assessmentData;
      const { isAllowed } = dataAccess;

      return (
        status === "active" ||
        (isAllowed && status !== "deactivated") ||
        (!isAllowed &&
          status !== "archived" &&
          status !== "draft" &&
          status !== "deactivated")
      );
    }
  };

  return (
    <React.Fragment>
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        enableMultiContainer={false}
        limit={1}
      />
      <CustomQuestions
        customOpen={customOpen}
        setCustomOpen={setCustomOpen}
        customQuestionsList={customQuestionsList}
        assessmentLoading={assessmentLoading}
      />
      <ScoresGraph
        setScoreGraph={setScoreGraph}
        ScoreGraph={ScoreGraph}
        heading={heading}
        moduleName={moduleName}
        candidateData={data?.data}
        sectionIndex={sectionIndex}
        setSortvalue={setSortvalue}
        sortvalue={sortvalue}
        setOrder={setOrder}
        setSortBy={setSortBy}
        currentPage={currentPage}
      />
      <HiringModal
        hiringOpen={hiringOpen}
        setHiringOpen={setHiringOpen}
        hiringLabel={hiringLabel}
        hiringStatus={hiringStatus}
        checkboxText={checkboxText}
        typeHiring={typeHiring}
        firstName={firstN}
        lastName={lasTN}
        uID={uID}
        noCustomization={customize}
        HiringType={typeMail}
        selectedCandidates={selectedRows}
        setSelectedRows={setSelectedRows}
        setSelectAll={setSelectAll}
      />
      <GeneralModal
        generalModal={generalModal}
        setGeneralModal={setGeneralModal}
        heading={heading}
        description={description}
        generalCase={true}
        noButtons={noButtons}
        setNoButtons={setNoButtons}
        buttonText="Upgrade"
        loadingBtn={false}
        loadingText="Upgrading"
      />
      <React.Fragment>
        <Premium premiumOpen={premiumOpen} setPremiumOpen={setPremiumOpen} />
        <InviteCandidatesModal
          inviteModal={inviteModal}
          setInviteModal={setInviteModal}
          modulesData={modulesData}
          candidatesAllowed={data?.data?.length}
          assessmentCode={assessmentCode}
          setCopyCount={setCopyCount}
          copyCount={copyCount}
        />
        <div className="mt-20">
          <div className="bg-bodyColor">
            <div className="lg:container bg-bodyColor mx-auto lg:px-0 sm:px-4">
              <div className="pt-10 mt-10 w-full">
                <div className="py-6 bg-[#DBD9C0] rounded-lg">
                  <div className="flex sm:flex-col md:flex-row lg:flex-row justify-between sm:px-2 lg:px-5 sm:gap-3 md:gap-0">
                    {/* Left Section */}
                    <div className="flex flex-row gap-4 md:gap-8">
                      <IoChevronBackCircleOutline
                        className="md:w-5 md:h-5 my-auto sm:w-4 sm:h-4 cursor-pointer"
                        onClick={() => navigate(`/dashboard`)}
                      />
                      <p
                        className="sm:text-xs md:text-base"
                        style={{ fontFamily: "Silka Bold" }}
                      >
                        {modulesLoading ? (
                          <Skeleton width={120} height={20} />
                        ) : (
                          assessmentData?.name || ""
                        )}
                      </p>
                      <p
                        className="sm:text-xs md:text-base"
                        style={{ fontFamily: "Silka" }}
                      >
                        {modulesLoading ? (
                          <Skeleton width={150} height={20} />
                        ) : (
                          <>
                            {modulesData[0]?.section?.length}{" "}
                            {modulesData[0]?.section?.length === 1
                              ? "Module"
                              : "Modules"}{" "}
                            | {timeCalculation} mins
                          </>
                        )}
                      </p>
                    </div>

                    {/* Right Section */}
                    {user_role !== "hiring-manager" && (
                      <div className="flex flex-row gap-4 md:gap-8 my-auto">
                        {/* Menu */}
                        <div>
                          <PiDotsThreeCircle
                            className="md:w-5 md:h-5 sm:w-4 sm:h-4 my-auto lg:mt-0 sm:mt-0.5 cursor-pointer"
                            id="demo-customized-button"
                            aria-controls={
                              open2 ? "demo-customized-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={open2 ? "true" : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick_menu}
                          />
                          <StyledMenu
                            id="demo-customized-menu"
                            MenuListProps={{
                              "aria-labelledby": "demo-customized-button",
                            }}
                            anchorEl={anchorEl2}
                            open={open2}
                            onClose={handleClose_menu}
                          >
                            <MenuItem
                              onClick={() => {
                                handleClose_menu();
                                dispatch(setPreviewToFalse(false));
                                navigate(`/preview/${id}`);
                              }}
                              disableRipple
                            >
                              <IoMdEye className="mr-2 w-4 h-4" />
                              Preview Sample questions
                            </MenuItem>
                            {checkEditTest() && (
                              <MenuItem
                                onClick={() => {
                                  handleClose_menu();
                                  localStorage.setItem(
                                    "current_module",
                                    "general"
                                  );
                                  localStorage.removeItem("assessment_ID");
                                  navigate(`/create-test?assessment_id=${id}`);
                                }}
                                disabled={assessmentData?.status === "archived"}
                                disableRipple
                              >
                                <FaEdit className="mr-2 w-4 h-4 my-auto" />
                                Edit test
                              </MenuItem>
                            )}
                            <MenuItem
                              onClick={() => {
                                if (!dataAccess?.isAllowed) {
                                  setHeading("You've reached your limit!");
                                  setdescription(
                                    user_package_check === "free"
                                      ? "Explore For Free subscription allows 1 Active Test, either change the status of your Active Test to 'Archived' or upgrade your subscription package"
                                      : "Starter subscription allows 5 Active Tests, either change the status of an Active Test to 'Archived' or upgrade your subscription package"
                                  );
                                  handleClose_menu();
                                  setGeneralModal(true);
                                } else {
                                  cloneAssessment();
                                }
                              }}
                              disableRipple
                            >
                              {!cloneLoading && (
                                <GrClone className="mr-2 w-4 h-4 icon-image" />
                              )}
                              {cloneLoading ? (
                                <span className="flex items-center justify-center">
                                  <Loader
                                    type="Oval"
                                    color="#103045"
                                    height={20}
                                    width={20}
                                  />
                                  <span className="ml-2">Cloning...</span>
                                </span>
                              ) : (
                                "Clone test"
                              )}
                            </MenuItem>
                            {(assessmentData?.status === "archived" ||
                              assessmentData?.status === "deactivated") && (
                              <MenuItem
                                onClick={() => {
                                  if (!dataAccess?.isAllowed) {
                                    setHeading("You've reached your limit!");
                                    setdescription(
                                      user_package_check === "free"
                                        ? "Explore For Free subscription allows 1 Active Test, either change the status of your Active Test to 'Archived' or upgrade your subscription package"
                                        : "Starter subscription allows 5 Active Tests, either change the status of an Active Test to 'Archived' or upgrade your subscription package"
                                    );
                                    handleClose_menu();
                                    setGeneralModal(true);
                                  } else {
                                    handleClose_menu();
                                    handleArchiveActive();
                                  }
                                }}
                                disableRipple
                              >
                                <MdOutlinePublishedWithChanges className="mr-2 w-4 h-4 my-auto" />
                                Activate
                              </MenuItem>
                            )}
                            {assessmentData?.status === "active" && (
                              <MenuItem
                                onClick={() => {
                                  handleClose_menu();
                                  handleArchiveClick();
                                }}
                                disableRipple
                              >
                                <LuArchive className="mr-1.5 w-5 h-5 my-auto" />
                                Archive
                              </MenuItem>
                            )}
                            <MenuItem
                              onClick={() => {
                                handleClose_menu();
                                setDeleteModal(true);
                              }}
                              disableRipple
                            >
                              <MdOutlineAutoDelete className="mr-1.5 w-5 h-5 my-auto" />
                              Delete
                            </MenuItem>
                          </StyledMenu>
                        </div>

                        {/* Download Results */}
                        <div className="flex flex-col gap-3 my-auto relative">
                          <p
                            style={{ fontFamily: "Silka" }}
                            className="underline cursor-pointer"
                            id="labelID"
                            onClick={() => {
                              if (data?.data?.length === 0) {
                                toast.error("No candidates found");
                                return;
                              } else {
                                setDownloadDropdown(!downloadDropdown);
                              }
                            }}
                          >
                            {modulesLoading ? (
                              <Skeleton width={120} height={20} />
                            ) : (
                              "Download results"
                            )}
                          </p>
                          {downloadDropdown && (
                            <div
                              id="education-drop"
                              className={`downloadClass absolute z-20 border w-[15rem] ${
                                isMobile ? "left-0" : "right-0"
                              } border-coalColor top-5 h-auto overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] mt-2`}
                            >
                              <ul
                                id="downloadID"
                                ref={downloadRef}
                                className="text-sm text-coalColor hover:bg-coalColor hover:text-white cursor-pointer"
                                style={{ fontFamily: "Silka" }}
                              >
                                <li>
                                  <a
                                    href={
                                      process.env.REACT_APP_Server +
                                      `/assessment/candidates/${id}/export?format=pdf&order=${order}&page=${currentPage}&take=${selectedEntries}&search=${cadidateinput}&status=${status}&hiringStage=${hiring}&sortBy=${soryBy}`
                                    }
                                    className="block px-5 py-2 text-sm font-medium"
                                    onClick={() => setDownloadDropdown(false)}
                                  >
                                    Download PDF
                                  </a>
                                </li>
                              </ul>
                              <ul
                                id="downloadID"
                                ref={downloadRef}
                                className="text-sm text-coalColor hover:bg-coalColor hover:text-white cursor-pointer"
                                style={{ fontFamily: "Silka" }}
                              >
                                <li>
                                  <a
                                    href={
                                      process.env.REACT_APP_Server +
                                      `/assessment/candidates/${id}/export?format=csv&order=${order}&page=${currentPage}&take=${selectedEntries}&search=${cadidateinput}&status=${status}&hiringStage=${hiring}&sortBy=${soryBy}`
                                    }
                                    className="block px-5 py-2 text-sm font-medium"
                                    onClick={() => setDownloadDropdown(false)}
                                  >
                                    Download CSV
                                  </a>
                                </li>
                              </ul>
                              <ul
                                id="downloadID"
                                ref={downloadRef}
                                className="text-sm text-coalColor hover:bg-coalColor hover:text-white cursor-pointer"
                                style={{ fontFamily: "Silka" }}
                              >
                                <li>
                                  <a
                                    className="block px-5 py-2 text-sm font-medium"
                                    onClick={() => setDownloadDropdown(false)}
                                    href={
                                      process.env.REACT_APP_Server +
                                      `/assessment/candidates/${id}/export?format=xlsx&order=${order}&page=${currentPage}&take=${selectedEntries}&search=${cadidateinput}&status=${status}&hiringStage=${hiring}&sortBy=${soryBy}`
                                    }
                                  >
                                    Download XLSX
                                  </a>
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>

                        {/* Invite Button */}
                        <p
                          style={{ fontFamily: "Silka" }}
                          className="underline sm:text-xs md:text-base cursor-pointer"
                          onClick={() => {
                            if (assessmentData?.status !== "active") {
                              setHeading("Invitation Restricted");
                              setdescription(
                                "You cannot invite users to deactivated, draft, or archived tests. Invitations are only allowed for active tests. Please ensure the test is active before proceeding."
                              );
                              setNoButtons("yes");
                              setGeneralModal(true);
                            } else {
                              setInviteModal(true);
                            }
                          }}
                        >
                          {isLoading ? (
                            <Skeleton width={80} height={20} />
                          ) : (
                            "Invite"
                          )}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-bodyColor">
          <div className="bg-bodyColor lg:container mx-auto">
            <div className="py-8 pt-10 rounded-lg lg:px-0 sm:px-4">
              {user_role !== "recruiter" && user_role !== "hiring-manager" && (
                <div className="bg-white p-5 rounded-lg">
                  <div className="flex justify-between sm:gap-3 lg:gap-0 sm:flex-col lg:flex-row w-full">
                    <div
                      className="flex flex-row my-auto text-sm"
                      style={{ fontFamily: "Silka Light" }}
                    >
                      Try the test yourself or invite your team members to trial
                      it.
                      <div className="relative">
                        <img
                          src={help}
                          className="w-5 h-5 my-auto ml-4 cursor-pointer"
                        />
                        <div
                          className={`
                            tooltip left-0 w-[20rem]
                         z-40 text-center absolute top-full ml-2 opacity-0 pointer-events-none`}
                          style={{ fontFamily: "Silka" }}
                        >
                          Use this feature to see and experience the test like
                          your candidates do!
                        </div>
                      </div>
                    </div>
                    <div
                      className="flex flex-row gap-2 h-14"
                      style={{ fontFamily: "Silka" }}
                    >
                      <CustomButton
                        bgColor="#EDEDED"
                        textSize="sm:text-xs lg:text-[15px]"
                        hoverBgColor="#252E3A"
                        hoverTextColor="#FFFFFF"
                        widthButton="w-[8rem]"
                        label="Try yourself"
                        onClickButton={HandleSendInvite}
                        loadingText="Inviting"
                        LoadingBtn={mutateInviteLoading}
                        loadingColor="black"
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="flex sm:flex-col scrollTable lg:flex-row md:justify-between mt-5">
                <div className="flex flex-row gap-3">
                  <p
                    className="my-auto text-sm pl-2  sm:w-1/4 lg:w-auto"
                    style={{ fontFamily: "Silka Light" }}
                  >
                    Sort by
                  </p>
                  <div className="flex flex-col gap-3 sm:w-full lg:w-auto my-auto relative">
                    <button
                      id="sort"
                      className="text-coalColor border bg-white border-[#D3D5D8] focus:border-coalColor p-2.5 sm:w-full lg:w-[12rem] xl:w-[12rem] 2xl:w-[16rem] py-3.5 font-medium rounded-md text-sm text-left inline-flex items-center"
                      type="button"
                      onClick={() => setSortDropdown(!sortDropdown)}
                      style={{
                        fontFamily: "Silka",
                        fontSize: isMobile && "16px",
                      }}
                    >
                      {sortvalue != "" ? (
                        <span className="text-coalColor">{sortvalue}</span>
                      ) : (
                        <span className="text-[#7C8289]">Average score</span>
                      )}
                      <svg
                        className="w-2.5 h-2.5 ml-auto"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 1 4 4 4-4"
                        />
                      </svg>
                    </button>
                    {sortDropdown && (
                      <div
                        id="education-drop"
                        className="sortClass absolute z-20 border border-coalColor right-0 top-full overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                      >
                        {sortValues.map((i) => (
                          <ul
                            key={i.title}
                            className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                            ref={sortRef}
                            onClick={() => {
                              setSortvalue(i?.title);
                              setOrder(
                                i?.value === "h2l"
                                  ? "DESC"
                                  : i?.value === "dateInv"
                                  ? "DESC"
                                  : i?.value === "dateComp"
                                  ? "DESC"
                                  : "ASC"
                              );
                              setSortDropdown(false);
                              setSortBy(
                                i?.value === "dateInv"
                                  ? "invited-on"
                                  : i?.value === "dateComp"
                                  ? "completed-on"
                                  : "score"
                              );
                            }}
                            style={{ fontFamily: "Silka" }}
                          >
                            <li>
                              <p className="block px-5 py-2 text-sm font-medium">
                                {i.title}
                              </p>
                            </li>
                          </ul>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex sm:flex-col md:flex-row sm:mt-5 lg:mt-0  gap-4">
                  <div className="flex flex-col gap-3 sm:w-full lg:w-auto my-auto relative">
                    <button
                      id="type"
                      className="text-[#7C8289] border bg-white border-[#D3D5D8] focus:border-coalColor p-2.5 sm:w-full lg:w-[12rem] xl:w-[12rem] 2xl:w-[16rem] py-3.5 font-medium rounded-md text-sm text-left inline-flex items-center"
                      type="button"
                      onClick={() => {
                        if (selectedRows?.length === 0) {
                          toast.error(
                            "Please select candidates before proceeding",
                            {
                              toastId: "copy-success",
                            }
                          );
                        } else {
                          setTypeDropdown(!typeDropdown);
                        }
                      }}
                      style={{
                        fontFamily: "Silka",
                        fontSize: isMobile && "16px",
                      }}
                    >
                      Bulk action
                      <svg
                        className="w-2.5 h-2.5 ml-auto"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 1 4 4 4-4"
                        />
                      </svg>
                    </button>
                    {typeDropdown && (
                      <div
                        id="education-drop"
                        className="typeClass absolute z-20 border border-coalColor right-0 top-full overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                      >
                        {type_mail.map((i) => (
                          <ul
                            key={i.title}
                            className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                            ref={typeRef}
                            style={{ fontFamily: "Silka" }}
                            onClick={() => {
                              setTypeDropdown(false);
                              setTypeMail(i?.title);
                              if (i?.title === "Accept") {
                                setCustomize(true);
                                sethiringStatus("Send offer to candidate");
                                sethiringLabel(
                                  "Are you sending offers to the candidates?"
                                );
                                setcheckboxText(
                                  "Would you like to send offer emails to the candidates?"
                                );
                                setHiringOpen(true);
                              } else {
                                setCustomize(true);
                                sethiringStatus("Reject candidates");
                                sethiringLabel("Are you rejecting candidates?");
                                setcheckboxText(
                                  "Would you like to send rejection email to candidates?"
                                );
                                setHiringOpen(true);
                              }
                            }}
                          >
                            <li className="flex flex-row">
                              <p className="block px-5 py-2 text-sm font-medium">
                                {i.value}
                              </p>
                              <AiOutlineSend className="w-4 h-4 ml-auto mr-2 my-auto" />
                            </li>
                          </ul>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="relative flex items-center">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <img
                        src={Search}
                        className="w-5 h-5 my-auto"
                        alt="Search Icon"
                      />
                    </div>
                    <input
                      type="text"
                      className="border focus:ring-0 focus:ring-offset-0 border-[#D3D5D8] focus:border-coalColor text-coalColor text-sm rounded-md block lg:w-auto sm:w-full pl-10 p-2.5 py-3.5 px-10 placeholder-[#7C8289]"
                      value={candidatesearch}
                      onChange={(e) => setCandidate(e.target.value)}
                      placeholder="Search candidates"
                      required
                      onKeyDown={(e) => handleSearchByEnter(e)}
                      style={{
                        fontFamily: "Silka",
                        fontSize: isMobile && "16px",
                        "::placeholder": { color: "#7C8289" },
                      }}
                    />
                  </div>
                  <div className="flex flex-col gap-3 my-auto relative">
                    <button
                      id="field"
                      className="text-coalColor border bg-white border-[#D3D5D8] lg:w-[12rem] xl:w-[12rem] 2xl:w-[16rem]  sm:w-full focus:border-coalColor p-2.5 py-3.5 font-medium rounded-md text-sm text-left inline-flex items-center"
                      type="button"
                      onClick={() => setStatusDrop(!statusDrop)}
                      style={{
                        fontFamily: "Silka",
                        fontSize: isMobile && "16px",
                      }}
                    >
                      {statusV != "" ? (
                        <span className="text-coalColor">{statusV}</span>
                      ) : (
                        <span className="text-[#7C8289]">Test Status</span>
                      )}
                      <svg
                        className="w-2.5 h-2.5 ml-auto"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 1 4 4 4-4"
                        />
                      </svg>
                    </button>
                    {statusDrop && (
                      <div
                        id="education-drop"
                        className="fieldclass absolute z-20 border border-coalColor right-0 top-full h-[9rem] overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                      >
                        {statuses.map((i) => (
                          <ul
                            key={i.value}
                            className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                            ref={fieldRef}
                            onClick={() => {
                              setStatus(i?.value);
                              setStatusDrop(false);
                              setStatusV(i?.title);
                            }}
                            style={{ fontFamily: "Silka" }}
                          >
                            <li>
                              <p className="block px-5 py-2 text-sm font-medium">
                                {i.title}
                              </p>
                            </li>
                          </ul>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col gap-3 relative">
                    <button
                      id="hiring"
                      className="text-coalColor border bg-white border-[#D3D5D8] focus:border-coalColor p-2.5 lg:w-[12rem] xl:w-[12rem] 2xl:w-[16rem] sm:w-full py-3.5 font-medium rounded-md text-sm text-left inline-flex items-center"
                      type="button"
                      onClick={() => setHiringDrop(!hiringDrop)}
                      style={{
                        fontFamily: "Silka",
                        fontSize: isMobile && "16px",
                      }}
                    >
                      {hiring != "" ? (
                        <span className="text-coalColor">
                          {hiring === "INTERVIEWED"
                            ? "Interview Completed"
                            : hiring === "REJECTED"
                            ? "Candidate Rejected"
                            : hiring
                                .toLowerCase()
                                .split(" ")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(" ")}
                        </span>
                      ) : (
                        <span className="text-[#7C8289]">Hiring stage</span>
                      )}
                      <svg
                        className="w-2.5 h-2.5 ml-auto"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 1 4 4 4-4"
                        />
                      </svg>
                    </button>
                    {hiringDrop && (
                      <div
                        id="education-drop"
                        className="hiringClass enable-scrollbar2 overflow-scroll absolute z-20 border border-coalColor right-0 top-full h-[18rem] bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                      >
                        <Scrollbars
                          autoHide
                          style={{ width: "100%", height: "100%" }}
                        >
                          {hiringData?.hiring_stage.map((i, index) => (
                            <ul
                              key={i}
                              className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                              ref={hiringRef}
                              onClick={() => {
                                i !== "ALL" ? setHiring(i) : setHiring("");
                                setHiringDrop(false);
                              }}
                              style={{ fontFamily: "Silka" }}
                            >
                              <li className="flex flex-row">
                                <p className="block px-5 py-2 text-sm font-medium">
                                  {i === "INTERVIEWED"
                                    ? "Interview Completed"
                                    : i === "REJECTED"
                                    ? "Candidate Rejected"
                                    : i
                                        .toLowerCase()
                                        .split(" ")
                                        .map(
                                          (word) =>
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1)
                                        )
                                        .join(" ")}
                                </p>
                              </li>
                            </ul>
                          ))}
                        </Scrollbars>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="bg-white py-4 px-6 rounded-lg mt-10 overflow-x-hidden">
                <div className="">
                  <table class="w-full lg:table-fixed mt-10 text-left responsive-table overflow-y-hidden">
                    <thead>
                      <tr>
                        <th colSpan="2" style={{ width: "15%" }}>
                          <div className="flex flex-row">
                            <div className="flex flex-col gap-3 my-auto relative">
                              <PiDotsThreeCircleVerticalFill
                                className="mr-2 w-6 h-6 align-middle cursor-pointer my-auto accent-primaryGreen"
                                id="threedots"
                                onClick={() => {
                                  setSelectDropdown(!selectDropdown);
                                }}
                              />

                              {selectDropdown && (
                                <div
                                  id="education-drop"
                                  className={`selectClass absolute z-20 border w-[15rem] ${
                                    isMobile ? "left-0" : "left-0"
                                  } border-coalColor top-10 h-auto overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] mt-2`}
                                >
                                  <ul
                                    id="selectID"
                                    ref={selectRef}
                                    className="text-sm text-coalColor hover:bg-coalColor hover:text-white cursor-pointer"
                                    style={{ fontFamily: "Silka" }}
                                  >
                                    <li>
                                      <label className="inline-block px-2 py-2">
                                        <input
                                          type="checkbox"
                                          checked={selectAll}
                                          onChange={(e) => {
                                            e.stopPropagation();
                                            setAllselect(!allselect);
                                            handleSelectAll();
                                            setSelectDropdown(false);
                                          }}
                                          className="mr-2 w-4 h-4 align-middle my-auto accent-primaryGreen"
                                        />
                                        <span
                                          className="align-middle font-normal my-auto"
                                          style={{ fontFamily: "Silka" }}
                                        >
                                          Select All
                                        </span>
                                      </label>
                                    </li>
                                  </ul>
                                  <ul
                                    id="selectID"
                                    ref={selectRef}
                                    className="text-sm text-coalColor hover:bg-coalColor hover:text-white cursor-pointer"
                                    style={{ fontFamily: "Silka" }}
                                  >
                                    <li>
                                      <label className="inline-block px-2 py-2">
                                        <input
                                          type="checkbox"
                                          checked={multiselect === true}
                                          onChange={(e) => {
                                            e.stopPropagation();
                                            setMultiselect(!multiselect);
                                            setSelectDropdown(false);
                                          }}
                                          className="mr-2 w-4 h-4 my-auto align-middle accent-primaryGreen"
                                        />
                                        <span
                                          className="align-middle font-normal my-auto"
                                          style={{ fontFamily: "Silka" }}
                                        >
                                          Select Multiple
                                        </span>
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </div>
                            {/* <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                              className="mr-2 w-4 h-4 align-middle accent-primaryGreen"
                            /> */}
                            <span className="align-middle text-base">
                              Candidate name
                            </span>
                          </div>
                        </th>
                        <th colSpan="8" className="lg:hidden w-full">
                          {modulesData?.map((module, index) => (
                            <React.Fragment key={index}>
                              <div
                                className={`grid gap-4 text-left grid-cols-${
                                  module?.section?.length + 1
                                } w-[40rem]`}
                              >
                                {module?.section?.map((section, secIndex) => (
                                  <React.Fragment key={secIndex}>
                                    <div className="flex flex-col gap-3 text-sm truncate">
                                      <div className="hidden sm:block truncate">
                                        {section?.name}
                                      </div>
                                      <CustomButton
                                        label="Graph view"
                                        borderCustom="border border-coalColor hover:bg-coalColor hover:text-white"
                                        textSize="text-sm"
                                        paddingx="px-0"
                                        paddingY={0.5}
                                        onClickButton={() => {
                                          if (
                                            candidatesData?.data?.filter(
                                              (i) => i?.status === "completed"
                                            )?.length >= 2
                                          ) {
                                            setHeading("Average score");
                                            setModuleName("");
                                            setScoreGraph(true);
                                          } else {
                                            toast.error(
                                              "Graphs will be available to see once 2 or more candidates have completed the test",
                                              {
                                                toastId: "copy-success",

                                                style: { width: "400px" },
                                              }
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  </React.Fragment>
                                ))}
                              </div>
                            </React.Fragment>
                          ))}
                        </th>

                        <th colSpan="8" className="sm:max-md:hidden">
                          {modulesData?.map((module, index) => (
                            <React.Fragment key={index}>
                              <div
                                className={`grid text-left 
                                    ${
                                      module?.section?.length === 1 &&
                                      "grid-cols-4"
                                    }
                                ${
                                  module?.section?.length === 4 && "grid-cols-5"
                                }
                                 ${
                                   module?.section?.length === 6 &&
                                   "grid-cols-7"
                                 }
                                ${
                                  module?.section?.length === 5
                                    ? "grid-cols-6"
                                    : `grid-cols-${module?.section?.length + 1}`
                                }`}
                              >
                                <div className="text-base">Average score</div>
                                {module?.section?.map((section, secIndex) => (
                                  <React.Fragment key={secIndex}>
                                    <div className="flex flex-row w-full gap-3 pr-2 justify-left text-base">
                                      Module {secIndex + 1}
                                    </div>
                                  </React.Fragment>
                                ))}
                              </div>
                            </React.Fragment>
                          ))}
                        </th>
                        <th colSpan="1" className="text-center text-base">
                          Status
                        </th>
                        <th colSpan="2" className="text-base">
                          Hiring stage
                        </th>
                        <th colSpan="1"></th>
                      </tr>
                      <tr>
                        <th colSpan="2" style={{ width: "15%" }}></th>
                        <th colSpan="8" className="sm:max-md:hidden">
                          {modulesData?.map((module, index) => (
                            <React.Fragment key={index}>
                              <div
                                className={`grid text-left 
                                    ${
                                      module?.section?.length === 1 &&
                                      "grid-cols-4"
                                    }
                                ${
                                  module?.section?.length === 4 && "grid-cols-5"
                                }
                                 ${
                                   module?.section?.length === 6 &&
                                   "grid-cols-7"
                                 }
                                ${
                                  module?.section?.length === 5
                                    ? "grid-cols-6"
                                    : `grid-cols-${module?.section?.length + 1}`
                                }`}
                              >
                                <div></div>
                                {module?.section?.map((section, secIndex) => (
                                  <React.Fragment key={secIndex}>
                                    <div
                                      className="flex flex-row w-full gap-3 pr-2 justify-left text-sm"
                                      style={{ fontFamily: "Silka Light" }}
                                    >
                                      {section?.name}
                                    </div>
                                  </React.Fragment>
                                ))}
                              </div>
                            </React.Fragment>
                          ))}
                        </th>
                        <th colSpan="1"></th>
                        <th colSpan="2"></th>
                        <th colSpan="1"></th>
                      </tr>
                      <tr>
                        <th colSpan="2" style={{ width: "15%" }}></th>
                        <th colSpan="8" className="sm:max-md:hidden">
                          {modulesData?.map((module, index) => (
                            <React.Fragment key={index}>
                              <div
                                className={`grid text-left  py-2 
                                    ${
                                      module?.section?.length === 1 &&
                                      "grid-cols-4"
                                    }
                                  ${
                                    module?.section?.length === 4 &&
                                    "grid-cols-5"
                                  }
                                   ${
                                     module?.section?.length === 6 &&
                                     "grid-cols-7"
                                   }
                                  ${
                                    module?.section?.length === 5
                                      ? "grid-cols-6"
                                      : `grid-cols-${
                                          module?.section?.length + 1
                                        }`
                                  }`}
                              >
                                <div
                                  style={{ fontFamily: "Silka" }}
                                  className="pr-2"
                                >
                                  <CustomButton
                                    label="Graph view"
                                    borderCustom="border border-coalColor  hover:bg-coalColor hover:text-white"
                                    textSize="text-sm"
                                    widthButton={
                                      module?.section?.length === 0
                                        ? "w-1/5"
                                        : module?.section?.length === 1
                                        ? "w-5/6"
                                        : module?.section?.length === 2
                                        ? "w-3/6"
                                        : module?.section?.length === 3
                                        ? "w-4/6"
                                        : module?.section?.length === 4
                                        ? "w-11/12"
                                        : "w-full"
                                    }
                                    paddingx="px-0"
                                    paddingY={0.5}
                                    onClickButton={() => {
                                      if (
                                        candidatesData?.data?.filter(
                                          (i) => i?.status === "completed"
                                        )?.length >= 2
                                      ) {
                                        setHeading("Average score");
                                        setModuleName("");
                                        setScoreGraph(true);
                                      } else {
                                        toast.error(
                                          "Graphs will be available to see once 2 or more candidates have completed the test",
                                          {
                                            toastId: "copy-success",

                                            style: { width: "400px" },
                                          }
                                        );
                                      }
                                    }}
                                  />
                                </div>
                                {module.section.map((section, secIndex) => (
                                  <React.Fragment key={secIndex}>
                                    <div
                                      className="flex flex-row w-full gap-3 pr-2 text-sm justify-left"
                                      style={{ fontFamily: "Silka Light" }}
                                    >
                                      <CustomButton
                                        label="Graph view"
                                        borderCustom="border border-coalColor hover:bg-coalColor hover:text-white"
                                        textSize="text-sm"
                                        widthButton={
                                          module?.section?.length === 1
                                            ? "w-5/6"
                                            : module?.section?.length === 2
                                            ? "w-3/6"
                                            : module?.section?.length === 3
                                            ? "w-4/6"
                                            : module?.section?.length === 4
                                            ? "w-11/12"
                                            : "w-full"
                                        }
                                        paddingx="px-0"
                                        paddingY={0.5}
                                        onClickButton={() => {
                                          if (
                                            data?.data?.filter(
                                              (i) => i?.status === "completed"
                                            )?.length >= 2
                                          ) {
                                            setHeading(
                                              `Module ${secIndex + 1}`
                                            );
                                            setModuleName(section?.name);
                                            setSectionIndex(secIndex);
                                            setScoreGraph(true);
                                          } else {
                                            toast.error(
                                              "Graphs will be available to see once 2 or more candidates have completed the test",
                                              {
                                                toastId: "copy-success",
                                                style: { width: "400px" },
                                              }
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  </React.Fragment>
                                ))}
                              </div>
                            </React.Fragment>
                          ))}
                        </th>
                        <th colSpan="1"></th>
                        <th colSpan="2"></th>
                        <th colSpan="1"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.data?.map((i, index) => (
                        <tr
                          className="bg-white odd:bg-[#F6F7F7] text-coalColor cursor-pointer"
                          key={index}
                          onClick={(e) => {
                            const isActionsColumn =
                              e.target.closest(".clickable");
                            if (isActionsColumn) {
                              dispatch(setCandidateToFalse(false));
                              navigate(
                                `/details/${id}?user_ID=${i?.candidates?.id}`
                              );
                            }
                          }}
                          style={{ fontFamily: "Silka" }}
                        >
                          <td colSpan="2" className="relative">
                            <div>
                              <div className="pl-6">
                                {(multiselect || allselect) && (
                                  <input
                                    type="checkbox"
                                    className="absolute left-0 top-1/2 transform -translate-y-1/2 w-4 h-4 accent-primaryGreen"
                                    checked={selectedRows.includes(
                                      i?.candidates?.id
                                    )}
                                    onChange={() => {
                                      if (i.status === "completed") {
                                        if (i?.isFinalizedEmailSent) {
                                          switch (i?.hiringStage) {
                                            case "HIRED":
                                              toast.error(
                                                "You cannot change the hiring stage of a candidate who has been sent hiring email.",
                                                {
                                                  toastId: "copy-success",
                                                }
                                              );
                                              break;
                                            case "REJECTED":
                                              toast.error(
                                                "You cannot change the hiring stage of a candidate who has been sent rejection email.",
                                                {
                                                  toastId: "copy-success",
                                                }
                                              );
                                              break;
                                            default:
                                              handleRowSelect(
                                                i?.candidates?.id
                                              );
                                          }
                                        } else {
                                          // Allow row selection if isFinalizedEmailSent is false
                                          handleRowSelect(i?.candidates?.id);
                                        }
                                      } else {
                                        switch (i.status) {
                                          case "InProgress":
                                            toast.error(
                                              "The candidate's test is still in progress.",
                                              {
                                                toastId: "copy-success",
                                              }
                                            );
                                            break;
                                          case "started":
                                            toast.error(
                                              "The candidate's test is still in progress.",
                                              {
                                                toastId: "copy-success",
                                              }
                                            );
                                            break;
                                          case "invited":
                                            toast.error(
                                              "The candidate has been invited but has not completed the test yet.",
                                              {
                                                toastId: "copy-success",
                                              }
                                            );
                                          case "draft":
                                            toast.error(
                                              "The candidate has been invited but has not completed the test yet.",
                                              {
                                                toastId: "copy-success",
                                              }
                                            );
                                            break;
                                          default:
                                            // Handle other statuses if needed
                                            break;
                                        }
                                      }
                                    }}
                                  />
                                )}

                                <p className="text-coalColor">
                                  {i?.candidates?.firstName}{" "}
                                  {i?.candidates?.lastName}{" "}
                                </p>
                                {i?.candidates?.roles === "recruiter" && (
                                  <span className="text-orange-500 text-xs ml-1">
                                    (recruiter)
                                  </span>
                                )}
                              </div>
                            </div>
                          </td>

                          <td colSpan="8" className="clickable">
                            {i?.sections && (
                              <div
                                className={`grid text-left gap-3 py-2 
                               ${i.sections?.length === 1 && "grid-cols-4"}
                             ${i.sections?.length === 4 && "grid-cols-5"}
                              ${i.sections?.length === 6 && "grid-cols-7"}
                             ${
                               i.sections?.length === 5
                                 ? "grid-cols-6"
                                 : `grid-cols-${i.sections?.length + 1}`
                             }`}
                              >
                                <div>
                                  {i?.score != null
                                    ? `${Number(i?.score).toFixed(0)}%`
                                    : "N/A"}
                                </div>
                                {i.sections.map((j, index) => (
                                  <div key={index}>
                                    {j.score !== null && j.score !== undefined
                                      ? `${Number(j.score).toFixed(0)}%`
                                      : "N/A"}
                                  </div>
                                ))}
                              </div>
                            )}
                          </td>
                          <td colSpan="1" className="clickable">
                            <div className="flex flex-row justify-center">
                              <p
                                className={`h-5 w-5 py-2 rounded-full 
                                        ${
                                          i.status === "completed" &&
                                          "bg-[#C0FF06]"
                                        } 
                                        ${
                                          i.status === "started" &&
                                          "bg-yellow-700"
                                        }
                                        ${
                                          i.status === "InProgress" &&
                                          "bg-yellow-700"
                                        }
                                        ${
                                          i.status === "invited" &&
                                          "bg-[#FFB500]"
                                        }
                                            ${
                                              i.status === "draft" &&
                                              "bg-[#FFB500]"
                                            }
                                        `}
                              ></p>
                            </div>
                          </td>
                          <td colSpan="2">
                            <div
                              className={`text-left font-medium text-coalColor py-3 rounded-lg w-auto`}
                              onClick={() => {
                                if (i.status === "completed") {
                                  if (i?.isFinalizedEmailSent) {
                                    switch (i?.hiringStage) {
                                      case "HIRED":
                                        toast.error(
                                          "You cannot change the hiring stage of a candidate who has been sent hiring email.",
                                          {
                                            toastId: "copy-success",
                                          }
                                        );
                                        break;
                                      case "REJECTED":
                                        toast.error(
                                          "You cannot change the hiring stage of a candidate who has been sent rejection email.",
                                          {
                                            toastId: "copy-success",
                                          }
                                        );
                                        break;
                                      default:
                                        setHiringDropInList({
                                          show: !hiringDropInList.show,
                                          usersId: i?.usersId,
                                        });
                                    }
                                  } else {
                                    // Allow dropdown to open if isFinalizedEmailSent is false
                                    setHiringDropInList({
                                      show: !hiringDropInList.show,
                                      usersId: i?.usersId,
                                    });
                                  }
                                } else {
                                  switch (i.status) {
                                    case "InProgress":
                                      toast.error(
                                        "The candidate's test is still in progress.",
                                        {
                                          toastId: "copy-success",
                                        }
                                      );
                                      break;
                                    case "started":
                                      toast.error(
                                        "The candidate's test is still in progress.",
                                        {
                                          toastId: "copy-success",
                                        }
                                      );
                                      break;
                                    case "invited":
                                      toast.error(
                                        "The candidate has been invited but has not completed the test yet.",
                                        {
                                          toastId: "copy-success",
                                        }
                                      );
                                    case "draft":
                                      toast.error(
                                        "The candidate has been invited but has not completed the test yet.",
                                        {
                                          toastId: "copy-success",
                                        }
                                      );
                                      break;
                                    default:
                                      break;
                                  }
                                }
                              }}
                            >
                              <div className="flex flex-col gap-3 align-left ">
                                <button
                                  id="hiring"
                                  className={`text-coalColor focus:outline-[#4A9CB9] font-medium rounded-lg text-md text-left flex justify-between items-center pr-4 relative`}
                                  type="button"
                                >
                                  <div className="flex flex-row relative">
                                    <div
                                      className={`absolute w-3 h-3 top-1/2 left-0 transform -translate-y-1/2 rounded-full mr-2
        ${i?.hiringStage === "NOT YET EVALUATED" && "bg-[#FFB500]"} 
        ${i?.hiringStage === "Not Yet Evaluated" && "bg-[#FFB500]"}
        ${i?.hiringStage === "INVITED FOR INTERVIEW" && "bg-primaryGreen"} 
        ${i?.hiringStage === "INTERVIEWED" && "bg-primaryGreen"} 
        ${i?.hiringStage === "REFRENCES CHECKED" && "bg-primaryGreen"}  
        ${i?.hiringStage === "OFFER SENT" && "bg-green-800"} 
        ${i?.hiringStage === "OFFER DECLINED" && "bg-[#FF5812]"} 
        ${i?.hiringStage === "CANDIDATE WITHDREW" && "bg-[#FF5812]"} 
        ${i?.hiringStage === "CANDIDATE UNRESPONSIVE" && "bg-[#FF5812]"} 
        ${i?.hiringStage === "REJECTED" && "bg-[#FF5812]"} 
        ${i?.hiringStage === "HIRED" && "bg-primaryGreen"}  
        ${i?.hiringStage === "PASSED" && "bg-primaryGreen"} 
        ${
          i?.hiringStage === "REMINDER TO RESPOND TO OFFER" && "bg-primaryGreen"
        } 
        ${i?.hiringStage === "POSITION WITHDRAWN" && "bg-[#FF5812]"}`}
                                    ></div>
                                    <span className="pl-4">
                                      {i?.hiringStage === "INTERVIEWED"
                                        ? "Interview Completed"
                                        : i?.hiringStage === "REJECTED"
                                        ? "Candidate Rejected"
                                        : i?.hiringStage
                                            .toLowerCase()
                                            .split(" ")
                                            .map(
                                              (word) =>
                                                word.charAt(0).toUpperCase() +
                                                word.slice(1)
                                            )
                                            .join(" ")}
                                    </span>
                                  </div>
                                  {i?.isFinalizedEmailSent ? (
                                    <IoIosMail className="w-5 h-5 ml-auto my-auto" />
                                  ) : (
                                    <svg
                                      className="w-2.5 h-2.5 ml-2 my-auto"
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 10 6"
                                    >
                                      <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m1 1 4 4 4-4"
                                      />
                                    </svg>
                                  )}
                                </button>

                                {hiringDropInList.show &&
                                  hiringDropInList.usersId == i?.usersId && (
                                    <div
                                      id="education-drop"
                                      className=" absolute hiringClass z-20 overflow-x-hidden border border-coalColor h-[310px] enable-scrollbar2 overflow-y-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-[250px] mt-9 -ml-5"
                                    >
                                      <Scrollbars
                                        autoHide
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          overflowX: "hidden",
                                        }}
                                        renderTrackHorizontal={(props) => (
                                          <div
                                            {...props}
                                            style={{ display: "none" }}
                                            className="track-horizontal"
                                          />
                                        )}
                                        renderView={(props) => (
                                          <div
                                            {...props}
                                            style={{
                                              ...props.style,
                                              overflowX: "hidden",
                                            }}
                                          />
                                        )}
                                      >
                                        {hiringData?.hiring_stage
                                          ?.slice(1)
                                          ?.map((j) => (
                                            <li
                                              className="text-sm text-coalColor flex flex-row rounded px-5 py-2 hover:bg-coalColor hover:text-white cursor-pointer"
                                              style={{ fontFamily: "Silka" }}
                                              ref={hiringRef}
                                              onClick={() => {
                                                j != "ALL"
                                                  ? handlehiringChange({
                                                      usersId: i?.usersId,
                                                      assessmentID: id,
                                                      status: j,
                                                      fname:
                                                        i?.candidates
                                                          ?.firstName,
                                                      lname:
                                                        i?.candidates?.lastName,
                                                    })
                                                  : setHiring("");
                                                setHiringDropInList({
                                                  show: false,
                                                  usersId: null,
                                                });
                                              }}
                                            >
                                              <div
                                                className={`w-3 h-3 my-auto ${
                                                  j === "NOT YET EVALUATED" &&
                                                  "bg-[#FFB500]"
                                                } ${
                                                  j ===
                                                    "INVITED FOR INTERVIEW" &&
                                                  "bg-primaryGreen"
                                                } ${
                                                  j === "INTERVIEWED" &&
                                                  "bg-primaryGreen"
                                                } ${
                                                  j === "REFRENCES CHECKED" &&
                                                  "bg-primaryGreen"
                                                }  ${
                                                  j === "OFFER SENT" &&
                                                  "bg-green-800"
                                                } 
                                          ${
                                            j === "OFFER DECLINED" &&
                                            "bg-[#FF5812]"
                                          } 
                                          ${
                                            j === "CANDIDATE WITHDREW" &&
                                            "bg-[#FF5812]"
                                          } 
                                          ${
                                            j === "CANDIDATE UNRESPONSIVE" &&
                                            "bg-[#FF5812]"
                                          } 
                                             ${
                                               j === "REJECTED" &&
                                               "bg-[#FF5812]"
                                             } 
                                              ${
                                                j === "HIRED" &&
                                                "bg-primaryGreen"
                                              }  ${
                                                  j === "PASSED" &&
                                                  "bg-primaryGreen"
                                                } 
                                           ${
                                             j ===
                                               "REMINDER TO RESPOND TO OFFER" &&
                                             "bg-primaryGreen"
                                           } 
                                    ${
                                      j === "POSITION WITHDRAWN" &&
                                      "bg-[#FF5812]"
                                    } 
                                          rounded-full mr-2`}
                                              ></div>
                                              {j === "INTERVIEWED"
                                                ? "Interview Completed"
                                                : j === "REJECTED"
                                                ? "Candidate Rejected"
                                                : j
                                                    .toLowerCase()
                                                    .split(" ")
                                                    .map(
                                                      (word) =>
                                                        word
                                                          .charAt(0)
                                                          .toUpperCase() +
                                                        word.slice(1)
                                                    )
                                                    .join(" ")}
                                              {j === "INVITED FOR INTERVIEW" ||
                                              j === "OFFER SENT" ||
                                              j === "REJECTED" ||
                                              j ===
                                                "REMINDER TO RESPOND TO OFFER" ||
                                              j === "HIRED" ||
                                              j === "PASSED" ||
                                              j === "POSITION WITHDRAWN" ? (
                                                <IoIosMail className="ml-auto my-auto w-4 h-4" />
                                              ) : null}
                                            </li>
                                          ))}
                                      </Scrollbars>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </td>
                          <td colSpan="1">
                            <div className="flex flex-row gap-3 sm:w-40 md:w-full">
                              <div className="relative my-auto">
                                <img
                                  src={eye}
                                  className="w-6 h-3 my-auto flex"
                                  onClick={() => {
                                    dispatch(setCandidateToFalse(false));
                                    navigate(
                                      `/details/${id}?user_ID=${i?.candidates?.id}`
                                    );
                                  }}
                                />
                                <div className="tooltip w-[12rem] text-center text-sm">
                                  View candidate details
                                </div>
                              </div>
                              {user_role !== "hiring-manager" && (
                                <>
                                  <div className="relative my-auto">
                                    <img
                                      src={bin}
                                      className="w-3 h-4 my-auto flex"
                                      onClick={() => {
                                        setCandidateID(i?.candidates?.id);
                                        setUserModal(true);
                                      }}
                                    />
                                    <div className="tooltip w-[10rem] text-center text-sm">
                                      Delete candidate
                                    </div>
                                  </div>
                                  <div className="relative my-auto">
                                    <LuSendHorizonal
                                      className="w-4 h-4 my-auto flex"
                                      onClick={() => {
                                        if (i?.status !== "completed") {
                                          setCandidateID(i?.candidates?.id);
                                          reminderFunction(i?.candidates?.id);
                                        }
                                      }}
                                    />
                                    <div className="tooltip w-[10rem] z-50 text-center text-sm">
                                      {i?.status !== "completed"
                                        ? "Send reminder"
                                        : "Reminder can't be sent to candidates who completed the test"}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                      {data?.data?.length === 0 && (
                        <tr className="px-6 mt-5 w-full">
                          <td colSpan="14">
                            <div className="border mt-10 mb-10 border-[#FF5812] py-4 rounded">
                              <p
                                className="text-alertRed text-center"
                                style={{ fontFamily: "Silka Bold" }}
                              >
                                No candidates found
                              </p>
                            </div>
                          </td>
                        </tr>
                      )}
                      {isLoading && (
                        <tr>
                          <td colSpan="14" className="text-center">
                            <div className="bg-white">
                              <div class="loader-container-3 col-span-6">
                                <div class="loader-3"></div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="flex md:flex-row sm:flex-col justify-between mt-4 pr-5">
                  <div className="flex flex-row gap-8">
                    <div className="my-auto">
                      <TablePagination
                        totalCount={
                          data?.meta?.pageCount === 0
                            ? 1
                            : data?.meta?.pageCount
                        }
                        onPageChange={handlePageChange}
                      />
                    </div>
                    <div>
                      <EntriesDropdown
                        isLoading={isLoading}
                        setSelectedEntries={setSelectedEntries}
                      />
                    </div>
                  </div>
                  <div
                    className="flex sm:flex-row lg:flex-row gap-5 mt-3 text-xs md:text-sm"
                    style={{ fontFamily: "Silka" }}
                  >
                    <div className="flex flex-row gap-1 md:gap-3">
                      <div className="w-4 h-4 rounded-full bg-[#FFB500] border border-gray-300"></div>
                      Invited
                    </div>
                    <div className="flex flex-row gap-1 md:gap-3">
                      <div className="w-4 h-4 rounded-full bg-yellow-700 border border-gray-300"></div>
                      In Progress
                    </div>
                    <div className="flex flex-row gap-1 md:gap-3">
                      <div className="w-4 h-4 rounded-full bg-[#C0FF06] border border-gray-300"></div>
                      Completed
                    </div>
                  </div>
                </div>
                <div className="px-2">
                  <hr className="w-full sm:mt-2 md:mt-0 bg-[#D3D5D8] border-1" />
                </div>
                <div className="mt-5">
                  <h1 style={{ fontFamily: "Archia Semibold" }}>
                    Selected modules
                  </h1>
                  <div className="mt-5 mb-5">
                    <div className="lg:grid-cols-2 xl:grid-cols-2 pr-4 2xl:grid-cols-3 gap-4 md:grid-cols-2 grid sm:grid-cols-1">
                      {modulesData?.map((j, index) => (
                        <React.Fragment key={index}>
                          {j.section.map((i, ind) => {
                            return (
                              <React.Fragment key={ind}>
                                {i.status === "active" && (
                                  <div className="" key={j}>
                                    <div className="py-2">
                                      <div
                                        className={`bg-[#F6F7F7] px-5 border border-[#D3D5D8] p-3 rounded-lg  overflow-hidden`}
                                      >
                                        <div className="w-full grid grid-cols-10 gap-4 h-[80px]">
                                          <div className="col-span-7 my-auto">
                                            <h1
                                              className="text-lg font-bold good3 my-auto"
                                              style={{
                                                fontFamily: "Archia Semibold",
                                              }}
                                            >
                                              {i.name}
                                            </h1>
                                          </div>
                                          <div
                                            className="my-auto col-span-3 ml-auto"
                                            style={{ fontFamily: "Silka" }}
                                          >
                                            {i?.experience === "Beginner" && (
                                              <div className="inline-flex items-center border border-[#0B5B23] text-black py-1 px-3 rounded-full text-[12px]">
                                                <span className="text-center">
                                                  Beginner
                                                </span>
                                              </div>
                                            )}
                                            {i?.experience ===
                                              "Intermediate" && (
                                              <div className="inline-flex items-center border border-[#FFB500] text-black  w-[90px] md:w-[100px] py-1 rounded-full text-[12px]">
                                                <span className="text-center mx-auto">
                                                  Intermediate
                                                </span>
                                              </div>
                                            )}
                                            {i?.experience === "Advanced" && (
                                              <div className="inline-flex items-center border border-[#FF5812] text-black  w-[90px] md:w-[100px] py-1 rounded-full text-[12px]">
                                                <span className="text-center mx-auto">
                                                  Advanced
                                                </span>
                                              </div>
                                            )}
                                            {i?.type === "custom-questions" && (
                                              <div className="inline-flex items-center border border-[#C0FF06] text-black  w-[90px] md:w-[150px] py-2 rounded-full text-[12px]">
                                                <span className="text-center mx-auto">
                                                  Custom questions
                                                </span>
                                              </div>
                                            )}
                                            {i?.experience === "General" && (
                                              <div className="inline-flex items-center border border-coalColor text-black  xl:w-[72px] 2xl:w-[90px] md:w-[100px] py-1 rounded-full xl:text-[10px] 2xl:text-[12px]">
                                                <span className="text-center mx-auto">
                                                  All levels
                                                </span>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className="flex flex-row gap-2 mt-5">
                                          <img
                                            src={clock}
                                            className="w-4 h-4"
                                          />
                                          <p
                                            className="text-xs text-coalColor"
                                            style={{
                                              fontFamily: "Silka Light",
                                            }}
                                          >
                                            {i?.time} mins
                                          </p>
                                        </div>
                                        <div className="h-[90px] pr-3 text-sm 2xl:mt-3 sm:mt-3">
                                          <div
                                            style={{
                                              fontFamily: "Silka",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              display: "-webkit-box",
                                              WebkitLineClamp: 2,
                                              WebkitBoxOrient: "vertical",
                                            }}
                                          >
                                            <div
                                              className={styles["html-content"]}
                                            >
                                              {ReactHtmlParser(i.notes)}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="my-auto">
                                          <button
                                            className={`inline-flex items-center bg-coalColor justify-center px-4 w-full py-3 hover:text-coalColor hover:bg-primaryGreen border border-coalColor text-white text-sm font-medium rounded-md relative`}
                                            onClick={() => {
                                              if (
                                                i?.type === "custom-questions"
                                              ) {
                                                setCustomOpen(true);
                                              } else {
                                                setSectionID(i.id);
                                                setDropdownOpen(true);
                                              }
                                            }}
                                            style={{ fontFamily: "Silka" }}
                                          >
                                            View Details
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {deleteModal && (
          <DeleteModal
            setDeleteModal={setDeleteModal}
            onClick={handleDeleteAccount}
            header="Delete your test"
            icon={DeleteIcon}
            loading={deleteLoading}
            description=" Are you sure you want to delete your test? This action cannot be undone."
          />
        )}
        {dropdownOpen && (
          <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div
              className="fixed inset-0 z-10 overflow-y-auto"
              onClick={() => setDropdownOpen(false)}
            >
              <div className="flex items-start justify-end  text-center">
                <div
                  className="relative transform overflow-x-scroll no-scrollbar bg-[#F8F8F8] text-left shadow-xl transition-all sm:w-full md:w-1/2"
                  onClick={handleButtonClick}
                >
                  <div className="bg-[#F8F8F8]  h-screen px-4 sm:px-6 no-scrollbar overflow-auto pb-8">
                    {isLoadingModule ? (
                      <div class="loader-container-1">
                        <div class="loader-1"></div>
                      </div>
                    ) : (
                      <>
                        <div
                          className="flex justify-end sticky top-0 "
                          style={{ paddingTop: "100px" }}
                        >
                          <img
                            src={closem}
                            className="w-8 h-8 cursor-pointer"
                            onClick={() => setDropdownOpen(false)}
                          />
                        </div>
                        <div className="sm:px-0 md:px-0 lg:px-0 xl:px-10">
                          <h1 className="mt-4 text-xl font-bold">
                            {ModuleData?.name}
                          </h1>
                          <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 bg-coalColor mt-10 rounded-lg">
                            <div className="p-2 pl-5 border-r py-5">
                              <img src={d1} className="w-8 h-8" />
                              <p className="text-lg text-white mt-1">
                                {ModuleData?.module_type === "multiple_choice"
                                  ? "Multiple Choice"
                                  : "Case Study"}
                              </p>
                              <p className="text-xs text-white">
                                {ModuleData?.category?.categoryName}
                              </p>
                            </div>
                            <div className="p-2 pl-5 border-r py-5">
                              <img src={d2} className="w-8 h-8" />
                              <p className="text-lg text-white mt-1">Time</p>
                              <p className="text-xs text-white">
                                {ModuleData?.time} mins
                              </p>
                            </div>
                            <div className="p-2 pl-5 border-r py-5">
                              <img src={d3} className="w-8 h-8" />
                              <p className="text-lg text-white mt-1">
                                Language
                              </p>
                              <p className="text-xs text-white">English</p>
                            </div>
                            <div className="p-2 pl-5 border-r py-5">
                              <img src={d4} className="w-8 h-8" />
                              <p className="text-lg text-white mt-1">Level</p>
                              <p className="text-xs text-white">
                                {ModuleData?.experience === "General"
                                  ? "All levels"
                                  : ModuleData?.experience}
                              </p>
                            </div>
                          </div>
                          <h1
                            className="mt-10 text-xl font-bold"
                            style={{ fontFamily: "Archia Bold" }}
                          >
                            Covered Skills
                          </h1>
                          <div
                            className={styles["html-content"]}
                            style={{ fontFamily: "Silka" }}
                          >
                            {ReactHtmlParser(ModuleData?.covering_skills)}
                          </div>
                          <h1
                            className="mt-10 text-xl font-bold"
                            style={{ fontFamily: "Archia Bold" }}
                          >
                            This module is relevant for
                          </h1>
                          <div
                            className="text-[#767676]"
                            style={{ fontFamily: "Silka" }}
                          >
                            <div className={styles["html-content"]}>
                              {ReactHtmlParser(ModuleData?.test_relevent_for)}
                            </div>
                          </div>
                          <h1
                            className="mt-10 text-xl font-bold"
                            style={{ fontFamily: "Archia Bold" }}
                          >
                            Description
                          </h1>
                          <div
                            className={styles["html-content"]}
                            style={{ fontFamily: "Silka" }}
                          >
                            {ReactHtmlParser(ModuleData?.notes)}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {deleteUserModal && (
          <main className="fixed inset-0 flex items-center justify-center z-50">
            <div
              className="bg-black opacity-80 absolute inset-0"
              onClick={() => setUserModal(false)}
            ></div>
            <div className="bg-white rounded-lg p-4 md:max-w-lg md:mx-auto relative">
              <div className="bg-white rounded-lg md:max-w-lg md:mx-auto p-4 fixed inset-x-0 bottom-0 z-50 mx-4 md:relative">
                <div className="md:flex items-center">
                  <div className="rounded-full border border-gray-300 flex items-center justify-center w-16 h-16 flex-shrink-0 mx-auto">
                    <img src={DeleteIcon} className="w-8 h-8" />
                  </div>
                  <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                    <p
                      className="font-bold"
                      style={{ fontFamily: "Archia Bold" }}
                    >
                      Delete your candidate
                    </p>
                    <p
                      className="text-sm text-gray-700 mt-1"
                      style={{ fontFamily: "Silka Light" }}
                    >
                      Are you sure you want to delete your candidate? This
                      action cannot be undone.
                    </p>
                  </div>
                </div>
                <div className="text-center md:text-right mt-4 md:flex md:justify-end">
                  <button
                    className="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-primaryGreen text-coalColor border border-coalColor rounded-md font-semibold text-sm md:ml-2 md:order-2"
                    onClick={handleDeleteUser}
                    style={{ fontFamily: "Silka" }}
                  >
                    {deleteUserLoading ? (
                      <span className="flex items-center justify-center">
                        <Loader
                          type="Oval"
                          color="black"
                          height={20}
                          width={20}
                        />
                        <span className="ml-2">Deleting...</span>
                      </span>
                    ) : (
                      <>{"Delete candidate"}</>
                    )}
                  </button>
                  <button
                    className="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-coalColor border border-coalColor text-white rounded-md text-sm mt-4 md:mt-0 md:order-1"
                    onClick={() => setUserModal(false)}
                    style={{ fontFamily: "Silka" }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </main>
        )}
      </React.Fragment>
    </React.Fragment>
  );
};

export default InviteCandidates;
