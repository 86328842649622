import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import "./Auth.css";
import { useNavigate } from "react-router-dom";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { forgotEmail } from "./hooks/forgotEmail";
import { toast } from "react-toastify";
import { ToastContainer, Zoom } from "react-toastify";
import MainLogo from "../../Dexta_assets/LoginLogov4.png";
import Email from "../../Dexta_assets/email-image.png";
import TextField from "../../Components/Dexta/TextField/TextField";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { FaArrowRightLong } from "react-icons/fa6";

const ForgetPassword = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [toastError, setToasterror] = useState("");
  const [toastSuccess, setToastSuccess] = useState(false);

  //#region api call for forget passwowrd
  const { mutate: mutateEmail, isLoading: emailLoading } = useMutation(
    forgotEmail,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries("/auth/forgot-password");
        if (response.message === "Reset password email sent")
          setToastSuccess("Email Sent");
        navigate(
          `/forget-password-email-sent/?email=${validation?.values?.email}`
        );
      },
      onError: (error) => {
        if (error.response.data.message[0] != "")
          setToasterror("Invalid Email");
      },
    }
  );
  //#endregion

  //#region success and error toasts
  useEffect(() => {
    if (toastSuccess != "") {
      toast.success(toastSuccess, {
        toastId: "copy-success",
      });
    }
    setTimeout(() => {
      setToastSuccess("");
    }, 1000);
  }, [toastSuccess]);

  useEffect(() => {
    if (toastError != "") {
      toast.success(toastError, {
        toastId: "copy-success",
      });
    }
    setTimeout(() => {
      setToasterror("");
    }, 1000);
  }, [toastError]);
  //#endregion

  //#region formik validations and handle submit function
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Email Required"),
    }),
    onSubmit: (values) => {
      let data = JSON.stringify({
        email: values?.email,
      });
      try {
        mutateEmail(data);
      } catch (error) {
        //onError will automatically detect
      }
    },
  });
  //#endregion

  document.title = "Forget Password | Dexta";

  return (
    <React.Fragment>
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="sm:grid-cols-1 lg:grid-cols-2 grid bg-bgAuth h-screen">
        <div className="bg-black sm:hidden lg:block">
          <img
            src={MainLogo}
            className="w-full lg:h-full xl:h-fit lg:object-cover"
          />
        </div>
        <div className="bg-bgAuth flex justify-center flex-col pb-40">
          <div className="sm:w-3/4 lg:w-1/2 mx-auto">
            <h1
              className="text-2xl text-left"
              style={{ fontFamily: "Archia Semibold" }}
            >
              {" "}
              Forgot Password?{" "}
            </h1>
            <div style={{ fontFamily: "Silka" }} className="text-sm mt-6">
              <span>No, worries, we’ll send you reset instructions.</span>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              id="login"
            >
              <div className="mt-6">
                <div className="h-[4rem]">
                  <TextField
                    name="email"
                    id="email"
                    type="email"
                    placeholder="Work email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    imageUrl={Email}
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <div className="ml-1">
                      <p className="text-rose-500 fade-in-text-validations sm:text-xs md:text-sm">
                        {validation.errors.email}
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="flex md:flex-row sm:flex-col justify-between mt-8">
                <div className="sm:w-full md:w-2/3">
                  <CustomButton
                    label="RESET PASSWORD"
                    borderCustom="border border-black text-white"
                    paddingY="0.7rem"
                    bgColor="#252E3A"
                    textColor="white"
                    hoverBgColor="#C0FF06"
                    hoverTextColor="#252E3A"
                    iconR={FaArrowRightLong}
                    noMarginIcon={false}
                    autoLeftMargin="ml-auto"
                    textMarginBotton="ml-auto"
                    LoadingBtn={emailLoading}
                    loadingText="SENDING MAIL"
                  />
                </div>
                <p
                  className="underline text-sm my-auto cursor-pointer md:mt-0 sm:mt-3"
                  style={{ fontFamily: "Silka" }}
                  onClick={() => navigate("/login")}
                >
                  Return to login
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ForgetPassword;
