import ReactHtmlParser from "react-html-parser";

export const transformDescription = (description) => {
  const tableRegex =
    /<table[^>]*class="[^"]*se-table[^"]*"[^>]*>[\s\S]*?<\/table>/gi;
  const parts = description.split(tableRegex);
  const tables = description.match(tableRegex);
  return (
    <>
      {parts.map((part, index) => {
        const key = `content-${index}`;
        return (
          <>
            <span key={key}>{ReactHtmlParser(part)}</span>
            {tables && tables[index] && (
              <div key={`table-${index}`} className="overflow-auto w-full">
                {ReactHtmlParser(tables[index])}
              </div>
            )}
          </>
        );
      })}
    </>
  );
};
