import React from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useSelector } from "react-redux";
import { options } from "./data";
import { useState } from "react";
import { GoArrowRight } from "react-icons/go";

const PreviewExam = () => {
  const previewData = useSelector((state) => state.preview.setPreview);
  const [selectedOption, setSelectedOption] = useState([]);
  const [isHovered, setIsHovered] = useState(false);

  const buttonStyle = {
    background: isHovered ? previewData[1] : previewData[1],
    transition: "background-color 0.1s, transform 0.1s",
    transform: isHovered ? "scale(1.02)" : "scale(1)",
    color: previewData[2],
    border: `1px solid ${previewData[2]}`,
    fontFamily: "Silka",
  };

  const buttonStyle_nav = {
    background: isHovered ? previewData[2] : previewData[2],
    transition: "background-color 0.1s, transform 0.1s",
    transform: isHovered ? "scale(1.02)" : "scale(1)",
    color: previewData[1],
    border: `1px solid ${previewData[1]}`,
    fontFamily: "Silka",
  };

  const handleRadios = (id) => {
    setSelectedOption([id]);
  };

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  const closeTab = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };

  return (
    <>
      <div className="flex relative cursor-pointer">
        <nav className="w-full top-0 left-0 py-2 bg-white">
          <div className="w-3/4 px-2 items-center py-2 text-xl text-white mx-auto">
            <div className="grid grid-cols-3 container mx-auto">
              <img
                src={previewData[0]}
                className="my-auto object-contain"
                style={{ height: "70px" }}
              />
              <div className=" my-auto w-full">
                <div className="flex flex-col gap-2"></div>
              </div>
              <div className="my-auto ml-auto flex">
                <div className="">
                  <button
                    className="inline-flex items-center w-full justify-center px-8 py-3 my-auto text-sm font-medium rounded-md"
                    style={buttonStyle_nav}
                    onClick={() => {
                      closeTab();
                    }}
                  >
                    Finish
                    <AiOutlineArrowRight className="w-5 h-5 ml-2 icon-image" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div className="bg-bodyColor">
        <div className="mx-auto lg:container">
          <div className="flex justify-center h-screen mb-[190px]">
            <div className="mt-[50px] w-5/6 rounded-lg ">
              <h1
                className="text-xl font-bold py-5"
                style={{ fontFamily: "Archia Semibold" }}
              >
                Investment Analysis and Strategy
              </h1>
              <div className="rounded-md bg-white">
                <div className="p-3 md:p-8">
                  <React.Fragment>
                    <div className="grid md:grid-cols-2 gap-5">
                      <div className="pt-3.5 md:pl-5">
                        <div className="md:pr-8">
                          <h1
                            className="text-coalColor text-xl font-bold"
                            style={{ fontFamily: "Archia Bold" }}
                          >
                            QUESTION 1 of 1
                          </h1>
                          <div
                            className="mt-5 text-coalColor"
                            style={{ fontFamily: "Silka" }}
                          >
                            <div>
                              <p className="italic text-lg">
                                Read the following paragraphs and choose the
                                right answer.
                              </p>
                              <p className="mt-5 text-lg">
                                The nation’s mobile home parks constitute a
                                highly fragmented market, where there is less
                                efficient institutional participation.
                                Inefficiency is our opportunity, but the asset
                                class is becoming more popular with experienced
                                and newer investors who may ‘bid-up’ prices,
                                resulting in inflated expectations of sellers.
                                Believing the timing is right, we are
                                aggressively evaluating opportunities to stay
                                ahead of its growing popularity.
                              </p>
                              <p className="mt-5 text-lg">
                                Because mobile home communities are located away
                                from dense city centers and are geographically
                                dispersed, they are an under-researched and
                                misunderstood asset class. Most investors have
                                greatly outmoded ideas about the quality and
                                appeal of modern mobile homes and mobile home
                                communities.
                              </p>
                              <p
                                className="mt-8 font-bold text-lg text-coalColor"
                                style={{ fontFamily: "Silka" }}
                              >
                                According to the author of the above passage....
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pt-5 md:pl-2">
                        <div
                          className="md:px-10 text-coalColor text-xl font-bold rounded-lg"
                          style={{ fontFamily: "Archia Bold" }}
                        >
                          SELECT ONLY ONE
                          {options.map((j) => (
                            <div
                              key={j.id}
                              onClick={() => handleRadios(j.id)}
                              className={`p-5 mt-5 cursor-pointer  ${
                                selectedOption.includes(j.id)
                                  ? "bg-black"
                                  : null
                              } hover:animate-[jiggle_1s_ease-in-out_infinite]
                                                                                	 rounded-2xl border border-[#B6B6B6]`}
                              style={{
                                background: selectedOption.includes(j.id)
                                  ? previewData[1]
                                  : "white",
                              }}
                            >
                              <div className="flex gap-10">
                                <div className="inline-flex items-center">
                                  <label
                                    className="relative flex cursor-pointer items-center rounded-full p-3"
                                    htmlFor={`radio_${j.id}`}
                                    data-ripple-dark="true"
                                  >
                                    <input
                                      id={`radio_${j.id}`}
                                      name="type"
                                      type="radio"
                                      className="peer relative h-7 w-7 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-coalColor transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:opacity-0 before:transition-opacity border-black checked:border-coalColor checked:before:bg-black hover:before:opacity-10"
                                      onChange={() => handleRadios(j.id)}
                                      checked={selectedOption.includes(j.id)}
                                    />
                                    <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-coalColor opacity-0 transition-opacity peer-checked:opacity-100">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5"
                                        viewBox="0 0 16 16"
                                        fill="currentColor"
                                      >
                                        <circle
                                          data-name="ellipse"
                                          cx="8"
                                          cy="8"
                                          r="8"
                                        ></circle>
                                      </svg>
                                    </div>
                                  </label>
                                  <label
                                    className={`mt-px cursor-pointer select-none text-sm font-medium ${
                                      selectedOption.includes(j.id)
                                        ? "text-[#FFFFFF]"
                                        : "text-black"
                                    }`}
                                    htmlFor={`radio_${j.id}`}
                                    style={{
                                      fontFamily: "Silka",
                                      color: selectedOption.includes(j.id)
                                        ? previewData[2]
                                        : "#252e3a",
                                    }}
                                  >
                                    {j.title}
                                  </label>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="flex relative justify-center my-auto md:px-10 mt-8">
                          <button
                            className={`inline-flex items-center w-full justify-center px-8 py-4 my-auto  text-white text-sm font-medium rounded-md`}
                            style={buttonStyle}
                            onMouseEnter={handleHover}
                            onMouseLeave={handleLeave}
                            onClick={() => {
                              closeTab();
                            }}
                          >
                            Finish
                            <GoArrowRight
                              alt="Add Transaction Icon"
                              className="w-5 h-5 ml-2 icon-image"
                            />
                          </button>
                          <div
                            className={`tooltip w-[8rem] text-center font-medium`}
                          >
                            Primary color
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewExam;
