import React, { useEffect, useState } from "react";
import Rightw from "../../../Assets/rightarrow.png";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getQuestions } from "../hooks/getQuestions";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { postQuestion } from "../hooks/postQuestions";
import "../../../Components/Loading/Loading2.css";
import styles from "../Test-screens/styling.module.css";
import { BsFillClockFill } from "react-icons/bs";
import { AiOutlineArrowRight } from "react-icons/ai";
import "../../../Components/Loading/Loading4.css";
import { GoArrowRight } from "react-icons/go";
import { useSelector, useDispatch } from "react-redux";
import {
  setRevealToFalse,
  setRevealToTrue,
} from "../../../redux/reducers/RevealAnswers/RevealSlice";
import correct from "../../../Dexta_assets/true.png";
import incorrect from "../../../Dexta_assets/false.png";
import closeIcon from "../../../Dexta_assets/closeModal.png";
import eye from "../../../Dexta_assets/magnifier.png";
import useWindowSize from "../../../Helpers/useWindowSize";
import worker from "workerize-loader!../Test-screens/worker"; // eslint-disable-line import/no-webpack-loader-syntax
import { transformDescription } from "../../../Helpers/ParseTable";

const PreviewTest = () => {
  let questionID = null;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const currentSection = JSON?.parse(localStorage.getItem("practice_section"));
  const timecheck = JSON?.parse(localStorage.getItem("practice_time"));
  const [selectedOption, setSelectedOption] = useState([]);
  const [remaining, setRemaining] = useState(timecheck.seconds);
  const moduleData = JSON?.parse(localStorage.getItem("practice_module_data"));
  const question = JSON?.parse(localStorage.getItem("practice_questions"));
  const minsec = JSON?.parse(localStorage.getItem("practice_min_sec"));
  const theme = JSON?.parse(localStorage.getItem("theme"));
  const revealCheck = useSelector((state) => state.reveal.setReveal);
  const completion_check = localStorage.getItem("test_completed");
  const user_exists = localStorage.getItem("user_exists");
  const questions = JSON?.parse(localStorage.getItem("prevQuestion"));
  const [response, setResponse] = useState(null);

  //#region Fetching questions details
  const { data, isLoading } = useQuery(["questions", currentSection], () =>
    getQuestions(currentSection)
  );
  const size = useWindowSize();
  const isMobile = size.width <= 1336;
  //#endregion

  //#region taking user to top of screen
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentSection.pageQuestion]);
  //#endregion

  //#region Post question react-query
  const handleNextQuestion = () => {
    if (!revealCheck) {
      currentSection.pageQuestion = currentSection.pageQuestion;
      localStorage.setItem("practice_section", JSON.stringify(currentSection));
      dispatch(setRevealToTrue(true));
    } else if (revealCheck) {
      currentSection.pageQuestion = currentSection.pageQuestion + 1;
      localStorage.setItem("practice_section", JSON.stringify(currentSection));
      dispatch(setRevealToFalse(false));
      setSelectedOption([]);
      if (data?.meta?.hasNextPage === false) {
        navigate("/get-ready");
      }
    }
  };
  //#endregion

  //#region Handle ID
  const handleRadios = (id) => {
    setSelectedOption((prevSelected) =>
      prevSelected.includes(id) ? [] : [id]
    );
  };
  //#endregion

  //#region Handle checboxes
  const handleCheckbox = (id) => {
    if (selectedOption.includes(id)) {
      setSelectedOption(selectedOption.filter((item) => item !== id));
    } else {
      setSelectedOption([...selectedOption, id]);
    }
  };
  //#endregion

  //#region Timer for test
  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining((prevTime) => {
        return prevTime + 1;
      });
    }, 1000);
    timecheck.seconds = remaining;
    localStorage.setItem("practice_time", JSON.stringify(timecheck));
    return () => clearInterval(interval);
  }, [timecheck]);

  let width = Math.min(
    (remaining / (parseInt(currentSection.time) * 60)) * 100,
    100
  );

  if (width > 50) {
    width += 1;
  }

  timecheck.timeLeft = width;
  localStorage.setItem("practice_time", JSON.stringify(timecheck));
  const remainingTime = parseInt(currentSection.time) * 60;
  const roundedValue = Math.round(remainingTime - timecheck.seconds);
  //#endregion

  //#region handling tab close
  useEffect(() => {
    const handleTabClose = (event) => {
      event.preventDefault();

      console.log("beforeunload event triggered");

      return (event.returnValue = "Are you sure you want to exit?");
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  //#endregion

  //#region calculating percentage for number of questions solved / skipped
  question.current = data?.meta?.page - 1;
  localStorage.setItem("practice_questions", JSON.stringify(question));

  question.total = data?.meta?.itemCount;
  localStorage.setItem("practice_questions", JSON.stringify(question));

  question.solved = Math.min((question.current / question.total) * 100);
  localStorage.setItem("practice_questions", JSON.stringify(question));

  //#endregion

  //#region Handling durations
  const [totalDuration, setTotalDuration] = useState(minsec.secleft);
  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTotalDuration((prevDuration) => {
        if (prevDuration > 0) {
          return prevDuration - 1;
        } else {
          clearInterval(timerInterval);
          return prevDuration;
        }
      });
    }, 1000);
    minsec.secleft = totalDuration;
    localStorage.setItem("practice_min_sec", JSON.stringify(minsec));
    return () => clearInterval(timerInterval);
  }, [totalDuration]);

  const minutes = Math.floor(totalDuration / 60);
  const seconds = totalDuration % 60;

  const formattedTime = `${String(minutes).padStart(2, "0")}:${String(
    seconds
  ).padStart(2, "0")}`;
  useEffect(() => {
    {
      if (totalDuration === 0 && moduleData.current !== moduleData.last) {
        localStorage.setItem("exam", "start");
        dispatch(setRevealToFalse(false));
        setTimeout(() => {
          navigate("/get-ready");
        }, 500);
      } else if (
        totalDuration === 0 &&
        moduleData.current === moduleData.last
      ) {
        localStorage.setItem("exam", "start");
        dispatch(setRevealToFalse(false));
        setTimeout(() => {
          navigate("/get-ready");
        }, 500);
      }
    }
  }, [
    totalDuration,
    parseInt(moduleData.current, 10),
    parseInt(moduleData.last, 10),
  ]);
  //#endregion

  //#region handle back browser
  useEffect(() => {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function (event) {
      window.history.pushState(null, null, window.location.href);
    };
    return () => {
      window.onpopstate = null;
    };
  }, []);
  //#endregion

  //#region Adjusting colors
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  const buttonStyle = {
    background: isHovered ? theme.color : theme.color,
    transition: "background-color 0.1s, transform 0.1s",
    transform: isHovered ? "scale(1.02)" : "scale(1)",
    color: theme.sec_color,
    border: `1px solid ${theme.sec_color}`,
    fontFamily: "Archia Semibold",
  };

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  const handleHover2 = () => {
    setIsHovered2(true);
  };

  const handleLeave2 = () => {
    setIsHovered2(false);
  };

  function LightenDarkenColor(col, amt) {
    var usePound = false;

    if (col[0] == "#") {
      col = col.slice(1);
      usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  }

  function DarkenColor(col, amt) {
    if (col === "#000000") {
      // If the color is black, handle it differently
      return "#454545";
    }

    var usePound = false;

    if (col[0] === "#") {
      col = col.slice(1);
      usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) - amt;

    if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00ff) - amt;

    if (b < 0) b = 0;

    var g = (num & 0x0000ff) - amt;

    if (g < 0) g = 0;

    return (
      (usePound ? "#" : "") +
      ("000000" + (g | (b << 8) | (r << 16)).toString(16)).slice(-6)
    );
  }

  var NewColor = LightenDarkenColor(theme.color, 140);
  var NewColor2 = LightenDarkenColor(theme.color, 140);
  var DarkenedColor = DarkenColor(theme.color, 50);

  //#endregion

  //#region numbers
  const numbersToWords = {
    0: "zero",
    1: "first",
    2: "second",
    3: "third",
    4: "fourth",
    5: "fifth",
    6: "sixth",
    7: "seventh",
    8: "eighth",
    9: "nineth",
    10: "tenth",
    11: "eleventh",
    12: "twelveth",
    13: "thirteenth",
    14: "fourteenth",
    15: "fifteenth",
    16: "sixteenth",
    17: "seventeenth",
    18: "eighteenth",
    19: "nineteenth",
    20: "twentyth",
    30: "thirtyth",
    40: "fortyth",
    50: "fiftyth",
    60: "sixtyth",
    70: "seventyth",
    80: "eightyth",
    90: "ninetyth",
  };

  function convertNumberToWords(number) {
    // if number present in object no need to go further
    if (number in numbersToWords) return numbersToWords[number];

    // Initialize the words variable to an empty string
    let words = "";

    // If the number is greater than or equal to 100, handle the hundreds place (ie, get the number of hundres)
    if (number >= 100) {
      // Add the word form of the number of hundreds to the words string
      words += convertNumberToWords(Math.floor(number / 100)) + " hundred";

      // Remove the hundreds place from the number
      number %= 100;
    }

    // If the number is greater than zero, handle the remaining digits
    if (number > 0) {
      // If the words string is not empty, add "and"
      if (words !== "") words += " and ";

      // If the number is less than 20, look up the word form in the numbersToWords object
      if (number < 20) words += numbersToWords[number];
      else {
        // Otherwise, add the word form of the tens place to the words string
        //if number = 37, Math.floor(number /10) will give you 3 and 3 * 10 will give you 30
        words += numbersToWords[Math.floor(number / 10) * 10];

        // If the ones place is not zero, add the word form of the ones place
        if (number % 10 > 0) {
          words += "-" + numbersToWords[number % 10];
        }
      }
    }

    // Return the word form of the number
    return words;
  }

  //#region check if user completed feedback
  useEffect(() => {
    if (completion_check === "yes") {
      navigate("/feedback");
    } else if (user_exists === "yes") {
      navigate("/candidate/dashboard");
    } else if (user_exists === "no") {
      navigate("/candidate/login");
    }
  }, []);
  //#endregion

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);

  const handleImageClick = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setIsModalOpen(true);
  };

  //#endregion

  //#region inactivity solution
  const handleApiRequest = () => {
    let DataObj = {
      data: {
        usersId: parseInt(localStorage.getItem("CP-CANDIDATE-ID")),
        evaluation: currentSection.evaluation,
        assessmentId: parseInt(localStorage.getItem("CANDIDATE-ASSESSMENT-ID")),
        sectionId: parseInt(currentSection.ModuleID),
        questionId: parseInt(localStorage.getItem("Question")),
      },
      requestOptions: {
        authorization: `Bearer ${localStorage.getItem("CP-CANDIDATE-TOKEN")}`,
      },
    };
    const workerInstance = worker();
    workerInstance.postToApi({ data: DataObj });
    workerInstance.addEventListener("message", (e) => {
      const { type, result } = e.data;
      if (type === "POST_RESPONSE") {
        setResponse(result);
        workerInstance.terminate();
      }
    });
  };

  useEffect(() => {
    const intervalId = setInterval(handleApiRequest, 1 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, []);
  //#endregion

  document.title = "Exam | Dexta";
  return (
    <>
      <nav className="w-full top-0 left-0 py-2 bg-white">
        <div className="w-3/4 px-2 items-center py-2 text-xl text-white mx-auto">
          <div className="grid md:grid-cols-3 container mx-auto">
            <img
              src={theme.logo}
              className="my-auto object-contain sm:max-lg:w-[100px] sm:max-lg:h-[70px]"
              style={{ height: isMobile ? "50px" : "70px" }}
            />
            <div className=" my-auto w-full">
              <div className="flex flex-col gap-2">
                <div className="flex flex-row">
                  <BsFillClockFill
                    color={DarkenedColor}
                    className="w-4 h-4 my-auto "
                  />
                  <p
                    className="text-coalColor ml-2 text-sm"
                    style={{ fontFamily: "Silka" }}
                  >
                    {formattedTime}
                  </p>
                  <div
                    className="w-full rounded-lg ml-2 my-auto"
                    style={{ backgroundColor: theme.sec_color }}
                  >
                    <div
                      className="p-2  rounded-lg text-center text-xs font-medium leading-none text-primary-100"
                      style={{
                        width: `${timecheck.timeLeft}%`,
                        background: DarkenedColor,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="my-auto ml-auto flex mt-5 md:mt-3">
              <button
                className={`inline-flex items-center w-full justify-center py-3 my-auto cursor-pointer text-sm font-medium rounded-md`}
                style={{
                  color: theme.color,
                  backgroundColor: theme.sec_color,
                  fontFamily: "Archia Semibold",
                  width: isMobile ? "110px" : "130px",
                }}
                onClick={handleNextQuestion}
              >
                {!revealCheck ? "Submit" : "Next"}
                <AiOutlineArrowRight className="w-5 h-5 ml-2 icon-image" />
              </button>
            </div>
          </div>
        </div>
      </nav>

      <div className="bg-bodyColor w-full">
        <div className="w-3/4 px-2 mx-auto items-center">
          <div className=" h-screen lg:container mb-[190px]">
            <div className="xl:mt-[30px] 2xl:mt-[50px]  rounded-lg ">
              {!isLoading && (
                <h1
                  className="text-xl font-bold py-5"
                  style={{ fontFamily: "Archia Bold" }}
                >
                  {currentSection.module_Name}
                </h1>
              )}
              {isModalOpen && (
                <div
                  className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
                  onClick={() => setIsModalOpen(false)}
                >
                  <div className="relative bg-white p-5 rounded-md">
                    <img
                      src={closeIcon}
                      className="absolute top-3 right-5 z-20 w-6 h-6 cursor-pointer"
                      onClick={() => setIsModalOpen(false)}
                    />
                    <img
                      src={selectedImageUrl}
                      alt="Selected"
                      className="p-5 object-contain"
                      style={{
                        height: "450px",
                        width: "850px",
                      }}
                    />
                  </div>
                </div>
              )}
              {!isLoading && (
                <div className="mt-1">
                  <div
                    class="flex items-center p-4 mb-4 text-sm rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)]"
                    role="alert"
                    style={{ background: "#DBD9C0" }}
                  >
                    <svg
                      class="flex-shrink-0 inline w-5 h-5 me-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                    </svg>
                    <span class="sr-only">Info</span>
                    <div>
                      <span
                        class="font-medium text-[15px]"
                        style={{ fontFamily: "Silka" }}
                      >
                        This is the{" "}
                        {convertNumberToWords(currentSection.pageQuestion)}{" "}
                        practice question.
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {isLoading ? (
                <div class="loader-container-3 col-span-6">
                  <div class="loader-3"></div>
                </div>
              ) : (
                <div className="rounded-md bg-white">
                  <div className="p-3 md:p-8">
                    {data?.data?.map((i, index) => {
                      questionID = i?.id;
                      localStorage.setItem("Question", i?.id);
                      let options_check;
                      if (
                        i?.options?.every((option) => option.imageUrl === null)
                      ) {
                        options_check = "null";
                      } else if (
                        i?.options?.every(
                          (option) =>
                            option.imageUrl !== null && option?.title !== null
                        )
                      ) {
                        options_check = "all";
                      } else if (
                        i?.options?.every(
                          (option) =>
                            option.imageUrl !== null && option?.title === null
                        )
                      ) {
                        options_check = "all images";
                      } else {
                        options_check = "some_image";
                      }
                      return (
                        <React.Fragment key={i.id}>
                          <div className="grid md:grid-cols-2 gap-5">
                            <div className="xxl:pt-1 2xl:pt-3.5 md:pl-5 overflow-hidden">
                              <div className="md:pr-8">
                                <h1
                                  className="text-coalColor text-lg font-bold"
                                  style={{ fontFamily: "Archia Bold" }}
                                >
                                  QUESTION {parseInt(question.current) + 1} of{" "}
                                  {question.total}
                                </h1>
                                <div
                                  className="mt-5"
                                  style={{ fontFamily: "Silka" }}
                                >
                                  <div className={styles["html-content"]}>
                                    {transformDescription(i?.description)}
                                  </div>
                                </div>
                              </div>

                              {i?.image != null &&
                                i?.image != " " &&
                                i?.image != "" && (
                                  <div className="mt-3">
                                    <img
                                      src={i?.image}
                                      className="rounded-lg object-cover cursor-pointer h-auto"
                                      style={{
                                        width: "100%",
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleImageClick(i?.image);
                                      }}
                                    />
                                  </div>
                                )}
                              {i?.figImage != null &&
                                i?.figImage != " " &&
                                i?.figImage != "" && (
                                  <div className="">
                                    <img
                                      src={i?.figImage}
                                      className="rounded-lg object-cover cursor-pointer h-auto mt-5"
                                      style={{
                                        width: "100%",
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleImageClick(i?.figImage);
                                      }}
                                    />
                                  </div>
                                )}
                            </div>
                            {i.type === "Single" ? (
                              <>
                                <div className="xxl:pt-2 2xl:pt-5 md:pl-2">
                                  <div
                                    className="md:pl-10 text-coalColor text-lg font-bold rounded-lg"
                                    style={{ fontFamily: "Archia Bold" }}
                                  >
                                    SELECT ONLY ONE
                                    {i?.options
                                      ?.sort((a, b) =>
                                        a?.optionPosition > b?.optionPosition
                                          ? 1
                                          : -1
                                      )
                                      .reduce((acc, curr, index, array) => {
                                        if (index % 2 === 0)
                                          acc.push(
                                            array.slice(index, index + 2)
                                          );
                                        return acc;
                                      }, [])
                                      .map((pair, rowIndex) => (
                                        <div
                                          key={rowIndex}
                                          className={`grid grid-cols-12`}
                                        >
                                          <div
                                            className={`${
                                              options_check === "all images"
                                                ? "col-span-12"
                                                : "col-span-11"
                                            }`}
                                          >
                                            <div
                                              className={`grid ${
                                                options_check === "all images"
                                                  ? "grid-cols-2"
                                                  : ""
                                              } gap-4 mt-5`}
                                            >
                                              {pair.map((j, index) => (
                                                <>
                                                  {options_check ===
                                                  "all images" ? (
                                                    <div
                                                      key={j.id}
                                                      onClick={() =>
                                                        handleRadios(j.id)
                                                      }
                                                      className={`cursor-pointer hover:animate-[jiggle_1s_ease-in-out_infinite] ${
                                                        index % 2 === 0
                                                          ? "justify-self-start"
                                                          : "justify-self-end"
                                                      }`}
                                                    >
                                                      <div className="relative flex items-center justify-center">
                                                        {/* Container for image and zoom button */}
                                                        <div className="relative group">
                                                          <img
                                                            src={j?.imageUrl}
                                                            className="w-[270px] h-[200px] object-cover rounded-lg transition-opacity duration-300"
                                                            style={{
                                                              borderColor:
                                                                selectedOption.includes(
                                                                  j.id
                                                                )
                                                                  ? theme.color
                                                                  : "white",
                                                              borderStyle:
                                                                "solid",
                                                              borderWidth:
                                                                "3px",
                                                            }}
                                                          />

                                                          <div className="absolute top-2 left-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                            <button
                                                              className="bg-white text-black px-3 py-2 rounded-lg shadow-md"
                                                              onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleImageClick(
                                                                  j?.imageUrl
                                                                );
                                                              }}
                                                            >
                                                              <img
                                                                src={eye}
                                                                className="w-5 h-5 object-contain"
                                                              />
                                                            </button>
                                                          </div>
                                                        </div>
                                                        {options_check ===
                                                          "all images" && (
                                                          <div className="col-span-1 align-center ml-auto flex flex-col">
                                                            <>
                                                              {revealCheck && (
                                                                <>
                                                                  {j?.isCorrectOption ===
                                                                  true ? (
                                                                    <img
                                                                      alt="Add Transaction Icon"
                                                                      src={
                                                                        correct
                                                                      }
                                                                      className="w-3 h-3 my-auto ml-4 icon-image"
                                                                    />
                                                                  ) : (
                                                                    <img
                                                                      alt="Add Transaction Icon"
                                                                      src={
                                                                        incorrect
                                                                      }
                                                                      className="w-3 h-3 my-auto ml-4 icon-image"
                                                                    />
                                                                  )}
                                                                </>
                                                              )}
                                                            </>
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <div
                                                      key={j.id}
                                                      onClick={() =>
                                                        handleRadios(j.id)
                                                      }
                                                      className={`p-3 2xl:p-5 mt-5 cursor-pointer ${
                                                        selectedOption.includes(
                                                          j.id
                                                        )
                                                          ? "bg-black"
                                                          : null
                                                      } hover:animate-[jiggle_1s_ease-in-out_infinite] rounded-2xl border border-[#B6B6B6]`}
                                                      style={{
                                                        background:
                                                          selectedOption.includes(
                                                            j.id
                                                          )
                                                            ? theme.color
                                                            : "white",
                                                      }}
                                                    >
                                                      <div className="flex justify-between">
                                                        <div className="flex gap-10">
                                                          <div className="inline-flex items-center">
                                                            <label
                                                              className="relative flex cursor-pointer items-center rounded-full p-3"
                                                              data-ripple-dark="true"
                                                            >
                                                              <input
                                                                id={`radio_${j.id}`}
                                                                name="type"
                                                                type="radio"
                                                                className="peer relative 2xl:h-7 2xl:w-7 w-5 h-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-coalColor transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:opacity-0 before:transition-opacity border-black checked:border-coalColor checked:before:bg-black hover:before:opacity-10"
                                                                checked={selectedOption.includes(
                                                                  j.id
                                                                )}
                                                              />
                                                              <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-coalColor opacity-0 transition-opacity peer-checked:opacity-100">
                                                                <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  className="2xl:h-5 2xl:w-5 w-3 h-3"
                                                                  viewBox="0 0 16 16"
                                                                  fill="currentColor"
                                                                >
                                                                  <circle
                                                                    data-name="ellipse"
                                                                    cx="8"
                                                                    cy="8"
                                                                    r="8"
                                                                  ></circle>
                                                                </svg>
                                                              </div>
                                                            </label>
                                                            <label
                                                              className={`mt-px cursor-pointer select-none text-sm font-medium`}
                                                              style={{
                                                                fontFamily:
                                                                  "Silka",
                                                                color:
                                                                  selectedOption.includes(
                                                                    j.id
                                                                  )
                                                                    ? theme?.sec_color
                                                                    : "#252e3a",
                                                              }}
                                                            >
                                                              {j.title}
                                                            </label>
                                                          </div>
                                                        </div>
                                                        {(options_check ===
                                                          "all" ||
                                                          options_check ===
                                                            "some_image") && (
                                                          <div className="my-auto">
                                                            {j?.imageUrl ? (
                                                              <div className="relative flex items-center justify-center">
                                                                {/* Container for image and zoom button */}
                                                                <div className="relative group">
                                                                  <img
                                                                    src={
                                                                      j?.imageUrl
                                                                    }
                                                                    className="my-auto w-20 h-20 object-cover"
                                                                  />

                                                                  <div className="absolute top-1 left-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                                    <button
                                                                      className="bg-white text-black px-2 py-2 rounded-md shadow-md"
                                                                      onClick={(
                                                                        e
                                                                      ) => {
                                                                        e.stopPropagation();
                                                                        handleImageClick(
                                                                          j?.imageUrl
                                                                        );
                                                                      }}
                                                                    >
                                                                      <img
                                                                        src={
                                                                          eye
                                                                        }
                                                                        className="w-3 h-3 object-contain"
                                                                      />
                                                                    </button>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            ) : (
                                                              <div className="w-20 h-20"></div>
                                                            )}
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                  )}
                                                </>
                                              ))}
                                            </div>
                                          </div>
                                          {/* Display checkmarks for correct/incorrect options */}
                                          {options_check !== "all images" && (
                                            <div className="col-span-1 align-center ml-auto flex flex-col mt-5">
                                              {pair.map((j) => (
                                                <>
                                                  {revealCheck && (
                                                    <>
                                                      {j?.isCorrectOption ===
                                                      true ? (
                                                        <img
                                                          alt="Add Transaction Icon"
                                                          src={correct}
                                                          className="w-3 h-3 my-auto ml-4 icon-image"
                                                        />
                                                      ) : (
                                                        <img
                                                          alt="Add Transaction Icon"
                                                          src={incorrect}
                                                          className="w-3 h-3 my-auto ml-4 icon-image"
                                                        />
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              ))}
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                  </div>

                                  {/* Submit Button */}
                                  <div className="grid grid-cols-12">
                                    <div
                                      className={`${
                                        options_check === "all images"
                                          ? "col-span-12"
                                          : "col-span-11"
                                      }  flex justify-center pl-10 my-auto mt-8`}
                                    >
                                      <button
                                        className={`inline-flex items-center w-full justify-center px-8 py-3 2xl:py-4 my-auto text-white text-sm font-medium rounded-md`}
                                        style={buttonStyle}
                                        onMouseEnter={handleHover}
                                        onMouseLeave={handleLeave}
                                        onClick={handleNextQuestion}
                                      >
                                        {!revealCheck ? "Submit" : "Next"}
                                        <GoArrowRight
                                          alt="Next Step"
                                          className="w-5 h-5 ml-2 icon-image"
                                        />
                                      </button>
                                    </div>
                                    <div className="col-span-1"></div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="pt-1 2xl:pt-5 md:pl-2">
                                  <div
                                    className="md:pl-10 text-coalColor text-lg font-bold rounded-lg"
                                    style={{ fontFamily: "Archia Bold" }}
                                  >
                                    SELECT ALL THAT APPLY
                                    {i?.options
                                      ?.sort((a, b) =>
                                        a?.optionPosition > b?.optionPosition
                                          ? 1
                                          : -1
                                      )
                                      .reduce((acc, curr, index, array) => {
                                        if (index % 2 === 0)
                                          acc.push(
                                            array.slice(index, index + 2)
                                          );
                                        return acc;
                                      }, [])
                                      .map((pair, rowIndex) => (
                                        <div
                                          key={rowIndex}
                                          className="grid grid-cols-12"
                                        >
                                          <div
                                            className={`${
                                              options_check === "all images"
                                                ? "col-span-12"
                                                : "col-span-11"
                                            } `}
                                          >
                                            <div
                                              className={`grid ${
                                                options_check === "all images"
                                                  ? "grid-cols-2"
                                                  : ""
                                              } gap-4 mt-5`}
                                            >
                                              {pair.map((j, index) => (
                                                <>
                                                  {options_check ===
                                                  "all images" ? (
                                                    <div
                                                      key={j.id}
                                                      onClick={() =>
                                                        handleCheckbox(j.id)
                                                      }
                                                      className={`cursor-pointer hover:animate-[jiggle_1s_ease-in-out_infinite] ${
                                                        index % 2 === 0
                                                          ? "justify-self-start"
                                                          : "justify-self-end"
                                                      }`}
                                                    >
                                                      <div className="relative flex items-center justify-center">
                                                        {/* Container for image and zoom button */}
                                                        <div className="relative group">
                                                          <img
                                                            src={j?.imageUrl}
                                                            className="w-[270px] h-[200px] object-cover rounded-lg transition-opacity duration-300"
                                                            style={{
                                                              borderColor:
                                                                selectedOption.includes(
                                                                  j.id
                                                                )
                                                                  ? theme.color
                                                                  : "white",
                                                              borderStyle:
                                                                "solid",
                                                              borderWidth:
                                                                "3px",
                                                            }}
                                                          />

                                                          <div className="absolute top-2 left-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                            <button
                                                              className="bg-white text-black px-3 py-2 rounded-lg shadow-md"
                                                              onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleImageClick(
                                                                  j?.imageUrl
                                                                );
                                                              }}
                                                            >
                                                              <img
                                                                src={eye}
                                                                className="w-5 h-5 object-contain"
                                                              />
                                                            </button>
                                                          </div>
                                                        </div>
                                                        {options_check ===
                                                          "all images" && (
                                                          <div className="col-span-1 align-center ml-auto flex flex-col">
                                                            <>
                                                              {revealCheck && (
                                                                <>
                                                                  {j?.isCorrectOption ===
                                                                  true ? (
                                                                    <img
                                                                      alt="Add Transaction Icon"
                                                                      src={
                                                                        correct
                                                                      }
                                                                      className="w-3 h-3 my-auto ml-4 icon-image"
                                                                    />
                                                                  ) : (
                                                                    <img
                                                                      alt="Add Transaction Icon"
                                                                      src={
                                                                        incorrect
                                                                      }
                                                                      className="w-3 h-3 my-auto ml-4 icon-image"
                                                                    />
                                                                  )}
                                                                </>
                                                              )}
                                                            </>
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <div
                                                      key={j.id}
                                                      onClick={() =>
                                                        handleCheckbox(j?.id)
                                                      }
                                                      className={`p-3 2xl:p-5 mt-5 cursor-pointer ${
                                                        selectedOption.includes(
                                                          j.id
                                                        )
                                                          ? "bg-black"
                                                          : null
                                                      } hover:animate-[jiggle_1s_ease-in-out_infinite] rounded-2xl border border-[#B6B6B6]`}
                                                      style={{
                                                        background:
                                                          selectedOption.includes(
                                                            j.id
                                                          )
                                                            ? theme.color
                                                            : "white",
                                                      }}
                                                    >
                                                      <div className="flex justify-between">
                                                        <div className="inline-flex items-center">
                                                          <label
                                                            className="relative flex cursor-pointer items-center rounded-full p-3"
                                                            htmlFor={`radio_${j.id}`}
                                                            data-ripple-dark="true"
                                                          >
                                                            <input
                                                              id="ripple-on"
                                                              type="checkbox"
                                                              className={`peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 border-gray-600  before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-coalColor  hover:before:opacity-10`}
                                                              onClick={() =>
                                                                handleCheckbox(
                                                                  j?.id
                                                                )
                                                              }
                                                              checked={selectedOption.includes(
                                                                j.id
                                                              )}
                                                              onChange={() =>
                                                                handleCheckbox(
                                                                  j?.id
                                                                )
                                                              }
                                                            />
                                                            <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-coalColor opacity-0 transition-opacity peer-checked:opacity-100">
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-3.5 w-3.5"
                                                                viewBox="0 0 20 20"
                                                                fill="currentColor"
                                                                stroke="currentColor"
                                                                strokeWidth="1"
                                                              >
                                                                <path
                                                                  fillRule="evenodd"
                                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                  clipRule="evenodd"
                                                                ></path>
                                                              </svg>
                                                            </div>
                                                          </label>
                                                          <label
                                                            className={`mt-px cursor-pointer select-none text-sm font-medium ${
                                                              selectedOption?.includes(
                                                                j?.id
                                                              )
                                                                ? "text-[#FFFFFF]"
                                                                : "text-coalColor"
                                                            }`}
                                                            htmlFor="html"
                                                            style={{
                                                              fontFamily:
                                                                "Silka",
                                                              color:
                                                                selectedOption.includes(
                                                                  j.id
                                                                )
                                                                  ? theme?.sec_color
                                                                  : "#252e3a",
                                                            }}
                                                          >
                                                            {j.title}
                                                          </label>
                                                        </div>

                                                        {(options_check ===
                                                          "all" ||
                                                          options_check ===
                                                            "some_image") && (
                                                          <div className="my-auto">
                                                            {j?.imageUrl ? (
                                                              <div className="relative flex items-center justify-center">
                                                                {/* Container for image and zoom button */}
                                                                <div className="relative group">
                                                                  <img
                                                                    src={
                                                                      j?.imageUrl
                                                                    }
                                                                    className="my-auto w-20 h-20 object-cover"
                                                                  />

                                                                  <div className="absolute top-1 left-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                                    <button
                                                                      className="bg-white text-black px-2 py-2 rounded-md shadow-md"
                                                                      onClick={(
                                                                        e
                                                                      ) => {
                                                                        e.stopPropagation();
                                                                        handleImageClick(
                                                                          j?.imageUrl
                                                                        );
                                                                      }}
                                                                    >
                                                                      <img
                                                                        src={
                                                                          eye
                                                                        }
                                                                        className="w-3 h-3 object-contain"
                                                                      />
                                                                    </button>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            ) : (
                                                              <div className="w-20 h-20"></div>
                                                            )}
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                  )}
                                                </>
                                              ))}
                                            </div>
                                          </div>
                                          {/* Display checkmarks for correct/incorrect options */}
                                          {options_check !== "all images" && (
                                            <div className="col-span-1 align-center ml-auto flex mt-6 flex-col">
                                              {pair.map((j) => (
                                                <>
                                                  {revealCheck && (
                                                    <>
                                                      {j?.isCorrectOption ===
                                                      true ? (
                                                        <img
                                                          alt="Add Transaction Icon"
                                                          src={correct}
                                                          className="w-3 h-3 my-auto ml-4 icon-image"
                                                        />
                                                      ) : (
                                                        <img
                                                          alt="Add Transaction Icon"
                                                          src={incorrect}
                                                          className="w-3 h-3 my-auto ml-4 icon-image"
                                                        />
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              ))}
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                  </div>

                                  <div className="grid grid-cols-12">
                                    <div
                                      className={`${
                                        options_check === "all images"
                                          ? "col-span-12"
                                          : "col-span-11"
                                      }  flex justify-center pl-10 my-auto mt-8`}
                                    >
                                      <button
                                        className={`inline-flex items-center w-full justify-center px-8 py-3 2xl:py-4 my-auto text-white text-sm font-medium rounded-md`}
                                        style={buttonStyle}
                                        onMouseEnter={handleHover}
                                        onMouseLeave={handleLeave}
                                        onClick={handleNextQuestion}
                                      >
                                        {!revealCheck ? "Submit" : "Next"}
                                        <GoArrowRight
                                          alt="Next Step"
                                          className="w-5 h-5 ml-2 icon-image"
                                        />
                                      </button>
                                    </div>
                                    <div className="col-span-1"></div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewTest;
