import {
  CardElement,
  PaymentElement,
  useElements,
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Cardimg from "../../Dexta_assets/card.png";
import { useFormik } from "formik";
import http from "../../http";
import Visa from "../../Dexta_assets/visa-logo.png";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { setPackageData } from "../../redux/reducers/packageData/packageDataSlice";
import { useDispatch } from "react-redux";
import { FaStar } from "react-icons/fa6";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { FaArrowRightLong } from "react-icons/fa6";
import { toast } from "react-toastify";
import { attachCard } from "../../Components/hooks/attachCard";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const inputStyle = {
  iconColor: "#c4f0ff",
  backgroundColor: "#fff",
  color: "#000",
  fontWeight: "500",
  fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
  fontSize: "14px",
  fontSmoothing: "antialiased",
  ":-webkit-autofill": {
    color: "#000",
  },
  "::placeholder": {
    color: "#6B6B6B",
  },
};

const CheckoutForm = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [load, setLoad] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const queryClient = useQueryClient();

  //#region Creating subscription for the recruiter

  const createSubscription = async () => {
    try {
      setIsLoading(true);
      // create a payment method
      const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: elements?.getElement(CardNumberElement),
        billing_details: {
          name: props?.packageDetail?.name,
        },
      });

      const methodID = paymentMethod?.error?.message;

      if (methodID) {
        toast.error(methodID, {
          toastId: "copy-success",
        });
        setIsLoading(false);
        return;
      }

      // First create subscription to get client secret
      const subscriptionResponse = await http.request({
        method: "post",
        maxBodyLength: Infinity,
        url: "/subscriptions",
        data: JSON.stringify({
          user: Number(localStorage.getItem("CP-USER-ID")),
          currency: props?.packageDetail?.prices[0].currency,
          package: props?.packageDetail?.id,
          interval: props?.packageDetail?.prices[0].interval,
          stripePaymentMethodID: paymentMethod?.paymentMethod?.id,
          stripeProductID: props?.packageDetail?.stripeProductID,
          stripePriceID:
            props?.packageDetail?.prices[0].interval === "year"
              ? props?.packageDetail?.stripeYearlyPriceID
              : props?.packageDetail?.stripePriceID,
          description: "Subscription ................",
        }),
      });

      // Now confirm the payment with the received client secret
      const confirmPayment = await stripe?.confirmCardPayment(
        subscriptionResponse.data.clientSecret
      );

      if (confirmPayment?.error) {
        toast.error(confirmPayment.error.message, {
          toastId: "copy-success",
        });
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      await dispatch(setPackageData(subscriptionResponse?.data?.code));
      localStorage.removeItem("CP-PACKAGE-ID");
      localStorage.removeItem("Registration");
      navigate(`/dashboard`);
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        const errorMessage = error?.response?.data?.message;
        if (Array.isArray(errorMessage)) {
          toast.error(errorMessage[0], {
            toastId: "copy-success",
            style: { width: "450px" },
          });
        } else if (typeof errorMessage === "string") {
          toast.error(errorMessage, {
            toastId: "copy-success",
            style: { width: "450px" },
          });
        } else {
          toast.error("An error occurred.", {
            toastId: "copy-success",
          });
        }
      } else if (error.request) {
        console.log(error.request);
        setError(true);
        setErrorMessage(error.request);
      } else {
        console.log("Error", error.message);
        setError(true);
        setErrorMessage(error.message);
      }
    }
  };
  //#endregion

  const { mutateAsync } = useMutation(attachCard, {
    onSuccess: () => {
      queryClient.invalidateQueries("/subscriptions/attachPaymentMethod");
    },
  });

  //#region static load to load packages
  useEffect(() => {
    setLoad(true);
    setTimeout(() => {
      setLoad(false);
    }, 1500);
  }, [props?.packageDetail]);
  //#endregion

  document.title = "Checkout | Dexta";

  return (
    <div className="mb-10" style={{ paddingBottom: "10px" }}>
      {load ? (
        <div
          className={`px-5 py-5 mt-5 rounded bg-gray-400 flex justify-between`}
        >
          <div
            className={`my-auto ${
              props?.packageDetail?.code === "Enterprise"
                ? "text-white"
                : "text-black"
            }`}
            style={{ fontFamily: "Archia Semibold" }}
          >
            <Skeleton width={100} height={20} />
          </div>
          <div className="border text-xs my-auto py-1 bg-white px-3">
            <Skeleton width={100} height={20} />
          </div>
        </div>
      ) : (
        <div
          className={`px-5 py-2 mt-5 rounded flex justify-between ${
            props?.packageDetail?.code === "free" && "bg-[#C0FF06]"
          } ${props?.packageDetail?.code === "pro" && "bg-[#FFB500]"} ${
            props?.packageDetail?.code === "Enterprise" && "bg-[#FF5812]"
          }`}
        >
          <div
            className={`my-auto flex flex-row gap-5 ${
              props?.packageDetail?.code === "Enterprise"
                ? "text-white"
                : "text-black"
            }`}
            style={{ fontFamily: "Archia Semibold" }}
          >
            <span className="text-[35.63px]">
              {props?.packageDetail?.code === "free" && "Free"}
              {props?.packageDetail?.code === "pro" && "Starter"}
              {props?.packageDetail?.code === "Enterprise" && "Pro"}
            </span>
            {/* <div className="bg-[#252E3A] px-4 h-5 my-auto flex flex-row text-white rounded-2xl">
              <FaStar className="w-3 h-3 my-auto mr-2 text-[#FFB500]" />
              <span className="my-auto text-[10px]">Most popular</span>
            </div> */}
          </div>
          <div className="flex flex-col leading-tight">
            <div className="text-2xl" style={{ fontFamily: "Archia Semibold" }}>
              {props?.packageDetail?.prices[0]?.currency === "usd" && "$"}
              {props?.packageDetail?.prices[0]?.currency === "eur" && "€"}
              {props?.packageDetail?.prices[0]?.currency === "gbp" && "£"}
              {props?.packageDetail?.prices[0]?.currency === "aud" && "$"}
              {Intl.NumberFormat("en-US", {
                style: "decimal",
                minimumFractionDigits: 0,
              }).format(parseFloat(props?.packageDetail?.prices[0]?.price))}
              {props?.packageDetail?.prices[0]?.currency === "sar" && " SAR"}
            </div>
            <div>per {props?.packageDetail?.prices[0]?.interval}</div>
          </div>
        </div>
      )}
      <div className="mt-10 bg-white rounded-md w-full py-4 px-3 ml-auto mr-auto border text-white relative">
        <div style={{ position: "relative" }}>
          <img
            src={Cardimg}
            alt="Card Image"
            className="w-6 h-6"
            style={{
              position: "absolute",
              top: "50%",
              left: "0px",
              transform: "translateY(-50%)",
            }}
          />
          <CardNumberElement
            className="ml-8"
            options={{
              style: {
                ...inputStyle,
                display: "inline-block",
              },
            }}
          />
          <img
            src={Visa}
            alt="Card Image"
            className="w-8 h-3"
            style={{
              position: "absolute",
              top: "50%",
              right: "0px",
              transform: "translateY(-50%)",
            }}
          />
        </div>
      </div>

      <div className="flex items-center justify-center mb-3 gap-7">
        <div className="mt-6 bg-white rounded-md w-full py-4 px-3 border border-[#B6B6B6] border-borderGray text-white">
          <div style={{ position: "relative" }}>
            <CardExpiryElement
              options={{
                style: {
                  base: inputStyle,
                },
              }}
            />
          </div>
        </div>
        <div className="mt-6 bg-white rounded-md w-full py-4 px-3 border border-[#B6B6B6] border-borderGray text-white">
          <div style={{ position: "relative" }}>
            <CardCvcElement
              options={{
                style: {
                  base: inputStyle,
                },
              }}
            />
          </div>
        </div>
      </div>
      <>
        {!load ? (
          <>
            <div
              className="flex justify-between px-1 mt-7 text-[#252E3A] text-[16px]"
              style={{ fontFamily: "Silka" }}
            >
              <p>Subtotal</p>
              <p>
                {props?.packageDetail?.prices[0]?.currency === "usd" && "$"}
                {props?.packageDetail?.prices[0]?.currency === "eur" && "€"}
                {props?.packageDetail?.prices[0]?.currency === "gbp" && "£"}
                {props?.packageDetail?.prices[0]?.currency === "aud" && "$"}
                {Intl.NumberFormat("en-US", {
                  style: "decimal",
                  minimumFractionDigits: 0,
                }).format(parseFloat(props?.packageDetail?.prices[0]?.price))}
                {props?.packageDetail?.prices[0]?.currency === "sar" && " SAR"}
              </p>
            </div>

            <div
              className="flex justify-between px-1 mt-2 text-[#252E3A] text-[22px]"
              style={{ fontFamily: "Archia Semibold" }}
            >
              <p>Total</p>
              <p>
                {props?.packageDetail?.prices[0]?.currency === "usd" && "$"}
                {props?.packageDetail?.prices[0]?.currency === "eur" && "€"}
                {props?.packageDetail?.prices[0]?.currency === "gbp" && "£"}
                {props?.packageDetail?.prices[0]?.currency === "aud" && "$"}
                {Intl.NumberFormat("en-US", {
                  style: "decimal",
                  minimumFractionDigits: 0,
                }).format(parseFloat(props?.packageDetail?.prices[0]?.price))}
                {props?.packageDetail?.prices[0]?.currency === "sar" && " SAR"}
              </p>
            </div>
          </>
        ) : (
          <>
            <div
              className="flex justify-between px-1 mt-7 text-[#252E3A] text-[16px]"
              style={{ fontFamily: "Silka" }}
            >
              <p>
                <Skeleton width={100} />
              </p>
              <p>
                <Skeleton width={50} />
              </p>
            </div>

            <div
              className="flex justify-between px-1 mt-2 text-[#252E3A] text-[22px]"
              style={{ fontFamily: "Archia Semibold" }}
            >
              <p>
                <Skeleton width={100} />
              </p>
              <p>
                <Skeleton width={50} />
              </p>
            </div>
          </>
        )}
      </>
      <CustomButton
        label="CHECKOUT"
        borderCustom="border border-black text-white"
        paddingY="0.7rem"
        marginTop="mt-8"
        bgColor="#252E3A"
        iconR={FaArrowRightLong}
        noMarginIcon={false}
        autoLeftMargin="ml-auto"
        textMarginBotton="ml-auto"
        hoverBgColor="#C0FF06"
        hoverTextColor="#252E3A"
        LoadingBtn={isLoading}
        loadingText="CHECKING OUT"
        onClickButton={createSubscription}
      />
    </div>
  );
};

export default CheckoutForm;

CheckoutForm.propTypes = {
  package: PropTypes.object,
};
