import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { IoClose } from "react-icons/io5";
import * as XLSX from "xlsx";

const VerifyImports = ({
  verifyModal,
  setVerifyModal,
  tableData,
  setTableData,
  setFile,
  fileName,
  setFileName,
}) => {
  const cancelButtonRef = useRef(null);

  const handleDeleteRow = (index) => {
    const newData = tableData.filter((_, i) => i !== index);
    setTableData(newData);
  };

  const handleUpdateFile = () => {
    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const csvData = XLSX.utils.sheet_to_csv(worksheet);
    const fileBlob = new Blob([csvData], { type: "text/csv" });
    const updatedFile = new File([fileBlob], fileName || "updated_file.csv", {
      type: "text/csv",
    });
    setFile(updatedFile);
    const url = URL.createObjectURL(fileBlob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName || "updated_file.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setVerifyModal(false);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const getMissingFields = () => {
    const missingFields = new Set();
    tableData.forEach((row) => {
      Object.keys(tableData[0]).forEach((key) => {
        if (
          !(key in row) ||
          row[key] === null ||
          row[key] === undefined ||
          row[key] === ""
        ) {
          missingFields.add(key);
        }
      });
    });
    return [...missingFields];
  };

  const missingFields = getMissingFields();

  const hasInvalidEmails = tableData.some((row, index) => {
    const isEmailInvalid = !validateEmail(row.Email);
    const duplicateCount = tableData.reduce((acc, currentRow) => {
      const isDuplicate = JSON.stringify(currentRow) === JSON.stringify(row);
      return isDuplicate ? acc + 1 : acc;
    }, 0);
    return isEmailInvalid || duplicateCount > 1;
  });

  return (
    <Transition.Root show={verifyModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        initialFocus={cancelButtonRef}
        onClose={() => {
          setFile(null);
          setVerifyModal(false);
          setFileName("");
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0 sm:px-4 md:px-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="transform overflow-hidden rounded-lg overflow-y-auto bg-white text-left transition-all sm:my-8 sm:max-md:w-full w-3/4 2xl:w-2/4 ">
                <div className="bg-white">
                  <p className="text-xl font-bold text-black cursor-pointer text-left px-8 pt-5">
                    Verify Candidates
                  </p>
                  <IoClose
                    className="absolute top-3 right-5 z-20 w-5 h-5 cursor-pointer"
                    onClick={() => {
                      setFile(null);
                      setVerifyModal(false);
                      setFileName("");
                    }}
                  />
                </div>
                <hr className="w-full mt-4 md:block sm:hidden bg-gray-500 border-1" />
                <div className="px-4 mt-3">
                  {missingFields.length > 0 && (
                    <div className="px-4 py-2 bg-red-100 border border-red-400 text-red-800 rounded mb-4">
                      <p className="font-semibold">
                        The following fields are missing or incomplete:
                      </p>
                      <ul className="list-disc list-inside">
                        {missingFields.map((field) => (
                          <li key={field}>
                            {field} is required and must not be empty.
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                {tableData.length > 0 && (
                  <div className="px-4 overflow-auto h-[35rem]">
                    <table className="w-full text-xs 2xl:text-sm rounded text-left rtl:text-right mt-5">
                      <thead className="text-sm bg-coalColor text-white uppercase text-left">
                        <tr
                          className="font-normal"
                          style={{ fontFamily: "Archia Semibold" }}
                        >
                          {Object.keys(tableData[0]).map((key) => (
                            <th
                              key={key}
                              className="px-6 py-3 border border-gray-400"
                            >
                              {key}
                            </th>
                          ))}
                          <th
                            className="px-6 py-3 border border-gray-400"
                            style={{ width: "50px" }}
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((row, index) => {
                          const hasMissingValue = Object.keys(
                            tableData[0]
                          ).some(
                            (key) =>
                              !(key in row) ||
                              row[key] === null ||
                              row[key] === undefined ||
                              row[key] === ""
                          );

                          const isEmailInvalid = !validateEmail(row.Email);

                          const duplicateCount = tableData.reduce(
                            (acc, currentRow) => {
                              const isDuplicate =
                                JSON.stringify(currentRow) ===
                                JSON.stringify(row);
                              return isDuplicate ? acc + 1 : acc;
                            },
                            0
                          );

                          const isDuplicateRow =
                            duplicateCount > 1 &&
                            index !==
                              tableData.findIndex(
                                (r) => JSON.stringify(r) === JSON.stringify(row)
                              );

                          const isRowInvalid =
                            hasMissingValue || isEmailInvalid || isDuplicateRow;

                          return (
                            <tr
                              key={index}
                              style={{ fontFamily: "Silka" }}
                              className={
                                isRowInvalid ? "bg-[#FEE2E2]" : "bg-[#DCFCE7]"
                              }
                            >
                              {Object.keys(tableData[0]).map((key) => (
                                <td
                                  key={key}
                                  className="px-4 py-4 border border-gray-400"
                                >
                                  {row[key] || "N/A"}
                                </td>
                              ))}
                              <td className="px-4 py-4 border border-gray-400">
                                <IoClose
                                  className="w-6 h-6 cursor-pointer text-alertRed my-auto mx-auto flex object-cover"
                                  onClick={() => handleDeleteRow(index)}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}

                <div
                  className="flex justify-end p-4"
                  style={{ fontFamily: "Silka" }}
                >
                  <button
                    className="mr-2 px-4 py-2 hover:text-alertRed rounded"
                    onClick={() => {
                      setFile(null);
                      setVerifyModal(false);
                      setFileName("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className={`px-4 py-2 ${
                      missingFields?.length > 0 || hasInvalidEmails
                        ? "bg-[#D3D5D8]"
                        : "bg-coalColor hover:bg-primaryGreen hover:text-coalColor text-white"
                    } rounded`}
                    onClick={handleUpdateFile}
                    disabled={missingFields?.length > 0 || hasInvalidEmails}
                  >
                    Confirm Emails
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default VerifyImports;
