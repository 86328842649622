import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const SkeletonCard = () => (
  <div className="py-2">
    <div className="bg-[#F6F7F7] px-5 border border-[#D3D5D8] p-3 rounded-lg">
      <div className="w-full grid grid-cols-10 gap-4 h-[80px]">
        <div className="col-span-7 my-auto">
          <Skeleton height={24} width="80%" />
        </div>
        <div className="col-span-3 my-auto">
          <Skeleton height={24} width={90} borderRadius={20} />
        </div>
      </div>
      <div className="flex flex-row gap-2 mt-5">
        <Skeleton circle width={16} height={16} />
        <Skeleton width={60} />
      </div>
      <div className="h-[90px] pr-3 text-sm 2xl:mt-3 sm:mt-3">
        <Skeleton count={3} />
      </div>
      <div className="my-auto mt-3">
        <Skeleton height={48} borderRadius={6} />
      </div>
    </div>
  </div>
);
