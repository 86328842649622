import React, { useEffect, useMemo, useRef, useState } from "react";
import Preview from "../../../../Assets/preview.png";
import Klose from "../../../../Assets/klose.png";
import { useQueryClient } from "@tanstack/react-query";
import { getModules } from "./hooks/getModules";
import { getModuleByID } from "./hooks/getModuleByID";
import Loader from "react-loader-spinner";
import "../../../../Components/Loading/Loading8.css";
import { useMutation } from "@tanstack/react-query";
import { updateStep } from "./hooks/updateStep";
import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import styles from "./styling2.module.css";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import "./invite.css";
import { useSelector } from "react-redux";
import King from "../../../../Assets/preee.png";
import { useDispatch } from "react-redux";
import { setNextModuleToFalse } from "../../../../redux/reducers/NextModules/NextModulesSlice";
import SearhBar from "../../../../Dexta_assets/searchBar.png";
import info from "../../../../Dexta_assets/helpIcon.png";
import clock from "../../../../Dexta_assets/clock.png";
import remove from "../../../../Dexta_assets/close_icon.png";
import { Scrollbars } from "react-custom-scrollbars";
import PremiumGeneral from "../../../../Components/Modals/PremiumGeneral";
import d1 from "../../../../Dexta_assets/d1.png";
import d2 from "../../../../Dexta_assets/d2.png";
import d3 from "../../../../Dexta_assets/d3.png";
import d4 from "../../../../Dexta_assets/d4.png";
import close from "../../../../Dexta_assets/closeModal.png";
import { IoAddCircleOutline, IoTrashBin } from "react-icons/io5";
import { FaRegEye } from "react-icons/fa";
import { setBackModuleToFalse } from "../../../../redux/reducers/BackModule/BackModuleSlice";
import { toast, ToastContainer, Zoom } from "react-toastify";

const Modules = (props) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [sectionID, setSectionID] = useState(0);
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const assessment_id = localStorage.getItem("assessment_ID");
  const [searchedValue, setSearchedValue] = useState("");
  const [searchData, setSearchData] = useState("");
  const navigate = useNavigate();
  const levels = ["entry", "mid", "senior"];
  const user_package_check = useSelector(
    (state) => state.packageDetails.setPackage
  );
  const [unmatchedPackageCodes, setUnmatchedPackageCodes] = useState([]);
  const [premiumGeneralOpen, setPremiumGeneral] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [staticLoad, setStaticLoad] = useState(false);
  const next = useSelector((state) => state.nextModule.setNextModule);
  const back = useSelector((state) => state.backModule.back);
  const [testType, setTestType] = useState("suggested");
  const [allData, setAllData] = useState([]);
  const listInnerRef = useRef(null);
  const [paginationInfo, setPaginationInfo] = useState({
    currentTake: 1,
    hasNextPage: true,
  });
  const [currentTake, setCurrentTake] = useState(1);
  const [load, setLoad] = useState(false);
  const [tempSearch, setTempSearch] = useState("");
  //#region search function on job experience
  useEffect(() => {
    if (props.data.experience != "all") {
      const newLevel = levels
        .filter((i) => i != props.data.experience)
        .toString();
      setSearchData(newLevel);
    } else {
      setSearchData("");
    }
  }, [props.data.experience]);
  //#endregion

  //#region calling api to update step
  const { mutate, isLoading: mutateLoad } = useMutation(updateStep, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("assessment");
      dispatch(setNextModuleToFalse(false));
      dispatch(setBackModuleToFalse(false));
      props.setModulesLoading(false);
    },
    onError: (error) => {
      setError(true);
      setErrorMessage(error.response.data.message[0]);
    },
  });

  const { mutate: backMutate, isLoading: backLoad } = useMutation(updateStep, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("assessment");
      props.setselecteditem("general");
      localStorage.setItem("current_module", "general");
      dispatch(setBackModuleToFalse(false));
    },
    onError: (error) => {
      dispatch(setBackModuleToFalse(false));
      props.setselecteditem("general");
      localStorage.setItem("current_module", "general");
    },
  });

  //#endregion

  //#region preview function
  const handleButtonClickPreview = (id) => {
    const url = `/preview-module/${id}`;
    window.open(url, "_blank"); // This opens the link in a new tab
  };
  //#endregion

  //#region function to add module
  const handleAddModule = (id, name, time) => {
    handleModulesIDS(id);
    handleAddModuleNamee(name, time);
    setTimeout(() => {
      setStaticLoad(false);
    }, 1000);
  };
  //#endregion

  //#region next function to proceed to next step
  const handleNext = () => {
    props.setselecteditem("questions");
    localStorage.setItem("current_module", "questions");
    props.setModulesLoading(true);
    let data = {
      content: {
        user: parseInt(localStorage.getItem("CP-USER-ID")),
        sections: props.data?.customQuestion
          ? [...props.data.modulesID, props.data?.customQuestion]
          : props.data.modulesID,
        status: "draft",
        setpTwo: "completed",
        notes: "very nice assessment",
      },
      categoryID:
        parsed.assessment_id != null
          ? parsed.assessment_id
          : parseInt(assessment_id),
    };
    try {
      mutate(data, props.data.categoryID);
    } catch (err) {
      //
    }
  };

  const handleBack = () => {
    let data = {
      content: {
        user: parseInt(localStorage.getItem("CP-USER-ID")),
        sections: props.data?.customQuestion
          ? [...props.data.modulesID, props.data?.customQuestion]
          : props.data.modulesID,
        setpTwo: "completed",
        notes: "very nice assessment",
      },
      categoryID:
        parsed.assessment_id != null
          ? parsed.assessment_id
          : parseInt(assessment_id),
    };
    try {
      backMutate(data, props.data.categoryID);
    } catch (err) {
      //
    }
  };
  useEffect(() => {
    if (next) {
      handleNext();
    }
  }, [next]);

  useEffect(() => {
    if (back) {
      handleBack();
    }
  }, [back]);
  //#endregion

  //#region adding module ids and names to props
  const handleModulesIDS = (id) => {
    if (props.data.modulesID.includes(id)) {
      return;
    }
    const newProps = { ...props.data };
    const newModuleID = newProps.modulesID;
    newModuleID.push(id);
    newProps["modulesID"] = newModuleID;
    props.setData(newProps);
  };
  const handleAddModuleNamee = (name, time = 0) => {
    if (props.data.moduleNames.includes(name)) {
      return;
    }
    props.setData({
      ...props.data,
      moduleNames: [...props.data.moduleNames, name],
      timeMod: props.data.timeMod + parseInt(time),
    });
  };
  //#endregion

  //#region Function to delete module ID and Name
  const handleDeleteModule = (id, name, time) => {
    if (props.data.modulesID.includes(id)) {
      const dataCopy = { ...props.data };
      const moduleID = dataCopy.modulesID;
      const findIndex = moduleID.findIndex((Mid) => Mid === id);
      moduleID.splice(findIndex, 1);
      dataCopy["modulesID"] = moduleID;
      props.setData(dataCopy);
      handleDeleteModuleName(name, time);
    }
  };

  const handleDeleteModuleName = (name, time) => {
    if (props.data.moduleNames.includes(name)) {
      const updatedModules = props.data.moduleNames.filter(
        (moduleId) => moduleId !== name
      );
      props.setData({
        ...props.data,
        moduleNames: updatedModules,
        timeMod: props.data.timeMod - parseInt(time),
      });
    }
  };
  //#endregion

  //#region fetching modules data
  const {
    data,
    error: errorD,
    isLoading,
  } = useQuery(
    [
      "sections",
      props.data.experience,
      props.data.job,
      searchedValue,
      paginationInfo.currentTake,
    ],
    () =>
      getModules(
        props.data.experience,
        props.data.job,
        searchedValue,
        paginationInfo.currentTake
      ),
    {
      keepPreviousData: true, // Preserve previous data while fetching new data
    }
  );

  const {
    data: data_section,
    error: error_section,
    isLoading: loading_section,
  } = useQuery(
    [
      "sections",
      props.data.experience,
      props.data.job,
      tempSearch,
      paginationInfo.currentTake,
    ],
    () =>
      getModules(
        props.data.experience,
        props.data.job,
        tempSearch,
        paginationInfo.currentTake
      )
  );
  function handleButtonClick(event) {
    event.stopPropagation();
  }

  const {
    data: ModuleData,
    error: errorModule,
    isLoading: isLoadingModule,
  } = useQuery(["sections", sectionID], () => getModuleByID(sectionID));
  //#endregion

  //#region pagination on scroll
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        if (data?.meta?.hasNextPage !== false) {
          setLoad(true);
          setPaginationInfo((prevPaginationInfo) => ({
            ...prevPaginationInfo,
            currentTake: prevPaginationInfo.currentTake + 1,
          }));
          setCurrentTake(currentTake + 1);
          setTimeout(() => {
            setLoad(false);
          }, 1000);
        }
      }
    }
  };

  useEffect(() => {
    if (!isLoading && data && paginationInfo.currentTake === 1) {
      setAllData(data?.data?.relatedData);
    } else if (!isLoading && data && paginationInfo.currentTake > 1) {
      setAllData((prevData) => {
        const newData = data?.data?.relatedData || [];
        const filteredData = newData.filter(
          (newItem) => !prevData.some((prevItem) => prevItem.id === newItem.id)
        );
        return [...prevData, ...filteredData];
      });
    }
  }, [data, isLoading, paginationInfo.currentTake]);

  useEffect(() => {
    if (!isLoading) {
      setPaginationInfo({
        currentTake: 1,
        hasNextPage: true,
      });
      setAllData(data?.data?.relatedData || []);
    }
  }, [isLoading, searchedValue]);

  //#endregion

  const handleEmailClick = () => {
    //     const recipient = "support@dexta.io";
    //     const subject = "Request of new module";
    //     const body = `Hi Dexta team,

    // Can you please create a new module which covers [add module details]. This module is for the role [add role name].

    // Thanks`;

    //     const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
    //       subject
    //     )}&body=${encodeURIComponent(body)}`;

    window.open("https://dexta.io/contact", "_blank");
  };

  useEffect(() => {
    props.setData({ ...props.data, fileBulk: null });
  }, []);

  console.log(data_section, "data of props");
  return (
    <div>
      <PremiumGeneral
        premiumGeneralOpen={premiumGeneralOpen}
        setPremiumGeneral={setPremiumGeneral}
      />
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        enableMultiContainer={false}
        limit={1}
      />
      <div className="sm:px-0 md:px-2">
        <div className="bg-white grid sm:grid-cols-1 lg:grid-cols-12 p-6">
          <div className="col-span-3 sm:border-none lg:border-r">
            <h2
              className="text-coalColor mt-3"
              style={{ fontFamily: "Archia Semibold" }}
            >
              Add modules
            </h2>
            <p
              className="mt-5 text-sm w-4/5"
              style={{ fontFamily: "Silka Light" }}
            >
              Your test can include up to <b>5 modules.</b> Scroll and select
              modules on the right. Use different types of modules for the best
              results.
            </p>
            <p className="mt-8 text-sm" style={{ fontFamily: "Silka Light" }}>
              You have added:
            </p>
            <div className="flex mt-5 gap-3 flex-col w-full">
              {props.data.moduleNames.map((moduleName, index) => (
                <div
                  className="sm:pr-0 lg:pr-5"
                  style={{ fontFamily: "Archia Semibold" }}
                >
                  <button
                    key={index}
                    type="button"
                    className={`text-coalColor relative flex good4 border border-[#252E3A] bg-[#C0FF06] focus:outline-none font-bold rounded-lg text-sm w-full align-center py-4 text-left overflow-hidden`}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                    title={moduleName}
                  >
                    <div className="flex items-center justify-center w-full relative">
                      <span className={`truncate px-5 w-5/6 mr-auto`}>
                        {moduleName || `Module ${index + 1}`}
                      </span>
                      <div className="absolute top-0 right-2 ml-4 bottom-0 flex items-center justify-center pr-2">
                        <img
                          src={remove}
                          alt="Cross Icon"
                          className="w-5 h-5"
                          onClick={() => {
                            // Checking if the module name matches any item in relatedData
                            data_section?.data?.relatedData?.forEach((item) => {
                              if (moduleName === item.name) {
                                handleDeleteModule(
                                  item?.id,
                                  item?.name,
                                  item?.time
                                );
                              }
                            });
                            // Checking if the module name matches any item in another array
                            data_section?.data?.other?.forEach(
                              (anotherItem) => {
                                if (moduleName === anotherItem.name) {
                                  handleDeleteModule(
                                    anotherItem?.id,
                                    anotherItem?.name,
                                    anotherItem?.time
                                  );
                                }
                              }
                            );
                          }}
                        />
                      </div>
                    </div>
                  </button>
                </div>
              ))}
              {[...Array(Math.max(5 - props.data.moduleNames.length, 0))].map(
                (_, index) => (
                  <div
                    className="sm:pr-0 lg:pr-5"
                    style={{ fontFamily: "Archia Semibold" }}
                  >
                    <button
                      key={props.data.moduleNames.length + index}
                      type="button"
                      className={`text-[#7C8289] flex good4 border-2 border-[#D3D5D8] bg-[#F6F7F7] px-4 focus:outline-none rounded-lg text-sm w-full align-center py-4 justify-center text-left`}
                    >
                      {`Select module ${
                        props.data.moduleNames.length + index + 1
                      }`}
                    </button>
                  </div>
                )
              )}
            </div>
          </div>
          <div className="col-span-9 lg:px-4">
            <div className="flex lg:justify-between sm:mt-10 lg:mt-0 lg:flex-row sm:flex-col">
              <div className="flex flex-row gap-5 px-2 my-auto">
                <h2
                  className={`${
                    testType === "suggested"
                      ? "bg-coalColor text-primaryGreen rounded-md"
                      : "text-[#7C8289]"
                  } p-3 cursor-pointer`}
                  onClick={() => {
                    if (testType !== "suggested") {
                      setTestType("suggested");
                    }
                  }}
                  style={{ fontFamily: "Archia Semibold" }}
                >
                  Suggested modules
                </h2>
                <h2
                  className={`${
                    testType === "other"
                      ? "bg-coalColor text-primaryGreen rounded-md"
                      : "text-[#7C8289]"
                  } p-3 cursor-pointer`}
                  style={{ fontFamily: "Archia Semibold" }}
                  onClick={() => {
                    if (testType !== "other") {
                      setTestType("other");
                    }
                  }}
                >
                  All modules
                </h2>
              </div>
              <div className="relative sm:mt-4 lg:mt-0 sm:w-full xl:w-1/3 2xl:w-1/4">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <img src={SearhBar} className="w-5 h-5" />
                </div>
                <input
                  type="text"
                  className="bg-gray-50 border focus:ring-0 border-gray-300 text-gray-900 text-sm rounded block w-full pl-10 p-3 px-10"
                  placeholder="Search test module library here"
                  required
                  value={searchedValue}
                  onChange={(e) => setSearchedValue(e.target.value)}
                />
                <div className="flex items-center inset-y-0 right-0 absolute pr-1">
                  <div className="group relative left-10 m-12 flex justify-center">
                    <img
                      src={info}
                      data-tooltip-target="tooltip-default"
                      className="w-5 h-5 cursor-pointer"
                    />
                    <span className="absolute scale-0 right-0 bottom-11 rounded bg-gray-800 left-50 p-2 text-xs w-[20rem] text-white group-hover:scale-100">
                      Type keywords here to search through our test library.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {isLoading ? (
              <div class="loader-container-1">
                <div class="loader-1"></div>
              </div>
            ) : (
              <React.Fragment>
                {testType === "suggested" ? (
                  <>
                    <div
                      className={`mt-5 mb-5 ${
                        !data?.data?.relatedData?.length == 0 && "h-[100vh]"
                      } enable-scrollbar2`}
                    >
                      <Scrollbars
                        autoHide
                        style={{ width: "100%", height: "90%" }}
                      >
                        <div
                          className="lg:grid-cols-2 xl:grid-cols-2 px-4 lg:pr-4 2xl:grid-cols-3 gap-4 md:grid-cols-2 grid sm:grid-cols-1"
                          ref={listInnerRef}
                          onScroll={onScroll}
                        >
                          {data?.data?.relatedData
                            ?.slice()
                            ?.sort((a, b) => a.name.localeCompare(b.name))
                            .map((i, j) => {
                              return (
                                <React.Fragment key={j}>
                                  {(i.status === "active" ||
                                    i?.status === "coming_soon") && (
                                    <div className="" key={j}>
                                      <div className="py-2">
                                        <div
                                          className={`bg-[#F6F7F7] px-5 border relative border-[#D3D5D8] corner-ribbon p-3 rounded-lg`}
                                        >
                                          {i?.status === "coming_soon" && (
                                            <div className="ribbon-container">
                                              <div className="ribbon">
                                                <span class="ribbon-text">
                                                  Coming soon
                                                </span>
                                              </div>
                                            </div>
                                          )}
                                          <div className="w-full grid grid-cols-10 gap-4 h-[80px]">
                                            <div className="col-span-7 my-auto">
                                              <h1
                                                className={`text-lg font-bold good3 ${
                                                  i?.status === "coming_soon" &&
                                                  "pl-10"
                                                }  my-auto`}
                                                style={{
                                                  fontFamily: "Archia Semibold",
                                                }}
                                              >
                                                {i.name}
                                              </h1>
                                            </div>
                                            <div
                                              className="my-auto col-span-3 ml-auto"
                                              style={{ fontFamily: "Silka" }}
                                            >
                                              {i?.experience === "Beginner" && (
                                                <div className="inline-flex items-center border border-[#0B5B23] text-black py-1 px-3 rounded-full text-[12px]">
                                                  <span className="text-center">
                                                    Beginner
                                                  </span>
                                                </div>
                                              )}
                                              {i?.experience ===
                                                "Intermediate" && (
                                                <div className="inline-flex items-center border border-[#FFB500] text-black  w-[90px] md:w-[100px] py-1 rounded-full text-[12px]">
                                                  <span className="text-center mx-auto">
                                                    Intermediate
                                                  </span>
                                                </div>
                                              )}
                                              {i?.experience === "Advanced" && (
                                                <div className="inline-flex items-center border border-[#FF5812] text-black  w-[90px] md:w-[100px] py-1 rounded-full text-[12px]">
                                                  <span className="text-center mx-auto">
                                                    Advanced
                                                  </span>
                                                </div>
                                              )}
                                              {i?.experience === "General" && (
                                                <div className="inline-flex items-center border border-coalColor text-black  xl:w-[72px] 2xl:w-[90px] md:w-[100px] py-1 rounded-full xl:text-[10px] 2xl:text-[12px]">
                                                  <span className="text-center mx-auto">
                                                    All levels
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                          <div className="flex flex-row gap-2 mt-5">
                                            <img
                                              src={clock}
                                              className="w-4 h-4"
                                            />
                                            <p
                                              className="text-xs text-coalColor"
                                              style={{
                                                fontFamily: "Silka Light",
                                              }}
                                            >
                                              {i?.time} mins
                                            </p>
                                          </div>
                                          <div className="h-[90px] pr-3 text-sm 2xl:mt-3 sm:mt-3">
                                            <div className="iosClamping h-[80px] overflow-hidden">
                                              <div
                                                className={
                                                  styles["html-content"]
                                                }
                                              >
                                                {ReactHtmlParser(i.notes)}
                                              </div>
                                            </div>
                                            {i.notes.length > 100 && (
                                              <div
                                                className="underline"
                                                style={{
                                                  color: "#252E3A",
                                                  cursor: "pointer",
                                                  fontFamily: "Silka",
                                                }}
                                                onClick={() => {
                                                  setSectionID(i.id);
                                                  setDropdownOpen(true);
                                                }}
                                              >
                                                {" "}
                                                Read more
                                              </div>
                                            )}
                                          </div>
                                          <div className="grid grid-cols-2 mt-5">
                                            <div className="my-auto">
                                              <a
                                                className="text-sm underline"
                                                style={{ fontFamily: "Silka" }}
                                                href={`/preview-module/${i.id}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                Sample questions
                                              </a>{" "}
                                            </div>
                                            <div>
                                              {props.data?.modulesID.includes(
                                                i?.id
                                              ) ? (
                                                <button
                                                  className="inline-flex items-center w-full px-4 py-3 justify-center border border-coalColor bg-[#C0FF06] text-coalColor text-sm font-bold rounded-md"
                                                  style={{
                                                    fontFamily: "Silka",
                                                  }}
                                                  onClick={() =>
                                                    handleDeleteModule(
                                                      i?.id,
                                                      i?.name,
                                                      i?.time
                                                    )
                                                  }
                                                >
                                                  Remove
                                                </button>
                                              ) : (
                                                <div className="relative">
                                                  {i?.packages &&
                                                  i?.packages.length > 0 ? (
                                                    i?.packages.filter(
                                                      (jj) =>
                                                        jj.code ===
                                                        user_package_check
                                                    ).length > 0 ||
                                                    i?.packages.filter(
                                                      (jj) => jj.code === "free"
                                                    ).length > 0 ? (
                                                      // First condition: user_package_check is present in packages array
                                                      <button
                                                        className={`inline-flex border  items-center justify-center px-4 w-full py-3 ${
                                                          props.data.modulesID
                                                            .length === 5 ||
                                                          i?.status ===
                                                            "coming_soon"
                                                            ? "bg-[#D3D5D8] text-[#7C8289] disabled cursor-not-allowed"
                                                            : "border-coalColor bg-coalColor hover:bg-primaryGreen/90 text-white hover:text-coalColor"
                                                        } text-sm font-medium rounded-md relative`}
                                                        disabled={
                                                          props.data.modulesID
                                                            .length === 5 ||
                                                          i?.status ===
                                                            "coming_soon"
                                                        }
                                                        onClick={() => {
                                                          setStaticLoad(true);
                                                          handleAddModule(
                                                            i?.id,
                                                            i?.name,
                                                            i.time
                                                          );
                                                        }}
                                                        style={{
                                                          fontFamily:
                                                            "Archia Semibold",
                                                        }}
                                                      >
                                                        {false ? (
                                                          <span className="flex items-center justify-center">
                                                            <Loader
                                                              type="Oval"
                                                              color="white"
                                                              height={20}
                                                              width={20}
                                                            />
                                                            <span className="ml-2">
                                                              Adding...
                                                            </span>
                                                          </span>
                                                        ) : (
                                                          "Add module"
                                                        )}
                                                      </button>
                                                    ) : (
                                                      // Second condition: user_package_check is not present in packages array
                                                      <button
                                                        className={`inline-flex border  items-center justify-center px-4 w-full py-3 ${
                                                          props.data.modulesID
                                                            .length === 5 ||
                                                          i?.status ===
                                                            "coming_soon"
                                                            ? "bg-[#D3D5D8] text-[#7C8289] disabled cursor-not-allowed"
                                                            : "border-coalColor bg-coalColor hover:bg-primaryGreen/90 text-white hover:text-coalColor"
                                                        } text-sm font-medium rounded-md relative`}
                                                        disabled={
                                                          props.data.modulesID
                                                            .length === 5 ||
                                                          i?.status ===
                                                            "coming_soon"
                                                        }
                                                        onClick={() => {
                                                          const unmatchedCodes =
                                                            i?.packages
                                                              .filter(
                                                                (pkg) =>
                                                                  pkg.code !==
                                                                  user_package_check
                                                              )
                                                              .map(
                                                                (pkg) =>
                                                                  pkg.code
                                                              );
                                                          setUnmatchedPackageCodes(
                                                            (prevCodes) => [
                                                              ...prevCodes,
                                                              ...unmatchedCodes,
                                                            ]
                                                          );
                                                          setPremiumGeneral(
                                                            true
                                                          );
                                                        }}
                                                        style={{
                                                          fontFamily:
                                                            "Archia Semibold",
                                                        }}
                                                      >
                                                        {false ? (
                                                          <span className="flex items-center justify-center">
                                                            <Loader
                                                              type="Oval"
                                                              color="white"
                                                              height={20}
                                                              width={20}
                                                            />
                                                            <span className="ml-2">
                                                              Adding...
                                                            </span>
                                                          </span>
                                                        ) : (
                                                          "Add module"
                                                        )}
                                                        {/* Premium icon */}
                                                        <span className="absolute top-0 right-0 -mt-2 -mr-2">
                                                          <img
                                                            src={King}
                                                            alt="Premium Icon"
                                                            className="w-6 h-6"
                                                          />
                                                        </span>
                                                      </button>
                                                    )
                                                  ) : (
                                                    // Third condition: packages array is empty
                                                    <button
                                                      className={`inline-flex border  items-center justify-center px-4 w-full py-3 ${
                                                        props.data.modulesID
                                                          .length === 5
                                                          ? "bg-[#D3D5D8] text-[#7C8289] disabled cursor-not-allowed"
                                                          : "border-coalColor bg-coalColor hover:bg-primaryGreen/90 text-white hover:text-coalColor"
                                                      } text-sm font-medium rounded-md relative`}
                                                      disabled={
                                                        props.data.modulesID
                                                          .length === 5
                                                      }
                                                      onClick={() => {
                                                        setStaticLoad(true);
                                                        handleAddModule(
                                                          i?.id,
                                                          i?.name,
                                                          i.time
                                                        );
                                                      }}
                                                      style={{
                                                        fontFamily:
                                                          "Archia Semibold",
                                                      }}
                                                    >
                                                      {false ? (
                                                        <span className="flex items-center justify-center">
                                                          <Loader
                                                            type="Oval"
                                                            color="white"
                                                            height={20}
                                                            width={20}
                                                          />
                                                          <span className="ml-2">
                                                            Adding...
                                                          </span>
                                                        </span>
                                                      ) : (
                                                        "Add module"
                                                      )}
                                                    </button>
                                                  )}
                                                  {props.data.modulesID
                                                    .length === 5 && (
                                                    <div className="tooltip2 w-[180px] text-center">
                                                      You cannot add more than 5
                                                      modules
                                                    </div>
                                                  )}
                                                  {i?.status ===
                                                    "coming_soon" && (
                                                    <div className="tooltip2 w-[180px] text-center">
                                                      You cannot add this module
                                                    </div>
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </React.Fragment>
                              );
                            })}
                        </div>
                      </Scrollbars>
                    </div>
                    {data?.data?.relatedData?.length == 0 && (
                      <div className="px-6 mt-3">
                        <div className="border border-[#FF5812] py-4 rounded">
                          <p
                            className="text-alertRed text-center"
                            style={{ fontFamily: "Silka" }}
                          >
                            No modules found. If you would like us to prioritise
                            creation of a new module, please let us know by {""}
                            <span
                              style={{ fontFamily: "Silka Bold" }}
                              className="font-bold cursor-pointer"
                              onClick={handleEmailClick}
                            >
                              clicking here
                            </span>
                            . Our typical turnaround time for a module is 1-2
                            weeks
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div
                      className={`mt-5 ${
                        !data?.data?.other?.length == 0 && "h-[100vh]"
                      } mb-5 enable-scrollbar2`}
                    >
                      <Scrollbars
                        autoHide
                        style={{ width: "100%", height: "90%" }}
                      >
                        <div
                          className="lg:grid-cols-2 xl:grid-cols-2 px-4 lg:pr-4 2xl:grid-cols-3 gap-4 md:grid-cols-2 grid sm:grid-cols-1"
                          ref={listInnerRef}
                          onScroll={onScroll}
                        >
                          {data?.data?.other
                            ?.slice()
                            ?.sort((a, b) => a.name.localeCompare(b.name))
                            .map((i, j) => {
                              return (
                                <React.Fragment key={j}>
                                  {(i.status === "active" ||
                                    i?.status === "coming_soon") && (
                                    <div className="" key={j}>
                                      <div className="py-2">
                                        <div
                                          className={`bg-[#F6F7F7] px-5 border relative border-[#D3D5D8] corner-ribbon p-3 rounded-lg`}
                                        >
                                          {i?.status === "coming_soon" && (
                                            <div className="ribbon-container">
                                              <div className="ribbon">
                                                <span class="ribbon-text">
                                                  Coming soon
                                                </span>
                                              </div>
                                            </div>
                                          )}
                                          <div className="w-full grid grid-cols-10 gap-4 h-[80px]">
                                            <div className="col-span-7 my-auto">
                                              <h1
                                                className={`text-lg font-bold good3 ${
                                                  i?.status === "coming_soon" &&
                                                  "pl-10"
                                                }  my-auto`}
                                                style={{
                                                  fontFamily: "Archia Semibold",
                                                }}
                                              >
                                                {i.name}
                                              </h1>
                                            </div>
                                            <div
                                              className="my-auto col-span-3 ml-auto"
                                              style={{ fontFamily: "Silka" }}
                                            >
                                              {i?.experience === "Beginner" && (
                                                <div className="inline-flex items-center border border-[#0B5B23] text-black py-1 px-3 rounded-full text-[12px]">
                                                  <span className="text-center">
                                                    Beginner
                                                  </span>
                                                </div>
                                              )}
                                              {i?.experience ===
                                                "Intermediate" && (
                                                <div className="inline-flex items-center border border-[#FFB500] text-black  w-[90px] md:w-[100px] py-1 rounded-full text-[12px]">
                                                  <span className="text-center mx-auto">
                                                    Intermediate
                                                  </span>
                                                </div>
                                              )}
                                              {i?.experience === "Advanced" && (
                                                <div className="inline-flex items-center border border-[#FF5812] text-black  w-[90px] md:w-[100px] py-1 rounded-full text-[12px]">
                                                  <span className="text-center mx-auto">
                                                    Advanced
                                                  </span>
                                                </div>
                                              )}
                                              {i?.experience === "General" && (
                                                <div className="inline-flex items-center border border-coalColor text-black  xl:w-[72px] 2xl:w-[90px] md:w-[100px] py-1 rounded-full xl:text-[10px] 2xl:text-[12px]">
                                                  <span className="text-center mx-auto">
                                                    All levels
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                          <div className="flex flex-row gap-2 mt-5">
                                            <img
                                              src={clock}
                                              className="w-4 h-4"
                                            />
                                            <p
                                              className="text-xs text-coalColor"
                                              style={{
                                                fontFamily: "Silka Light",
                                              }}
                                            >
                                              {i?.time} mins
                                            </p>
                                          </div>
                                          <div className="h-[90px] pr-3 text-sm 2xl:mt-3 sm:mt-3 ">
                                            <div className="iosClamping h-[80px] overflow-hidden">
                                              <div
                                                className={
                                                  styles["html-content"]
                                                }
                                              >
                                                {ReactHtmlParser(i.notes)}
                                              </div>
                                            </div>
                                            {i.notes.length > 100 && (
                                              <div
                                                className="underline"
                                                style={{
                                                  color: "#252E3A",
                                                  cursor: "pointer",
                                                  fontFamily: "Silka",
                                                }}
                                                onClick={() => {
                                                  setSectionID(i.id);
                                                  setDropdownOpen(true);
                                                }}
                                              >
                                                {" "}
                                                Read more
                                              </div>
                                            )}
                                          </div>
                                          <div className="grid grid-cols-2 mt-5">
                                            <div className="my-auto">
                                              <a
                                                className="text-sm underline"
                                                style={{ fontFamily: "Silka" }}
                                                href={`/preview-module/${i.id}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                Sample questions
                                              </a>{" "}
                                            </div>
                                            <div>
                                              {props.data?.modulesID.includes(
                                                i?.id
                                              ) ? (
                                                <button
                                                  className="inline-flex items-center w-full  px-4 py-3 justify-center border border-coalColor bg-[#C0FF06] text-coalColor text-sm font-bold rounded-md"
                                                  style={{
                                                    fontFamily: "Silka",
                                                  }}
                                                  onClick={() =>
                                                    handleDeleteModule(
                                                      i?.id,
                                                      i?.name,
                                                      i?.time
                                                    )
                                                  }
                                                >
                                                  Remove
                                                </button>
                                              ) : (
                                                <div className="relative">
                                                  {i?.packages &&
                                                  i?.packages.length > 0 ? (
                                                    i?.packages.filter(
                                                      (jj) =>
                                                        jj.code ===
                                                        user_package_check
                                                    ).length > 0 ||
                                                    i?.packages.filter(
                                                      (jj) => jj.code === "free"
                                                    ).length > 0 ? (
                                                      // First condition: user_package_check is present in packages array
                                                      <button
                                                        className={`inline-flex border  items-center justify-center px-4 w-full py-3 ${
                                                          props.data.modulesID
                                                            .length === 5 ||
                                                          i?.status ===
                                                            "coming_soon"
                                                            ? "bg-[#D3D5D8] text-[#7C8289] disabled cursor-not-allowed"
                                                            : "border-coalColor bg-coalColor hover:bg-primaryGreen/90 text-white hover:text-coalColor"
                                                        } text-sm font-medium rounded-md relative`}
                                                        disabled={
                                                          props.data.modulesID
                                                            .length === 5 ||
                                                          i?.status ===
                                                            "coming_soon"
                                                        }
                                                        onClick={() => {
                                                          setStaticLoad(true);
                                                          handleAddModule(
                                                            i?.id,
                                                            i?.name,
                                                            i.time
                                                          );
                                                        }}
                                                        style={{
                                                          fontFamily:
                                                            "Archia Semibold",
                                                        }}
                                                      >
                                                        {false ? (
                                                          <span className="flex items-center justify-center">
                                                            <Loader
                                                              type="Oval"
                                                              color="white"
                                                              height={20}
                                                              width={20}
                                                            />
                                                            <span className="ml-2">
                                                              Adding...
                                                            </span>
                                                          </span>
                                                        ) : (
                                                          "Add module"
                                                        )}
                                                      </button>
                                                    ) : (
                                                      // Second condition: user_package_check is not present in packages array
                                                      <button
                                                        className={`inline-flex border  items-center justify-center px-4 w-full py-3 ${
                                                          props.data.modulesID
                                                            .length === 5 ||
                                                          i?.status ===
                                                            "coming_soon"
                                                            ? "bg-[#D3D5D8] text-[#7C8289] disabled cursor-not-allowed"
                                                            : "border-coalColor bg-coalColor hover:bg-primaryGreen/90 text-white hover:text-coalColor"
                                                        } text-sm font-medium rounded-md relative`}
                                                        disabled={
                                                          props.data.modulesID
                                                            .length === 5 ||
                                                          i?.status ===
                                                            "coming_soon"
                                                        }
                                                        onClick={() => {
                                                          const unmatchedCodes =
                                                            i?.packages
                                                              .filter(
                                                                (pkg) =>
                                                                  pkg.code !==
                                                                  user_package_check
                                                              )
                                                              .map(
                                                                (pkg) =>
                                                                  pkg.code
                                                              );
                                                          setUnmatchedPackageCodes(
                                                            (prevCodes) => [
                                                              ...prevCodes,
                                                              ...unmatchedCodes,
                                                            ]
                                                          );
                                                          setPremiumGeneral(
                                                            true
                                                          );
                                                        }}
                                                        style={{
                                                          fontFamily:
                                                            "Archia Semibold",
                                                        }}
                                                      >
                                                        {false ? (
                                                          <span className="flex items-center justify-center">
                                                            <Loader
                                                              type="Oval"
                                                              color="white"
                                                              height={20}
                                                              width={20}
                                                            />
                                                            <span className="ml-2">
                                                              Adding...
                                                            </span>
                                                          </span>
                                                        ) : (
                                                          "Add module"
                                                        )}
                                                        {/* Premium icon */}
                                                        <span className="absolute top-0 right-0 -mt-2 -mr-2">
                                                          <img
                                                            src={King}
                                                            alt="Premium Icon"
                                                            className="w-6 h-6"
                                                          />
                                                        </span>
                                                      </button>
                                                    )
                                                  ) : (
                                                    // Third condition: packages array is empty
                                                    <button
                                                      className={`inline-flex border  items-center justify-center px-4 w-full py-3 ${
                                                        props.data.modulesID
                                                          .length === 5
                                                          ? "bg-[#D3D5D8] text-[#7C8289] disabled cursor-not-allowed"
                                                          : "border-coalColor bg-coalColor hover:bg-primaryGreen/90 text-white hover:text-coalColor"
                                                      } text-sm font-medium rounded-md relative`}
                                                      disabled={
                                                        props.data.modulesID
                                                          .length === 5
                                                      }
                                                      onClick={() => {
                                                        setStaticLoad(true);
                                                        handleAddModule(
                                                          i?.id,
                                                          i?.name,
                                                          i.time
                                                        );
                                                      }}
                                                      style={{
                                                        fontFamily:
                                                          "Archia Semibold",
                                                      }}
                                                    >
                                                      {false ? (
                                                        <span className="flex items-center justify-center">
                                                          <Loader
                                                            type="Oval"
                                                            color="white"
                                                            height={20}
                                                            width={20}
                                                          />
                                                          <span className="ml-2">
                                                            Adding...
                                                          </span>
                                                        </span>
                                                      ) : (
                                                        "Add module"
                                                      )}
                                                    </button>
                                                  )}
                                                  {props.data.modulesID
                                                    .length === 5 && (
                                                    <div className="tooltip2 w-[180px] text-center">
                                                      You cannot add more than 5
                                                      modules
                                                    </div>
                                                  )}
                                                  {i?.status ===
                                                    "coming_soon" && (
                                                    <div className="tooltip2 w-[180px] text-center">
                                                      You cannot add this module
                                                    </div>
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </React.Fragment>
                              );
                            })}
                        </div>
                      </Scrollbars>
                    </div>
                    {/* {!data?.data?.other || data?.data?.other?.length === 0 || data?.data?.other.every(i => i.status === "pending") && ( */}
                    {data?.data?.other?.length === 0 && (
                      <div className="px-6 mt-3">
                        <div className="border border-[#FF5812] py-4 rounded">
                          <p
                            className="text-alertRed text-center"
                            style={{ fontFamily: "Silka" }}
                          >
                            No modules found. If you would like us to prioritise
                            creation of a new module, please let us know by {""}
                            <span
                              style={{ fontFamily: "Silka Bold" }}
                              className="font-bold cursor-pointer"
                              onClick={handleEmailClick}
                            >
                              clicking here
                            </span>
                            . Our typical turnaround time for a module is 1-2
                            weeks
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
        {dropdownOpen && (
          <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div
              className="fixed inset-0 z-10 overflow-y-auto"
              onClick={() => setDropdownOpen(false)}
            >
              <div className="flex items-start justify-end  text-center">
                <div
                  className="relative transform overflow-x-scroll no-scrollbar bg-[#F8F8F8] text-left shadow-xl transition-all sm:w-full md:w-1/2"
                  onClick={handleButtonClick}
                >
                  <div className="bg-[#F8F8F8]  h-screen px-4 sm:px-6 no-scrollbar overflow-auto pb-8">
                    {isLoadingModule ? (
                      <div class="loader-container-1 col-span-6">
                        <div class="loader-1"></div>
                      </div>
                    ) : (
                      <>
                        <div
                          className="flex justify-end sticky top-0 "
                          style={{ paddingTop: "100px" }}
                        >
                          <img
                            src={close}
                            className="w-8 h-8 cursor-pointer"
                            onClick={() => setDropdownOpen(false)}
                          />
                        </div>
                        <div className="sm:px-0 md:px-0 lg:px-0 xl:px-10">
                          <div className="mt-10 flex sm:flex-col md:flex-col lg:flex-row md:gap-5">
                            {props.data?.modulesID.includes(ModuleData?.id) ? (
                              <button
                                className="inline-flex items-center px-4 py-3 bg-primaryGreen hover:bg-coalColor hover:text-white text-coalColor border border-coalColor text-sm font-medium rounded-md"
                                style={{ fontFamily: "Silka" }}
                                onClick={() => {
                                  handleDeleteModule(
                                    ModuleData?.id,
                                    ModuleData?.name,
                                    ModuleData?.time
                                  );
                                }}
                              >
                                <IoTrashBin
                                  alt="Add Transaction Icon"
                                  className="w-4 h-4 mr-4 icon-image "
                                />
                                Remove module
                              </button>
                            ) : (
                              <button
                                className="inline-flex items-center px-4  sm:mt-4 md:mt-0 py-3 bg-coalColor hover:bg-primaryGreen hover:text-black text-white border border-coalColor text-sm font-medium rounded-md"
                                style={{ fontFamily: "Silka" }}
                                onClick={() => {
                                  setStaticLoad(true);
                                  handleAddModule(
                                    ModuleData?.id,
                                    ModuleData?.name,
                                    ModuleData?.time
                                  );
                                }}
                              >
                                <IoAddCircleOutline
                                  alt="Add Transaction Icon"
                                  className={`w-5 h-5 mr-4 icon-image`}
                                />
                                {false ? (
                                  <span className="flex items-center justify-center">
                                    <Loader
                                      type="Oval"
                                      color="white"
                                      height={20}
                                      width={20}
                                    />
                                    <span className="ml-2">Adding...</span>
                                  </span>
                                ) : (
                                  "Add module"
                                )}
                              </button>
                            )}
                            <button
                              className="inline-flex items-center px-4  sm:mt-4 md:mt-0 py-3 bg-coalColor hover:bg-primaryGreen hover:text-black text-white border border-coalColor text-sm font-medium rounded-md"
                              style={{ fontFamily: "Silka" }}
                              onClick={() => {
                                handleButtonClickPreview(ModuleData?.id);
                              }}
                            >
                              <FaRegEye
                                src={Preview}
                                alt="preview"
                                className={`w-5 h-5 mr-4 icon-image`}
                              />
                              {false ? (
                                <span className="flex items-center justify-center">
                                  <Loader
                                    type="Oval"
                                    color="white"
                                    height={20}
                                    width={20}
                                  />
                                  <span className="ml-2">Previewing...</span>
                                </span>
                              ) : (
                                "Preview module"
                              )}
                            </button>
                          </div>
                          <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 bg-coalColor mt-10 rounded-lg">
                            <div className="p-2 pl-5 border-r py-5">
                              <img src={d1} className="w-8 h-8" />
                              <p className="text-lg text-white mt-1">
                                {ModuleData?.module_type === "multiple_choice"
                                  ? "Multiple Choice"
                                  : "Case Study"}
                              </p>
                              <p className="text-xs text-white">
                                {ModuleData?.category?.categoryName}
                              </p>
                            </div>
                            <div className="p-2 pl-5 border-r py-5">
                              <img src={d2} className="w-8 h-8" />
                              <p className="text-lg text-white mt-1">Time</p>
                              <p className="text-xs text-white">
                                {ModuleData?.time} mins
                              </p>
                            </div>
                            <div className="p-2 pl-5 border-r py-5">
                              <img src={d3} className="w-8 h-8" />
                              <p className="text-lg text-white mt-1">
                                Language
                              </p>
                              <p className="text-xs text-white">English</p>
                            </div>
                            <div className="p-2 pl-5 border-r py-5">
                              <img src={d4} className="w-8 h-8" />
                              <p className="text-lg text-white mt-1">Level</p>
                              <p className="text-xs text-white">
                                {ModuleData?.experience === "General"
                                  ? "All levels"
                                  : ModuleData?.experience}
                              </p>
                            </div>
                          </div>
                          <h1
                            className="mt-4 text-xl font-bold"
                            style={{ fontFamily: "Archia Bold" }}
                          >
                            {ModuleData?.name}
                          </h1>{" "}
                          <h1
                            className="mt-10 text-xl font-bold"
                            style={{ fontFamily: "Archia Bold" }}
                          >
                            Covered Skills
                          </h1>
                          <div
                            className={styles["html-content"]}
                            style={{ fontFamily: "Silka" }}
                          >
                            {ReactHtmlParser(ModuleData?.covering_skills)}
                          </div>
                          <h1
                            className="mt-10 text-xl font-bold"
                            style={{ fontFamily: "Archia Bold" }}
                          >
                            This module is relevant for
                          </h1>
                          <div
                            className="text-[#767676]"
                            style={{ fontFamily: "Silka" }}
                          >
                            <div className={styles["html-content"]}>
                              {ReactHtmlParser(ModuleData?.test_relevent_for)}
                            </div>
                          </div>
                          <h1
                            className="mt-10 text-xl font-bold"
                            style={{ fontFamily: "Archia Bold" }}
                          >
                            Description
                          </h1>
                          <div
                            className={styles["html-content"]}
                            style={{ fontFamily: "Silka" }}
                          >
                            {ReactHtmlParser(ModuleData?.notes)}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Modules;
