import React, { useState, useEffect, useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ToastContainer, Zoom } from "react-toastify";
import TextField from "../../../../Components/Dexta/TextField/TextField";
import CustomButton from "../../../../Components/CustomButton/CustomButton";
import MainLogo from "../../../../Dexta_assets/signup_girl.png";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaInfoCircle } from "react-icons/fa";
import { handlePassword } from "../../../../Components/Regex/Regex";
import { createcandidateAccount } from "../../hooks/createcandidateAccount";
import { experience_years, gender } from "../../../../data/mapData";
import { getAllJobs } from "../../../Profile/MyAssessments/Assessments-main/hooks/getAlljobs";
import { IoIosArrowDown, IoMdClose } from "react-icons/io";
import Scrollbars from "react-custom-scrollbars";
import { IoAddCircle } from "react-icons/io5";
import useOutsideClick from "../../../../Components/OutsideClick/OutsideClick";

const RegisterCandidate = () => {
  const queryClient = useQueryClient();
  const email = localStorage.getItem("CP-CANDIDATE-EMAIL");
  const navigate = useNavigate();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [dispatchLoad, setDispatchLoad] = useState(false);
  const [gen, setGender] = useState("");
  const [gendrop, setGendrop] = useState(false);
  const genderRef = useRef(null);
  const [focusedField, setFocusedField] = useState(null);
  const [otherGender, setOtherGender] = useState("");
  const [genderError, setGenderError] = useState(false);
  const [yearError, setYearError] = useState(false);
  const [field, setField] = useState("");
  const [fieldDrop, setFieldDrop] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const listInnerRef = useRef(null);
  const [allData, setAllData] = useState([]);
  const fieldRef = useRef(null);
  const [yearexp, setyearexp] = useState("Years of experience in field");
  const [expDropdown, setExpDropdown] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState({
    capital: false,
    number: false,
    length: false,
    special: false,
  });
  const [paginationInfo, setPaginationInfo] = useState({
    currentTake: 1,
    hasNextPage: true,
    pageSize: 10,
  });
  const expRef = useRef(null);
  const [primaryError, setPrimaryError] = useState(false);
  const [restrictSubmit, setRestrictSubmit] = useState(false);
  const [BarPercentage, setBarPercentage] = useState(0);

  //#region calculating trues for bar percentage
  useEffect(() => {
    const trueCount = Object.values(passwordValidation).filter(Boolean).length;
    const newBarPercentage = trueCount * 25;
    setBarPercentage(newBarPercentage);
  }, [passwordValidation]);
  //#endregion

  //#region password hide and unhide
  function togglePasswordVisibility() {
    setIsPasswordVisible((prevState) => !prevState);
  }
  //#endregion

  //#region checking password conditions
  const checkingPassword = (text) => {
    const { hasNumber, hasCapital, hasLength, hasSpecialCharacter } =
      handlePassword(text);
    setPasswordValidation({
      number: hasNumber,
      capital: hasCapital,
      length: hasLength,
      special: hasSpecialCharacter,
    });
  };
  //#endregion

  //#region validation with formik
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: email,
      password: "",
      firstName: "",
      lastName: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Email Required"),
      password: Yup.string().required("Password Required"),
      firstName: Yup.string().required("First Name Required"),
      lastName: Yup.string().required("Last Name Required"),
    }),

    onSubmit: (values) => {
      // Reset error states
      setGenderError(false);
      setPrimaryError(false);
      setYearError(false);

      let data = JSON.stringify({
        email: values?.email,
        password: values?.password,
        firstName: values?.firstName,
        lastName: values?.lastName,
        candidateMeta: {
          gender: gen !== "Other (Please specify)" ? gen : otherGender,
          experience_in: field,
          experience_years: yearexp,
        },
      });
      try {
        if (!restrictSubmit && BarPercentage >= 100) {
          mutate(data);
        }
      } catch (error) {
        // onError will automatically detect
      }
    },
  });

  console.log(validation?.errors);

  const { mutate, isLoading } = useMutation(createcandidateAccount, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("/auth/sign-up");
      // const { accessToken, user } = response.data;
      validation.values.email = "";
      validation.values.password = "";
      setTimeout(() => {
        toast.success("Candidate account has been created successfully", {
          toastId: "copy-success",
        });
      }, 500);
      if (response?.user?.isUserOnboard === false) {
        navigate(`/candidate/profile`);
      } else {
        navigate(`/candidate/dashboard`);
      }
    },
    onError: (error) => {
      const errorMessage = error?.response?.data?.message;
      if (Array.isArray(errorMessage)) {
        setTimeout(() => {
          toast.success(errorMessage[0], {
            toastId: "copy-success",
          });
        }, 500);
      } else if (typeof errorMessage === "string") {
        setTimeout(() => {
          toast.success(errorMessage, {
            toastId: "copy-success",
          });
        }, 500);
      } else {
        setTimeout(() => {
          toast.success("An error occurred.", {
            toastId: "copy-success",
          });
        }, 500);
      }
    },
  });
  //#endregion

  //#region taking user to top of screen
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //#endregion

  //#region preview function
  const handleTermsAndPrivacy = (type) => {
    if (type === "terms") {
      const url = `/candidate/candidate-terms`;
      window.open(url, "_blank");
    } else {
      const url = `/candidate-recruiter/privacy-policy`;
      window.open(url, "_blank");
    }
  };
  //#endregion

  //#region Handle Focus and Blur
  const handleFocus = (fieldName) => {
    setFocusedField(fieldName);
  };

  const handleBlur = () => {
    setFocusedField(null);
  };
  //#endregion

  console.log(gen);

  //#region clearing text errors
  useEffect(() => {
    if (
      (gen !== "" && gen !== "Other (Please specify)") ||
      (gen === "Other (Please specify)" && otherGender !== "")
    ) {
      setGenderError(false);
    }
    if (field !== "") {
      setPrimaryError(false);
    }
    if (yearexp !== "") {
      setYearError(false);
    }
  }, [gen, field, yearexp, otherGender]);
  //#endregion

  //#region handle pagination of jobs
  const { data: jobsData, isLoading: jobsLoading } = useQuery(
    ["jobs", paginationInfo.currentTake, field],
    () => getAllJobs(paginationInfo.currentTake, field),
    {
      keepPreviousData: true,
    }
  );

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight && !jobsLoading) {
        if (
          jobsData?.meta?.hasNextPage !== false &&
          allData.length >= paginationInfo.currentTake * paginationInfo.pageSize
        ) {
          setPaginationInfo((prevPaginationInfo) => ({
            ...prevPaginationInfo,
            currentTake: prevPaginationInfo.currentTake + 1,
          }));
        }
      }
    }
  };
  const handleScrollFrame = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      onScroll();
    }
  };

  useEffect(() => {
    if (!jobsLoading && jobsData && paginationInfo.currentTake === 1) {
      setAllData(jobsData?.data);
    } else if (!jobsLoading && jobsData && paginationInfo.currentTake > 1) {
      setAllData((prevData) => {
        const newData = jobsData?.data || [];
        const filteredData = newData.filter(
          (newItem) => !prevData.some((prevItem) => prevItem.id === newItem.id)
        );
        const updatedData = [...prevData, ...filteredData];

        // Update paginationInfo.hasNextPage based on whether all data is fetched
        if (filteredData.length < paginationInfo.pageSize) {
          setPaginationInfo((prevPaginationInfo) => ({
            ...prevPaginationInfo,
            hasNextPage: false,
          }));
        }

        return updatedData;
      });
    }
  }, [jobsData, jobsLoading, paginationInfo.currentTake, field]);

  useEffect(() => {
    if (paginationInfo?.currentTake > 1) {
      setAllData([]);
      setPaginationInfo((prev) => ({
        ...prev,
        currentTake: 1,
        hasNextPage: true,
      }));
    }
  }, [field]);
  //#endregion

  //#region Calculate height based on the number of items
  const calculateHeight = () => {
    const itemHeight = 40;
    const maxVisibleItems = 8;

    // Number of items in allData
    const itemsCount = allData.length > 0 ? allData.length : 1; // Ensure there's height for the "No results" message

    // Determine the height based on the items count
    const height =
      itemsCount > maxVisibleItems
        ? itemHeight * maxVisibleItems
        : itemHeight * itemsCount;

    return height;
  };
  //#endregion

  //#region Dropdown close on outside click -> Country
  useOutsideClick(
    [
      {
        ref: genderRef,
        excludeClasses: [".genderClass"],
        excludeIds: ["gender"],
      },
      {
        ref: fieldRef,
        excludeClasses: [".fieldclass"],
        excludeIds: ["field"],
      },
      {
        ref: expRef,
        excludeClasses: [".expClass"],
        excludeIds: ["expYear"],
      },
    ],
    (ref) => {
      if (ref === genderRef) setGendrop(false);
      if (ref === fieldRef) setFieldDrop(false);
      if (ref === expRef) setExpDropdown(false);
    }
  );

  const errorCheck = () => {
    let hasError = false;

    if (
      gen === "" ||
      (gen === "Other (Please specify)" && otherGender === "")
    ) {
      setGenderError(true);
      hasError = true;
    }
    if (field === "") {
      setPrimaryError(true);
      hasError = true;
    }
    if (yearexp === "" || yearexp === "Years of experience in field") {
      setYearError(true);
      hasError = true;
    }

    console.log(hasError, "hase errro?");
    if (hasError) {
      setRestrictSubmit(true);
    } else {
      setRestrictSubmit(false);
    }
  };

  //#endregion
  document.title = "Register | Dexta";
  return (
    <React.Fragment>
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="sm:grid-cols-1 lg:grid-cols-2 grid bg-bgAuth h-screen">
        <div className="bg-black sm:hidden lg:block">
          <img src={MainLogo} className="w-full lg:h-full lg:object-cover" />
        </div>
        <div className="bg-bgAuth flex justify-center flex-col md:pb-8 2xl:pb-[15rem]">
          <div className="sm:w-5/6 lg:w-2/3 mx-auto">
            <h1
              className="md:text-xl 2xl:text-2xl text-left"
              style={{ fontFamily: "Archia Semibold" }}
            >
              Create your Dexta profile
            </h1>
            <div style={{ fontFamily: "Silka" }} className="text-sm mt-6">
              <span>
                Enter the email address to which you received a test invite.
              </span>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                errorCheck();
                return false;
              }}
            >
              <div className="mt-6">
                <div className="grid grid-cols-2 gap-3 h-[4.7rem]">
                  <div className="h-[4.7rem]">
                    <TextField
                      name="firstName"
                      id="firstName"
                      type="name"
                      placeholder="Enter first name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.firstName || ""}
                    />
                    {validation.touched.firstName &&
                    validation.errors.firstName ? (
                      <div className="ml-1">
                        <p className="text-rose-500 fade-in-text-validations sm:text-xs md:text-sm">
                          {validation.errors.firstName}
                        </p>
                      </div>
                    ) : null}
                  </div>
                  <div className="h-[4.7rem]">
                    <TextField
                      name="lastName"
                      id="lastName"
                      type="name"
                      placeholder="Enter last name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.lastName || ""}
                    />
                    {validation.touched.lastName &&
                    validation.errors.lastName ? (
                      <div className="ml-w-1/2 ml-1">
                        <p className="text-rose-500 fade-in-text-validations sm:text-xs md:text-sm">
                          {validation.errors.lastName}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="h-[4.7rem]">
                <TextField
                  name="email"
                  id="email"
                  type="email"
                  placeholder="Enter your email"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  marginTop="mt-2"
                  value={validation.values.email || ""}
                />
                {validation.touched.email && validation.errors.email ? (
                  <div className="ml-1">
                    <p className="text-rose-500 fade-in-text-validations sm:text-xs md:text-sm">
                      {validation.errors.email}
                    </p>
                  </div>
                ) : null}
              </div>
              <div className="h-[4.7rem]">
                <TextField
                  name="password"
                  id="password"
                  type={isPasswordVisible ? "text" : "password"}
                  placeholder="Password"
                  onChange={(e) => {
                    validation.handleChange(e);
                    checkingPassword(e.target.value);
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.password || ""}
                  marginTop="mt-2"
                  onClick={togglePasswordVisibility}
                  passwordIcon={true}
                  isPasswordVisible={isPasswordVisible}
                />
                {validation.touched.password && validation.errors.password ? (
                  <div className="ml-1">
                    <p className="text-rose-500 fade-in-text-validations sm:text-xs md:text-sm">
                      {validation.errors.password}
                    </p>
                  </div>
                ) : null}
              </div>
              <div className="h-[4.7rem] relative">
                <button
                  id="gender"
                  className={`border font-medium rounded-lg text-base flex justify-between ${
                    gen === "" ? "text-[#999999]" : "text-coalColor"
                  } px-3 w-full py-4 bg-white text-center flex items-center mt-2`}
                  style={{
                    fontFamily: "Silka",
                  }}
                  type="button"
                  onClick={() => setGendrop(!gendrop)}
                  onFocus={() => handleFocus("gender")}
                  onBlur={(event) => {
                    handleFocus("");
                    if (
                      gen === "" ||
                      (gen === "Other (Please specify)" && otherGender === "")
                    ) {
                      setGenderError(true);
                    }
                  }}
                >
                  {gen != "" ? (
                    gen === "Other (Please specify)" ? (
                      <input
                        placeholder="Please specify"
                        autoFocus
                        value={otherGender}
                        onChange={(e) => setOtherGender(e.target.value)}
                      />
                    ) : (
                      gen
                    )
                  ) : (
                    "Gender"
                  )}
                  <IoIosArrowDown className="mr-1" />
                </button>
                {gendrop && (
                  <div
                    id="education-drop"
                    className="genderClass absolute z-40 border border-coalColor right-0 top-full h-auto overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full"
                    style={{ fontFamily: "Silka" }}
                  >
                    {gender?.map((i) => (
                      <ul
                        key={i.title}
                        className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                        ref={genderRef}
                        onClick={() => {
                          setGender(i?.title);
                          setGendrop(false);
                        }}
                      >
                        <li>
                          <a className="block px-5 py-2 text-sm font-medium">
                            {i.title}
                          </a>
                        </li>
                      </ul>
                    ))}
                  </div>
                )}
                {genderError ? (
                  <p
                    className="text-rose-500 text-xs ml-2 fade-in-text-candidate"
                    style={{ fontFamily: "Silka" }}
                  >
                    Gender Required
                  </p>
                ) : null}
              </div>
              <div className="h-[4.7rem] relative">
                <input
                  id="field"
                  className={`border border-[#D3D5D8] mt-2 text-coalColor focus-within:border focus-within:border-coalColor rounded-md px-3 text-left w-full py-4`}
                  value={field}
                  style={{ fontFamily: "Silka" }}
                  onChange={(e) => {
                    setField(e.target.value);
                    setSearchTerm(e.target.value);
                    setFieldDrop(true);
                    setPrimaryError(false);
                  }}
                  onFocus={() => handleFocus("role")}
                  onBlur={(event) => {
                    handleFocus("");
                    if (field === "") {
                      setPrimaryError(true);
                    }
                    // Delay closing to allow clicks on the dropdown
                    setTimeout(() => {
                      setFieldDrop(false);
                    }, 200);
                  }}
                  onClick={() => setFieldDrop(!fieldDrop)}
                  placeholder="Most relevant experience"
                />
                <div className="absolute top-1/2 right-4 my-auto transform -translate-y-1/2 flex flex-row ">
                  <IoIosArrowDown
                    className={`${
                      field === "" ? "text-[#999999]" : "text-coalColor"
                    }`}
                  />
                </div>
                {fieldDrop && (
                  <div
                    className={`fieldclass absolute z-40 border enable-scrollbar2 border-coalColor right-0 top-full  overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2`}
                    id="experienceDropdown"
                    ref={listInnerRef}
                    style={{
                      fontFamily: "Silka",
                      height: calculateHeight(),
                    }}
                  >
                    <Scrollbars
                      style={{
                        width: "100%",
                        height: "100%",
                        overflowX: "hidden",
                      }}
                      onScrollFrame={handleScrollFrame}
                      hideTracksWhenNotNeeded
                    >
                      {allData?.map((item, index) => (
                        <ul
                          key={index}
                          className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                          ref={fieldRef}
                          onClick={() => {
                            setField(item?.name);
                            setFieldDrop(false);
                          }}
                        >
                          <li>
                            <a className="block px-5 py-2 text-sm font-medium">
                              {item.name}
                            </a>
                          </li>
                        </ul>
                      ))}
                      <>
                        {field !== "" &&
                          !allData.some((item) => item.name === field) && (
                            <ul
                              className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                              ref={fieldRef}
                              onClick={() => {
                                setFieldDrop(false);
                              }}
                            >
                              <li className="flex flex-row">
                                <a className="block px-5 py-2 text-sm font-medium">
                                  Add "{field}"
                                </a>
                                <IoAddCircle className="w-5 h-5 ml-auto my-auto" />
                              </li>
                            </ul>
                          )}
                      </>
                    </Scrollbars>
                  </div>
                )}
                {primaryError ? (
                  <p
                    className="text-rose-500 text-xs ml-2 fade-in-text-candidate"
                    style={{ fontFamily: "Silka" }}
                  >
                    Most relevant experience Required
                  </p>
                ) : null}
              </div>
              <div className="h-[4.7rem] relative">
                <input
                  id="expYear"
                  type="button"
                  className={`border border-[#D3D5D8] bg-white cursor-pointer mt-3 ${
                    yearexp === "Years of experience in field"
                      ? "text-[#999999]"
                      : "text-coalColor"
                  }  focus-within:border focus-within:border-coalColor rounded-md px-3 text-left w-full py-4`}
                  value={yearexp}
                  style={{ fontFamily: "Silka" }}
                  onChange={(e) => {
                    setyearexp(e.target.value);
                    setExpDropdown(true);
                  }}
                  onFocus={() => handleFocus("yearsxp")}
                  onBlur={(event) => {
                    handleFocus("");
                    if (
                      yearexp === "" ||
                      yearexp === "Years of experience in field"
                    ) {
                      setYearError(true);
                    }
                  }}
                  onClick={() => setExpDropdown(!expDropdown)}
                />
                <div className="absolute top-1/2 right-4 mt-1.5 transform -translate-y-1/2 flex flex-row ">
                  <IoIosArrowDown
                    className={`${
                      yearexp === "Years of experience in field"
                        ? "text-[#999999]"
                        : "text-coalColor"
                    } `}
                  />
                </div>

                {expDropdown && (
                  <div
                    id="education-drop"
                    className="expClass absolute z-40 enable-scrollbar2 border border-coalColor right-0 top-full h-80 overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                    style={{ fontFamily: "Silka" }}
                  >
                    <Scrollbars
                      autoHide
                      style={{ width: "100%", height: "100%" }}
                    >
                      {experience_years?.map((i) => (
                        <ul
                          key={i.title}
                          className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                          ref={expRef}
                          onClick={() => {
                            setyearexp(i?.title);
                            setExpDropdown(false);
                          }}
                        >
                          <li>
                            <a className="block px-5 py-2 text-sm font-medium">
                              {i.title}
                            </a>
                          </li>
                        </ul>
                      ))}
                    </Scrollbars>
                  </div>
                )}
                {yearError ? (
                  <p
                    className="text-rose-500 text-xs ml-2 fade-in-text-candidate"
                    style={{ fontFamily: "Silka" }}
                  >
                    Years of experience Required
                  </p>
                ) : null}
              </div>
              <ul
                class="max-w-md text-sm mt-6 pl-2 text-black list-inside space-y-1"
                style={{ fontFamily: "Silka" }}
              >
                <li class="flex items-center gap-2 rtl:space-x-reverse">
                  {passwordValidation.number ? (
                    <svg
                      class="flex-shrink-0 w-3.5 h-3.5 fade-in-image text-green-700 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 16 12"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M1 5.917 5.724 10.5 15 1.5"
                      />
                    </svg>
                  ) : (
                    <FaInfoCircle
                      className="w-4 h-4 fade-in-image"
                      color="#252E3A"
                    />
                  )}
                  <span>contains at least one number</span>
                </li>
                <li class="flex items-center gap-2">
                  {passwordValidation.capital ? (
                    <svg
                      class="flex-shrink-0 w-3.5 h-3.5 text-green-700 fade-in-image"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 16 12"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M1 5.917 5.724 10.5 15 1.5"
                      />
                    </svg>
                  ) : (
                    <FaInfoCircle
                      className="w-4 h-4 fade-in-image"
                      color="#252E3A"
                    />
                  )}
                  contains at least one upper character
                </li>
                <li class="flex items-center gap-2">
                  {passwordValidation.special ? (
                    <svg
                      class="flex-shrink-0 w-3.5 h-3.5 text-green-700 fade-in-image"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 16 12"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M1 5.917 5.724 10.5 15 1.5"
                      />
                    </svg>
                  ) : (
                    <FaInfoCircle
                      className="w-4 h-4 fade-in-image"
                      color="#252E3A"
                    />
                  )}
                  contains at least one special character
                </li>
                <li class="flex items-center gap-2">
                  {passwordValidation?.length ? (
                    <svg
                      class="flex-shrink-0 w-3.5 h-3.5 text-green-700 fade-in-image"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 16 12"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M1 5.917 5.724 10.5 15 1.5"
                      />
                    </svg>
                  ) : (
                    <FaInfoCircle
                      className="w-4 h-4 fade-in-image"
                      color="#252E3A"
                    />
                  )}
                  contains at least 12 characters
                </li>
              </ul>
              <div
                className="flex flex-row p-2 mt-6"
                style={{ fontFamily: "Silka" }}
              >
                <input
                  type="checkbox"
                  required
                  className="my-auto default:ring-2 ... accent-coalColor border-2 border-black w-4 h-4"
                />
                <p className="ml-2 sm:text-xs  md:text-sm text-coalColor">
                  I have read and accepted the{" "}
                  <b
                    className="text-coalColor font-medium cursor-pointer"
                    style={{ fontFamily: "Silka Bold" }}
                    onClick={() => handleTermsAndPrivacy("terms")}
                  >
                    candidate terms
                  </b>{" "}
                  and{" "}
                  <b
                    className="text-coalColor font-medium cursor-pointer"
                    style={{ fontFamily: "Silka Bold" }}
                    onClick={() => handleTermsAndPrivacy("policy")}
                  >
                    Privacy Policy
                  </b>
                </p>
              </div>
              <CustomButton
                label="Sign up"
                borderCustom="border border-black text-white"
                paddingY="0.7rem"
                hoverBgColor="#C0FF06"
                hoverTextColor="#252E3A"
                marginTop="mt-4"
                bgColor="#252E3A"
                iconR={FaArrowRightLong}
                noMarginIcon={false}
                autoLeftMargin="ml-auto"
                textMarginBotton="ml-auto"
                LoadingBtn={isLoading || dispatchLoad}
                loadingText="Signing up"
              />
            </form>
            <span
              className="text-coalColor text-sm text-center mt-8"
              style={{ fontFamily: "Silka" }}
            >
              Already have an account?{" "}
              <span
                style={{ fontFamily: "Silka Bold" }}
                className="text-coalColor font-medium cursor-pointer"
                onClick={() => navigate("/candidate/login")}
              >
                Log in here
              </span>
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RegisterCandidate;
