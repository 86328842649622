export const options = [
  {
    id: 1,
    title: "This is not an opportune time to invest in mobile home parks.",
  },
  {
    id: 2,
    title: "Now is the right time to think of investing in mobile home parks.",
  },
  {
    id: 3,
    title: "Gradually mobile home parks are concentrating to city centers.",
  },
  {
    id: 4,
    title:
      "Nowadays, most investors are up-to-date on mobile home parks' potential.",
  },
];

export const screen_selection = [
  {
    title: "Welcome screen",
  },
  {
    title: "Webcam screen",
  },
  {
    title: "Question screen",
  },
  {
    title: "Feedback screen",
  },
];

export const highestEducation = [
  {
    title: "Some High School",
  },
  {
    title: "High School Diploma / GED",
  },
  {
    title: "College",
  },
  {
    title: "Associate Degree",
  },
  {
    title: "Bachelor's Degree",
  },
  {
    title: "Master's Degree or Higher",
  },
];

export const experiences = [
  { title: "Software Engineer" },
  { title: "Data Scientist" },
  { title: "Product Manager" },
  { title: "UX Designer" },
  { title: "Frontend Developer" },
  { title: "Backend Developer" },
  { title: "Full Stack Developer" },
  { title: "DevOps Engineer" },
  { title: "Graphic Designer" },
  { title: "UI Designer" },
  { title: "Network Engineer" },
  { title: "Marketing Manager" },
  { title: "Sales Associate" },
  { title: "Business Analyst" },
  { title: "Financial Analyst" },
  { title: "Human Resources Manager" },
  { title: "Project Manager" },
  { title: "Quality Assurance Analyst" },
  { title: "Content Writer" },
  { title: "Customer Support Representative" },
  { title: "Operations Manager" },
  { title: "Accountant" },
  { title: "System Administrator" },
  { title: "UX Researcher" },
  { title: "Product Designer" },
  { title: "Machine Learning Engineer" },
  { title: "Database Administrator" },
  { title: "Business Development Manager" },
  { title: "Mobile App Developer" },
  { title: "Technical Writer" },
  { title: "Social Media Manager" },
  { title: "Network Administrator" },
  { title: "Data Analyst" },
  { title: "IT Manager" },
  { title: "Software Architect" },
  { title: "Sales Manager" },
  { title: "Digital Marketing Specialist" },
  { title: "Content Strategist" },
  { title: "Web Developer" },
  { title: "Customer Success Manager" },
  { title: "UX/UI Developer" },
  { title: "Financial Advisor" },
  { title: "Operations Coordinator" },
  { title: "Public Relations Specialist" },
  { title: "E-commerce Manager" },
  { title: "Business Consultant" },
  { title: "Legal Counsel" },
  { title: "Market Research Analyst" },
  { title: "Account Manager" },
  { title: "Software Tester" },
  { title: "Database Analyst" },
  { title: "Systems Analyst" },
  { title: "Network Security Analyst" },
  { title: "Customer Service Manager" },
  { title: "IT Support Specialist" },
  { title: "Frontend Designer" },
  { title: "IT Consultant" },
  { title: "Data Engineer" },
  { title: "Product Owner" },
  { title: "UX Research Assistant" },
  { title: "UI Developer" },
  { title: "Game Developer" },
  { title: "Financial Planner" },
  { title: "Operations Analyst" },
  { title: "Public Relations Manager" },
  { title: "E-commerce Specialist" },
  { title: "Business Manager" },
  { title: "Corporate Counsel" },
  { title: "Market Analyst" },
  { title: "Account Executive" },
  { title: "Test Engineer" },
  { title: "Database Administrator" },
  { title: "Systems Administrator" },
  { title: "Network Security Specialist" },
  { title: "Customer Support Manager" },
  { title: "IT Specialist" },
  { title: "Frontend Developer" },
  { title: "IT Analyst" },
  { title: "Data Scientist Assistant" },
  { title: "UI/UX Designer" },
  { title: "Mobile App Developer" },
  { title: "Financial Analyst Assistant" },
  { title: "Operations Assistant" },
  { title: "Public Relations Coordinator" },
  { title: "E-commerce Coordinator" },
  { title: "Business Analyst Assistant" },
  { title: "Legal Assistant" },
  { title: "Market Research Assistant" },
  { title: "Account Coordinator" },
  { title: "Software Quality Assurance Analyst" },
  { title: "Database Developer" },
  { title: "Systems Developer" },
  { title: "Network Security Developer" },
  { title: "Customer Support Representative" },
  { title: "IT Coordinator" },
  { title: "Frontend Developer Assistant" },
  { title: "IT Assistant" },
  { title: "Data Scientist Intern" },
  { title: "UI/UX Designer Assistant" },
  { title: "Mobile App Developer Assistant" },
  { title: "Financial Analyst Intern" },
  { title: "Operations Intern" },
  { title: "Public Relations Intern" },
  { title: "E-commerce Intern" },
  { title: "Business Analyst Intern" },
  { title: "Legal Intern" },
  { title: "Market Research Intern" },
  { title: "Account Manager Assistant" },
  { title: "Software Developer Assistant" },
  { title: "Systems Developer Assistant" },
  { title: "Network Security Developer Assistant" },
];

export const gender = [
  {
    title: "Women",
  },
  {
    title: "Man",
  },
  {
    title: "Nonbinary/Other",
  },
  {
    title: "Prefer not to answer",
  },
];

export const ethnicity = [
  {
    title: "Arab",
  },
  {
    title: "Asian",
  },
  {
    title: "Black, Caribbean, or African",
  },
  {
    title: "Hispanic or Latin American",
  },
  {
    title: "Mixed or multiple ethnic groups",
  },
  {
    title: "Pacific Islander",
  },
  {
    title: "Some other ethnicity",
  },
  {
    title: "White",
  },
];

export const language = [
  {
    title: "English",
  },
  {
    title: "Danish",
  },
  {
    title: "Dutch",
  },
  {
    title: "French",
  },
  {
    title: "German",
  },
  {
    title: "Italian",
  },
  {
    title: "Norwegian",
  },
  {
    title: "polish",
  },
  {
    title: "Portuguese (Brazil)",
  },
  {
    title: "Spanish",
  },
  {
    title: "Swedish",
  },
  {
    title: "Other",
  },
  {
    title: "Prefer not to answer",
  },
];

export const stepsCandidates = [
  {
    title: "Edit Profile",
    element: "#setting1",
    intro: (
      <div>
        <p>Welcome to your profile page!</p>
        <p style={{ marginTop: "8px" }}>
          Here, you can update your personal information, change your profile
          picture, and manage your account settings
        </p>
        <p style={{ marginTop: "8px" }}>
          To get started, follow the steps below:
        </p>
        <ul className="list-disc px-4" style={{ marginTop: "8px" }}>
          <li>Click on your profile picture to upload a new one, if desired</li>
          <li>
            Update your first name, last name, and phone number in the
            respective fields
          </li>
          <li>
            Change your email address by clicking on the edit button next to it
          </li>
          <li>
            Update your password by clicking on the edit button next to it
          </li>
        </ul>
        <p style={{ marginTop: "8px" }}>
          Once you've made your desired changes, don't forget to save them by
          clicking the 'Save Changes' button
        </p>
      </div>
    ),
  },
  {
    title: "Notification Preferences",
    element: "#setting2",
    intro: (
      <div>
        <p>
          Here, you can customise how you receive notifications about candidate
          tests
        </p>
        <p style={{ marginTop: "8px" }}>
          You have two types of notifications available:
        </p>
        <ul className="list-disc px-4">
          <li>
            <label htmlFor="notification1">
              Email me every time a candidate completes a test
            </label>
          </li>
          <li>
            <label htmlFor="notification2">
              Email me every day with a summary of candidates that completed a
              test
            </label>
          </li>
        </ul>
        <p style={{ marginTop: "8px" }}>
          You'll receive the daily summary at 12:00 PM (GMT+0)
        </p>
        <p style={{ marginTop: "8px" }}>
          Choose the options that best suit your preferences, and click the
          'Save Changes' button to apply them
        </p>
      </div>
    ),
  },
  {
    title: "Company Details & Test Colors",
    element: "#setting3",
    intro: (
      <div>
        <p>
          Here, you can customise your company's information and choose colors
          for your test pages
        </p>
        <p style={{ marginTop: "8px" }}>
          To get started, follow the steps below:
        </p>
        <ul className="list-disc px-4">
          <li>
            Upload your company logo by clicking on the 'Upload Logo' button
          </li>
          <li>Enter your company name in the provided field</li>
          <li>Select your country from the dropdown menu</li>
          <li>
            Choose primary and secondary colors for your test pages using the
            color pickers
          </li>
          <li>
            Preview the test pages with the selected colors by clicking on the
            'Preview' button
          </li>
        </ul>
        <p style={{ marginTop: "8px" }}>
          Once you're satisfied with your changes, don't forget to save them by
          clicking the 'Save Changes' button
        </p>
      </div>
    ),
  },
  {
    title: "Team Management",
    element: "#setting4",
    intro: (
      <div>
        <p>
          Here, you can manage your team members and their access to test
          creation:
        </p>
        <p style={{ marginTop: "8px" }}>
          To get started, follow the steps below:
        </p>
        <ul className="list-disc px-4">
          <li>
            <b>Add Team Members:</b> You can decide whether a member will be
            able to view or edit tests, and control access to other editors and
            viewers
          </li>
          <li>
            <b>Send Email Invitations:</b> Each added team member will receive
            an email invitation to register their accounts
          </li>
        </ul>
        <p style={{ marginTop: "8px" }}>
          Once registered, team members can collaborate with you in managing
          candidate evaluations.
        </p>
      </div>
    ),
  },
  {
    title: "Subscription & Billing",
    element: "#setting5",
    intro: (
      <div>
        <p>
          Here, you can view your subscribed plan, explore upgrade options, and
          manage your billing details
        </p>
        <p style={{ marginTop: "8px" }}>
          To get started, follow the steps below:
        </p>
        <ul className="list-disc px-4">
          <li>
            View your current subscribed plan to see its features and
            limitations
          </li>
          <li>Explore upgrade options to see plans with more features</li>
          <li>Review your billing history to track past payments</li>
          <li>
            Manage your billing address and payment method for future
            transactions
          </li>
        </ul>
        <p style={{ marginTop: "8px" }}>
          Once you've reviewed your subscribed plan and updated your billing
          information, make sure to save any changes if applicable
        </p>
      </div>
    ),
  },
  {
    intro: (
      <div>
        <p>
          Your product tour is now complete. If you have any other questions,
          we're here to help. Message us by{" "}
          <a
            href="https://dexta.io/contact"
            target="_blank"
            className="font-bold cursor-pointer underline"
          >
            clicking here
          </a>
        </p>
      </div>
    ),
  },
];

export const aspectRatios = [
  { label: "16:9", value: 16 / 9 },
  { label: "9:3", value: 9 / 2.5 },
  { label: "4:3", value: 4 / 3 },
  { label: "4:1", value: 4 / 1 },
  { label: "3:1", value: 3 / 1 },
];
