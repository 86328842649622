import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ".././auth.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { ToastContainer, Zoom } from "react-toastify";
import TextField from "../../../../Components/Dexta/TextField/TextField";
import Loader from "react-loader-spinner";
import { forgetEmail } from "../../hooks/forgetEmail";
import queryString from "query-string";
import { FaArrowRightLong } from "react-icons/fa6";
import MainLogo from "../../../../Dexta_assets/signup_girl.png";
import CustomButton from "../../../../Components/CustomButton/CustomButton";

const EmailSent = () => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const parseOptions = { decode: false };
  const parsed = queryString.parse(location.search, parseOptions);

  //#region validation with formik
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Email Required"),
    }),
    onSubmit: (values) => {
      let data = JSON.stringify({
        email: values?.email,
      });
      try {
        mutate(data);
      } catch (error) {
        //onError will automatically detect
      }
    },
  });
  //#endregion

  //#region login with candidate
  const { mutate, isLoading } = useMutation(forgetEmail, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("/auth/forgot-password");
    },
    onError: (error) => {
      const errorMessage = error?.response?.data?.message;
      if (Array.isArray(errorMessage)) {
        setTimeout(() => {
          toast.error(errorMessage[0], {
            toastId: "copy-success",
          });
        }, 500);
      } else if (typeof errorMessage === "string") {
        setTimeout(() => {
          toast.error(errorMessage, {
            toastId: "copy-success",
          });
        }, 500);
      } else {
        toast.error("An error occurred.", {
          toastId: "copy-success",
        });
      }
    },
  });
  //#endregion

  //#region opening emailbox
  const handleOpenEmailInboxButtonClick = () => {
    // Constructing the mailto link without any additional parameters
    const mailtoLink = "mailto:";

    // Opening the default email client
    window.location.href = mailtoLink;
  };
  //#endregion

  //#region api call for forget passwowrd
  const { mutate: mutateEmail, isLoading: emailLoading } = useMutation(
    forgetEmail,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries("/auth/forgot-password");
        if (response.message === "Reset password email sent") {
          setTimeout(() => {
            toast.success("Email Sent", {
              toastId: "copy-success",
            });
          }, 500);
        }
      },
      onError: (error) => {
        const errorMessage = error?.response?.data?.message;
        if (Array.isArray(errorMessage)) {
          setTimeout(() => {
            toast.error(errorMessage[0], {
              toastId: "copy-success",
            });
          }, 500);
        } else if (typeof errorMessage === "string") {
          setTimeout(() => {
            toast.error(errorMessage, {
              toastId: "copy-success",
            });
          }, 500);
        } else {
          toast.error("An error occurred.", {
            toastId: "copy-success",
          });
        }
      },
    }
  );
  //#endregion

  //#region Submitting Email
  const handleEmailSubmit = () => {
    let data = JSON.stringify({
      email: parsed?.email,
    });
    try {
      mutateEmail(data);
    } catch (error) {}
  };
  //#endregion

  return (
    <>
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="sm:grid-cols-1 lg:grid-cols-2 grid bg-bgAuth h-screen">
        <div className="bg-black sm:hidden lg:block">
          <img src={MainLogo} className="w-full lg:h-full  lg:object-cover" />
        </div>
        <div className="bg-bgAuth flex justify-center flex-col md:pb-40">
          <div className="sm:w-5/6 lg:w-2/3 mx-auto">
            <h1
              className="text-2xl text-left"
              style={{ fontFamily: "Archia Semibold" }}
            >
              Check your email
            </h1>
            <p
              className="sm:text-xs md:text-sm mt-5 text-black"
              style={{ fontFamily: "Silka" }}
            >
              We sent a password reset link to
            </p>
            <p>{parsed?.email}</p>
            <CustomButton
              label="Open Email App"
              borderCustom="border border-black text-white"
              paddingY="0.7rem"
              hoverBgColor="#C0FF06"
              hoverTextColor="#252E3A"
              marginTop="mt-4"
              bgColor="#252E3A"
              iconR={FaArrowRightLong}
              noMarginIcon={false}
              autoLeftMargin="ml-auto"
              textMarginBotton="ml-auto"
              onClickButton={handleOpenEmailInboxButtonClick}
            />
            <span
              className="text-coalColor text-sm text-center"
              style={{ fontFamily: "Silka" }}
            >
              Didn’t receive the email?{" "}
              <span
                className="text-coalColor font-medium cursor-pointer"
                style={{ fontFamily: "Silka Bold" }}
                onClick={handleEmailSubmit}
              >
                Click to resend
              </span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailSent;
