import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NavbarPreviews from "./Navbar/Navbar";
import { FaCheck, FaCircle } from "react-icons/fa";
import Clock from "react-clock";
import Checkmark from "../../../../Dexta_assets/checkmark.png";
import CheckmarkGray from "../../../../Dexta_assets/checkmark_grey.png";

const TimerScreen = () => {
  const previewData = useSelector((state) => state.preview.setPreview);
  const [isHovered, setIsHovered] = useState(false);
  const [value, setValue] = useState(new Date());
  const [time, setTime] = useState(10);
  const [isIntervalRunning, setIsIntervalRunning] = useState(true);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    user_email: "",
  });

  //#region Timer for test
  useEffect(() => {
    let interval;
    if (isIntervalRunning) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    }

    if (time === 0) {
      window.opener = null;
      window.open("", "_self");
      window.close();
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [time, isIntervalRunning]);

  //#endregion

  //#region useEffect for clock
  useEffect(() => {
    const interval = setInterval(() => setValue(new Date()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  //#endregion

  //#region Styling of continue button
  const buttonStyle = {
    background: isHovered ? previewData[1] : previewData[1],
    transition: "background-color 0.1s, transform 0.1s",
    transform: isHovered ? "scale(1.02)" : "scale(1)",
  };

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };
  //#endregion

  //#region closing tab on onClick
  const closeTab = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };
  //#endregion

  return (
    <>
      <NavbarPreviews
        previewData={previewData}
        fixed={false}
        marginTop={true}
      />
      <div className="">
        <div className="mx-auto lg:container">
          <div className="flex justify-center xl:max-xxl:mt-10 h-screen sm:max-md:px-4">
            <div className="m-auto sm:max-md:w-full sm:w-5/6 md:w-4/5 2xl:w-3/4 px-2 rounded-md bg-white relative overflow-hidden">
              <div className="rounded-lg">
                <div className="bg-white sm:p-4 md:p-10 grid md:grid-cols-2">
                  <div className="flex flex-col justify-center">
                    <h1
                      className="text-2xl text-left"
                      style={{ fontFamily: "Archia Bold" }}
                    >
                      Investment Analysis and Strategy
                    </h1>
                    <div className="mt-8">
                      <b
                        className="text-coalColor font-bold mt-1"
                        style={{ fontFamily: "Silka" }}
                      >
                        The actual test starts now.
                      </b>{" "}
                    </div>
                    <div className="flex mt-5">
                      <div className="border py-3 px-3 rounded-md border-coalColor">
                        <p className="text-[#767676] gap-2 flex flex-row">
                          {Array.from(
                            {
                              length: 5,
                            },
                            (_, index) => {
                              if (index < 3 - 1) {
                                // For completed steps, show checkmarks
                                return (
                                  <FaCheck
                                    key={index}
                                    className="text-green-500 w-5 h-5 my-auto"
                                  />
                                );
                              } else if (index === 3 - 1) {
                                // For the current step, show the animated primary green circle
                                return (
                                  <img
                                    src={Checkmark}
                                    key={index}
                                    className="object-cover animate-pulse w-5 h-5"
                                  />
                                );
                              } else {
                                // For steps yet to be completed, show a gray circle
                                return (
                                  <img
                                    src={CheckmarkGray}
                                    key={index}
                                    className="text-gray-400 my-auto w-4 h-4"
                                  />
                                );
                              }
                            }
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="bg-[#EFEFEF] rounded-md p-5 md:mt-0 sm:mt-5">
                    <Clock value={value} className="mx-auto mt-10" size={200} />
                    <div className="mt-8">
                      {isIntervalRunning && (
                        <h1
                          className="text-lg font-medium text-center mt-5"
                          style={{ fontFamily: "Silka Light" }}
                        >
                          The module will start in{" "}
                          <span
                            className="text-coalColor"
                            style={{ fontFamily: "Silka Bold" }}
                          >
                            {time}{" "}
                          </span>
                          <span
                            className="text-coalColor"
                            style={{ fontFamily: "Silka Bold" }}
                          >
                            seconds
                          </span>
                        </h1>
                      )}
                    </div>
                    <p
                      className="text-coalColor mt-8 text-center mb-10"
                      style={{ fontFamily: "Silka" }}
                    >
                      Please stay on this screen. The timer for your next module
                      has started, and it cannot be paused.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimerScreen;
